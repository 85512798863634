import React, { useContext, useEffect, useState } from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { CoreContext } from '../../../../core/context/CoreContext'
import DetailedItemTemplate from './DetailedItemTemplate'

const useStyles = makeStyles(() => ({
  loader: {
    marginTop: 100,
    textAlign: 'center',
  },
}))

export default function DetailedProductView(props) {
  const itemId = props.match.params.id
  const classes = useStyles()
  const core = useContext(CoreContext)
  const [isLoading, setIsLoading] = useState(true)
  const [itemDetails, setItemDetails] = useState({
    productNo: '',
    description: '',
    colli: '',
    brandNo: '',
    designCollectionNo: '',
    functionGroupNo: '',
    inventoryQuantity: '',
    multiplesQuantity: '',
    orderMultiple: '',
    width: '',
    height: '',
    depth: '',
    inriverId: 0,
    defaultPriceDto: {
      minQuantity: 0,
      grossPrice: 0,
      netPrice: 0,
      discountPercentage: 0,
    },
    priceDtos: [
      {
        minQuantity: 0,
        grossPrice: 0,
        netPrice: 0,
        discountPercentage: 0,
      },
    ],
    dimension: [
      {
        depthCm: 0,
        widthCm: 0,
        heightCm: 0,
        volumeL: 0,
        diameterCm: 0,
        grossWeightKg: 0,
      },
    ],
    giftBox: [
      {
        depthCm: 0,
        widthCm: 0,
        heightCm: 0,
        noOfGiftBoxesPerPallet: 0,
      },
    ],
    brownBox: [
      {
        boxEanCode: 0,
        depthCm: 0,
        widthCm: 0,
        heightCm: 0,
        numberOfItemsInBox: 0,
      },
    ],
  })

  function load() {
    return core.dataStore
      .getProductById(
        core.user.selectedCustomer.accountNo,
        itemId,
        core.user.languageCode,
        core.user.userId,
      )
      .then((res) => {
        if (res.status === 200) {
          setItemDetails(res.data)
          setIsLoading(false)
        } else {
          core.snackbar('warning', res.statusText)
        }
      })
      .catch((e) => {
        core.snackbar('warning', e.toString())
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (core.user.selectedCustomer.accountNo) {
      load()
    }
  }, [core.user.userId, core.user.selectedCustomer.accountNo])

  return isLoading ? (
    <div className={classes.loader}>
      <CircularProgress
        size={30}
        thickness={5}
      />
    </div>
  ) : (
    <DetailedItemTemplate
      isLoading={isLoading}
      item={itemDetails}
    />
  )
}
