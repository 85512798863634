import React, { createContext, useContext, useReducer } from 'react'
import labels from '../assets/feeds/texts.json'

const StoreContext = createContext()
const initialState = {
  //common
  texts: labels,

  //templates
  templates: [],
  selectedTemplate: '',

  //languages
  languages: [],

  //
  templateFieldData: [],
}

const reducer = (state, action) => {
  switch (action.type) {
    // Templates
    case 'SET_TEMPLATES':
      return { ...state, templates: action.payload }
    case 'SET_TEMPLATE':
      return { ...state, selectedTemplate: action.payload }
    // Languages
    case 'SET_LANGUAGES':
      return { ...state, languages: action.payload }

    // TemplatefieldData
    case 'SET_TEMPLATE_FIELD_DATA':
      return { ...state, templateFieldData: action.payload }

    default:
      console.error('oops', action.type)
      return state
  }
}

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  )
}

export const useStore = () => useContext(StoreContext)
