import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'

import Paper from '@material-ui/core/Paper/Paper'
import Popper from '@material-ui/core/Popper/Popper'
import Fade from '@material-ui/core/Fade/Fade'
import ListItem from '@material-ui/core/ListItem/ListItem'
import List from '@material-ui/core/List/List'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'

const styles = (theme) => ({
  root: {
    height: 250,
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(3),
  },
})

class AutoSuggest extends Component {
  handleSelect = (e, r) => {
    e.preventDefault()
    this.props.onSelect(r)
  }

  render() {
    const { anchorEl, open } = this.props
    const id = open ? 'simple-popper' : null

    return (
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        fullWidth
        style={{ zIndex: 100 }}>
        {({ TransitionProps }) => (
          <Fade
            {...TransitionProps}
            timeout={350}>
            <Paper>
              <List
                component='nav'
                style={{ width: anchorEl.clientWidth }}>
                {this.props.items.map((r) => (
                  <ListItem
                    button
                    divider={true}
                    onClick={(e) => this.handleSelect(e, r)}
                    key={r.id}>
                    <ListItemText primary={this.props.primary(r)} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>
    )
  }
}

export default withStyles(styles)(AutoSuggest)
