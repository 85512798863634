import React, { useContext, useEffect, useState } from 'react'
//Core
import { CoreContext } from '../../../../core/context/CoreContext'
import { BasketContext } from '../../../../core/context/BasketContext'
import { CheckoutContext } from '../../context/CheckoutContext'

//MUI
import Grid from '@material-ui/core/Grid/Grid'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import OrderDetails from './components/OrderDetails'
import Confirmation from './components/Confirmation'
import DetailedOrderView from '../Orders/DetailedOrderView'
import TranslationLabel from '../../../../core/components/TranslationLabel'

function BasketPage(props) {
  const { match } = props
  const core = useContext(CoreContext)
  const basketModel = useContext(BasketContext)
  const checkout = useContext(CheckoutContext)
  const [activeStep] = useState(getActiveStep)
  useEffect(() => {
    core.setTitles(core.dictionary.getLabel('checkout'))
  }, [])

  function getSteps() {
    return [
      <TranslationLabel name='orderDetails' />,
      <TranslationLabel name='confirmation' />,
      <TranslationLabel name='receipt' />,
    ]
  }

  function getActiveStep() {
    switch (match.path) {
      case '/sales/basket':
      case '/fair-app/basket':
        return 0 // details
      case '/sales/basket/confirmation':
      case '/fair-app/basket/confirmation':
        return 1
      case '/sales/basket/receipt':
      case '/fair-app/basket/receipt':
        return 2
      default:
        return 'Unknown step'
    }
  }

  function advanceStep() {
    switch (activeStep + 1) {
      case 1:
        if (basketModel.basketType == 0) {
          core.history.push('/sales/basket/confirmation')
        } else if (basketModel.basketType == 1) {
          core.history.push('/fair-app/basket/confirmation')
        }
        break
      case 2:
        if (basketModel.basketType == 0) {
          core.history.push('/sales/basket/receipt')
        } else if (basketModel.basketType == 1) {
          core.history.push('/fair-app/basket/receipt')
        }
        break
      default:
        if (basketModel.basketType == 0) {
          core.history.push('/sales/basket') // details
        } else if (basketModel.basketType == 1) {
          core.history.push('/fair-app/basket')
        }
    }
  }

  function goBack() {
    checkout.setOrderDetailsValidated(false)
    if (basketModel.basketType == 0) {
      core.history.push('/sales/basket')
    } else if (basketModel.basketType == 1) {
      core.history.push('/fair-app/basket')
    }
  }

  function getCurrentStep() {
    switch (activeStep) {
      case 0:
        return <OrderDetails advanceStep={advanceStep} />
      case 1:
        return (
          <Confirmation
            advanceStep={advanceStep}
            goBack={goBack}
          />
        )
      case 2:
        return (
          <DetailedOrderView
            isReceipt
            salesId={getOrderId()}
          />
        )
      default:
        return 'Unknown step'
    }
  }

  function getOrderId() {
    let rs = localStorage.getItem('lastOrderId')
    if (!rs) return undefined
    else return rs
  }

  return (
    // <CheckoutProvider>
    <>
      <Grid
        container
        direction='row'
        alignItems='stretch'
        justify='center'
        spacing={1}>
        <Grid
          item
          xs={12}
          md={10}
          lg={8}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel>
            {getSteps().map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
      {getCurrentStep()}
    </>
    // </CheckoutProvider>
  )
}

export default BasketPage
