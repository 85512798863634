import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  palette: {
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    primary: {
      light: '#919de6',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff5f52',
      main: '#c62828',
      dark: '#8e0000',
      contrastText: '#ffffff',
    },
    edit: {
      main: '#00ecd9',
    },
    appBar: {
      background: '#F5F5F5',
      color: '#737373',
    },
    background: {
      default: 'white',
    },
    button: {
      primary: '#747474',
      secondary: '#747474',
      add: '#097E15',
      remove: 'red',
      update: 'orange',
    },
  },
})
