import React, { useContext, useEffect, useState, useRef } from 'react'
import { CoreContext } from '../../../../core/context/CoreContext'
import { makeStyles, Button, TextField } from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import TableChartIcon from '@material-ui/icons/TableChart'
import { saveAsCsv } from '../../../../shared/SaveAsCsv'
import ValidateEmail from '../../../../shared/EmailValidation'

import BackButton from '../../components/BackButton'
import PrintButton from '../../components/PrintButton'
import ReactToPrint from 'react-to-print'

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #e0e0e0',
    backgroundColor: '#F5F5F5',
    overflowX: 'auto',
    padding: 30,
    paddingTop: 10,
    maxWidth: '1042px',
    margin: 'auto',
  },
}))

const emailSendingStatusEnum = {
  SENDING: 'SENDING',
  SENT: 'SENT',
  ERROR: 'ERROR',
  INITIAL: null,
}

function RestOrdersView(props) {
  const { SENDING, SENT, ERROR, INITIAL } = emailSendingStatusEnum
  const classes = useStyles()
  const core = useContext(CoreContext)
  const [isLoading, setIsLoading] = useState(true)
  const [showEmailField, setShowEmailField] = useState(false)
  const [email, setEmail] = useState('')
  const [emailSentStatus, setEmailSentStatus] = useState(INITIAL)
  const [restOrders, setRestOrders] = useState()
  const componentRef = useRef(null)
  const [highlight, setHighlight] = useState(false)

  useEffect(() => {
    setEmail(core.user.selectedCustomer.email)
  }, [core.user.selectedCustomer.email])

  useEffect(() => {
    if (core.user.selectedCustomer.accountNo) {
      core.dataStore
        .getBackOrdersHtml(
          core.user.selectedCustomer.accountNo,
          core.user.language,
        )
        .then((res) => {
          document.getElementById('order').innerHTML = res.data
        })
        .catch((err) => setIsLoading(false))
        .finally(() => {
          setIsLoading(false)
        })

      core.dataStore
        .getBackOrders(core.user.selectedCustomer.accountNo)
        .then((res) => setRestOrders(res.data))
    }
  }, [core.user.selectedCustomer.accountNo, core.user.language])

  var emailValidated = false
  const displayWarning = (x) => (x === undefined || x.length < 3 || !emailValidated) && highlight ? true : false

  const sendEmail = () => {
    if (ValidateEmail(email)) {
      emailValidated = true
      setHighlight(false)

      setEmailSentStatus(SENDING)
      core.dataStore
        .emailRestOrders(
          email,
          core.user.language,
          core.user.selectedCustomer.accountNo,
        )
        .then(() => setEmailSentStatus(SENT))
        .catch((err) => setEmailSentStatus(ERROR))
    } else {
      core.snackbar('warning', 'Wrong email format!')
      emailValidated = false
      setHighlight(true)
    }
  }

  const saveCsv = () => {
    if (!restOrders) return
    const rows = []
    //first row is headers
    rows[0] = [
      '',
      `${core.dictionary.getLabel('backOrders')} ${
        core.user.selectedCustomer.accountNo
      }`,
    ]
    rows[1] = [
      'itemId',
      'itemDescription',
      'expectedStockDate',
      'qty',
      'quantityOutstanding',
      'amount',
      'orderNumber',
      'customerRef',
    ].map((el) => core.dictionary.getLabel(el))
    restOrders.forEach((productLine, i) => {
      const {
        itemId,
        itemDescription,
        confirmedDeliveryDate,
        quantityOrdered,
        quantityOutstanding,
        amount,
        salesId,
        customerRef,
      } = productLine
      rows[i + 2] = [
        itemId,
        itemDescription,
        confirmedDeliveryDate.split('T')[0],
        quantityOrdered,
        quantityOutstanding,
        amount.toLocaleString('da-DK'),
        salesId,
        customerRef,
      ]
    })

    saveAsCsv(
      rows.map((el) => el.join(';')).join('\n'),
      `${core.dictionary.getLabel('backOrders')} ${
        core.user.selectedCustomer.accountNo
      }`,
    )
  }

  const EmailField = (
    <>
      <TextField
        style={{ paddingTop: 6, marginLeft: 10, marginRight: 5, width: 250 }}
        size='small'
        autoFocus
        error={displayWarning(email)}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        variant='outlined'
        onClick={() => sendEmail()}>
        <TranslationLabel name='sendEmail'></TranslationLabel>
      </Button>
    </>
  )

  const renderEmailStatus = () => {
    switch (emailSentStatus) {
      case SENT:
        return (
          <>
            <CheckCircleOutlineOutlinedIcon
              style={{ color: 'green', verticalAlign: 'middle', margin: 5 }}
            />
            <span style={{ fontSize: '16px' }}>
              <TranslationLabel name='emailSent' />
            </span>
          </>
        )
      case SENDING:
        return (
          <CircularProgress
            style={{
              height: 20,
              width: 20,
              margin: 5,
              verticalAlign: 'middle',
            }}
          />
        )
      case ERROR:
        return (
          <>
            <ErrorOutlineIcon
              style={{
                height: 20,
                width: 20,
                margin: 5,
                verticalAlign: 'middle',
                color: 'red',
              }}
            />
            <span style={{ fontSize: '16px' }}>
              <TranslationLabel name='emailError' />
            </span>
          </>
        )
      case INITIAL:
      default:
        return null
    }
  }

  return (
    <div className={classes.root}>
      <div
        style={{
          margin: 'auto',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <BackButton
          path={props.isFairApp ? '/fair-app/orders' : '/sales/orders'}
        />
        <div>
          <ReactToPrint
            trigger={() => <PrintButton />}
            content={() => componentRef.current}
          />
          <Button onClick={saveCsv}>
            <TableChartIcon style={{ marginRight: '10px' }} />
            <TranslationLabel name='saveCsv' />
          </Button>
          {showEmailField ? (
            <>
              {EmailField} {renderEmailStatus()}
            </>
          ) : (
            <Button onClick={() => setShowEmailField(true)}>
              <MailOutlineIcon style={{ marginRight: '10px' }} />
              <TranslationLabel name='emailOrder' />
            </Button>
          )}
        </div>
      </div>
      <div
        style={{ backgroundColor: 'white' }}
        ref={componentRef}
        id='order'
      />
    </div>
  )
}

export default RestOrdersView
