import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

//Components
import SignIn from '../../apps/sales-web-app/components/ui/SignIn'

//Material UI
import withStyles from '@material-ui/core/styles/withStyles'
import { CoreContext } from '../context/CoreContext'
import { findApp } from '../settings/hubRoutes'
import History from '../settings/history'

const styles = (theme) => ({
  main: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundColor: '#312926',
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundImage: 'url(/images/login-background.jpg)',
  },
  root: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
})

export function LoginPage(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [loginForm, setLoginForm] = useState({ userName: '', password: '' })
  const [canSubscribe, setCanSubscribe] = useState(true)
  const { classes } = props
  const core = useContext(CoreContext)

  function handleSetUser(value) {
    setLoginForm({ ...loginForm, userName: value })
  }

  function handleSetPassword(value) {
    setLoginForm({ ...loginForm, password: value })
  }

  function handleSubmit() {
    setIsLoading(true)
    core.authenticateLogin(loginForm).catch(() => {
      if (canSubscribe) {
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    if (core.isAuthenticated && core.user.userSetting.apps.length > 0) {
      let rs = findApp(core.user.userSetting.primaryAppId)
      History.push(rs.path)
    }
    return () => {
      setCanSubscribe(false)
    }
  }, [core])

  return (
    <main className={classes.main}>
      <div className={classes.root}>
        <SignIn
          isLoading={isLoading}
          loginForm={loginForm}
          setUser={handleSetUser}
          setPassword={handleSetPassword}
          submit={handleSubmit}
          //status      = {status}
          //disabled    = {core.sessionModel.isBlocked}
          //attempts    = {core.sessionModel.loginAttempts}
          //timeOut     = {core.sessionModel.sessionTimeOut}
        />
      </div>
    </main>
  )
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LoginPage)
