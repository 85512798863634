import React, { useContext, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import ShoppingBasket from '@material-ui/icons/ShoppingBasket'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'

import NavigationDrawer from '../../../core/components/navigation/NavigationDrawer'
import Topbar from '../../../core/components/navigation/Topbar'
import MiniBasket from '../../sales-web-app/pages/Basket/components/MiniBasket'

import { CoreContext } from '../../../core/context/CoreContext'
import { BasketContext } from '../../../core/context/BasketContext'
import GetNavItems from '../config/navigation'

const useStyles = makeStyles((theme) => ({
  facebook2: {
    color: '#6798e5',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
}))

function Navigation(props) {
  const classes = useStyles()
  const core = useContext(CoreContext)
  const basket = useContext(BasketContext)
  const [open, setOpen] = useState(false)

  function handleClickMiniBasket(val) {
    setOpen(val)
  }

  return (
    <>
      <Topbar {...props}>
        <IconButton
          color='inherit'
          onClick={() => handleClickMiniBasket(true)}>
          {!basket.isLoading ? (
            <Badge
              badgeContent={basket.count}
              color='secondary'>
              <ShoppingBasket />
            </Badge>
          ) : (
            <CircularProgress
              variant='indeterminate'
              disableShrink
              className={classes.facebook2}
              size={24}
              thickness={4}
            />
          )}
        </IconButton>
      </Topbar>

      <NavigationDrawer
        upperItems={GetNavItems().upperNavigation}
      />

      <MiniBasket
        open={open}
        onClick={handleClickMiniBasket}
      />
    </>
  )
}

export default Navigation
