import React, { useContext, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import CardMedia from '@material-ui/core/CardMedia'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import ApiUrls from '../../core/data/ApiUrls'
import { CoreContext } from '../../core/context/CoreContext'
import { findApp } from '../../core/settings/hubRoutes'
import TranslationLabel from '../../core/components/TranslationLabel'

import hubpic1 from '../../assets/images/hubpic1.jpg'
import hubpic2 from '../../assets/images/hubpic2.jpg'
import hubpic7 from '../../assets/images/hubpic7.jpg'
import hubpic9 from '../../assets/images/hubpic9.jpg'
import hubpic12 from '../../assets/images/hubpic12.jpg'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  cardRoot: {
    width: '100%',
  },
  media: {
    height: 140,
  },
  title: {
    padding: '5px',
    fontSize: '1.4rem',
    margin: '0',
    fontWeight: '400',
    textAlign: 'center',
  },
}))

const images = {
  2: hubpic1,
  6: hubpic2,
  7: hubpic7,
  9: hubpic9,
  12: hubpic12,
}

export default function HubPortal() {
  const core = useContext(CoreContext)
  const classes = useStyles()
  const [apps, setApps] = useState([])

  useEffect(() => {
    core.setTitles('Hub')
  }, [core.setTitles])

  useEffect(() => {
    const userApps = core.getUserApps()
    core.dataStore.getApps().then((res) => {
      setApps(
        res.data.filter((app) => app.id !== 1 && userApps.includes(app.id)),
      )
    })
  }, [])

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={3}>
        {apps.length === 0 ? (
          <Grid item>
            <TranslationLabel name='noAppsRegistered' />
          </Grid>
        ) : (
          apps.map((v) => {
            var app = findApp(v.id)

            return (
              <Grid
                item
                key={v.id}
                xs={12}
                sm={6}
                md={4}>
                {app.id !== '' ? (
                  <Card
                    style={{ position: 'relative' }}
                    className={classes.cardRoot}>
                    <Link to={app.path}>
                      <CardActionArea>
                        <CardMedia
                          component='img'
                          height='150'
                          image={
                            v.backgroundImage !== '' &&
                            v.backgroundImage != null
                              ? ApiUrls.hubImageUrl + v.backgroundImage
                              : images[v.id]
                          }
                        />
                        <h1 className={classes.title}>
                          {<TranslationLabel name={'app' + v.id} />}
                        </h1>
                      </CardActionArea>
                    </Link>
                  </Card>
                ) : (
                  <></>
                )}
              </Grid>
            )
          })
        )}
      </Grid>
    </div>
  )
}
