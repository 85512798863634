import React, { useState, useEffect, useContext } from 'react'
import { CoreContext } from '../../../context/CoreContext'
import TranslationLabel from '../../../components/TranslationLabel'
import { Typography, Box } from '@material-ui/core'
import FormSection from '../../../components/forms/FormSection'
import FormHeader from '../../../components/forms/FormHeader'
import MasterDataDates from './MasterDataDates'
import MasterDataAvailableDateSettings from './MasterDataAvailableDateSettings'

export default function MasterDataSettings(props) {
  const core = useContext(CoreContext)
  const [standardTemplates, setStandardTemplates] = useState([])

  useEffect(() => {
    core.dataStore.getMasterDataStandardTemplates().then((res) => {
      setStandardTemplates(res.data)
    })
  }, [])

  return (
    <>
      <FormSection>
        <FormHeader title={<TranslationLabel name='MasterDataAvailableDateSettings' />} />
        <MasterDataAvailableDateSettings />
      </FormSection>
      <FormSection>
        <FormHeader title={<TranslationLabel name='masterDataDates' />} />
        <MasterDataDates />
        <FormSection>
          <FormHeader
            title={<TranslationLabel name='standardTemplatesHeadline' />}
          />
          {standardTemplates.map((template) => (
            <Box style={{ padding: '4px', borderBottom: '1px solid grey' }}>
              <Typography
                style={{ display: 'inline-block', minWidth: '600px' }}>
                {template.name}
              </Typography>
              <Typography style={{ display: 'inline-block' }}>
                {template.defaultLanguageCode}
              </Typography>
            </Box>
          ))}
        </FormSection>
      </FormSection>
    </>
  )
}
