import React, { useContext, useState } from 'react'
import { CoreContext } from '../context/CoreContext'
import { makeStyles, Paper } from '@material-ui/core'
import OutlinedInputField from '../../apps/sales-web-app/components/ui/OutlinedInputField'
import Button from '@material-ui/core/Button'
import TranslationLabel from '../components/TranslationLabel'
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress'
import ValidateEmail from '../../shared/EmailValidation'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {},
}))
export default function UserPasswordResetPage(props) {
  const core = useContext(CoreContext)
  const classes = useStyles()

  const [email, setEmail] = useState('')
  const [status, setStatus] = useState('')
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)

  let requesterId = core.user.userId

  function handleChange(evt) {
    setEmail(evt.target.value)
  }

  function handleSubmit() {
    if (email === '') {
      setStatus('E-mail is empty')
      return
    }

    if (!ValidateEmail(email)) {
      setStatus('E-mail is not valid!')
      return
    }
    setLoading(true)
    core.hubStore
      .requestResetPassword(email)
      .then((res) => {
        if (res.status === 200) {
          setStep(2)
          setStatus('')
        }
      })
      .catch((e) => {
        console.log(e.response)
        if (e.response) {
          setStatus('Something went wrong.')
        }
        core.snackbar('warning', e.response.status)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className={classes.root}>
      <div style={{ textAlign: 'center' }}>
        <h2>
          <TranslationLabel name='passwordResetTitle' />
        </h2>
        {status ? <p style={{ color: 'red' }}>{status}</p> : null}
      </div>

      {step === 1 ? (
        <Paper className={classes.paper}>
          {loading ? <LinearProgress /> : null}
          <OutlinedInputField
            label='Enter e-mail'
            error={status !== ''}
            onChange={handleChange}
          />
          <Button
            variant='contained'
            color='primary'
            onClick={handleSubmit}
            fullWidth>
            <TranslationLabel name='submit' />
          </Button>
        </Paper>
      ) : (
        <TranslationLabel name='passwordResetStep2' />
      )}
    </div>
  )
}
