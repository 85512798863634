import React, { useContext, useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import classNames from 'classnames'

import { ComponentRoute } from '../components/ProtectedRoutes'
import AdminPage2 from './AdminPage'
import Feedback from './Feedback'
import { CoreContext } from '../context/CoreContext'
import HubPortal from '../../apps/hub-portal/HubPortal'
import HubHelpPage from './../components/HelpPage'

import GetAdminNav from '../admin/adminNav'
import UserSettingsPage from './UserSettingsPage'
import Topbar from '../components/navigation/Topbar'
import NavigationDrawer from '../components/navigation/NavigationDrawer'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    height: '100%',
    backgroundColor: 'white',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 240,
  },
  contentStatic: {
    marginLeft: 70,
  },
}))

export default function HubDashboard(props) {
  const core = useContext(CoreContext)
  const classes = useStyles()
  const matches = useMediaQuery(core.theme.breakpoints.up('sm'))

  return (
    <>
      <Topbar
        {...props}
        expandDrawer={core.useSetIsExpanded}
        isExpanded={core.isExpanded}></Topbar>

      <NavigationDrawer
        upperItems={GetAdminNav().upperNavigation}></NavigationDrawer>

      <main
        className={classNames(classes.content, {
          [classes.contentShift]: core.isExpanded && matches,
          [classes.contentStatic]: !core.isExpanded && matches,
        })}>
        <div className={classes.toolbar} />
        <Route
          exact
          path='/hub'
          render={() => <HubPortal />}
        />
        <Route
          exact
          path='/hub/settings'
          render={() => <UserSettingsPage />}
        />
        <Route
          exact
          path='/hub/help'
          render={() => <HubHelpPage appId={1} />}
        />
        <Route
          exact
          path='/hub/feedback'
          render={() => <Feedback />}
        />

        <ComponentRoute
          path='/hub/admin'
          core={core}
          component={AdminPage2}
          componentId={11}
        />
      </main>
    </>
  )
}
