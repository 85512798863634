import { useContext, useState } from 'react'
import { CoreContext } from '../../../../core/context/CoreContext'
import FindNextShippingDate from '../../libraries/FindNextShippingDate'

export function useCheckoutModel() {
  const defaultOrderDetails = {
    hasNewAddress: false,

    deliveryAddressId: '',
    deliveryName: '',
    deliveryStreetName1: '',
    deliveryStreetName2: '',
    deliveryZipCode: '',
    deliveryCity: '',
    deliveryCountry: '',

    shippingDate: FindNextShippingDate(new Date()).toJSON(),
    customerRef: '',
    requisitionNo: '',
    attention: '',
    emailForTracking: '',
    mobilePhoneNo: '',
    comment: '',
  }

  const [isLoading, setIsLoading] = useState(true)
  const [orderDetailsValidated, setOrderDetailsValidated] = useState(false)
  const [orderDetails, setOrderDetails] = useState(defaultOrderDetails)
  const [addressFormLocked, setAddressFormLocked] = useState(true)

  const core = useContext(CoreContext)

  function validateOrderDetails(formData, callback) {
    return core.dataStore
      .validateOrder(formData)
      .then((x) => {
        if (x.status === 200 && x.data) {
          setOrderDetails(x.data)
          setOrderDetailsValidated(true)
          if (typeof callback === 'function') {
            callback()
          }
        }
      })
      .catch((error) => core.snackbar('warning', error.toString()))
      .finally(() => {
        setIsLoading(false)
      })
  }

  function resetOrderDetails(params) {
    setOrderDetails(defaultOrderDetails)
    setOrderDetailsValidated(false)
  }

  return {
    isLoading,
    setIsLoading,
    addressFormLocked,
    setAddressFormLocked,
    orderDetails,
    setOrderDetails,
    validateOrderDetails,
    orderDetailsValidated,
    setOrderDetailsValidated,
    resetOrderDetails,
  }
}
