import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { CoreContext } from '../../../../core/context/CoreContext'
import BackIcon from '@material-ui/icons/ArrowBack'
import FieldItemHeader from './FieldItemHeader'
import InriverImage from '../../../../core/components/InriverImage'
import TableView from '../../components/TableView'

const useStyles = makeStyles((theme) => ({
  fillHeight: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  detailedItemRoot: {
    flexGrow: 1,
    height: 'calc(100% - 33px)',
    margin: '-15px',
  },
  detailedImageItem: {
    height: '100%',
    background: '#E5E5E5',
    flexDirection: 'column',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '150px',
      flexGrow: 0,
      flexBasis: '100%',
    },
  },
  detailedImage: {
    maxWidth: '100%',
    maxHeight: '75%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  detailedImageSize: {
    width: '100%',
    height: 'auto',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '150px',
      maxWidth: '150px',
    },
  },
  body: {
    padding: theme.spacing(5),
    backgroundColor: 'white',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
      marginBottom: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  detailedTitle: {
    textAlign: 'left',
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  floatingBackButton: {
    position: 'absolute',
    padding: theme.spacing(1),
  },
  barcodeWrapper: {},
  rightColumn: {
    height: '100%',
    overflowY: 'scroll',
  },
  cta: {
    marginLeft: -10,
    marginRight: -10,
  },
}))

export default function DetailedItemTemplate(props) {
  const classes = useStyles()
  const core = useContext(CoreContext)

  const { item: product } = props

  function handleClickBack() {
    core.history.goBack()
  }

  return (
    <div className={classes.detailedItemRoot}>
      <div className={classes.floatingBackButton}>
        <Button
          className={classes.button}
          onClick={handleClickBack}>
          <BackIcon className={classes.btnIcon} />
          <TranslationLabel name='back' />
        </Button>
      </div>

      <Grid
        container
        spacing={0}
        className={classes.fillHeight}
        direction='row'>
        <Grid
          item
          className={classes.detailedImageItem}
          xs={12}
          md={6}
          lg={7}>
          <div className={classes.detailedImage}>
            <div className={classes.imageContainer}>
              <InriverImage
                alt={product.description}
                className={classes.detailedImageSize}
                productNo={product.productNo}
                showImage={product.showImage}
                displayConfig='SalesSite_Large'
              />
            </div>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          lg={5}
          className={classes.rightColumn}>
          <div className={classes.body}>
            <TableView
              hideHeader={true}
              hideDetailed={true}
              products={[{
                ...product,
                quantity: product.quantity || product.colli
              }]}
              showRetailPrice={false}
              listKey={'productNo'}
            />

            <div style={{ marginTop: '15px' }}></div>

            <Typography
              variant='h5'
              component='h2'
              className={classes.detailedTitle}>
              {product.description}
            </Typography>

            <FieldItemHeader label='productInformation' />
            {!!product.designerName && (
              <FieldItemRow
                label='designedBy'
                value={product.designerName}
              />
            )}
            <FieldItemRow
              label='recommendedRetailPrice'
              value={product.recommendedRetailPrice}
            />
            <FieldItemRow
              label='itemNumber'
              value={product.productNo}
            />
            <FieldItemRow
              label='ean'
              value={product.ean}
            />
            {core.user.userSetting.showInventory && (
              <FieldItemRow
                label='stock'
                value={product.inventoryText}
              />
            )}
            <div style={{ marginTop: '15px' }}></div>

            <FieldItemHeader label='quantityOptions' />
            {product.priceDtos.map((price, index) => (
              <div key={`p-${index}`}>
                <FieldItemRow
                  label={
                    price.minQuantity === product.colli
                      ? 'salesColliQuantity'
                      : 'quantity'
                  }
                  value={price.minQuantity}
                />
                <FieldItemRow
                  label={
                    price.minQuantity === product.colli
                      ? 'netUnitPrice'
                      : 'SplitColliPrice'
                  }
                  value={price.netPrice}
                />
                {price.discountPercentage !== 0 && (
                  <FieldItemRow
                    label='discountPercentage'
                    value={price.discountPercentage + '%'}
                  />
                )}
                <div style={{ marginTop: '15px' }}></div>
              </div>
            ))}

            <FieldItemHeader label='dimensions' />
            <FieldItemRow
              label='depth'
              value={product.dimension.depthCm + ' cm'}
            />
            <FieldItemRow
              label='width'
              value={product.dimension.widthCm + ' cm'}
            />
            <FieldItemRow
              label='height'
              value={product.dimension.heightCm + ' cm'}
            />
            <FieldItemRow
              label='volumeL'
              value={product.dimension.volumeL + ' L'}
            />
            <FieldItemRow
              label='diameter'
              value={product.dimension.diameterCm + ' cm'}
            />
            <FieldItemRow
              label='grossWeight'
              value={product.dimension.grossWeightKg + ' kg'}
            />
            <div style={{ marginTop: '15px' }}></div>

            <FieldItemHeader label='giftBox' />
            <FieldItemRow
              label='giftBoxesPerPallet'
              value={product.giftBox.noOfGiftBoxesPerPallet}
            />

            <FieldItemRow
              label='depth'
              value={product.giftBox.depthCm + ' cm'}
            />
            <FieldItemRow
              label='width'
              value={product.giftBox.widthCm + ' cm'}
            />
            <FieldItemRow
              label='height'
              value={product.giftBox.heightCm + ' cm'}
            />
            <div style={{ marginTop: '15px' }}></div>

            <FieldItemHeader label='brownBox' />
            <FieldItemRow
              label='itemsPerBrownBox'
              value={product.brownBox.numberOfItemsInBox}
            />

            <FieldItemRow
              label='ean'
              value={product.brownBox.boxEanCode}
            />
            <FieldItemRow
              label='depth'
              value={product.brownBox.depthCm + ' cm'}
            />
            <FieldItemRow
              label='width'
              value={product.brownBox.widthCm + ' cm'}
            />
            <FieldItemRow
              label='height'
              value={product.brownBox.heightCm + ' cm'}
            />
            <div style={{ marginTop: '15px' }}></div>

          </div>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles2 = makeStyles((theme) => ({
  fieldItemName: {
    display: 'flex',
    width: '50%',
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  fieldItemValue: {
    display: 'flex',
    flex: 1,
  },
  exp: {
    display: 'flex',
    marginBottom: 10,
  }
}))
export function FieldItemRow(props) {
  const classes = useStyles2()
  const { label, name, value } = props

  return (
    <div className={classes.exp}>
        <div className={classes.fieldItemName}>
          {label ? <TranslationLabel name={label} /> : { name }}
        </div>
        <div className={classes.fieldItemValue}>{value}</div>
    </div>
  )
}
