import React from 'react'
import TranslationLabel from '../../../core/components/TranslationLabel'

export default function () {
  return (
    <a
      target='_blank'
      rel='noopener noreferrer'
      style={{ fontSize: 16, color: 'blue', textAlign: 'center', margin: 16 }}
      href='https://imagebank.rdg.dk/'>
      <TranslationLabel name='billedeBankLink' />
    </a>
  )
}
