import React, { useContext, useState, useEffect } from 'react'
import { CoreContext } from '../../../../core/context/CoreContext'
import { ReturnServiceContext } from '../../context/ReturnServiceContext'
import { ProductsContext } from '../../context/ProductsContext'
import PageTitle from '../../components/PageTitle'
import TranslationLabel, { translationLink } from '../../../../core/components/TranslationLabel'
import {
  LinearProgress,
  makeStyles,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import InriverImage from '../../../../core/components/InriverImage'
import { useDebounce } from 'use-lodash-debounce'
import { CircularProgress } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle'
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  listImageContainer: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    width: 200,
    marginBottom: 0,
  },
  img: {
    maxHeight: '100%',
    maxWidth: '100%',
    display: 'inline-block',
  },
  selectItemText: {
    lineHeight: '60px',
    height: '60px',
    fontSize: '16px',
    verticalAlign: 'bottom',
    marginRight: '20px',
  },
  submitButton: {
    float: 'right',
  },
  hr: {
    opacity: '0.5',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    }
  }
}))

export default function ReturnServicePageForm(props) {
  const classes = useStyles()
  const core = useContext(CoreContext)
  const returnServiceModel = useContext(ReturnServiceContext)
  const productsModel = useContext(ProductsContext)

  const [suggetions, setSuggetions] = useState([]);
  const [entries, setEntries] = useState([]);
  const [value, setValue] = useState('')
  const [srcTxt, setSrcTxt] = useState('')
  const debouncedValue = useDebounce(value, 300)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [uploadErrors, setUploadErrors] = useState([])


  useEffect(() => {
    search(debouncedValue)
  }, [debouncedValue])

  function handleSearchInput(evt) {
    setValue(evt.target.value)
  }

  function search(itemId) {
    if (!itemId || itemId === '' || itemId.length < 4) return
    productsModel.getReturnProductById(itemId).then((products) => {
      if (products) {
        setSuggetions(products)
      } else {
        setSrcTxt('No items found.')
      }
    })
  }

  function add(item) {
    if (!item) {
      return
    }

    setEntries([...entries, item])
    setValue('')
  }

  function UploadRma(e) {
    if (!e.target.files[0]) {
      return
    }

    setUploadLoading(true)
    setEntries([])
    setUploadErrors([])

    core.dataStore.getReturnProductByExcel(
      core.user.selectedCustomer.accountNo,
      core.user.languageCode,
      e
    ).then(res => {

      setTimeout(() => {
        setEntries([...res.data.items])
        setValue('')
        setUploadLoading(false)

        if (res.data.withErrors) {
          setUploadErrors([...res.data.errors])
        }
      }, 500);
    })

    e.target.value = ''
  }

  function validate() {
    var error = false,
    errors = entries.map(item => {
      if (item.returnReasonId == '' || item.returnReasonId == 'null' || item.returnReasonId == null) {
        error = true;
        item.returnReasonIdError = true;
      } else {
        item.returnReasonIdError = false
      }

      if (item.quantity == '' || item.quantity <= 0) {
        error = true;
        item.quantityError = true;
      } else {
        item.quantityError = false;
      }

      console.log(item)

      return item;
    });

    setEntries([...errors]);

    return !error;
  }

  function onSubmit(e) {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    returnServiceModel.multiAdd(entries, () => {
      setEntries([])
      setUploadErrors([])
    })
  }

  const Item = (itemData) => {
    return (
      <div>
        <div
          className={classes.listImageContainer}
          style={{
            height: 65,
            width: 65,
            textAlign: 'center',
            display: 'inline-block',
          }}>
          <InriverImage
            className={classes.img}
            productNo={itemData.productNo}
            showImage={itemData.showImage}
            alt={itemData.description}
          />
        </div>
        <span className={classes.selectItemText}>
          <TranslationLabel name='productNumberShort' />
          {' - '}
          {itemData.productNo}
        </span>
        <span
          className={classes.selectItemText}
          style={{ maxWidth: '300px' }}>
          {itemData.description}
        </span>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.pageHeader}>
        <div style={{marginRight: 'auto'}}>
        <PageTitle
          label='returnServiceForm'
          core={core}
        />
        <Typography variant='subtitle1'>
          {entries.length + ' '}
          <TranslationLabel name='products' />
        </Typography>
        </div>
        <div className={classes.pageHeader}>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={translationLink(core.dictionary, "RMA-Excel-download")}>
            <Typography
              style={{ color: 'blue', textDecoration: 'underline', marginRight: '15px' }}
              variant='subtitle1'
              component='span'>
              <TranslationLabel
                type='link'
                name={"RMA-Excel-download"}
              />
            </Typography>
          </a>

          <a
            target='_blank'
            rel='noopener noreferrer'
            href={translationLink(core.dictionary, "RMA-Help")}>
            <Typography
              style={{ color: 'blue', textDecoration: 'underline', marginRight: '15px' }}
              variant='subtitle1'
              component='span'>
              <TranslationLabel
                type='link'
                name={"RMA-Help"}
              />
            </Typography>
          </a>

          <input
            className={classes.input}
            style={{ display: 'none' }}
            id='excel-upload'
            multiple
            type='file'
            onChange={UploadRma}
          />
          <label htmlFor='excel-upload'>
            <Button
              variant='contained'
              color='primary'
              component='span'>
                <span style={{ visibility: uploadLoading ? 'hidden' : 'visible' }} ><TranslationLabel name='fromRMAExcel' /></span>
                <CircularProgress style={{color: "#fff", position: 'absolute', left: 'calc(50% - 10px)', top: "calc(50% - 10px)", width: '20px', height: '20px', display: uploadLoading ? 'inline' : 'none' }} />
            </Button>
          </label>
        </div>
      </div>


      { uploadErrors.length > 0 && (
        <Alert severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setUploadErrors([]);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle><TranslationLabel name="RMA-Excel-Error-Headline" /></AlertTitle>
          <Typography variant='p'><TranslationLabel name="RMA-Excel-Error-SubHeadline" /></Typography>
          <ul style={{padding: '0'}}>
            {uploadErrors.map((item) => {
              return (
                <li><TranslationLabel name="RMA-Excel-Error-line" /> { item.line } <TranslationLabel name="RMA-Excel-Error-Ean" /> {item.query} <TranslationLabel name="RMA-Excel-Error-Not-Found" /></li>
              )
            })}
          </ul>
        </Alert>
      )}

      <Autocomplete
        onChange={(event, newval) => add(newval)}
        value={value}
        options={suggetions}
        onBlur={() => {
          setValue('')
          setSuggetions([])
        }}
        disableClearable
        filterOptions={(x) => x}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.selectItemText)}
        renderOption={(option) => <Item {...option} />}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={handleSearchInput}
            fullWidth
            label={<TranslationLabel name='searchItemNumber' />}
            id='filled-margin-dense'
            variant='outlined'
            autoComplete={'off'}
            style={{ margin: '10px 0px 10px 0px' }}
          />
        )}
      />
      {productsModel.isLoading ? (
        <LinearProgress style={{ margin: '-10px 0px 10px 0px' }} />
      ) : null}
      {srcTxt}

      <Grid
          container
          spacing={0}
          direction={'column'}>
            <form onSubmit={onSubmit}>
            {entries.map((item, index) => {
              return (
                <Grid
                    item
                    xs
                    key={item.productNo}>
                  <div className={classes.root}>
                    <Grid container>
                      <Grid
                        item
                        xs
                        style={{ maxWidth: '80px' }}>
                        <div
                          className={classes.listImageContainer}
                          style={{
                            height: 65,
                            width: 65,
                            textAlign: 'center',
                          }}>
                          <InriverImage
                            className={classes.img}
                            productNo={item.productNo}
                            showImage={item.showImage}
                            alt={item.description}
                          />
                        </div>
                      </Grid>

                      <Grid
                          style={{ minWidth: '60' }}
                          item
                          xs={4}
                          md={2}>
                        <TextField
                            fullWidth
                            defaultValue={item.productNo}
                            disabled={true}
                            className={classes.margin}
                            variant='outlined'
                            margin='dense'
                          />
                      </Grid>

                      <Grid
                          item
                          xs={5}
                          md={4}>
                        <TextField
                            fullWidth
                            defaultValue={item.description}
                            disabled={true}
                            className={classes.margin}
                            variant='outlined'
                            margin='dense'
                          />
                      </Grid>

                      <Grid
                        item
                        xs
                        style={{ maxWidth: '200px' }}>
                        <FormControl
                          margin='dense'
                          variant='outlined'
                          className={`${classes.formControl}`}>
                          <InputLabel id='return-reason-select-outlined-label'>
                            <TranslationLabel name='selectReturnReason' />
                          </InputLabel>
                          <Select
                            className={classes.margin}
                            labelId='return-reason-select-outlined-label'
                            id='return-reason-select-outlined'
                            defaultValue={item.returnReasonId}
                            onChange={(e) => {
                              entries[index].returnReasonId = e.target.value
                              validate()
                            }}
                            label='Return reason'
                            required>
                            {/* alphabetical returnReasons */}
                            {item?.returnReasons
                              ?.sort((a, b) =>
                                a.label < b.label
                                  ? -1
                                  : Number(a.label > b.label),
                              )
                              .map((r) => (
                                <MenuItem
                                  key={r.id}
                                  value={r.id}>
                                  {r.label}
                                </MenuItem>
                              ))}
                          </Select>
                          <FormHelperText error>
                              { entries[index].returnReasonIdError
                                ? <TranslationLabel name='formFieldIsRequired' />
                                : null}
                            </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid
                          item
                          xs
                          style={{ maxWidth: '100px' }}>
                        <TextField
                            defaultValue={item.quantity}
                            className={classes.margin}
                            label={<TranslationLabel name='quantity' />}
                            onChange={(e) => entries[index].quantity = e.target.value * 1}
                            type='number'
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            margin='dense'
                            required
                          />
                          <FormHelperText error>
                            { entries[index].quantityError
                              ? <TranslationLabel name='formFieldIsRequired' />
                              : null}
                          </FormHelperText>
                      </Grid>
                      <Grid
                          item
                          xs
                          style={{ maxWidth: '40px' }}>
                          <IconButton
                            edge='end'
                            aria-label='Delete'
                            onClick={() => {
                              var e = entries;
                              e.splice(index, 1);
                              setEntries([...e]);
                            }}
                            tabIndex={-1}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                    </Grid>
                    <hr className={classes.hr} />
                  </div>
                </Grid>
              )
            })}
            <Grid
              item
              xs
              key='-1'>
              <Button
                style={{ display: entries.length === 0 ? 'none' : 'block'}}
                type='submit'
                variant='contained'
                color='primary'
                onClick={onSubmit}
                disabled={entries.length === 0}
                className={classes.submitButton}>
                <TranslationLabel name='submit' />
              </Button>
            </Grid>
            </form>
      </Grid>
    </div>
  )
}
