import React, { useState } from 'react'
import {
  Checkbox,
  FormControlLabel,
  makeStyles
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import TranslationLabel from '../../../../core/components/TranslationLabel'

const facetsStyles = makeStyles(() => ({
  facets: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}))

export default function Facets(props) {
  const classes = facetsStyles()
  const { facets, selected, change, hideChosenFacetCategoryCounts } = props
  const brand = facets && facets.find((x) => x.name === 'brand')
  const subCategory = facets && facets.find((x) => x.name === 'subCategory')
  const mainColor = facets && facets.find((x) => x.name === 'mainColor')
  const categorys = facets && facets.find((x) => x.name === 'category')

  // hideChosenFacetCategoryCounts is used to hide counts that are incorrect in fairApp
  // They are only incorrect once a facet in their category is chosen

  return (
    <div className={classes.facets}>
      <Facet
        hideChosenFacetCategoryCounts={hideChosenFacetCategoryCounts}
        facet={brand}
        checked={selected['brand'] || []}
        change={change}>
      </Facet>
      <Facet
        hideChosenFacetCategoryCounts={hideChosenFacetCategoryCounts}
        facet={mainColor}
        checked={selected['mainColor'] || []}
        change={change}
      />
      <Facet
        hideChosenFacetCategoryCounts={hideChosenFacetCategoryCounts}
        facet={categorys}
        checked={selected['category'] || []}
        change={change}
      />
      <Facet
        hideChosenFacetCategoryCounts={hideChosenFacetCategoryCounts}
        facet={subCategory}
        checked={selected['subCategory'] || []}
        change={change}
      />
    </div>
  )
}

const facetStyles = makeStyles((theme) => ({
  facet: {
    position: 'relative',
  },
  btn: {
    'borderRadius': 4,
    'whiteSpace': 'nowrap',
    'textTransform': 'uppercase',
    'display': 'inline-flex',
    'padding': '5px 4px 4px 10px',
    'marginLeft': 5,
    'fontSize': 14,
    'cursor': 'pointer',

    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  btnCount: {
    fontSize: 10,
    display: 'inline-block',
    backgroundColor: 'white',
    padding: '2px 3px 1px',
    borderRadius: 1,
    lineHeight: 1,
    height: 14,
    color: theme.palette.grey[700],
    margin: '2px 3px 0 7px',
  },
  lists: {
    position: 'relative',
    top: 14,
    left: 0,
    zIndex: 10,
  },
  cbList: {
    position: 'absolute',
    width: 310,
    backgroundColor: 'white',
    padding: '6px 0px 15px 15px',
    top: 0,
    left: 0,
    border: '1px solid #EAEAEA',
    height: 410,
  },
  listItems: {
    'maxHeight': 340,
    'overflowY': 'auto',
    '&::-webkit-scrollbar': {
      width: 5,
      height: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey[400],
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.grey[100],
    },
    // For Internet Explorer
    'scrollbarFaceColor': theme.palette.grey[400],
    'scrollbarTrackColor': theme.palette.grey[100],
    'scrollbarShadowColor': theme.palette.grey[400],
    'scrollbarDarkShadowColor': theme.palette.grey[400],
  },
  listItemsExtra: {
    maxHeight: 310,
    overflowY: 'auto',
  },
  cbListExtra: {
    backgroundColor: theme.palette.grey[100],
    left: 249,
    borderLeft: 'none',
  },
  angle: {
    marginTop: -3,
  },
  label: {
    'display': 'block',

    '& .MuiFormControlLabel-label': {
      fontSize: 15,
    },

    '& sup': {
      color: theme.palette.grey[600],
      verticalAlign: 'text-top',
      fontSize: '70%',
    },
  },
  ctaBtn: {
    position: 'absolute',
    top: 405,
    left: 10,
    zIndex: 11,
  },
  cb: {
    padding: '6px 10px',
  },
  titleExtra: {
    textTransform: 'uppercase',
    fontSize: 14,
    margin: '9px 0 5px',
  },
}))

function Facet(props) {
  const classes = facetStyles()
  const [open, setOpen] = useState(false)
  const {
    change,
    facet,
    checked,
    children = null,
    hideChosenFacetCategoryCounts,
  } = props
  const hasChildren = children != null

  function handleToggle() {
    if (open) {
      handleClose()
    } else {
      handleOpen()
    }
  }

  const handleOpen = () => {
    setOpen(true)
    document
      .getElementById('root')
      .addEventListener('click', handleOutsideClick)
  }

  const handleClose = () => {
    setOpen(false)
    document
      .getElementById('root')
      .removeEventListener('click', handleOutsideClick)
  }

  const handleOutsideClick = (e) => {
    let $element = e.target
    let outsideTarget = $element.closest(`#${facet.name}`) === null
    if (outsideTarget) {
      handleClose()
    }
  }

  if (!facet) {
    return null
  }

  return (
    <div
      id={facet.name}
      className={classes.facet}>
      <a
        className={classes.btn}
        onClick={handleToggle}>
        <TranslationLabel name={facet.name}></TranslationLabel>
        {checked.length > 0 && (
          <span className={classes.btnCount}>{checked.length}</span>
        )}
        <ExpandMoreIcon className={classes.angle} />
      </a>
      {open && (
        <div>
          <div className={`${classes.lists}`}>
            <div className={classes.cbList}>
              <div className={classes.listItems}>
                {/* alphabetic sort */}
                {facet.facetValues
                  .sort((a, b) =>
                    a.name < b.name ? -1 : Number(a.name > b.name),
                  )
                  .map((fv) => (
                    <FormControlLabel
                      key={fv.id}
                      className={classes.label}
                      label={
                        <>
                          {fv.name}{' '}
                          {!(
                            hideChosenFacetCategoryCounts && checked.length
                          ) && <sup>({fv.count})</sup>}
                        </>
                      }
                      control={
                        <Checkbox
                          color='primary'
                          checked={checked.indexOf(fv.id) !== -1}
                          icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                          checkedIcon={<CheckBoxIcon fontSize='small' />}
                          className={classes.cb}
                          onChange={(e) => change(facet.name, fv.id)}
                        />
                      }
                    />
                  ))}
              </div>
            </div>
            {checked.length > 0 && hasChildren && children}
          </div>
          <div className={classes.ctaBtn}>
            <Button
              variant='outlined'
              onClick={handleClose}>
              <TranslationLabel name='closeFacetDropdown' />
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
