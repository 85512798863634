import React, { useState, useEffect, useContext } from 'react'
import { CoreContext } from '../../../core/context/CoreContext'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  Checkbox,
} from '@material-ui/core'
import TranslationLabel from '../../../core/components/TranslationLabel'
import EditIcon from '@material-ui/icons/Edit'
const headers = [
  {
    label: 'displayName',
    name: 'displayName',
    type: 'textfield',
  },
  {
    label: 'name',
    name: 'name',
    type: 'default',
  },
  {
    label: 'description',
    name: 'description',
    type: 'textfield',
  },
  {
    label: 'internalField',
    name: 'internal',
    type: 'checkbox',
    disabled: false,
  },
  {
    label: 'multiLanguage',
    name: 'multiLanguage',
    type: 'checkbox',
    disabled: true,
  },
]

export default function DataFields() {
  const core = useContext(CoreContext)
  const [dataFields, setDataFields] = useState()

  const getField = (header, dataField, active, setActive) => {
    if (header.type === 'textfield') {
      return active ? (
        <TextField
          onBlur={(e) => {
            onFieldChange(e.target.value, header.name, dataField)
            setActive(false)
          }}
          style={{ width: header.name === 'description' ? 400 : 200 }}
          fullWidth
          defaultValue={dataField[header.name]}></TextField>
      ) : (
        <Typography
          style={{ width: header.name === 'description' ? 400 : 200 }}>
          {dataField[header.name]}
        </Typography>
      )
    }

    if (header.type === 'default') {
      return <Typography type='subtitle2'>{dataField[header.name]}</Typography>
    }

    if (header.type === 'checkbox') {
      return active ? (
        <Checkbox
          disabled={header.disabled}
          color='primary'
          checked={dataField[header.name]}
          onChange={(e) => {
            onFieldChange(!dataField[header.name], header.name, dataField)
            setActive(false)
          }}
        />
      ) : (
        <Checkbox
          disabled
          color='primary'
          checked={dataField[header.name]}
        />
      )
    }
  }

  useEffect(() => {
    core.masterItemDataStore
      .readTemplateFieldData()
      .then((res) => setDataFields(res.data))
  }, [])

  const onFieldChange = (value, header, dataField) => {
    core.masterItemDataStore
      .updateDataField(dataField.id, {
        description: dataField.description,
        displayName: dataField.displayName,
        internal: dataField.internal,
        [header]: value,
      })
      .then((res) => {
        const newDataFields = dataFields.map((el) => {
          if (el.id === dataField.id) {
            return {
              ...el,
              description: dataField.description,
              displayName: dataField.displayName,
              internal: dataField.internal,
              [header]: value,
            }
          } else {
            return el
          }
        })
        setDataFields(newDataFields)
      })
  }

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650 }}
        aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell />
            {headers.map((header) => (
              <TableCell key={header.label}>
                <TranslationLabel name={header.label} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataFields
            ?.sort((a, b) =>
              a.displayName < b.displayName
                ? -1
                : Number(a.displayName > b.displayName),
            )
            .map((dataField) => (
              <Row
                key={dataField.name + dataField.description}
                dataField={dataField}
                getField={getField}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const Row = ({ dataField, getField }) => {
  const [active, setActive] = useState(false)

  return (
    <TableRow
      key={dataField.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell
        style={{ cursor: 'pointer', color: active ? 'blue' : 'black' }}>
        <EditIcon onClick={() => setActive(!active)} />
      </TableCell>
      {headers.map((header) => (
        <TableCell  key={header.name}>{getField(header, dataField, active, setActive)}</TableCell>
      ))}
    </TableRow>
  )
}
