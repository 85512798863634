import React from 'react'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import { Typography } from '@material-ui/core'

export default function DashboardMessage(props) {
  return (
    <div style={{ padding: 15 }}>
      <Typography type='paragraph'>
        <TranslationLabel name={props.messageLabelName} />
      </Typography>
    </div>
  )
}
