import React, { useContext, useEffect } from 'react'
import { CoreContext } from '../../../../../core/context/CoreContext'
import { CheckoutContext } from '../../../context/CheckoutContext'
import { BasketContext } from '../../../../../core/context/BasketContext'

import { useHistory } from 'react-router-dom'

import { Grid, makeStyles } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import TranslationLabel from '../../../../../core/components/TranslationLabel'
import Button from '@material-ui/core/Button'
import BasketListView from './BasketListView'
import BasketSummary from './BasketSummary'
import { FieldItemRow } from '../../Products/DetailedItemTemplate'
import { format } from 'date-fns'

const useStyles = makeStyles((theme) => ({
  btns: {
    display: 'flex',
    marginTop: 30,
    padding: '8px 0',
  },
  btn: {
    width: 'auto',
    minWidth: 120,
    height: 40,
    marginRight: 10,
  },
  btnNext: {
    'color': 'white',
    'backgroundColor': 'black',
    'width': '100%',
    'marginRight': 0,
    'marginLeft': 'auto',
    'maxWidth': 250,

    '&:hover': {
      backgroundColor: theme.palette.grey[800],
    },
  },
}))

function Confirmation(props) {
  const history = useHistory()
  const core = useContext(CoreContext)
  const checkout = useContext(CheckoutContext)
  const basket = useContext(BasketContext)
  const classes = useStyles()

  const { goBack, advanceStep } = props

  function checkoutFlow() {
    basket.submit(checkout.orderDetails, function (orderId) {
      checkout.resetOrderDetails()
      advanceStep()
    })
  }

  const handleBasketPreviewClick = () => {
    if (basket.basketType == 0) {
      core.history.push('/sales/basketOverview')
    } else if (basket.basketType == 1) {
      core.history.push('/fair-app/basketOverview')
    }
  }

  useEffect(() => {
    if (core.isInitialized) {
      //redirect if no customer accountNo set,
      //could be if basket is accessed directly from browser refresh
      if (!core.user.selectedCustomer.accountNo) {
        if (basket.basketType == 0) {
          core.history.push('/sales')
        } else if (basket.basketType == 1) {
          core.history.push('/fair-app')
        }
      }
    }
  }, [])

  return (
    <Grid
      container
      direction='row'
      justify='center'>
      <Grid
        item
        xs={12}
        md={9}
        lg={6}>
        <h1>
          2. <TranslationLabel name='confirmation' />
        </h1>
        <h2>
          <TranslationLabel name='informations' />
        </h2>
        <FieldItemRow
          expanded='true'
          label='deliveryname'
          value={checkout.orderDetails.deliveryName}
        />
        <FieldItemRow
          expanded='true'
          label='deliveryStreetName1'
          value={checkout.orderDetails.deliveryStreetName1}
        />
        <FieldItemRow
          expanded='true'
          label='deliveryStreetName2'
          value={checkout.orderDetails.deliveryStreetName2}
        />
        <FieldItemRow
          expanded='true'
          label='deliveryZipCode'
          value={checkout.orderDetails.deliveryZipCode}
        />
        <FieldItemRow
          expanded='true'
          label='deliveryCity'
          value={checkout.orderDetails.deliveryCity}
        />
        <FieldItemRow
          expanded='true'
          label='deliveryCountry'
          value={checkout.orderDetails.deliveryCountry}
        />

        <FieldItemRow
          expanded='true'
          label='customerRef'
          value={checkout.orderDetails.customerRef}
        />
        {/* <FieldItemRow
            expanded="true"
            label="requisitionNo"
            value={checkout.orderDetails.requisitionNo}
          /> */}
        <FieldItemRow
          expanded='true'
          label='attention'
          value={checkout.orderDetails.attention}
        />
        <FieldItemRow
          expanded='true'
          label='mobilePhoneNo'
          value={checkout.orderDetails.mobilePhoneNo}
        />
        <FieldItemRow
          expanded='true'
          label='emailForTracking'
          value={checkout.orderDetails.emailForTracking}
        />
        <FieldItemRow
          expanded='true'
          label='comment'
          value={checkout.orderDetails.comment}
        />
        <FieldItemRow
          expanded='true'
          label='desiredShippingdate'
          value={format(
            new Date(checkout.orderDetails.shippingDate),
            'dd-MM-yyyy',
          )}
        />

        <h2 style={{ marginTop: 30 }}>
          <TranslationLabel name='basket' />
        </h2>
        <BasketListView
          isMiniBasket={false}
          disabled={true}
        />
        <BasketSummary
          showMarkup
          hideEmptyBasketButton
        />

        <div className={classes.btns}>
          <Button
            variant='contained'
            disableElevation
            className={classes.btn}
            onClick={goBack}>
            <ArrowBackIcon
              fontSize='small'
              style={{ marginRight: 5 }}
            />
            <TranslationLabel name='back' />
          </Button>
          <Button
            onClick={handleBasketPreviewClick}
            className={classes.deleteButton}
            color='primary'>
            <TranslationLabel name='seeBasketOverview' />
          </Button>
          <Button
            variant='contained'
            disableElevation
            className={`${classes.btn} ${classes.btnNext}`}
            onClick={checkoutFlow}>
            <TranslationLabel name='completeOrder' />
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

export default Confirmation
