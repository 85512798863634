import React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
} from '@material-ui/core'

function Message(props) {
  const [open] = React.useState(true)

  return (
    <div>
      {
        {
          info: (
            <Dialog
              open={open}
              onClose={() => props.onCloseMessage()}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'>
              <DialogTitle id='alert-dialog-title'>{props.title}</DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  {props.text}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => props.onCloseMessage()}
                  color='primary'
                  autoFocus>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          ),
        }[props.type]
      }
    </div>
  )
}

export default Message
