import React, { useContext, useEffect, useState, useRef } from 'react'
import {
  Button,
  LinearProgress,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { ListViewItem } from './ListViewItem'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import { Typography } from '@material-ui/core'
import ContentScroller from '../../../sales-web-app/components/ContentScroller'
import EmptyState from '../../../sales-web-app/components/EmptyState'
import { CoreContext } from '../../../../core/context/CoreContext'
import { BasketContext } from '../../../../core/context/BasketContext'
import { ProductsContext } from '../../../sales-web-app/context/ProductsContext'
import findNextAllowedAmount from '../../../sales-web-app/libraries/FindNextAllowedQuantity'
import ProductSearchBar from '../../../sales-web-app/pages/Products/ProductSearchBar'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid/Grid'
import { useHistory } from 'react-router-dom'
import DatePicker from '../../../../shared/DatePicker'

const useStyles = makeStyles((theme) => ({
  productsPageRoot: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: 1,
    margin: 0,
    background: 'white',
    height: '100%',
  },
  stickyloader: {
    margin: 'auto',
    position: 'sticky',
    zIndex: 1,
    width: '100%',
    visibility: 'hidden',
  },
  loaderVisible: {
    visibility: 'visible',
  },
  stickyScrollButton: {
    'position': 'fixed',
    'top': 220,
    'left': '50%',
    'transform': 'translate(-50%)',
    'zIndex': 100,
    'backgroundColor': 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  buttons: {
    margin: '10px 2px',
    marginRight: '10px',
  },
  productsWrapper: {
    flex: '1 1 auto',
  },
  select: {
    width: 200,
  },
  topContainer: {
    marginBottom: 30,
    flew: '0 1 auto',
  },
  leftSideOfBar: {
    width: 420,
    display: 'inline-block',
  },
  rightButtons: {
    display: 'inline-block',
    verticalAlign: 'top',
    float: 'right',
  },
  datePicker: {
    position: 'absolute',
    right: 15,
    top: 9,
    width: 200,
    backgroundColor: 'white',
    borderRadius: 3,
  },
  count: {
    fontSize: 14,
  },
  resetButton: {
    display: 'inline-block',
    border: '1px solid lightgrey',
    marginRight: 5,
    marginBottom: 5,
    borderRadius: 2,
    padding: 5,
  },
  resetButtonText: {
    verticalAlign: 'middle',
  },
  greyedOutResetButtonText: {
    color: theme.palette.grey['500'],
  },
  resetButtonIcon: {
    verticalAlign: 'middle',
    marginLeft: 5,
    width: 15,
    height: 15,
  },
  facets: {
    marginTop: 5,
  },
  shippingDateInfo: {
    display: 'inline-block',
    marginLeft: 10,
  },
  fromDate: {
    margin: 5,
  },
  toDate: {
    margin: 5,
  },
  fixedTop: {
    top: '64px',
    paddingTop: '10px',
    right: 0,
    position: 'fixed',
    width: 'calc(100% - 80px)',
    background: 'white',
    zIndex: 5,
  },
}))

const latestDate = (date1, date2) => {
  return date1 > date2 ? date1 : date2
}

export default function SearchPage(props) {
  const classes = useStyles()
  const core = useContext(CoreContext)
  const basketModel = useContext(BasketContext)
  const productsModel = useContext(ProductsContext)
  const [showScrollToTop, setShowScrollToTop] = useState(false)
  const [selectedProducts, _setSelectedProducts] = useState(
    JSON.parse(localStorage.getItem('selectedProducts') || '[]'),
  )
  const [isLoading, setIsLoading] = useState(false)
  const [showSelected, setShowSelected] = useState(false)

  const today = new Date().toJSON().split('T')[0] + 'T00:00:00'
  const deliveryFrom = productsModel.campaign.deliveryValidFrom
  const shippingStartOrToday = latestDate(today, deliveryFrom)
  const localStorageDate = localStorage.getItem('shippingDate')
  const [shippingDate, _setShippingDate] = useState(
    latestDate(shippingStartOrToday, localStorageDate),
  )
  const [shippingDateError, setShippingDateError] = useState(false)
  const [campaignDrowdownOpen, setCampaignDrowdownOpen] = useState(false)
  const firstRender = useRef(true)
  const history = useHistory()

  const setShippingDate = (date) => {
    localStorage.setItem('shippingDate', date)
    _setShippingDate(date)
  }

  const closeCampaignDropdown = () => {
    setCampaignDrowdownOpen(false)
  }

  const openCampaignDropdown = () => {
    setCampaignDrowdownOpen(true)
  }
  useEffect(() => {
    window.addEventListener('scroll', closeCampaignDropdown)
    return () => {
      window.removeEventListener('scroll', closeCampaignDropdown)
    }
  }, [])

  const setSelectedProducts = (products) => {
    localStorage.setItem('selectedProducts', JSON.stringify(products))
    _setSelectedProducts(products)
  }

  const onSelectCampaign = (e) => {
    e.preventDefault()
    setShowSelected(false)
    setSelectedProducts([])
    const campaign = e.target.value
    productsModel.setCampaign(campaign)
    setShippingDate(latestDate(campaign.deliveryValidFrom, today))
    history.push({
      search: '',
    })
  }

  function getTimeMinusOffset(date) {
    return (
      new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toJSON()
        .split('T')[0] + 'T00:00:00'
    )
  }

  const onShippingDateChange = (date) => {
    if (!date) {
      _setShippingDate('')
      return
    }
    const jsonDate = date.toJSON()
    let newDate
    if (jsonDate) {
      //if jsonDate isnt null it means the date is valid. it can be a day behind with time T22:00:00 because of timezone if filled out by keyboard,
      //or actual date + timestamp if filled out by clicking
      //set it to actual date. check if date is in different timezone by seeing if it ends in 00:00.000Z
      if (jsonDate.split('T')[1].substring(3) === '00:00.000Z') {
        newDate = getTimeMinusOffset(new Date(date))
      } else {
        newDate = jsonDate.split('T')[0] + 'T:00:00:00'
      }

      //is date within allowed window?
      //if it isn't then only set shippingdate in state, don't set invalid date in localStorage
      if (
        productsModel.campaign.deliveryValidTo >= newDate &&
        shippingStartOrToday <= newDate
      ) {
        setShippingDate(newDate)
        setShippingDateError(false)
      } else {
        _setShippingDate(newDate)
        setShippingDateError(true)
      }
    } else {
      _setShippingDate(date)
      _setShippingDate(productsModel.campaign.deliveryValidFrom)
    }
  }

  //define products to show as searchCollection with quantities taken from selectedProducts
  const productsModelProducts = productsModel.searchCollection.products || []
  const products = productsModelProducts.map((product) => {
    const selectedProductInSeachCollection = selectedProducts.find(
      (el) => el.productNo === product.productNo,
    )
    return {
      ...product,
      quantity: selectedProductInSeachCollection
        ? selectedProductInSeachCollection.quantity
        : '',
    }
  })

  const sortCampaigns = (arr) => {
    return arr.sort((a, b) => {
      if (a.name > b.name) return 1
      if (a.name < b.name) return -1
      return 0
    })
  }

  useEffect(() => {
    if (core.user.selectedCustomer.accountNo) {
       if (!firstRender.current) {
         //reset selected products when changing account, but not on normal pageload
         setSelectedProducts([])
       }
      //fetch campaigns list if it is missing
      core.dataStore
        .getCampaigns(core.user.selectedCustomer.accountNo)
        .then((res) => {
          if (res.data) {
            productsModel.setCampaigns(sortCampaigns(res.data))
            productsModel.setCampaign(
              res.data?.find(
                (el) =>
                  el.campaignId ===
                  JSON.parse(localStorage.getItem('campaign') || '{}')
                    .campaignId,
              ) || {},
            )
          }
        })
        .then(() => {
          //once we have the campaigns list we know which campaign we're on, so fetch the products
          productsModel.init()
        })
        .catch(console.log)
    }
    firstRender.current = false
  }, [core.user.selectedCustomer.accountNo, productsModel.campaign.campaignId])

  useEffect(() => {
    const scrollHandler = (ev) => {
      if (window.scrollY > 100) {
        setShowScrollToTop(true)
      } else {
        setShowScrollToTop(false)
      }
    }
    window.document.addEventListener('scroll', scrollHandler)
    return () => window.document.removeEventListener('scroll', scrollHandler)
  }, [])

  function scrollToTop() {
    window.scrollTo(0, 0)
  }

  const showSelectedHandler = () => {
    setShowSelected(!showSelected)
  }

  const addToBasket = () => {
    if (shippingDateError) {
      return
    }
    basketModel.multiAdd(
      selectedProducts.map((el) => {
        return {
          ...el,
          qty: findNextAllowedAmount(el, el.quantity),
          requestedShippingDate: shippingDate,
          campaignId: productsModel.campaign.campaignId,
        }
      }),
      () => {
        setSelectedProducts([])
      },
    )
  }

  const addSingleProductToBasket = (product) => {
    if (shippingDateError) {
      return
    }
    setIsLoading(true)
    basketModel.add(
      {
        itemId: product.productNo,
        qty: Math.max(
          product.quantity || 0,
          Math.max(product.minimumQuantity, product.colli),
        ),
        campaignId: productsModel.campaign.campaignId,
        requestedShippingDate: shippingDate,
      },
      () => {
        setIsLoading(false)
        const indexOfProduct = products.findIndex(
          (el) => el.productNo === product.productNo,
        )
        const newProducts = [...products]
        newProducts[indexOfProduct].quantity = ''
        setSelectedProducts(
          selectedProducts.filter((el) => el.productNo !== product.productNo),
        )
      },
    )
  }

  const onQuantityChange = (productNo, quantity) => {
    //dont fire if qty wasn't changed
    const newQty = products.find((el) => el.productNo === productNo)?.quantity
    if (newQty === quantity || (!newQty && !quantity)) {
      return
    }
    const changingProduct = products.findIndex(
      (el) => el.productNo === productNo,
    )
    const changingSelectedProduct = selectedProducts.findIndex(
      (el) => el.productNo === productNo,
    )

    if (changingProduct + changingSelectedProduct === -2) {
      return console.log('changed quantity on product not in products lists')
    }

    //if product is already in selectedProducts, then change quantity
    if (changingSelectedProduct > -1) {
      let newSelectedProducts = [...selectedProducts]
      if (quantity == '0' || !quantity) {
        newSelectedProducts = newSelectedProducts.filter(
          (el) => el.productNo !== productNo,
        )
      } else {
        newSelectedProducts[changingSelectedProduct].quantity = quantity
      }
      setSelectedProducts(newSelectedProducts)

      //if product is not in selectedProducts, then add it
    } else if (changingProduct > -1 && quantity > 0) {
      const newSelectedProducts = [...selectedProducts]
      newSelectedProducts.push({
        ...products[changingProduct],
        quantity,
      })
      setSelectedProducts(newSelectedProducts)
    }
  }

  const formatDate = (time) => {
    const date = new Date(time)
    window.date = date
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
  }

  const getFacetLabelByCategoryAndId = (category, id) => {
    //searchCollection.facets can exclude facets that are selected, therefore we use allFacets for a complete map of facet labels
    const allFacets = productsModel.allFacets
    const facetsWithCounts = productsModel.searchCollection.facets
    let facetLabel = ''
    if (!allFacets) {
      return
    }
    const cat = allFacets.find((el) => el.name === category)
    const catWithCounts = facetsWithCounts.find((el) => el.name === category)
    if (!cat) {
      return
    }
    const label = cat.facetValues.find((el) => el.id === id)
    let countLabel = catWithCounts.facetValues.find((el) => el.id === id)
    const count = countLabel ? countLabel.count : 0
    //counts removed from labels, as they are not working correctly atm
    if (category === 'news') {
      facetLabel = (
        <span>
          <TranslationLabel name='news' />
        </span>
      )
    } else {
      facetLabel = label && `${label.name}`
    }

    return (
      <>
        <span
          className={
            count ? classes.resetButtonText : classes.greyedOutResetButtonText
          }>

          {facetLabel}
        </span>
        <CloseIcon className={classes.resetButtonIcon} />
      </>
    )
  }

  function getArrayOfFacets(selectedFacetValues) {
    let facets = []
    for (const category in selectedFacetValues) {
      selectedFacetValues[category].forEach((el) => {
        facets.push({
          category,
          value: el,
        })
      })
    }
    return facets
  }

  function handleToggleFacetvalue(facet, facetValue) {
    productsModel.toggleFacetValue(facet, facetValue)
  }

  function reset() {
    productsModel.reset()
  }

  function countLabel() {
    let label = ''
    if (productsModel.searchCollection.products) {
      let count = productsModel.searchCollection.products.length
      let total = productsModel.searchCollection.totalNumberOfResults
      label = (
        <>
          <TranslationLabel name='displaying' /> {` ${count} `}
          <TranslationLabel name='of' /> {` ${total} `}
          <TranslationLabel name='results' />
        </>
      )
    }
    return label
  }

  const query = productsModel.searchCollection.query

  return (
    <div className={classes.productsPageRoot}>

      {/* the width should account for the drawer being open/closed, and since it's position:fixed "100%" won't work */}
      <div
        className={classes.fixedTop}
        style={{
          width: props.drawerExpanded
            ? 'calc(100% - 255px)'
            : 'calc(100% - 80px)',
        }}>
        <div className={classes.topContainer}>
          <div className={classes.leftSideOfBar}>
            <FormControl
              variant='outlined'
              className={classes.select}>
              <InputLabel id='campaignId'>
                <TranslationLabel name='chooseCampaign' />
              </InputLabel>
              <Select
                open={campaignDrowdownOpen}
                onClose={closeCampaignDropdown}
                onOpen={openCampaignDropdown}
                labelId='campaignId'
                value={productsModel.campaign}
                onChange={onSelectCampaign}>

                {productsModel.campaigns.map((campaign) => {
                  return (
                    <MenuItem
                      key={campaign.campaignId}
                      value={campaign}>
                      {campaign.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            {productsModel.campaign.deliveryValidFrom && (
              <div className={classes.shippingDateInfo}>
                <p className={classes.fromDate}>
                  <TranslationLabel name='shippingDateStart' />
                  {formatDate(productsModel.campaign.deliveryValidFrom)}
                </p>
                <p className={classes.toDate}>
                  <TranslationLabel name='shippingDateEnd' />
                  {formatDate(productsModel.campaign.deliveryValidTo)}
                </p>
              </div>
            )}
          </div>
          <div className={classes.rightButtons}>
            <Button
              onClick={showSelectedHandler}
              className={classes.buttons}
              variant='contained'
              color='primary'>
              <TranslationLabel
                name={showSelected ? 'backToSearch' : 'seeSelectedProducts'}
              />
            </Button>
            <Button
              onClick={addToBasket}
              className={classes.buttons}
              variant='contained'
              color='primary'>
              <span>
                <TranslationLabel name='add' /> {`(${selectedProducts.length})`}
              </span>
            </Button>
          </div>
        </div>
        <>
          {productsModel.isInitialized && (
            <div
              style={{
                position: 'relative',
                clear: 'both',
              }}>
              <ProductSearchBar
                hideChosenFacetCategoryCounts
                facets={productsModel.searchCollection.facets}
                query={productsModel.searchCollection.query}
                selected={productsModel.selectedFacetValues}
                change={handleToggleFacetvalue}
                changeQuery={productsModel.handleSearchQueryChange}
              />
              <DatePicker
                autoOk
                size='small'
                disablePast
                variant='inline'
                inputVariant='outlined'
                label={<TranslationLabel name='chooseShippingDate' />}
                maxDate={productsModel.campaign.deliveryValidTo}
                minDate={productsModel.campaign.deliveryValidFrom}
                onChange={onShippingDateChange}
                className={classes.datePicker}
                disabled={!productsModel.campaign}
                value={shippingDate}
              />
            </div>
          )}
          <LinearProgress
            className={`${classes.stickyloader} ${
              productsModel.isLoading && classes.loaderVisible
            }`}>

          </LinearProgress>
          <Grid
            container
            justify='space-between'
            className={classes.middleSection}>
            <Grid
              item
              xs={12}
              className={classes.facets}>

              {(productsModel.getSelecteFacetValuesCount() > 0 || query) && (
                <div>
                  <Button
                    size='small'
                    onClick={reset}
                    className={classes.resetButton}>
                    <span className={classes.resetButtonText}>
                      <TranslationLabel name='resetFilters' />
                      <CloseIcon className={classes.resetButtonIcon} />
                    </span>
                  </Button>
                  {getArrayOfFacets(productsModel.selectedFacetValues).map(
                    (el) => (
                      <Button
                        size='small'
                        key={el.value + el.category}
                        onClick={() =>
                          handleToggleFacetvalue(el.category, el.value)
                        }
                        className={classes.resetButton}>

                        {getFacetLabelByCategoryAndId(
                          el.category,
                          el.value,
                        )}
                      </Button>
                    ),
                  )}
                  {query ? (
                    <Button
                      size='small'
                      onClick={() => productsModel.handleSearchQueryChange('')}
                      className={classes.resetButton}>
                      <span className={classes.resetButtonText}> {query} </span>
                      <CloseIcon className={classes.resetButtonIcon} />
                    </Button>
                  ) : null}
                </div>
              )}
              <Typography className={classes.count}>

                {countLabel()}
              </Typography>
            </Grid>
          </Grid>
        </>
        <LinearProgress
          className={`${classes.stickyloader} ${
            isLoading && classes.loaderVisible
          }`}>

        </LinearProgress>
        {showScrollToTop ? (
          <Button
            variant='outlined'
            onClick={scrollToTop}
            className={classes.stickyScrollButton}>
            <ExpandLessIcon />
            <TranslationLabel name='scrollToTop' />
          </Button>
        ) : null}
      </div>
      {/* Product list */}
      {/* The product list must be pushed down to show facets, but the facets have fixed position.
                Therefore add marginTop if any facets are chosen */}
      <div
        className={classes.productsWrapper}
        style={{
          marginTop: productsModel.getSelecteFacetValuesCount() ? 200 : 170,
        }}>

        {showSelected ? (
          selectedProducts.map((product, i) => (
            <ListViewItem
              product={product}
              addSingleProductToBasket={addSingleProductToBasket}
              onQuantityChange={onQuantityChange}
              currency={i === 0 && core.user.selectedCustomer.currency}
              key={product.productNo}
            />
          ))
        ) : (
          <ContentScroller
            loadMore={productsModel.increment}
            hasMore={productsModel.hasMore}
            useWindow
            content={products.map((product, i) => (
              <ListViewItem
                product={product}
                addSingleProductToBasket={addSingleProductToBasket}
                onQuantityChange={onQuantityChange}
                currency={i === 0 && core.user.selectedCustomer.currency}
                key={product.productNo}
              />
            ))}
          />
        )}
      </div>
      {/* No products */}
      {!isLoading && products.length === 0 && query && (
        <EmptyState
          main='productSearchEmptyMain'
          sub='productSearchEmptySub'
        />
      )}
    </div>
  )
}
