import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  LinearProgress,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import { ListViewItem } from './ListViewItem'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import { Typography } from '@material-ui/core'
import ContentScroller from '../../../sales-web-app/components/ContentScroller'

import { CoreContext } from '../../../../core/context/CoreContext'
import { ProductsContext } from '../../../sales-web-app/context/ProductsContext'
import Grid from '@material-ui/core/Grid/Grid'

const useStyles = makeStyles((theme) => ({
  productsPageRoot: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: 1,
    margin: 0,
    background: 'white',
    height: '100%',
  },
  stickyloader: {
    margin: 'auto',
    position: 'sticky',
    zIndex: 1,
    width: '100%',
    visibility: 'hidden',
  },
  loaderVisible: {
    visibility: 'visible',
  },
  stickyScrollButton: {
    'position': 'fixed',
    'top': 220,
    'left': '50%',
    'transform': 'translate(-50%)',
    'zIndex': 100,
    'backgroundColor': 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  buttons: {
    margin: '10px 2px',
    marginRight: '10px',
  },
  productsWrapper: {
    flex: '1 1 auto',
  },
  select: {
    width: 200,
  },
  topContainer: {
    marginBottom: 30,
    flew: '0 1 auto',
  },
  leftSideOfBar: {
    width: 420,
    display: 'inline-block',
  },
  rightButtons: {
    display: 'inline-block',
    verticalAlign: 'top',
    float: 'right',
  },
  datePicker: {
    position: 'absolute',
    right: 15,
    top: 9,
    width: 200,
    backgroundColor: 'white',
    borderRadius: 3,
  },
  count: {
    fontSize: 14,
  },
  resetButton: {
    display: 'inline-block',
    border: '1px solid lightgrey',
    marginRight: 5,
    marginBottom: 5,
    borderRadius: 2,
    padding: 5,
  },
  resetButtonText: {
    verticalAlign: 'middle',
  },
  greyedOutResetButtonText: {
    color: theme.palette.grey['500'],
  },
  resetButtonIcon: {
    verticalAlign: 'middle',
    marginLeft: 5,
    width: 15,
    height: 15,
  },
  facets: {
    marginTop: 5,
  },
  shippingDateInfo: {
    display: 'inline-block',
    marginLeft: 10,
  },
  fromDate: {
    margin: 5,
  },
  toDate: {
    margin: 5,
  },
  fixedTop: {
    top: '64px',
    paddingTop: '10px',
    right: 0,
    position: 'fixed',
    width: 'calc(100% - 80px)',
    background: 'white',
    zIndex: 5,
  },
}))

export default function SearchPage(props) {
  const classes = useStyles()
  const core = useContext(CoreContext)
  const productsModel = useContext(ProductsContext)
  const [showScrollToTop, setShowScrollToTop] = useState(false)

  const onSelectCampaign = (e) => {
    productsModel.setCampaign(e.target.value)
  }

  const products = productsModel.searchCollection.products || []

  const sortCampaigns = (arr) => {
    return arr.sort((a, b) => {
      if (a.name > b.name) return 1
      if (a.name < b.name) return -1
      return 0
    })
  }

  useEffect(() => {
    if (core.user.selectedCustomer.accountNo) {
      //fetch campaigns list if it is missing

      if (!productsModel.campaigns.length) {
        core.dataStore
          .getCampaigns(core.user.selectedCustomer.accountNo)
          .then((res) => {
            if (res.data) {
              productsModel.setCampaigns(sortCampaigns(res.data))
              productsModel.setCampaign(
                res.data?.find(
                  (el) =>
                    el.campaignId ===
                    JSON.parse(localStorage.getItem('campaign') || '{}')
                      .campaignId,
                ) || {},
              )
            }
          })
          .then(() => {
            //once we have the campaigns list we know which campaign we're on, so fetch the products
            productsModel.init()
          })
          .catch(console.log)
      } else {
        productsModel.init()
      }
    }
  }, [core.user.selectedCustomer.accountNo, productsModel.campaign])

  useEffect(() => {
    const scrollHandler = (ev) => {
      if (window.scrollY > 100) {
        setShowScrollToTop(true)
      } else {
        setShowScrollToTop(false)
      }
    }
    window.document.addEventListener('scroll', scrollHandler)
    return () => window.document.removeEventListener('scroll', scrollHandler)
  }, [])

  function scrollToTop() {
    window.scrollTo(0, 0)
  }

  const formatDate = (time) => {
    const date = new Date(time)
    window.date = date
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
  }

  function countLabel() {
    let label = ''
    if (productsModel.searchCollection.products) {
      let count = productsModel.searchCollection.products.length
      let total = productsModel.searchCollection.totalNumberOfResults
      label = (
        <>
          <TranslationLabel name='displaying' />
          {` ${count} `}
          <TranslationLabel name='of' />
          {` ${total} `}
          <TranslationLabel name='results' />
        </>
      )
    }
    return label
  }

  return (
    <div className={classes.productsPageRoot}>
      {/* the width should account for the drawer being open/closed, and since it's position:fixed "100%" won't work */}
      <div
        className={classes.fixedTop}
        style={{
          width: props.drawerExpanded
            ? 'calc(100% - 255px)'
            : 'calc(100% - 80px)',
        }}>
        <div className={classes.topContainer}>
          <div className={classes.leftSideOfBar}>
            <FormControl
              variant='outlined'
              className={classes.select}>
              <InputLabel id='campaignId'>
                <TranslationLabel name='chooseCampaign' />
              </InputLabel>
              <Select
                labelId='campaignId'
                value={productsModel.campaign}
                onChange={onSelectCampaign}>
                {productsModel.campaigns.map((campaign) => {
                  return (
                    <MenuItem
                      key={campaign.campaignId}
                      value={campaign}>
                      {campaign.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            {productsModel.campaign.deliveryValidFrom && (
              <div className={classes.shippingDateInfo}>
                <p className={classes.fromDate}>
                  <TranslationLabel name='shippingDateStart' />
                  {formatDate(productsModel.campaign.deliveryValidFrom)}
                </p>
                <p className={classes.toDate}>
                  <TranslationLabel name='shippingDateEnd' />
                  {formatDate(productsModel.campaign.deliveryValidTo)}
                </p>
              </div>
            )}
            <Button
              style={{ marginTop: 8 }}
              variant='outlined'
              onClick={() =>
                core.dataStore
                  .createSortOrderForCampaign(productsModel.campaign.campaignId)
                  .then((res) => productsModel.init())
              }>
              <TranslationLabel name='resetSortOrder' />
            </Button>
          </div>
        </div>
        <>
          <LinearProgress
            className={`${classes.stickyloader} ${
              productsModel.isLoading && classes.loaderVisible
            }`}></LinearProgress>
          <Grid
            container
            justify='space-between'
            className={classes.middleSection}>
            <Grid
              item
              xs={12}
              className={classes.facets}>
              <Typography className={classes.count}>{countLabel()}</Typography>
            </Grid>
          </Grid>
        </>
        <LinearProgress
          className={`${classes.stickyloader} ${
            productsModel.isLoading && classes.loaderVisible
          }`}></LinearProgress>

        {showScrollToTop ? (
          <Button
            variant='outlined'
            onClick={scrollToTop}
            className={classes.stickyScrollButton}>
            <ExpandLessIcon />
            <TranslationLabel name='scrollToTop' />
          </Button>
        ) : null}
      </div>

      {/* Product list */}
      {/* The product list must be pushed down to show facets, but the facets have fixed position.
      Therefore add marginTop if any facets are chosen */}
      <div
        className={classes.productsWrapper}
        style={{
          marginTop: productsModel.getSelecteFacetValuesCount() ? 200 : 170,
        }}>
        <ContentScroller
          loadMore={productsModel.increment}
          hasMore={productsModel.hasMore}
          useWindow
          content={products.map((product, i) => (
            <ListViewItem
              campaignId={productsModel.campaign.campaignId}
              refreshProducts={productsModel.init}
              product={product}
              addSingleProductToBasket={() => {}}
              onQuantityChange={() => {}}
              currency={i === 0 && core.user.selectedCustomer.currency}
              key={Math.random()}
            />
          ))}
        />
      </div>
    </div>
  )
}
