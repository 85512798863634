import React, { useState, useContext, useEffect } from 'react'
import { CoreContext } from '../../../../core/context/CoreContext'
import { CategoryContext } from '../../context/CategoryContext'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import { makeStyles } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { FormControlLabel, Switch } from '@material-ui/core'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { LinearProgress } from '@material-ui/core'

import SimpleTable from './SimpleTable'
import Monkey from '../../../../core/images/mini-abe.png'
import { valueTypes } from './Enums'
import RosendahlLogo from '../../../../core/images/rosendahl-design-group-logo.png'

const useStyles = makeStyles((theme) => ({
  leftAligned: {
    textAlign: 'left',
  },
  progress: {
    height: 5,
  },
  header: {
    backgroundColor: theme.palette.grey['700'],
    color: 'white',
    fontWeight: 600,
  },
  logo: {
    margin: 'auto',
    marginTop: 50,
    marginBottom: 50,
    display: 'block',
  },
  image: {
    width: '50%',
    maxWidth: 500,
  },
  imageWrapper: {
    textAlign: 'center',
  },
}))

export default function StatisticsPage() {
  const { CURRENCY, QUANTITY } = valueTypes
  const core = useContext(CoreContext)
  const userId = core.user.userId
  const [headerRow, setHeaderRow] = useState({})
  const [rows, setRows] = useState([])
  const [footer, setFooter] = useState({})
  const [loading, setLoading] = useState(false)
  const [valueType, setValueType] = useState(CURRENCY)

  const toggleValueType = () => {
    setValueType(valueType === CURRENCY ? QUANTITY : CURRENCY)
  }

  const showStats = !loading && headerRow.fields

  useEffect(() => {
    setLoading(true)
    core.dataStore
      .getSalesByMetric(userId, valueType)
      .then((res) => {
        if (!res.data) {
          return
        }
        const { footer, header, rows } = res.data
        setHeaderRow(header)
        setRows(rows)
        setFooter(footer)
      })
      .catch(console.log)
      .finally(() => {
        setLoading(false)
      })
  }, [valueType])

  const classes = useStyles()

  const Statistics = () => {
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headerRow.fields &&
              headerRow.fields.map((headline, i) => {
                return (
                  <TableCell
                    key={headline.value + i}
                    align={i ? 'right' : 'left'}>
                    {headline.value}
                  </TableCell>
                )
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <Row
              key={i}
              {...row}
              valueType={valueType}
            />
          ))}
          {footer.fields &&
            footer.fields.map((field, i) => {
              return (
                <TableCell
                  key={field.value + i}
                  align={i ? 'right' : 'left'}>
                  {field.value}
                </TableCell>
              )
            })}
        </TableBody>
      </Table>
    )
  }

  const NoStatistics = () => {
    return (
      <div className={classes.imageWrapper}>
        <img
          className={classes.image}
          src={Monkey}
          alt=''
        />
        <p>
          <TranslationLabel name='noStatisticsHere' />
        </p>
      </div>
    )
  }

  return (
    <div>
      <img
        className={classes.logo}
        src={RosendahlLogo}
        alt='Rosendahl logo'
      />

      <hr style={{ width: '100%' }} />

      {!showStats ? (
        <NoStatistics />
      ) : (
        <>
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={valueType === CURRENCY}
                onChange={toggleValueType}
              />
            }
            label={<TranslationLabel name='showValuesInCurrency' />}
          />

          <p>
            <span>
              <TranslationLabel name='showingValuesIn' />{' '}
              {valueType === CURRENCY ? (
                core.user.selectedCustomer.currency
              ) : (
                <TranslationLabel name='quantity' />
              )}
            </span>
          </p>

          {loading ? (
            <LinearProgress
              className={classes.progress}
              color='primary'
            />
          ) : (
            <Statistics />
          )}
          <p>
            <TranslationLabel name='tableExplanationLine1' />
          </p>
          <p>
            <TranslationLabel name='tableExplanationLine2' />
          </p>
        </>
      )}
    </div>
  )
}

function Row({ fields, name, valueType }) {
  const core = useContext(CoreContext)
  const brands = useContext(CategoryContext).brands
  const userId = core.user.userId
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [insideTableData, setInsideTableData] = useState(undefined)
  const classes = useStyles()

  const brand =
    fields[0] && brands && brands.find((el) => el.label === fields[0].value)

  useEffect(() => {
    if (!insideTableData && open && brands && brands.length) {
      setLoading(true)
      //this assumes that the brand name is in the first column (fields[0])
      core.dataStore
        .getSalesByMetricAndBrand(userId, valueType, brand && brand.id)
        .then((res) => {
          if (res.data) {
            setInsideTableData(res.data)
            setLoading(false)
          }
        })
    }
  }, [open])

  return (
    <>
      <TableRow key={name}>
        {fields.map((field, i) => {
          return (
            <TableCell
              key={field.value + i}
              align={i ? 'right' : 'left'}>
              <span>
                {!i && (
                  <IconButton
                    aria-label='expand row'
                    size='small'
                    onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                )}
                {field.value}
              </span>
            </TableCell>
          )
        })}
      </TableRow>
      {open && (
        <TableRow>
          <TableCell colSpan={fields.length}>
            {loading ? (
              <LinearProgress
                className={classes.progress}
                color='primary'
              />
            ) : (
              <SimpleTable {...insideTableData} />
            )}
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
