import React from 'react'
import NavigationDrawer from '../../../core/components/navigation/NavigationDrawer'
import Topbar from '../../../core/components/navigation/Topbar'
import GetNavItems from '../config/navigation'

function Navigation(props) {
  return (
    <>
      <Topbar {...props}></Topbar>
      <NavigationDrawer
        upperItems={GetNavItems().upperNavigation}
      />
    </>
  )
}

export default Navigation
