import React from 'react'
import NavigationDrawer from '../../../core/components/navigation/NavigationDrawer'
import Topbar from '../../../core/components/navigation/Topbar'
import navigationSettings from '../config/navigation'
function Navigation(props) {
  return (
    <>
      <Topbar {...props} />

      <NavigationDrawer
        upperItems={navigationSettings.upperNavigation}
      />
    </>
  )
}

export default Navigation
