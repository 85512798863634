import React from 'react'
import ReceivedIcon from '@material-ui/icons/QueryBuilder'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import TranslationLabel from '../../../../core/components/TranslationLabel'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  text: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10px',
  },
}))

export default function OrderStatusLabel(props) {
  const { status } = props
  const classes = useStyles()

  function handleStatus() {
    return {
      text: `orderStatus${status}`,
      icon: <ReceivedIcon style={{ color: '#3579f5' }} />,
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.text}>
        <Typography
          variant='subtitle1'
          component='h2'>
          <TranslationLabel name={handleStatus().text} />
        </Typography>
      </div>
    </div>
  )
}
