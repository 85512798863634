import React from 'react'
import missingProduct from '../../assets/images/missingProduct.svg'

export default function InriverImage(props) {
  const { productNo, showImage = false, displayConfig = 'SalesSite_Large', ...rest } = props

  const sizeCodes = {
    SalesSite_SmallThumbnail: 3814,
    SalesSite_Large: 3813,
  }

  const src = `https://imagebank.rdg.dk/customerurl/rdg/cdn/packshot/${productNo}?d=${sizeCodes[displayConfig]}`

  return (
    <img
      src={showImage ? src : missingProduct}
      alt='item missing'
      {...rest}
      onError={(e) => {
        e.target.src = missingProduct
        e.target.style = 'padding: 8px;'
      }}
    />
  )
}
