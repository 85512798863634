import React, { useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import TemplateDialog from './TemplateDialog'
import TemplateColumnDialog from './TemplateColumnDialog'
import CurrencyDialog from './CurrencyDialog'

const useStyles = makeStyles(() => ({
  title: {
    margin: '10px 10px 0',
  },
}))

function DialogWrapper(props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    setOpen(true)
  }, [])

  const handleClose = (args) => {
    props.close(args)
    setOpen(false)
  }

  const close = () => {
    setOpen(false)
    props.closeDialogWithoutReloading()
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby='form-dialog-title'
        fullWidth={true}
        disableBackdropClick>
        <DialogTitle
          id='form-dialog-title'
          className={classes.title}>
          {props.title}
        </DialogTitle>
        {props.name === 'currency' && (
          <CurrencyDialog
            onClose={handleClose}
            {...props}></CurrencyDialog>
        )}
        {props.name === 'template' && (
          <TemplateDialog
            close={close}
            onClose={handleClose}
            {...props}></TemplateDialog>
        )}
        {props.name === 'templateColumn' && (
          <TemplateColumnDialog
            onClose={handleClose}
            {...props}></TemplateColumnDialog>
        )}
      </Dialog>
    </div>
  )
}

export default DialogWrapper
