import React, { useContext } from 'react'

//Material UI
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import AddCircle from '@material-ui/icons/AddCircle'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import DatePicker from '../../../../../shared/DatePicker'
import DateRangeIcon from '@material-ui/icons/DateRange'

import TranslationLabel from '../../../../../core/components/TranslationLabel'
import { BasketContext } from '../../../../../core/context/BasketContext'
import InriverImage from '../../../../../core/components/InriverImage'
import StockItem from '../../Products/StockItem'

const useStyles = makeStyles((theme) => ({
  listItemRoot: {
    padding: '10px 0px',
  },
  txt: {
    marginLeft: 10,
    marginRight: 10,
  },
  title: {
    fontWeight: 700,
  },
  productNo: {
    fontSize: 15,
  },
  addedBy: {
    fontSize: 12,
  },
  price: {
    minWidth: 80,
    marginLeft: 'auto',
    textAlign: 'right',
  },
  old: {
    color: theme.palette.grey[500],
    fontSize: 11,
  },
  new: {
    fontSize: 14,
    fontWeight: 700,
  },
  row: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  quantity: {
    fontWeight: 700,
    fontSize: 13,
    textAlign: 'center',
    minWidth: 20,
  },
  buttonItemWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 34,
    borderBottom: '1px solid #cecece',
    justifyContent: 'flex-start',
    width: '100%',
    paddingLeft: 55,
  },
  delete: {
    marginLeft: 'auto',
  },
  imgWrapper: {
    display: 'block',
    marginRight: 6,
    marginTop: 10,
    width: 50,
    height: 50,
  },
  img: {
    maxHeight: 50,
    maxWidth: '100%',
  },
  datePicker: {
    width: 140,
  },
  shipping: {
    fontSize: 16,
    verticalAlign: '-webkit-baseline-middle',
  },
}))

export default function BasketListViewItem(props) {
  const {
    data,
    onDelete,
    disabled,
    validDateTo,
    validDateFrom,
    updateShippingDate,
    isCampaignItem,
  } = props
  const classes = useStyles()

  return (
    <div className={classes.listItemRoot}>
      <div className={classes.row}>
        <div>
          <Link
            to={`/sales/products/item/${data.itemId}`}
            color='blue'
            className={classes.imgWrapper}>
            <InriverImage
              showImage={data.showImage}
              className={classes.img}
              productNo={data.itemId}
              alt={data.description}
            />
          </Link>
        </div>
        <div>
          <Link
            to={`/sales/products/item/${data.itemId}`}
            color='blue'
            className={classes.productNo}>
            {`${data.itemId} - ${data.itemName}`}
          </Link>
          <div className={classes.productNo}>
            {isCampaignItem ? (
              <div>
                <span className={classes.shipping}>
                  <TranslationLabel name='shipping' />
                </span>
                <DatePicker
                  autoOk
                  readOnly={disabled}
                  KeyboardButtonProps={
                    disabled && { disabled: true, style: { display: 'none' } }
                  }
                  variant='inline'
                  maxDate={validDateTo}
                  minDate={validDateFrom}
                  className={classes.datePicker}
                  value={data.requestedShippingDate}
                  onChange={(date) => updateShippingDate(date, data)}
                  keyboardIcon={
                    <DateRangeIcon
                      style={{ width: 20, height: 20, padding: 0 }}
                    />
                  }
                  InputProps={{
                    disableUnderline: true,
                    style: { fontSize: '16px' },
                    readOnly: true,
                  }}
                />
              </div>
            ) : (
              <p className={classes.addedBy}>
                <TranslationLabel name='productAddedBy' /> {data.createdByUser}
              </p>
            )}
          </div>
        </div>
        <div className={classes.price}>
          {data.priceBeforeDiscount !== data.price && (
            <del className={classes.old}>
              {data.priceBeforeDiscount.toLocaleString('da-DK', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </del>
          )}
          <div className={classes.new}>
            {data.price.toLocaleString('da-DK', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
          <div className={classes.new}>
            {data.amount.toLocaleString('da-DK', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
      </div>
      <Grid container>
        <Grid
          item
          className={classes.buttonItemWrapper}>
          <BasketQuantitySelection
            minimumQuantity={data.minimumQuantity}
            multiplesQuantity={data.multiplesQuantity}
            colli={data.colli}
            className={classes.quantityWrapper}
            entryId={data.basketEntryId}
            qty={data.quantity}
            isReadOnly={disabled}
          />
          {disabled && (
            <div style={{ marginLeft: 10, marginTop: -6 }}>
              <StockItem
                text={data.inventoryText}
                status={data.inventoryStatus}
              />
            </div>
          )}
          {!disabled && (
            <IconButton
              size='small'
              className={classes.delete}
              edge='end'
              aria-label='Delete'
              onClick={() => onDelete(data.basketEntryId)}>
              <DeleteIcon fontSize='small' />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

function BasketQuantitySelection(props) {
  const { entryId, qty, isReadOnly, minimumQuantity, multiplesQuantity } = props
  const classes = useStyles()
  const basketModel = useContext(BasketContext)
  const increment = multiplesQuantity || 1

  function handleIncrement() {
    if (isReadOnly) return
    basketModel.updateEntry({
      basketEntryId: entryId,
      quantity: qty + increment,
    })
  }

  function handleDecrement() {
    if (isReadOnly) return
    if (qty - increment < minimumQuantity) {
      return
    }
    basketModel.updateEntry({
      basketEntryId: entryId,
      quantity: qty - increment,
    })
  }

  function handleManualSelectQuantity() {
    if (isReadOnly) return
    alert('Manual quantity selection not implemented yet.')
  }

  return !isReadOnly ? (
    <>
      <IconButton
        size='small'
        aria-label='update'
        onClick={handleDecrement}
        className={classes.qtyBtn}>
        <RemoveCircle fontSize='small' />
      </IconButton>
      <div
        className={classes.quantity}
        onClick={handleManualSelectQuantity}>
        {qty}
      </div>
      <IconButton
        size='small'
        aria-label='update'
        onClick={handleIncrement}>
        <AddCircle fontSize='small' />
      </IconButton>
    </>
  ) : (
    <div
      style={{ marginLeft: '10px' }}
      onClick={handleManualSelectQuantity}>
      <TranslationLabel name='quantity' />: {qty}
    </div>
  )
}
