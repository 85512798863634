import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import Monkey from '../images/mini-abe.png'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  image: {
    maxHeight: '250px',
  },
  centerGrid: {
    alignItems: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
}))

function NotFound() {
  const classes = useStyles()
  return (
    <Grid
      container
      direction='column'
      className={classes.centerGrid}>
      <Grid
        item
        xs={6}>
        <img
          className={classes.image}
          src={Monkey}
        />
      </Grid>
      <Grid
        item
        xs={6}>
        <div className={classes.textCenter}>
          <h1>404</h1>
          <p>The page could not be found.</p>
          <p>Can't find what you're looking for?</p>
          <Button
            href='/login'
            variant='contained'
            className={classes.button}>
            Log in
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

export default NotFound
