import React from 'react'
//Components
import LoadingBarBox from '../../../../core/components/ui/LoadingBarBox'
import OutlinedInputField from './OutlinedInputField'

//Material UI
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { Link } from 'react-router-dom'
import Logo from '../../../../core/images/rosendahl-design-group-logo.png'

const styles = (theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3,
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  marginTop: {
    marginTop: '45vh',
    transform: 'translateY(-50%)',
  },
  logo: {
    width: 'auto',
    height: '100px',
    filter: 'brightness(0.5)',
    padding: theme.spacing(1),
  },
  newUserInfoWrapper: {
    '& > *': {
      display: 'inline-block',
    },
    'marginTop': 20,
  },
  leftSide: {
    marginRight: 5,
  },
  link: {
    textDecoration: 'underline',
    color: 'blue',
  },
})

function SignIn(props) {
  const { classes, isLoading, loginForm, setUser, setPassword, submit } = props

  function handleChangeUserName(evt) {
    evt.preventDefault()
    setUser(evt.target.value)
  }

  function handleChangePassword(evt) {
    evt.preventDefault()
    setPassword(evt.target.value)
  }

  return (
    <LoadingBarBox
      className={classes.marginTop}
      isLoading={isLoading}>
      <Paper className={classes.paper}>
        <img
          alt='complex'
          src={Logo}
          className={classes.logo}
        />

        <Typography
          component='h1'
          variant='subtitle1'>

          Sign in
        </Typography>

        <OutlinedInputField
          id='userNameOutlinedInputField'
          htmlFor='username'
          label='Username / E-mail'
          value={loginForm.userName}
          onChange={handleChangeUserName}
          onEnter={submit}
          icon={<AccountCircle />}
          required
          fullWidth
        />

        <OutlinedInputField
          id='passwordOutlinedInputField'
          htmlFor='password'
          label='Password'
          value={loginForm.password}
          onChange={handleChangePassword}
          onEnter={submit}
          password='true'
          required
          fullWidth
        />

        <Button
          key='loginSubmit'
          type='submit'
          variant='contained'
          color='primary'
          className={classes.submit}
          onClick={submit}
          href={void 0}
          fullWidth>
          Sign In
        </Button>

        <Link
          to='reset'
          style={{ marginTop: '10px' }}>
          Forgot Password?
        </Link>
        <div className={classes.newUserInfoWrapper}>
          <Typography variant='subtitle2'>
            New user? Please contact our customer service on:
          </Typography>
          <br />

          <Typography
            className={classes.leftSide}
            variant='subtitle2'>
            DK:
          </Typography>
          <a
            className={classes.link}
            target='_blank'
            href='mailto: kundeservice@rdg.dk'>
            kundeservice@rdg.dk
          </a>
          <br />

          <Typography
            className={classes.leftSide}
            variant='subtitle2'>
            NO:
          </Typography>
          <a
            className={classes.link}
            target='_blank'
            href='mailto: salgno@rdg.dk'>
            salgno@rdg.dk
          </a>
          <br />

          <Typography
            className={classes.leftSide}
            variant='subtitle2'>
            Other countries:
          </Typography>
          <a
            className={classes.link}
            target='_blank'
            href='mailto: customerservice@rdg.dk'>
            customerservice@rdg.dk
          </a>
          <br />

          <Typography variant='subtitle2'>
            Please inform us of your customer number and email address.
          </Typography>
        </div>
      </Paper>
    </LoadingBarBox>
  )
}

export default withStyles(styles)(SignIn)
