import React from 'react'

import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = (theme) => ({
  formPaper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    borderRadius: 0,
  },
  outlined: {
    boxShadow: 'none',
    padding: theme.spacing(0),
  },
  topBorder: {
    borderTop: '1px solid #e0e0e0',
  },
})

function FormSection(props) {
  const { classes, children, isOutlined, topBorder, ...rest } = props
  return (
    <Paper
      className={`${classes.formPaper} ${isOutlined ? classes.outlined : ''} ${
        topBorder ? classes.topBorder : ''
      }`}
      {...rest}>
      {children}
    </Paper>
  )
}

export default withStyles(styles)(FormSection)
