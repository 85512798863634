import React, { useContext, useEffect, useState } from 'react'
//Core
import { CoreContext } from '../../../../core/context/CoreContext'
import { ReturnServiceContext } from '../../context/ReturnServiceContext'
import TranslationLabel from '../../../../core/components/TranslationLabel'

//MUI
import Grid from '@material-ui/core/Grid/Grid'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import ReturnServiceOrderDetails from './components/ReturnServiceOrderDetails'
import ReturnServiceConfirmation from './components/ReturnServiceConfirmation'
import DetailedRMAOrderView from '../Orders/DetailedRMAOrderView'

function ReturnServiceCheckoutPage(props) {
  const { history, match } = props
  const core = useContext(CoreContext)
  const checkout = useContext(ReturnServiceContext)
  const [activeStep] = useState(getActiveStep)

  useEffect(() => {
    core.setTitles(core.dictionary.getLabel('checkout'))
  }, [])

  function getSteps() {
    return [
      <TranslationLabel name='returnOrderDetails' />,
      <TranslationLabel name='returnConfirmation' />,
      <TranslationLabel name='returnReceipt' />,
    ]
  }

  function getActiveStep() {
    switch (match.path) {
      case '/sales/returnService/checkout':
        return 0 // details
      case '/sales/returnService/checkout/confirmation':
        return 1
      case '/sales/returnService/checkout/receipt/*':
        return 2
      default:
        return 'Unknown step'
    }
  }

  function advanceStep(orderId) {
    switch (activeStep + 1) {
      case 1:
        history.push('/sales/returnService/checkout/confirmation')
        break
      case 2:
        history.push(`/sales/returnService/checkout/receipt/${orderId}`)
        break
      default:
        history.push('/sales/returnService/checkout') // details
    }
  }

  function goBack() {
    checkout.setOrderDetailsValidated(false)
    history.push('/sales/returnService/checkout')
  }

  function getCurrentStep() {
    switch (activeStep) {
      case 0:
        return <ReturnServiceOrderDetails advanceStep={advanceStep} />
      case 1:
        return (
          <ReturnServiceConfirmation
            advanceStep={advanceStep}
            goBack={goBack}
          />
        )
      case 2:
        return (
          <DetailedRMAOrderView
            isReceipt
            salesId={getOrderId()}
          />
        )
      default:
        return 'Unknown step'
    }
  }

  function getOrderId() {
    return window.location.pathname.split('/receipt/')[1]
  }

  return (
    <>
      <Grid
        container
        direction='row'
        alignItems='stretch'
        justify='center'
        spacing={1}>
        <Grid
          item
          xs={12}
          md={10}
          lg={8}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel>
            {getSteps().map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
      {getCurrentStep()}
    </>
  )
}

export default ReturnServiceCheckoutPage
