export const statusEnum = {
  ALL: 0,
  OPEN: 1,
  FINISHED: 2,
  FUTURE: 3,
}

export const viewModeEnum = {
  CUSTOMERVIEW: 0,
  SALESVIEW: 1,
  EDIT: 2,
  CREATE: 3,
}

export const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
export const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]
