import React, { useState, useEffect, useContext } from 'react'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import { CoreContext } from '../../../../core/context/CoreContext'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@material-ui/core'

export default function BlockedCustomers() {
  const core = useContext(CoreContext)
  const [customers, setCustomers] = useState([])

  useEffect(() => {
    if (core.user.selectedCustomer.accountNo)
      core.dataStore
        .getBlockedCustomers(core.user.userSetting.salesPersonAgentInitials)
        .then((res) => {
          setCustomers(res.data.result)
        })
  }, [core.user.selectedCustomer.accountNo])

  const headers = [
    'customerName',
    'currency',
    'balance',
    'balanceDue',
    'blocked',
    'modifiedBy',
  ]

  return (
    <div style={{ height: 302, overflowY: 'scroll' }}>
      <TableContainer>
        <Table
          stickyHeader
          size='small'>
          <TableHead>
            <TableRow>
              {headers.map((header) => {
                return (
                  <TableCell key={header}>
                    <TranslationLabel name={header} />
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer) => {
              return (
                <TableRow key={customer.customerNo}>
                  {headers.map((header) => {
                    return (
                      <TableCell
                        style={{ maxWidth: 150 }}
                        key={customer.customerNo + header}>
                        {header == 'blocked' ? (
                          <TranslationLabel
                            name={'blockedLabel-' + customer[header]}
                          />
                        ) : header == 'customerName' ? (
                          `${customer.customerNo} - ${customer[header]}`
                        ) : (
                          customer[header]
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
