import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  bar: {
    top: '4px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
}))

export default function LoadingBarBox(props) {
  const classes = useStyles()
  const { children, className, isLoading } = props

  return (
    <div className={classes.root + ' ' + className}>
      <div className={classes.content}>
        {isLoading ? <LinearProgress className={classes.bar} /> : null}
        {children}
      </div>
    </div>
  )
}
