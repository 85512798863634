import React, { useContext, useEffect, useState, useRef } from 'react'
import { CoreContext } from '../../../../core/context/CoreContext'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles, Button, TextField } from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import TableChartIcon from '@material-ui/icons/TableChart'

import BackButton from '../../components/BackButton'
import PrintButton from '../../components/PrintButton'
import ReactToPrint from 'react-to-print'
import { saveAsCsv } from '../../../../shared/SaveAsCsv'
import ValidateEmail from '../../../../shared/EmailValidation'

const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid #e0e0e0',
    backgroundColor: '#F5F5F5',
    overflowX: 'auto',
    padding: 30,
    paddingTop: 10,
    maxWidth: '1042px',
    margin: 'auto',
  },
}))

const emailSendingStatusEnum = {
  SENDING: 'SENDING',
  SENT: 'SENT',
  ERROR: 'ERROR',
  INITIAL: null,
}

export default function DetailedInvoiceView(props) {
  const { SENDING, SENT, ERROR, INITIAL } = emailSendingStatusEnum
  const classes = useStyles()
  const invoiceId = props.invoiceId ? props.invoiceId : props.match.params.id
  const core = useContext(CoreContext)
  const [isLoading, setIsLoading] = useState(true)
  const [showEmailField, setShowEmailField] = useState(false)
  const [email, setEmail] = useState(core.user.selectedCustomer.email)
  const [emailSentStatus, setEmailSentStatus] = useState(INITIAL)
  const [invoice, setInvoice] = useState() //only used to save as excel
  const componentRef = useRef(null)
  const [highlight, setHighlight] = useState(false)

  useEffect(() => {
    setEmail(core.user.selectedCustomer.email)
  }, [core.user.selectedCustomer.email])

  useEffect(() => {
    if (core.user.selectedCustomer.accountNo) {
      core.dataStore
        .getInvoiceDetailed(core.user.selectedCustomer.accountNo, invoiceId)
        .then((res) => {
          setInvoice(res.data)
        })
      core.dataStore
        .getInvoiceHtml(
          core.user.selectedCustomer.accountNo,
          invoiceId,
          core.user.language,
        )
        .then((res) => {
          document.getElementById('invoice').innerHTML = res.data
        })
        .catch((err) => setIsLoading(false))
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [core.user.selectedCustomer.accountNo])

  const displayWarning = (x) => (x === undefined || x.length < 3 || !emailValidated) && highlight ? true : false
  var emailValidated = false

  const sendEmail = () => {
    if (ValidateEmail(email)) {
      emailValidated = true
      setHighlight(false)

      setEmailSentStatus(SENDING)
      core.dataStore
        .emailInvoice(
          email,
          core.user.language,
          core.user.selectedCustomer.accountNo,
          invoiceId,
        )
        .then(() => setEmailSentStatus(SENT))
        .catch((err) => setEmailSentStatus(ERROR))
    } else {
      core.snackbar('warning', 'Wrong email format!')
      emailValidated = false
      setHighlight(true)
    }
  }

  const saveCsv = () => {
    const rows = []
    //first row is headers
    rows[0] = [
      invoice.invoiceName,
      `${core.dictionary.getLabel('invoice')} ${invoice.invoice.invoiceNumber}`,
    ]
    rows[1] = [
      'itemId',
      'itemDescription',
      'qty',
      'salesPrice',
      'discountPercentage',
      'totalPrice',
    ].map((el) => core.dictionary.getLabel(el))
    invoice.invoiceLines.forEach((productLine, i) => {
      const {
        itemId,
        itemDescription,
        qty,
        salesPrice,
        discountPercentage,
        lineAmount,
      } = productLine
      rows[i + 2] = [
        itemId,
        itemDescription,
        qty,
        salesPrice,
        discountPercentage,
        lineAmount,
      ]
    })
    rows[rows.length] = [''] //space
    rows[rows.length] = [
      core.dictionary.getLabel('orderTotal'),
      '',
      '',
      '',
      '',
      invoice.orderBalance,
    ]
    rows[rows.length] = [
      core.dictionary.getLabel('vatAmount'),
      '',
      '',
      '',
      '',
      invoice.vatAmount,
    ]
    rows[rows.length] = [
      core.dictionary.getLabel('orderAmount'),
      '',
      '',
      '',
      '',
      invoice.totalInvoiceAmount,
    ]

    saveAsCsv(
      rows.map((el) => el.join(';')).join('\n'),
      `${core.dictionary.getLabel('invoice')}-${invoice.invoice.invoiceNumber}`,
    )
  }

  const EmailField = (
    <>
      <TextField
        style={{ paddingTop: 6, marginLeft: 10, marginRight: 5, width: 250 }}
        size='small'
        autoFocus
        value={email}
        error={displayWarning(email)}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        variant='outlined'
        onClick={() => sendEmail()}>
        <TranslationLabel name='sendEmail'></TranslationLabel>
      </Button>
    </>
  )

  const renderEmailStatus = () => {
    switch (emailSentStatus) {
      case SENT:
        return (
          <>
            <CheckCircleOutlineOutlinedIcon
              style={{ color: 'green', verticalAlign: 'middle', margin: 5 }}
            />
            <span style={{ fontSize: '16px' }}>
              <TranslationLabel name='emailSent' />
            </span>
          </>
        )
      case SENDING:
        return (
          <CircularProgress
            style={{
              height: 20,
              width: 20,
              margin: 5,
              verticalAlign: 'middle',
            }}
          />
        )
      case ERROR:
        return (
          <>
            <ErrorOutlineIcon
              style={{
                height: 20,
                width: 20,
                margin: 5,
                verticalAlign: 'middle',
                color: 'red',
              }}
            />
            <span style={{ fontSize: '16px' }}>
              <TranslationLabel name='emailError' />
            </span>
          </>
        )
      case INITIAL:
      default:
        return null
    }
  }

  return (
    <div className={classes.root}>
      <div
        style={{
          margin: 'auto',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <BackButton
          path={props.isFairApp ? '/fair-app/invoices' : '/sales/invoices'}
        />
        <div>
          <ReactToPrint
            trigger={() => <PrintButton />}
            content={() => componentRef.current}
          />
          <Button onClick={saveCsv}>
            <TableChartIcon style={{ marginRight: '10px' }} />
            <TranslationLabel name='saveCsv' />
          </Button>
          {showEmailField ? (
            <>
              {EmailField} {renderEmailStatus()}
            </>
          ) : (
            <Button onClick={() => setShowEmailField(true)}>
              <MailOutlineIcon style={{ marginRight: '10px' }} />
              <TranslationLabel name='emailInvoice' />
            </Button>
          )}
        </div>
      </div>
      {invoiceId.length > 0 ? isLoading ? <LinearProgress /> : null : null}
      <div
        style={{ backgroundColor: 'white' }}
        ref={componentRef}
        id='invoice'
      />
    </div>
  )
}
