import Button from '@material-ui/core/Button'
import BackIcon from '@material-ui/icons/ArrowBack'
import TranslationLabel from '../../../core/components/TranslationLabel'
import { Link } from 'react-router-dom'
import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  btnIcon: {
    marginRight: theme.spacing(1),
  },
}))

export default function BackButton(props) {
  const classes = useStyles()
  const { path } = props
  return (
    <Link to={path}>
      <Button className={classes.button}>
        <BackIcon className={classes.btnIcon} />
        <TranslationLabel name='order-goBack' />
      </Button>
    </Link>
  )
}
