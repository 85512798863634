import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import TranslationLabel from '../../../core/components/TranslationLabel'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: 12,
  },
  h3: {
    margin: '25px 0 0',
  },
  p: {
    margin: 0,
  },
}))

function ProductNumberInput(props) {
  const classes = useStyles()

  const handleProductNumberChange = (event) => {
    props.onProductNumberChange(event.target.value.split(','))
  }

  const handleFileUpload = (e) => {
    if (!e.target.files[0]) {
      return
    }
    props.core.masterItemDataStore.getFilterFromExcelFile(e).then((res) => {
      props.onProductNumberChange(res.data.split(','))
    })
    e.target.value = ''
  }

  return (
    <div style={{ width: '100%' }}>
      <h3 className={classes.h3}>
        <TranslationLabel name='productNumbersTitle' />
      </h3>
      <p className={classes.p}>
        <TranslationLabel name='productNumbersIntro' />
      </p>
      <TextField
        style={{ display: 'inline-block', width: 'calc(100% - 150px' }}
        fullWidth
        value={props.value}
        variant='outlined'
        onChange={handleProductNumberChange}
        className={classes.formControl}
        aria-label={<TranslationLabel name='productNumbersLabel' />}
        rowsmin={5}
        label={<TranslationLabel name='productNumbersLabel' />}
      />
      <div style={{ display: 'inline-block', margin: '20px' }}>
        <input
          className={classes.input}
          style={{ display: 'none' }}
          id='excel-upload'
          multiple
          type='file'
          onChange={handleFileUpload}
        />
        <label htmlFor='excel-upload'>
          <Button
            variant='contained'
            color='primary'
            component='span'>
            <TranslationLabel name='fromExcel' />
          </Button>
        </label>
      </div>
    </div>
  )
}

export default ProductNumberInput
