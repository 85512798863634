import axios from 'axios'
import ApiUrls from './ApiUrls'
import { func } from 'prop-types'

export default function DataStore(baseStore) {
  function getUserCustomers(
    salesInitials,
    employeeAccountNo,
    samplesPurchaseAccount,
    pageNumber,
    resultsPerPage,
  ) {
    return baseStore.get(
      `/Sellers/customers/${salesInitials}/${employeeAccountNo}/${samplesPurchaseAccount}/${pageNumber}/${resultsPerPage}`,
    )
  }

  function getUserCustomersBySearch(
    salesInitials,
    employeePurchaseAccountNo,
    samplesPurchaseAccount,
    searchText,
    limit,
  ) {
    return baseStore.getSingleton(
      `/Sellers/customers/search/${salesInitials}/${employeePurchaseAccountNo}/${samplesPurchaseAccount}/${
        searchText || null
      }/${limit}`,
      'getcustomers',
    )
  }

  function getCustomerCount(
    salesInitials,
    employeeAccountNo,
    samplesPurchaseAccount,
  ) {
    return baseStore.get(
      `/Sellers/customerCount/${salesInitials}/${employeeAccountNo}/${samplesPurchaseAccount}`,
    )
  }

  function getBlockedCustomers(salesInitials) {
    return baseStore.get(`/Sellers/customers/blocked/${salesInitials}`)
  }

  function getCustomerByAccountNo(accountNo) {
    return baseStore.get(`/customers/${accountNo}`)
  }

  function getCustomersBySearch(term) {
    return baseStore.get(`/customers/search/${term}/5`)
  }

  function getCustomerAddressesByAccountNo(accountNo) {
    return baseStore.get(`/customers/addresses/${accountNo}`)
  }

  function getCustomerDeliveryCountries(languageId) {
    return baseStore.get(`/Customers/deliveryCountries/${languageId}`)
  }

  function postCustomerNote(accountNo, note) {
    return baseStore.post(`/Customers/note`, { accountNo, note })
  }

  function getCustomerNotes(accountNo) {
    return baseStore.get(`/Customers/note/${accountNo}`)
  }

  function getSellersBySearch(term) {
    return baseStore.get(`/sellers/search/${term}/5`)
  }

  function getEmployeesBySearch(term) {
    return baseStore.get(`/employees/search/${term}/5`)
  }

  function getAllFacetValues(obj) {
    return baseStore.post('/Product/facetValues', obj)
  }

  function searchProducts(obj) {
    return baseStore.postSingleton(`/product/search`, obj)
  }

  function searchExcel(obj) {
    return API.post(`/product//searchExcel`, obj, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
      },
      responseType: 'blob'
    })
  }

  function getProductById(customerId, productId, languageCode, userId) {
    return baseStore.get(
      `/Product/${customerId}/${productId}/${languageCode}/${userId}`,
    )
  }

  function getReturnProductById(customerId, productId, languageCode) {
    return baseStore.get(
      `/Product/${customerId}/${productId}/${languageCode}/rma`,
    )
  }

  const API = axios.create({
    baseURL: ApiUrls.dataUrl,
  });

  function getReturnProductByExcel(customerId, languageCode, e) {
    e.preventDefault()
    const formData = new FormData()
    formData.append('excelFile', e.target.files[0])

    return API.post(`/Product/${customerId}/${languageCode}/rma/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
      },
    })
  }

  function getProductByExcel(customerId, languageCode, userId, e) {
    e.preventDefault()
    const formData = new FormData()
    formData.append('excelFile', e.target.files[0])

    return API.post(`/Product/${customerId}/${languageCode}/${userId}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
      },
    })
  }

  function getCustomerList(sellerId) {
    return baseStore.get(`/Sellers/${sellerId}/customers`)
  }

  function getInvoices(customer, page, offset) {
    return baseStore.get(`/invoices/all/${customer}/${page}/${offset}`)
  }

  function getInvoicesCount(customer) {
    return baseStore.get(`/invoices/all/${customer}/count`)
  }

  function searchInvoices(customer, txt) {
    return baseStore.get(`/invoices/${customer}/search/${txt}`)
  }

  function getInvoiceDetailed(customer, invoice) {
    return baseStore.get(`/invoices/${customer}/${invoice}/detailed`)
  }

  function getInvoiceHtml(customer, invoiceId, languageId) {
    return baseStore.get(
      `/invoices/${customer}/${invoiceId}/${languageId}/detailedHtml`,
    )
  }

  function getTrackingBySalesId(salesId) {
    return baseStore.get(`/tracking/invoiceNo/${salesId}`)
  }

  function getOrders(customer) {
    return baseStore.get(`/orders/${customer}`)
  }

  function getOrder(customer, salesId, languageId) {
    return baseStore.get(
      `/Orders/OrderConfirmation/${customer}/${salesId}/${languageId}`,
    )
  }

  function searchOrders(customer, txt) {
    return baseStore.get(`/orders/${customer}/search/${txt}`)
  }

  function getDetailedOrderHtml(customer, salesId, onlyBackOrders, languageId) {
    return baseStore.get(
      `/Orders/OrderConfirmation/${customer}/${salesId}/${onlyBackOrders}/${languageId}`,
    )
  }

  function getDetailedRMAOrderHtml(customer, salesId, languageId) {
    return baseStore.get(
      `/RMAOrders/OrderConfirmation/${customer}/${salesId}/${languageId}`,
    )
  }

  function getRMAOrders(customer) {
    return baseStore.get(`/RMAorders/${customer}`)
  }

  function getBackOrdersHtml(customer, languageId) {
    return baseStore.get(`/orders/BackOrdersHtml/${customer}/${languageId}`)
  }

  function getBackOrders(customer) {
    return baseStore.get(`/orders/BackOrder/${customer}`)
  }

  function searchRMAOrders(customer, txt) {
    return baseStore.get(`/RMAorders/${customer}/search/${txt}`)
  }

  function getRMAOrderDetailed(customer, salesId) {
    return baseStore.get(`/RMAorders/${customer}/${salesId}`)
  }

  function emailOrderConfirmation(
    email,
    languageId,
    salesId,
    customer,
    onlyBackOrders,
  ) {
    return baseStore.post('Mail/OrderConfirmation', {
      email,
      languageId,
      salesId,
      customer,
      onlyBackOrders,
    })
  }

  function emailRMAOrderConfirmation(email, languageId, salesId, customer) {
    return baseStore.post('Mail/RMAOrderConfirmation', {
      email,
      languageId,
      salesId,
      customer,
    })
  }

  function emailRestOrders(email, languageId, customer) {
    return baseStore.post('Mail/BackOrders', { email, languageId, customer })
  }

  function emailInvoice(email, languageId, customer, invoiceId) {
    return baseStore.post('Mail/Invoice', {
      email,
      languageId,
      customer,
      invoiceId,
    })
  }

  function emailBasketOverview(email, userId, selectedCustomer, languageId) {
    return baseStore.post('Mail/BasketOverview', {
      email,
      userId,
      selectedCustomer,
      languageId,
    })
  }

  function getReturnReasons() {
    return baseStore.get('/System/returnReasons')
  }

  function getBrands() {
    return baseStore.get('/System/brands')
  }

  function getFunctionGroups(language) {
    return baseStore.get(`/System/functionGroups/${language}`)
  }

  function getDesignCollections() {
    return baseStore.get('/System/designCollections')
  }

  //Orders
  function createOrder(dto) {
    return baseStore.post(`/orders/createOrder`, dto)
  }

  function validateOrder(dto) {
    return baseStore.post(`/orders/validateOrder`, dto)
  }

  function createRMA(dto) {
    return baseStore.post(`/RMAorders/createRMAOrder`, dto)
  }

  function validateRMA(dto) {
    return baseStore.post(`/RMAorders/validateRMAOrder`, dto)
  }

  //Basket
  function getBasket(customerNo, userId, basketType, includeMarkup = false) {
    return baseStore.get(`/Basket/user/${customerNo}/${userId}/${includeMarkup}/${basketType}`)
  }

  function getBasketOverviewHtml(userId, customerNo, basketType) {
    return baseStore.get(`/Basket/basketOverviewHtml/${userId}/${customerNo}/${basketType}`);
  }

  function addBasketEntry(
    itemId,
    quantity,
    userId,
    customerNo,
    tradeFairCampaignId,
    basketType,
    requestedShippingDate = null,
    calculateMarkup = false,
  ) {
    return baseStore.post(`/Basket`, {
      itemId,
      quantity,
      userId,
      customerNo,
      tradeFairCampaignId,
      requestedShippingDate,
      calculateMarkup,
      basketType
    })
  }

  function addMultipleEntries(entries) {
    return baseStore.post(`/Basket/multi`, entries)
  }

  function updateBasketEntry(
    entryChanges,
    userId,
    customerNo,
    calculateMarkup = false,
    basketType
  ) {
    return baseStore.put(`/Basket`, {
      ...entryChanges,
      userId,
      customerNo,
      calculateMarkup,
      basketType
    })
  }

  function removeBasketEntry(basketEntryId) {
    return baseStore.del(`/Basket/entry/${basketEntryId}`);
  }

  function deleteBasket(basketId) {
    return baseStore.del(`/Basket/${basketId}`)
  }

  function archiveBasket(dto) {
    return baseStore.post(`/Basket/archive`, dto)
  }

  function getArchivedBasketById(archiveId) {
    return baseStore.get(`/Basket/archive/${archiveId}`)
  }

  //RMA Basket
  function getRMABasket(customerNo, userId) {
    return baseStore.get(`/RMABasket/user/${customerNo}/${userId}`)
  }

  function addRMABasketEntry(itemId, quantity, userId, customerNo) {
    return baseStore.post(`/RMABasket`, {
      itemId,
      quantity,
      userId,
      customerNo,
    })
  }

  function addMultipleRMAEntries(entries) {
    return baseStore.post(`/RMABasket/multi`, entries)
  }

  function updateRMABasketEntry(
    basketEntryId,
    entryChanges,
    userId,
    customerNo,
  ) {
    return baseStore.put(`/RMABasket`, {
      basketEntryId,
      ...entryChanges,
      userId,
      customerNo,
    })
  }

  function removeRMABasketEntry(basketEntryId, accountNo, userId) {
    return baseStore.del(`/RMABasket/${basketEntryId}/${accountNo}/${userId}`)
  }

  function deleteRMABasket(basketId) {
    return baseStore.del(`/RMABasket/${basketId}`)
  }

  function archiveRMABasket(dto) {
    return baseStore.post(`/RMABasket/archive`, dto)
  }

  function getArchivedRMABasketById(archiveId) {
    return baseStore.get(`/Basket/archive/${archiveId}`)
  }

  function getUserAppointments(languageId, userId, status) {
    if (status === 3) {
      //for future only
      return baseStore.get(
        `/Agreement/user?languageId=${languageId}&userId=${userId}&status=${0}&futureOnly=${true}`,
      )
    } else {
      return baseStore.get(
        `/Agreement/user?languageId=${languageId}&userId=${userId}&status=${status}&futureOnly=${false}`,
      )
    }
  }

  function getCustomerAppointments(languageId, customerNo, status) {
    if (status === 3) {
      return baseStore.get(
        `/Agreement/customer?languageId=${languageId}&customerNo=${customerNo}&status=${0}&futureOnly=${true}`,
      )
    } else {
      return baseStore.get(
        `/Agreement/customer?languageId=${languageId}&customerNo=${customerNo}&status=${status}&futureOnly=${false}`,
      )
    }
  }

  function getCustomerAppointmentsInDateRange(
    languageId,
    customerNo,
    from,
    to,
  ) {
    return baseStore.get(
      `/Agreement/customer/daterange?languageId=${languageId}&customerNo=${customerNo}&from=${from}&to=${to}`,
    )
  }

  function getAppointmentById(language, id) {
    return baseStore.get(`/Agreement/${id}?languageId=${language}`)
  }

  function changeAppointment(appointmentObject) {
    return baseStore.put(`/Agreement`, appointmentObject)
  }

  function createAppointment(appointmentObject) {
    return baseStore.post(`Agreement`, appointmentObject)
  }

  function getAppointmentTypes(language, userId) {
    return baseStore.get(
      `Agreement/types?languageId=${language}&userId=${userId}`,
    )
  }

  function getSalesByMetric(userId, metric) {
    return baseStore.get(`Statistics/${userId}/${metric}`)
  }

  function getSalesByMetricAndBrand(userId, metric, brand) {
    return baseStore.get(`Statistics/${userId}/${metric}/${brand}`)
  }

  function getLanguages() {
    return baseStore.get('/System/languages')
  }

  function getAllUsers() {
    return baseStore.get('/user/all')
  }

  function getLoggedInUser() {
    return baseStore.get(`/user`)
  }

  function createLabel(label) {
    return baseStore.post('/Labels', label)
  }

  function updateLabel(label) {
    return baseStore.put('/Labels', label)
  }

  function getLabelGroups() {
    return baseStore.get('/Labels/LabelGroup')
  }
  function getLabels() {
    return baseStore.get('/Labels')
  }

  function searchLabels(term) {
    return baseStore.get(`/Labels/search/${term}`)
  }

  function getLabelByKey(key) {
    return baseStore.get(`/Labels/key/${key}`)
  }

  function getDetailedLabelById(id) {
    return baseStore.get(`/Labels/${id}`)
  }

  function getLabelIdByKey(key) {
    return baseStore.get(`/Labels/key/${key}`)
  }

  function removeLabel(id) {
    return baseStore.del(`/Labels/${id}`)
  }

  function getDictionary(languageId) {
    return baseStore.get(`/labels/language/${languageId}`)
  }

  function updateAppImage(id, formData) {
    return baseStore.httpClient.put(`/apps/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  function getApps() {
    return baseStore.get('/apps')
  }

  function getApp(id) {
    return baseStore.get(`/apps/${id}`)
  }

  function getUserTypes() {
    return baseStore.get('/User/Usertypes')
  }

  function getCampaigns(customerNo) {
    return baseStore.get(`/tradefairCampaign/tradeFair/${customerNo}`)
  }

  function setSortingOnCampaignProduct(obj) {
    return baseStore.post(`/tradefairCampaign/newSortOrder`, obj)
  }

  function createSortOrderForCampaign(campaignId) {
    return baseStore.post(
      `/tradefairCampaign/createSortOrderForCampaign/${campaignId}`,
    )
  }

  function campaignProductSearch(obj) {
    return baseStore.postSingleton(`/tradefairCampaign/search/`, obj)
  }

  function getYoutubeVideos() {
    return baseStore.get('/YoutubeVideos/all')
  }

  function addYoutubeVideo(video) {
    return baseStore.post('/YoutubeVideos/add', video)
  }

  function editYoutubeVideo(video) {
    return baseStore.put('/YoutubeVideos/edit', video)
  }

  function deleteYoutubeVideo(videoId) {
    return baseStore.del(`/YoutubeVideos/delete/${videoId}`)
  }

  function getIntroDates() {
    return baseStore.get(`/Apps/introDates`)
  }

  function createIntroDates(datesObj) {
    return baseStore.post(`/Apps/introDate`, datesObj)
  }

  function editIntroDates(datesObj) {
    return baseStore.put(`/Apps/introDate`, datesObj)
  }

  function deleteIntroDates(id) {
    return baseStore.del(`/Apps/introDate/${id}`)
  }

  function getMasterDataFacets() {
    return baseStore.get(`/MasterDataFacets/all`)
  }

  function addFacetToUser(userId, facetId) {
    return baseStore.post(`/MasterDataFacets/FacetToUser/${userId}/${facetId}`)
  }

  function removeFacetFromUser(userId, facetId) {
    return baseStore.del(`MasterDataFacets/FacetToUser/${userId}/${facetId}`)
  }

  function addMasterDataFacet(facetName) {
    return baseStore.post(`MasterDataFacets/addFacet`, { facetName })
  }

  function removeMasterDataFacet(facetId) {
    return baseStore.del(`MasterDataFacets/removeFacet/${facetId}`)
  }
  function getMasterDataDatesByUserType(userTypeId) {
    return baseStore.get(`MasterDataDate/priceToDate/${userTypeId}`)
  }
  function getMasterDataDates() {
    return baseStore.get('MasterDataDate/masterDataDates')
  }
  function CreateMasterDataDate(obj) {
    return baseStore.post(`MasterDataDate/masterDataDates`, obj)
  }
  function UpdateMasterDataDate(obj) {
    return baseStore.put(`MasterDataDate/masterDataDates`, obj)
  }
  function DeleteMasterDataDate(obj) {
    return baseStore.del(
      'MasterDataDate/masterDataDates?introductionDate=' + obj,
    )
  }
  function getMasterDataStandardTemplates() {
    return baseStore.get(`MasterDataTemplate/standard`)
  }

  function GetUserTypesGroup() {
    return baseStore.get('User/UsertypesGroup')
  }

  function GetMasterDataItemAvailableDate() {
    return baseStore.get('MasterDataItemAvailableDate')
  }

  function CreateMasterDataItemAvailableDate(obj) {
    return baseStore.post('MasterDataItemAvailableDate', obj)
  }

  function UpdateMasterDataItemAvailableDate(obj) {
    return baseStore.put('MasterDataItemAvailableDate', obj)
  }

  function DeleteMasterDataItemAvailableDate(obj) {
    return baseStore.del('MasterDataItemAvailableDate?introductionDate=' + obj)
  }

  const GetHelpLinks = appId => baseStore.get(`HelpLinkManagement/${appId}`);
  const DelHelpLinks = (appId, id) => baseStore.del(`HelpLinkManagement/${appId}?entryId=${id}`)
  const NewHelpLinks = (appId, link) => baseStore.post(`HelpLinkManagement/${appId}`, link)
  const UpdateHelpLinks = (appId, link) => baseStore.put(`HelpLinkManagement/${appId}`, link)

  return {
    GetHelpLinks,
    DelHelpLinks,
    NewHelpLinks,
    UpdateHelpLinks,
    GetMasterDataItemAvailableDate,
    UpdateMasterDataItemAvailableDate,
    DeleteMasterDataItemAvailableDate,
    CreateMasterDataItemAvailableDate,
    GetUserTypesGroup,
    getMasterDataDates,
    getMasterDataDatesByUserType,
    CreateMasterDataDate,
    UpdateMasterDataDate,
    DeleteMasterDataDate,
    getIntroDates,
    createIntroDates,
    editIntroDates,
    deleteIntroDates,
    getUserCustomers,
    getUserCustomersBySearch,
    getCustomerCount,
    getBlockedCustomers,
    getCustomerNotes,
    postCustomerNote,
    getCampaigns,
    createSortOrderForCampaign,
    setSortingOnCampaignProduct,
    campaignProductSearch,
    getApps,
    updateAppImage,
    getApp,
    getUserTypes,
    createLabel,
    updateLabel,
    getLabelGroups,
    getLabels,
    searchLabels,
    getLabelByKey,
    getDetailedLabelById,
    getLabelIdByKey,
    removeLabel,
    getDictionary,
    getAllUsers,
    getLoggedInUser,
    getSalesByMetricAndBrand,
    getSalesByMetric,
    getUserAppointments,
    getCustomerAppointments,
    getCustomerAppointmentsInDateRange,
    getAppointmentById,
    changeAppointment,
    createAppointment,
    getAppointmentTypes,
    getCustomerByAccountNo: getCustomerByAccountNo,
    getCustomersBySearch,
    getCustomerAddressesByAccountNo,
    getCustomerDeliveryCountries,
    getSellersBySearch,
    getEmployeesBySearch,
    searchProducts,
    searchExcel,
    getProductById,
    getProductByExcel,
    getReturnProductById,
    getCustomerList,
    getInvoices,
    getInvoicesCount,
    searchInvoices,
    getInvoiceDetailed,
    getInvoiceHtml,
    getTrackingBySalesId,
    getOrders,
    getOrder,
    searchOrders,
    getDetailedOrderHtml,
    getDetailedRMAOrderHtml,
    emailOrderConfirmation,
    emailRMAOrderConfirmation,
    emailRestOrders,
    emailInvoice,
    emailBasketOverview,
    getRMAOrderDetailed,
    getRMAOrders,
    getBackOrdersHtml,
    getBackOrders,
    searchRMAOrders,
    createOrder,
    validateOrder,
    createRMA,
    validateRMA,
    getBasket,
    getBasketOverviewHtml,
    addBasketEntry,
    addMultipleEntries,
    updateBasketEntry,
    removeBasketEntry,
    deleteBasket,
    archiveBasket,
    getArchivedBasketById,

    getRMABasket,
    addRMABasketEntry,
    addMultipleRMAEntries,
    updateRMABasketEntry,
    removeRMABasketEntry,
    archiveRMABasket,
    getArchivedRMABasketById,
    deleteRMABasket,

    getReturnReasons,
    getBrands,
    getLanguages,
    getFunctionGroups,
    getDesignCollections,

    getAllFacetValues,

    getYoutubeVideos,
    addYoutubeVideo,
    editYoutubeVideo,
    deleteYoutubeVideo,
    getMasterDataFacets,
    addFacetToUser,
    removeFacetFromUser,
    addMasterDataFacet,
    removeMasterDataFacet,
    getMasterDataStandardTemplates,

    getReturnProductByExcel
  }
}
