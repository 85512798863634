import React, { useContext, useEffect, useState, useRef } from 'react'
import { CoreContext } from '../../context/CoreContext'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles, Button, TextField } from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import TranslationLabel from '../../components/TranslationLabel'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import TableChartIcon from '@material-ui/icons/TableChart'
import PrintButton from '../../../apps/sales-web-app/components/PrintButton'
import ReactToPrint from 'react-to-print'
import { saveAsCsv } from '../../../shared/SaveAsCsv'
import ValidateEmail from '../../../shared/EmailValidation'
import { BasketContext } from '../../context/BasketContext'

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #e0e0e0',
    backgroundColor: '#F5F5F5',
    overflowX: 'auto',
    padding: 30,
    paddingTop: 10,
    maxWidth: '1042px',
    margin: 'auto',
  },
}))

const emailSendingStatusEnum = {
  SENDING: 'SENDING',
  SENT: 'SENT',
  ERROR: 'ERROR',
  INITIAL: null,
}

function BasketOverview() {
  const { SENDING, SENT, ERROR, INITIAL } = emailSendingStatusEnum
  const classes = useStyles()
  const core = useContext(CoreContext)
  const basketCore = useContext(BasketContext)
  const [basket, setBasket] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [showEmailField, setShowEmailField] = useState(false)
  const [email, setEmail] = useState(core.user.selectedCustomer.email)
  const [emailSentStatus, setEmailSentStatus] = useState(INITIAL)
  const componentRef = useRef(null)

  useEffect(() => {
    setEmail(core.user.selectedCustomer.email)
  }, [core.user.selectedCustomer.email])

  useEffect(() => {
    if (!core.user.selectedCustomer.accountNo) {
      return
    }
    core.dataStore
      .getBasket(core.user.selectedCustomer.accountNo, core.user.userId, basketCore.basketType, true)
      .then((res) => {
        if (res.status === 200) {
          setBasket(res.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [core.user.selectedCustomer.accountNo, core.user.userId])

  useEffect(() => {
    if (!core.user.selectedCustomer.accountNo) {
      return
    }
    core.dataStore
      .getBasketOverviewHtml(
        core.user.userId,
        core.user.selectedCustomer.accountNo,
        basketCore.basketType,
      )
      .then((res) => {
        document.getElementById('basket').innerHTML = res.data
      })
      .catch((err) => setIsLoading(false))
      .finally(() => {
        setIsLoading(false)
      })
  }, [core.user.selectedCustomer.accountNo])

  const displayWarning = (x) => (x === undefined || x.length < 3 || !emailValidated ? true : false)
  var emailValidated = false
  const sendEmail = () => {
    if (ValidateEmail(email)) {
      emailValidated = true
      setEmailSentStatus(SENDING)
      core.dataStore
        .emailBasketOverview(
          email,
          core.user.userId,
          core.user.selectedCustomer.accountNo,
          core.user.language,
        )
        .then(() => setEmailSentStatus(SENT))
        .catch((err) => setEmailSentStatus(ERROR))
    } else {
      emailValidated = false
    }
  }

  const EmailField = (
    <>
      <TextField
        style={{ paddingTop: 6, marginLeft: 10, marginRight: 5, width: 250 }}
        size='small'
        autoFocus
        value={email}
        error={displayWarning(email)}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        variant='outlined'
        onClick={() => sendEmail()}>
        <TranslationLabel name='sendEmail'></TranslationLabel>
      </Button>
    </>
  )

  const renderEmailStatus = () => {
    switch (emailSentStatus) {
      case SENT:
        return (
          <>
            <CheckCircleOutlineOutlinedIcon
              style={{ color: 'green', verticalAlign: 'middle', margin: 5 }}
            />
            <span style={{ fontSize: '16px' }}>
              <TranslationLabel name='emailSent' />
            </span>
          </>
        )
      case SENDING:
        return (
          <CircularProgress
            style={{
              height: 20,
              width: 20,
              margin: 5,
              verticalAlign: 'middle',
            }}
          />
        )
      case ERROR:
        return (
          <>
            <ErrorOutlineIcon
              style={{
                height: 20,
                width: 20,
                margin: 5,
                verticalAlign: 'middle',
                color: 'red',
              }}
            />
            <span style={{ fontSize: '16px' }}>
              <TranslationLabel name='emailError' />
            </span>
          </>
        )
      case INITIAL:
      default:
        return null
    }
  }

  const formatNumber = (number) => {
    return (Math.round(number * 100) / 100).toLocaleString('da-DK')
  }

  const getStockStatus = (productLine) => {
    const { inventoryStatus, expectedStockDate } = productLine
    if (inventoryStatus == 1) {
      return core.dictionary.getLabel('inStock')
    } else if (expectedStockDate === '0001-01-01T00:00:00') {
      return core.dictionary.getLabel('unknown')
    } else {
      return expectedStockDate.split('T')[0]
    }
  }

  const saveCsv = () => {
    const rows = []
    //first row is headers
    rows[0] = [core.dictionary.getLabel('basket')]
    rows[1] = ['itemId', 'itemDescription', 'expectedStock', 'qty']
      .map((el) => core.dictionary.getLabel(el))
      .concat(
        core.dictionary.getLabel('salesPrice') +
          ` (${core.user.selectedCustomer.currency})`,
      )
    basket.entries.forEach((productLine, i) => {
      const { itemId, itemName, quantity, amount } = productLine
      rows[i + 2] = [
        itemId,
        itemName,
        getStockStatus(productLine),
        quantity,
        formatNumber(amount),
      ]
    })
    rows[rows.length] = [''] //space
    rows[rows.length] = [
      core.dictionary.getLabel('orderTotal'),
      '',
      '',
      '',
      formatNumber(basket.amount),
    ]
    if (basket.markupAmount) {
      rows[rows.length] = [
        core.dictionary.getLabel('markupAmount'),
        '',
        '',
        '',
        formatNumber(basket.markupAmount),
      ]
    }
    if (basket.vatAmount) {
      rows[rows.length] = [
        core.dictionary.getLabel('vatAmount'),
        '',
        '',
        '',
        formatNumber(basket.vatAmount),
      ]
    }
    rows[rows.length] = [
      core.dictionary.getLabel('orderAmount'),
      '',
      '',
      '',
      formatNumber(basket.totalAmount),
    ]

    saveAsCsv(
      rows.map((el) => el.join(';')).join('\n'),
      `${core.dictionary.getLabel('basket')}-${
        core.user.selectedCustomer.accountNo
      }`,
    )
  }

  return (
    <div className={classes.root}>
      <div
        style={{
          margin: 'auto',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <div></div>
        <div>
          <ReactToPrint
            trigger={() => <PrintButton />}
            content={() => componentRef.current}
          />
          <Button onClick={saveCsv}>
            <TableChartIcon style={{ marginRight: '10px' }} />
            <TranslationLabel name='saveCsv' />
          </Button>
          {showEmailField ? (
            <>
              {EmailField} {renderEmailStatus()}
            </>
          ) : (
            <Button onClick={() => setShowEmailField(true)}>
              <MailOutlineIcon style={{ marginRight: '10px' }} />
              <TranslationLabel name='emailOrder' />
            </Button>
          )}
        </div>
      </div>
      {isLoading ? <LinearProgress /> : null}
      <div
        style={{ backgroundColor: 'white' }}
        ref={componentRef}
        id='basket'
      />
    </div>
  )
}

export default BasketOverview
