import { useContext } from 'react'
import { CoreContext } from '../../core/context/CoreContext'
import UserFormView from './components/UserManager/UserFormView'

export default function GetAdminNav() {
  const core = useContext(CoreContext)
  const { languageManagement, allUserManagement, settingsAdministration } =
    core.user.userSetting

  const userManagementItem = {
    id: 'Customers',
    index: 2,
    labelKey: 'userAdmin',
    route: {
      id: 'UserAdmin',
      path: '/hub/admin/users',
      component: UserFormView,
    },
  }

  const languageItem = {
    id: 'Translate',
    index: 3,
    labelKey: 'language',
    route: {
      id: 'Translate',
      path: '/hub/admin/language',
    },
  }

  const settingsAdministationItem = {
    id: 'Settings',
    index: 4,
    labelKey: 'settingsManagement',
    route: {
      id: 'settingsManagement',
      path: '/hub/admin/settingsManagement',
    },
  }

  const feedbackItem = {
    id: 'Feedback',
    index: 5,
    labelKey: 'Feedback',
    route: {
      id: 'Feedback',
      path: '/hub/feedback',
    },
  }
  const helpItem = {
    id: 'Help',
    index: 6,
    labelKey: 'help',
    route: {
      id: 'Help',
      path: '/hub/help',
    },
  }

  const upperNavigation = [
    {
      id: 'Hub',
      index: 1,
      labelKey: 'hub',
      route: {
        id: 'Hub',
        path: '/hub/',
      },
    },
  ]

  if (allUserManagement) {
    upperNavigation.push(userManagementItem)
  }
  if (languageManagement) {
    upperNavigation.push(languageItem)
  }
  if (settingsAdministration) {
    upperNavigation.push(settingsAdministationItem)
  }
  upperNavigation.push(feedbackItem)
  upperNavigation.push(helpItem)

  const adminNav = [
    {
      id: 1,
      component: 1,
      label: 'User administration',
      path: '/hub/admin/users',
      weight: 0,
      indent: false,
    },
    {
      id: 6,
      component: 13,
      label: 'Language',
      path: '/hub/admin/language',
      weight: 0,
      indent: false,
    },
  ]

  return {
    adminNav,
    upperNavigation
  }
}
