import React, { useContext, useState, useEffect } from 'react'
import {
  makeStyles,
  Switch,
  FormControlLabel,
  Modal,
  TextField,
  Dialog,
} from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Popover from '@material-ui/core/Popover'
import IconButton from '@material-ui/core/IconButton'
import Settings from '@material-ui/icons/Settings'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { CoreContext } from '../context/CoreContext'
import TranslationLabel from './TranslationLabel'
import Button from '@material-ui/core/Button'
import SwitchIcon from '@material-ui/icons/SupervisedUserCircle'
import EditIcon from '@material-ui/icons/Edit'
import ExitIcon from '@material-ui/icons/ExitToApp'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { DashboardDialogView } from '../../apps/sales-web-app/components/DashboardWidgetContainer'
import CustomerSelection from './CustomerSelection'
import Select from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import OutlinedInputField from '../../apps/sales-web-app/components/ui/OutlinedInputField'
import ValidateEmail from '../../shared/EmailValidation'

const useStyles = makeStyles((theme) => ({
  popperBg: {
    borderRadius: '8px',
    border: '1px solid #ccc',
    boxShadow: '0 2px 10px rgba(0,0,0,.2)',
  },
  rowPadded: {
    padding: 15,
  },
  row: {
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  containerRoot: {
    minWidth: '250px',
  },
  borderTop: {
    borderTop: '1px solid #e8eaed',
  },
  button: {
    margin: 0,
    padding: '15px',
  },
  labelOpenerWrapper: {
    width: 350,
    height: 200,
    textAlign: 'center',
    zIndex: 10,
    backgroundColor: 'white',
    position: 'absolute',
    left: '50vw',
    top: '50vh',
    transform: 'translateY(-50%)',
    padding: 20,
  },
}))

export default function UserBadge() {
  const classes = useStyles()
  const core = useContext(CoreContext)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [labelOpenerOpen, setLabelOpenerOpen] = useState(false)
  const [labelEditorOpen, setLabelEditorOpen] = useState(false)
  const [labelValue, setLabelValue] = useState('')
  const [newsletterUserMenuOpen, setNewsletterUserMenuOpen] = useState(false)
  let avatarInitials = core.user.displayName.substring(1, 0)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [openSwitchCustomer, setOpenSwitchCustomer] = useState(false)
  const handleClickOpenSwitchCustomer = () => {
    setOpenSwitchCustomer(true)
  }
  const handleCloseSwitchCustomer = () => {
    setOpenSwitchCustomer(false)
  }

  const openLabel = (e) => {
    console.log('open label')
    e.preventDefault()
    setLabelOpenerOpen(false)
    setLabelEditorOpen(true)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}>
        <Settings />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.popperBg}>
        <Grid
          container
          direction='column'
          justify='center'
          alignItems='center'
          className={classes.containerRoot}>
          <Grid
            item
            className={classes.row + ' ' + classes.rowPadded}>
            <Grid
              container
              direction='column'>
              <Grid
                item
                className={classes.row + ' ' + classes.rowPadded}>
                <Avatar className={classes.largeAvatar}>
                  {avatarInitials}
                </Avatar>
              </Grid>
              <Grid item>
                <Typography
                  variant='subtitle1'
                  style={{ color: '#202124' }}>
                  {core.user.displayName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant='subtitle2'
                  style={{ color: '#5f6368' }}>
                  {core.user.account}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            className={
              classes.row + ' ' + classes.borderTop + ' ' + classes.rowPadded
            }>
            {core.user.selectedCustomer.accountNo}
            {' - '}
            {core.user.selectedCustomer.accountName}
          </Grid>
          {core.user.hasMultipleAccounts ? (
            <Grid
              item
              className={classes.row + ' ' + classes.borderTop}>
              <Button
                className={classes.button}
                startIcon={<SwitchIcon />}
                onClick={handleClickOpenSwitchCustomer}
                fullWidth>
                <TranslationLabel name='SwitchCustomer' />
              </Button>

              <DashboardDialogView
                open={openSwitchCustomer}
                handleClose={() => {
                  handleCloseSwitchCustomer()
                }}
                content={
                  <CustomerSelection
                    isModal
                    handleClose={() => {
                      handleClose()
                      handleCloseSwitchCustomer()
                    }}
                    useConfirmationDialogue={false}
                  />
                }
                hideTitle
              />
            </Grid>
          ) : null}
          <Grid
            item
            className={classes.row + ' ' + classes.borderTop}>
            <Button
              className={classes.button}
              startIcon={<EditIcon />}
              onClick={() => core.history.push('/hub/settings')}
              fullWidth>
              <TranslationLabel name='editProfile' />
            </Button>
          </Grid>
          {core.user.userSetting.canCreateNewsLetterUser && (
            <Grid
              item
              className={classes.row + ' ' + classes.borderTop}>
              <Button
                className={classes.button}
                startIcon={<AddCircleOutlineIcon />}
                onClick={() =>
                  setNewsletterUserMenuOpen(!newsletterUserMenuOpen)
                }
                fullWidth>
                <TranslationLabel name='CREATENEWSLETTERUSER' />
              </Button>
            </Grid>
          )}
          <Dialog
            open={newsletterUserMenuOpen}
            onClose={() => setNewsletterUserMenuOpen(false)}>
            <NewsLetterUserMenu
              onClose={() => setNewsletterUserMenuOpen(false)}
            />
          </Dialog>
          {core.user.userSetting.languageManagement && (
            <Grid
              item
              className={classes.row + ' ' + classes.borderTop}>
              <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                {core.dictionary.isEditMode && (
                  <>
                    <Button
                      style={{ margin: 10 }}
                      onClick={() => setLabelOpenerOpen(true)}
                      variant='outlined'>
                      Open a label
                    </Button>
                    <Button
                      style={{ margin: 10 }}
                      onClick={() =>
                        core.setShowLabelNames(!core.showLabelNames)
                      }
                      variant='outlined'>
                      Show label names
                    </Button>
                  </>
                )}
                <FormControlLabel
                  control={
                    <Switch
                      color='primary'
                      checked={core.dictionary.isEditMode}
                      onChange={() => {
                        core.dictionary.useSetIsEditMode(
                          !core.dictionary.isEditMode,
                        )
                      }}
                    />
                  }
                  label={<TranslationLabel name='editMode' />}
                />
              </div>
            </Grid>
          )}
          <Modal
            open={labelOpenerOpen}
            onClose={() => setLabelOpenerOpen(false)}>
            <div className={classes.labelOpenerWrapper}>
              <Typography variant='h4'>Label name</Typography>
              <form onSubmit={openLabel}>
                <TextField
                  autoFocus
                  value={labelValue}
                  onChange={(e) => setLabelValue(e.target.value)}
                  variant='outlined'
                />
                <Button
                  style={{ marginTop: 20 }}
                  variant='outlined'
                  color='primary'
                  type='submit'>
                  Open label
                </Button>
              </form>
            </div>
          </Modal>
          {labelEditorOpen && (
            <TranslationLabel
              name={labelValue}
              onClose={() => {
                setLabelEditorOpen(false)
              }}
              open={true}
            />
          )}
          <Grid
            item
            className={classes.row + ' ' + classes.borderTop}>
            <Button
              className={classes.button}
              startIcon={<ExitIcon />}
              onClick={() => core.logout()}
              fullWidth>
              <TranslationLabel name='signOut' />
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </div>
  )
}

const NewsLetterUserMenu = ({ onClose }) => {
  const core = useContext(CoreContext)

  const [highlight, setHighlight] = useState(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [language, setLanguage] = useState(core.user.userSetting.languageId)
  const [created, setCreated] = useState(false)
  const [loading, setLoading] = useState(false)
  const displayWarning = (x) => (x === undefined || x.length < 3 || !emailValidated) && highlight ? true : false
  var emailValidated = false

  return (
    <div style={{ padding: '24px', width: '400px' }}>
      <Typography
        style={{ marginBottom: '24px' }}
        variant='h5'>
        <TranslationLabel name='createNewsLetterUserHeadline' />
      </Typography>
      <OutlinedInputField
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        error={displayWarning(email)}
        style={{ width: '100%', display: 'block', marginBottom: '12px' }}
        variant='outlined'
        label='email'
      />
      <OutlinedInputField
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        error={displayWarning(name)}
        style={{ width: '100%', display: 'block', marginBottom: '12px' }}
        variant='outlined'
        label='navn'
      />
      <LanguageSelection
        language={language}
        setLanguage={setLanguage}
      />
      {!created ? (
        <Button
          disabled={loading}
          style={{ marginTop: '24px', float: 'right' }}
          color='primary'
          variant='contained'
          onClick={() => {
            if (ValidateEmail(email)) {
              setLoading(true)
              emailValidated = true
              setHighlight(false)

              core.hubStore
                .createNewsletterUser({
                  displayName: name,
                  email,
                  language,
                  selectedCustomerAccount: core.user.selectedCustomer.accountNo,
                  selectedCustomerName: core.user.selectedCustomer.accountName,
                  sendEmail: true,
                  salesPerson: core.user.displayName,
                })
                .then((res) => {
                  setCreated(true)
                })
            } else {
              emailValidated = false
              setHighlight(true)
            }
          }}>
          Opret
        </Button>
      ) : (
        <>
          <div style={{ textAlign: 'center' }}>
            <Typography
              style={{ margin: '24px', color: 'green', fontSize: '20px' }}>
              <TranslationLabel name='newsletterUserCreatedSuccess' />
            </Typography>
          </div>
          <Button
            style={{ marginTop: '24px', float: 'right' }}
            color='primary'
            variant='contained'
            onClick={() => {
              onClose()
            }}>
            Ok
          </Button>
        </>
      )}
    </div>
  )
}

function LanguageSelection({ language, setLanguage }) {
  const core = useContext(CoreContext)
  const [languages, setLanguages] = useState([])

  function loadLanguages() {
    core.dataStore
      .getLanguages()
      .then((x) => {
        if (x.status === 200) {
          setLanguages(x.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {})
  }

  useEffect(() => {
    loadLanguages()
  }, [])

  const selectLanguage = (e) => {
    const index = e.target.value
    setLanguage(languages[index].id)
  }

  const selectedLanguage = languages.find((el) => el.id === language)?.title

  return selectedLanguage ? (
    <FormControl
      variant='outlined'
      style={{ width: '100%' }}>
      <Select
        value={languages.findIndex((el) => el.id === language)}
        onChange={selectLanguage}
        input={
          <OutlinedInput
            name='currency'
            id='outlined-age-simple'
          />
        }>
        {languages.map((v, i) => (
          <MenuItem
            key={v.id}
            value={i}>
            {v.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : null
}
