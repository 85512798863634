import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'

export default function ContentScroller(props) {
  const { content, onLoad, hasMore, pageStart = 0, ...rest } = props

  return (
    <InfiniteScroll
      pageStart={pageStart}
      loadMore={onLoad}
      hasMore={hasMore}
      threshold={100}
      initialLoad={false}
      {...rest}>
      {content}
    </InfiniteScroll>
  )
}
