import React from 'react'
import Content from './components/Content'
import Navigation from './components/Navigation'

import BasketProvider from '../../core/context/BasketContext'
import ProductsProvider from '../sales-web-app/context/ProductsContext'
import CategoryProvider from '../sales-web-app/context/CategoryContext'
import CheckoutProvider from '../sales-web-app/context/CheckoutContext'
import OrderProvider from '../sales-web-app/context/OrderContext'

export default function FairAppRoot() {
  return (
    <ProductsProvider>
      <CheckoutProvider>
        <BasketProvider basketId={1}>
            <CategoryProvider>
              <OrderProvider>
                <Navigation />
                <Content />
              </OrderProvider>
            </CategoryProvider>
        </BasketProvider>
      </CheckoutProvider>
    </ProductsProvider>
  )
}
