import React, { useState, useRef } from 'react'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import useStyles from './itemStyles'
import { TextField, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import findNextAllowedAmount from '../../../sales-web-app/libraries/FindNextAllowedQuantity'
import { ProductDescription } from '../../../sales-web-app/pages/Products/ViewModes/ListViewItem'

export const ListViewItem = React.memo((props) => {
  const {
    product = {},
    onQuantityChange,
    currency,
    addSingleProductToBasket,
  } = props
  const { minimumQuantity, colli } = product
  const classes = useStyles()
  const inputRef = useRef(null)
  const [currentPrice, setCurrentPrice] = useState(
    findPrice(product.priceDtos[0].minQuantity),
  )
  const hasDiscount = currentPrice.discountPercentage > 0

  const handleChangeQuantity = (quantity) => {
    if (quantity < 0) {
      return
    }

    onQuantityChange(product.productNo, quantity)
    let price = findPrice(Math.max(quantity, minimumQuantity || 1))
    setCurrentPrice(price)
  }

  const addToBasket = () => {
    addSingleProductToBasket(product)
  }

  const onBlurHandler = () => {
    onQuantityChange(
      product.productNo,
      findNextAllowedAmount(product, product.quantity),
    )
  }

  function findPrice(qty) {
    let _currentPrice = {
      minQuantity: 0,
    }
    product.priceDtos.forEach((price) => {
      if (
        price.minQuantity <= qty &&
        price.minQuantity > _currentPrice.minQuantity
      ) {
        _currentPrice = price
      }
    })
    return _currentPrice
  }

  const hasNoPrice = !product.priceDtos.length

  return (
    <div className={classes.listBox}>
      <ProductDescription product={product} />
      {/* Price, Quantity and Buy button */}
      <div className={classes.quantityAndPriceWrapper}>
        <div className={classes.quantityInfos}>
          <p className={classes.quantityInfo}>
            <TranslationLabel name='colli' /> {colli}
          </p>
          <p className={classes.quantityInfo}>
            <TranslationLabel name='minQuantity' /> {minimumQuantity}
          </p>
        </div>
        <div className={`${classes.prices} ${classes.listPrices}`}>
          {/* Add currency at the top of table */}
          <div className={`${classes.currency} ${classes.hideOnMobile}`}>
            {currency}
          </div>

          {/* Normal price */}
          {hasDiscount ? (
            <del className={classes.priceNormal}>{currentPrice.grossPrice}</del>
          ) : (
            <span className={classes.priceNormal}>
              <TranslationLabel name='price' />
            </span>
          )}

          {/* Your price */}
          <div>
            <span className={classes.priceYours}>{currentPrice.netPrice}</span>
            <span className={classes.priceText}>
              {hasDiscount ? (
                <span>
                  <TranslationLabel name='price' />
                </span>
              ) : (
                ''
              )}
              <TranslationLabel name='productsQuantityPrefix' />
              {`${product.quantity || currentPrice.minQuantity} `}
              <TranslationLabel name='productsUnit' />
            </span>
          </div>
        </div>
      </div>
      <div className={classes.cta}>
        <TextField
          disabled={hasNoPrice}
          size='small'
          type='number'
          value={product.quantity}
          onChange={(e) => handleChangeQuantity(e.target.value)}
          className={classes.quantity}
          label={<TranslationLabel name='numberOf' />}
          InputLabelProps={{ shrink: true }}
          inputRef={inputRef}
          onClick={() => {
            inputRef.current.select(0, -1)
          }}
          onBlur={onBlurHandler}
          InputProps={{
            inputProps: {
              step: product.colli || product.multiplesQuantity,
            },
          }}
          variant='outlined'
        />

        <Button
          disabled={hasNoPrice}
          className={classes.incrementButton}
          onClick={addToBasket}>
          <AddIcon />
        </Button>
      </div>
    </div>
  )
})
