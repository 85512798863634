import React, { useContext, useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, TextField, Button } from '@material-ui/core'

import InriverImage from '../../../core/components/InriverImage'
import TranslationLabel from '../../../core/components/TranslationLabel'
import priceDtoHelper from '../../../shared/PriceDtoHelper'
import StockItem from './StockItem'

import { BasketContext } from '../../../core/context/BasketContext'
import { ProductsContext } from '../context/ProductsContext'

import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles((theme) => ({
  tableRoot: {
    display: 'grid',
    gridAutoRows: 'auto',
    gridTemplateColumns: 'min-content auto min-content min-content min-content min-content',
    gridTemplateRows: 'min-content',
    gap: '0px 0px',
    alignItems: 'center'
  },
  tableHeaderCell: {
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
    textAlign: 'left',
    padding: '5px 20px'
  },
  tableCell: {
    position: 'relative',
    padding: '5px 20px',
    whiteSpace: 'nowrap'
  },
  tableCellQuntity: {
    borderLeft: '1px solid #eee',
    padding: '0 0 0 20px',
  },
  tableCellLast: {
    padding: '0 20px 0 0',
    marginLeft: 5
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    display: 'block',

    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    },
  },
  label: {
    textTransform: 'uppercase',
    fontSize: 11,
    backgroundColor: 'rgba(255, 255, 255, .6)',
    color: theme.palette.grey[600],
    position: 'static',
    marginRight: 15,
    padding: 0,
  },
  small: {
    fontSize: 13,
  },
  listStock: {
    display: 'inline-block',
    marginLeft: 15,
    transform: 'translateY(-1px)',
  },
  listOpenOrders: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  quantity: {
    width: '64px',

    '& input': {
      paddingRight: 0,
    },
  },
  btn: {
    color: 'white',
    backgroundColor: 'black',
    flexGrow: 1,
    height: 40,

    '&:hover': {
      backgroundColor: theme.palette.grey[800],
    },
  },
  priceNormal: {
    fontSize: 12,
    color: theme.palette.grey[500],
    display: 'block',
  },
  priceText: {
    fontSize: 12,
    color: theme.palette.grey[500],
    display: 'inline-block',
    marginLeft: 10,
  },
  currency: {
    position: 'absolute',
    top: '-16px',
    left: '62px',
    color: theme.palette.grey[600],
    fontSize: 12,
  },
  priceYours: {
    fontSize: 16,
    fontWeight: 'bold',
    display: 'inline-block',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  quantityOptionsHeader: {
    display: 'block',
    marginRight: '15px'
  },
  quantityOptionsCell: {
    display:'block',
    whiteSpace:'nowrap'
  },
  quantityOptions: { display:'flex',fontWeight:'400',lineHeight:'1.5',letterSpacing:'0.00938em',padding:'5px 0',fontSize:'13px'}
}))

export default function TableView(props) {
  const classes = useStyles()
  const productsModel = useContext(ProductsContext)
  const basket = useContext(BasketContext)
  const settings = {emptyQuantity: false, hideHeader: false, hideDetailed: false, remove: undefined, ...props}

  if (!props.products) {
    return (<></>)
  }

  const Cell = (props) => {
    const [currentPrice, setCurrentPrice] = useState(priceDtoHelper.findPrice(props.product.quantity || props.product.colli, props.product))
    const [quantity, setQuantity] = useState(props.product.quantity)
    const inputRef = useRef(null)

    useEffect(() => {
      if (!props.product.quantity) {
        return
      }

      if (props.product.quantity <= 0) {
        handleChangeQuantity({
          target: {
            value: (props.product.priceDtos[0] && props.product.priceDtos[0].minQuantity) || 1,
          },
        })
      }
    }, [props.product])

    const handleChangeQuantity = (e) => {
      let val = e.target.value
      let price = priceDtoHelper.findPrice(val, props.product)

      props.product.quantity = val
      setQuantity(val)
      setCurrentPrice(price)

      if (settings.onQuantityChange) {
        settings.onQuantityChange(props.product.productNo, val)
      }
    }


    const addToBasket = (e) => {
      basket.add({
        itemId: props.product.productNo,
        qty: priceDtoHelper.findNextAllowedNumber(props.product, quantity),
        campaignId: null,
        requestedShippingDate: null,
      })

      if (settings.addColiToBasket) {
        settings.addColiToBasket(props.product)
      }
    }

    const onBlurHandler = () => {
      const allowedQuantity = priceDtoHelper.findNextAllowedNumber(props.product, quantity)
      setQuantity(allowedQuantity)
      setCurrentPrice(priceDtoHelper.findPrice(allowedQuantity, props.product))
    }

    const renderColiQuntity = () => {
      var price = props.product.priceDtos.find(e => e.priceType == priceDtoHelper.priceEnum.Archive || e.priceType == priceDtoHelper.priceEnum.Campaign)

      if (!price) {
        return props.product.colli;
      }

      return price.minQuantity;
    }

    const renderColiName = () => {
      var price = props.product.priceDtos.find(e => e.priceType == priceDtoHelper.priceEnum.Archive || e.priceType == priceDtoHelper.priceEnum.Campaign)

      if (!price) {
        return (
          <span className={classes.quantityOptionsHeader}>
            <TranslationLabel name='salesColliQuantity' />
          </span>
        )
      }

      if (price.priceType == priceDtoHelper.priceEnum.Campaign) {
        return (
          <span className={classes.quantityOptionsHeader} style={{color: 'green'}}>
            <TranslationLabel name='campaignPrice' />
          </span>
        )
      }
      else {
        return (
          <span className={classes.quantityOptionsHeader} style={{color: 'red'}}>
            <TranslationLabel name='ArchivePrice' />
          </span>
        );
      }
    }

    return (
      <>
        <div style={!props.hideDetailed ? {borderBottom: '1px solid #eee', gridColumn: '1 / -1'} : {}}></div>
        {!props.hideDetailed && (
          <>
            <div className={classes.tableCell} style={{paddingRight: 0}}>
              <div style={{width: '77px', height: '55px', position: 'relative'}}>
                <div style={{top: 0, left: 0, width: '100%',height: '100%',position: 'absolute'}}>
                  <Link
                    to={`/sales/products/item/${props.product.productNo}`}
                    onClick={productsModel.gotoProduct}
                    className={classes.imgWrapperInner}
                    style={{display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center'}}
                  >
                    <InriverImage
                      className={classes.img}
                      productNo={props.product.productNo}
                      alt={props.product.description}
                      displayConfig='SalesSite_SmallThumbnail'
                      showImage={props.product.showImage}
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className={classes.tableCell}>
              <Link
                to={`/sales/products/item/${props.product.productNo}`}
                className={`${classes.title}`}
                onClick={productsModel.gotoProduct}>{props.product.description}</Link>

              <span className={`${classes.label}`}>{props.product.brandNo}</span>

              <span className={classes.small}><TranslationLabel name='productNumber' /><span> {props.product.productNo}</span></span>

              <div className={classes.listStock}>
                <StockItem
                  text={props.product.inventoryText}
                  status={props.product.inventoryStatus}
                />
              </div>

              {props.product.openOrders.length > 0 && (
                <div
                  onClick={() => props.setOpenOrdersModal(props.product.productNo)}>
                  <span className={classes.listOpenOrders}>
                    <TranslationLabel name='openOrders' />
                  </span>
                </div>
              )}
            </div>
          </>
        )}
        <div className={classes.tableCell} style={props.hideDetailed ? {paddingLeft: 0}: {}}>
          <div className={classes.quantityOptions}>
            <div>
              {props.product.colli !== 1 && (<span className={classes.quantityOptionsHeader}><TranslationLabel name='splitColliQuantity' /></span>)}
              {renderColiName()}
              <span className={classes.quantityOptionsHeader}>Brownbox</span>
            </div>
            <div style={{textAlign: 'end'}}>
              {props.product.colli !== 1 && (<span className={classes.quantityOptionsCell}>1-{props.product.colli-1} <TranslationLabel name='productsUnit' /></span>)}
              <span className={classes.quantityOptionsCell}>{renderColiQuntity()} <TranslationLabel name='productsUnit' /></span>
              <span className={classes.quantityOptionsCell}>{props.product.brownBox.numberOfItemsInBox} <TranslationLabel name='productsUnit' /></span>
            </div>
          </div>
        </div>
        <div className={classes.tableCell} style={{textAlign: 'right'}}>
          {!props.showRetailPrice && (
            <>
              {priceDtoHelper.hasDiscount(currentPrice) && (
                <del className={classes.priceNormal}>{currentPrice.grossPrice}</del>
              )}
              <span className={classes.priceYours}>{currentPrice.netPrice}</span>
            </>
          )}

          {props.showRetailPrice && (
            <span className={classes.priceYours}>
              {props.product.recommendedRetailPrice}
            </span>
          )}
        </div>
        <div className={classes.tableCellQuntity}>
          <TextField
            onBlur={onBlurHandler}
            size='small'
            type='number'
            value={props.product.quantity}
            onChange={handleChangeQuantity}
            className={classes.quantity}
            InputProps={{
              inputProps: {
                step: props.product.colli || 1,
              },
            }}
            inputRef={inputRef}
            onClick={() => {inputRef.current.select(0, -1)}}
            label={<TranslationLabel name='numberOf' />}
            variant='outlined'
          />
        </div>
        <div className={classes.tableCellLast} style={props.hideDetailed ? {paddingRight: 0}: {}}>
          {!settings.remove && (
            <Button
              disabled={priceDtoHelper.hasNoPrice(props.product)}
              variant='contained'
              disableElevation
              className={classes.btn}
              onClick={addToBasket}>
              <AddIcon />
            </Button>
          )}

          {settings.remove && (
            <Button
              variant='contained'
              className={classes.btn}
              onClick={() => settings.remove(props.product.productNo)}>
              <DeleteIcon />
            </Button>
          )}
        </div>
      </>
    )
  }

  return (
    <div className={classes.tableRoot}>
      {!settings.hideHeader && (
        <>
          <div style={{borderBottom: 'none', gridColumn: '1 / -1'}}></div>
          <div className={classes.tableHeaderCell} style={{paddingRight: 0}}></div>
          <div className={classes.tableHeaderCell}><TranslationLabel name='productListNoAndName'/></div>
          <div className={classes.tableHeaderCell}><TranslationLabel name='productListSalesUnits'/></div>
          <div className={classes.tableHeaderCell} style={{textAlign: 'right'}}><TranslationLabel name='productListPrice'/></div>
          <div className={classes.tableHeaderCell}><TranslationLabel name='productListQuantity'/></div>
          <div className={classes.tableHeaderCell}></div>
        </>
      )}

      {props.products.map((product) => (
        <Cell product={product} setOpenOrdersModal={props.setOpenOrdersModal} showRetailPrice={props.showRetailPrice} hideDetailed={props.hideDetailed} />
      ))}
    </div>
  )
}
