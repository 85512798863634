import React, { useContext, useEffect, useState } from 'react'

//Core
import { CoreContext } from '../../context/CoreContext'

//MUI
import Select from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import TranslationLabel from '../../components/TranslationLabel'

function PrimaryAppSelection() {
  const core = useContext(CoreContext)
  const [app, setApp] = useState(core.user.userSetting.primaryAppId)
  const [allApps, setAllApps] = useState([])
  let userApps = core.getUserApps()

  function handleChange(e) {
    setApp(e.target.value)
    core.user.setPrimaryApp(e.target.value)
  }

  function buildMenuItem(item) {
    let rs = allApps.find((x) => x.id === item)
    let description = ''
    if (rs) {
      description = rs.title
    }
    return (
      <MenuItem
        key={item}
        value={item}>
        {description}
      </MenuItem>
    )
  }

  useEffect(() => {
    core.dataStore
      .getApps()
      .then((rs) => {
        setAllApps(rs.data)
      })
      .catch((e) => {
        console.log(e)
        throw e
      })
  }, [])

  return (
    <form autoComplete='off'>
      <FormControl
        variant='outlined'
        style={{ width: '100%' }}>
        <Select
          value={app}
          input={
            <OutlinedInput
              name='primaryApp'
              id='outlined-app-simple'
            />
          }
          onChange={handleChange}
          displayEmpty>
          <MenuItem
            value=''
            disabled>
             <TranslationLabel name='selectPrimaryApp' />
          </MenuItem>
          {userApps.map((v) => buildMenuItem(v))}
        </Select>
      </FormControl>
    </form>
  )
}

export default PrimaryAppSelection
