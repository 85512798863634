import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { CheckoutContext } from '../../../context/CheckoutContext'
import { BasketContext } from '../../../../../core/context/BasketContext'
import { CoreContext } from '../../../../../core/context/CoreContext'

import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import OutlinedInputField from '../../../components/ui/OutlinedInputField'
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  makeStyles,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Button from '@material-ui/core/Button'
import TranslationLabel from '../../../../../core/components/TranslationLabel'

import { useForm, Controller } from 'react-hook-form'
import DatePicker from '../../../../../shared/DatePicker'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 400,
    marginBottom: 20,
  },
  margin: {
    margin: theme.spacing(1),
  },
  hr: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  padd: {
    paddingTop: 6,
  },
  btns: {
    display: 'flex',
    padding: theme.spacing(1),
  },
  btn: {
    width: 'auto',
    height: 40,
    marginTop: 30,
    marginRight: 10,
    minWidth: 100,
  },
  btnNext: {
    'color': 'white',
    'backgroundColor': 'black',
    'width': '100%',
    'maxWidth': 300,
    'marginRight': 0,
    'marginLeft': 'auto',

    '&:hover': {
      backgroundColor: theme.palette.grey[800],
    },
  },
}))

const firstCountriesInSelect = ['DNK', 'DEU', 'NOR', 'SWE']

function OrderDetails(props) {
  const history = useHistory()
  const core = useContext(CoreContext)
  const checkout = useContext(CheckoutContext)
  const basketModel = useContext(BasketContext)
  const classes = useStyles()
  const { advanceStep } = props
  const { addressFormLocked, setAddressFormLocked } = checkout
  const [deliveryAddresses, setDeliveryAddresses] = useState([])

  const [countries, setCountries] = useState([])

  useEffect(() => {
    if (core.user.language) {
      core.dataStore
        .getCustomerDeliveryCountries(core.user.language)
        .then((res) => {
          const countries = res?.data?.result?.sort((a, b) => {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0
          })
          const firstCountries = firstCountriesInSelect.map((el) =>
            countries.find((country) => country.code === el),
          )

          setCountries([...firstCountries, ...countries])
        })
        .catch(() => {})
    }
  }, [core.user.language])

  const {
    handleSubmit,
    control,
    errors: fieldsErrors,
    setValue,
    clearErrors,
  } = useForm({ defaultValues: checkout.orderDetails })

  let checkObject = (obj) => (obj ? true : false)

  //temp
  const canChangeDeliveryAddress =
    core.user.selectedCustomer.canChangeDeliveryAddress

  function onSubmit(formData) {
    checkout.setIsLoading(true)
    checkout.validateOrderDetails(formData, advanceStep)
  }

  const goBack = () => {
    console.log(basketModel)
    history.push(
      basketModel.basket.tradeFairCampaignId ? '/fair-app' : '/sales/products',
    )
  }

  function resetDeliveryAdress(isChecked) {
    if (isChecked) {
      setValue('deliveryName', '')
      setValue('deliveryStreetName1', '')
      setValue('deliveryStreetName2', '')
      setValue('deliveryZipCode', '')
      setValue('deliveryCity', '')
      setValue('deliveryCountry', '')

      //reset select
      setValue('deliveryAddressId', '')
      setAddressFormLocked(false)
    } else {
      let address =
        deliveryAddresses.find((addr) => addr.addressType === 2) ||
        deliveryAddresses.find((addr) => addr.addressType === 0) ||
        deliveryAddresses[0]
      setValue('deliveryAddressId', address.id)
      onInvoiceAddressSelectChange(address.id)
    }

    setValue('hasNewAddress', isChecked)

    clearErrors([
      'deliveryName',
      'deliveryStreetName1',
      'deliveryStreetName2',
      'deliveryZipCode',
      'deliveryCity',
      'deliveryCountry',
    ])
  }

  function onInvoiceAddressSelectChange(id) {
    setAddressFormLocked(false)
    let address = deliveryAddresses.find((x) => x.id === id)
    setAdresse(address)
    setAddressFormLocked(true)
  }

  function setAdresse(address) {
    setValue('deliveryName', address.name)
    setValue('deliveryStreetName1', address.street)
    setValue('deliveryStreetName2', address.street2)
    setValue('deliveryZipCode', address.zip)
    setValue('deliveryCity', address.city)
    setValue('deliveryCountry', address.country)
  }

  useEffect(() => {
    if (basketModel.basket.entries && basketModel.basket.tradeFairCampaignId) {
      //find earliest delivery date in the product list and set shippingDate to that
      let entriesByDate = basketModel.basket.entries.map(
        (el) => el.requestedShippingDate,
      )
      const earliestDate = (entriesByDate = entriesByDate.sort()[0])
      setValue('shippingDate', earliestDate)
      console.log('sat value of shipping date')
    }
  }, [basketModel.basket.entries, basketModel.basket.tradeFairCampaignId])

  useEffect(() => {
    function load() {
      core.dataStore
        .getCustomerAddressesByAccountNo(core.user.selectedCustomer.accountNo)
        .then((x) => {
          if (x.status === 200) {
            setDeliveryAddresses(
              x.data.sort((a, b) =>
                a.name < b.name ? -1 : Number(a.name > b.name),
              ),
            )
            let address
            //if canChangeAddress = true set default address to first addr of type 0. Otherwise to first addr of type 2

            address =
              x.data.find((addr) => addr.addressType === 2) ||
              x.data.find((addr) => addr.addressType === 0) ||
              x.data[0]
            if (addressFormLocked) {
              setAdresse(address)
            }
            setValue('deliveryAddressId', address.id)
            checkout.setIsInitialized(true)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          checkout.setIsLoading(false)
        })
    }

    if (core.isInitialized) {
      //redirect if no customer accountNo set,
      //could be if basket is accessed directly from browser refresh
      if (!core.user.selectedCustomer.accountNo) {
        history.push('/sales')
      } else {
        load()
      }
    }
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        direction='row'
        justify='center'>
        <Grid
          item
          xs={12}
          md={9}
          lg={6}>
          <h1>
            1. <TranslationLabel name='orderDetails' />
          </h1>
          {
            <div
              style={{
                display: canChangeDeliveryAddress ? 'block' : 'none',
              }}>
              <div>
                {/* Select address */}
                <FormControl
                  variant='outlined'
                  className={`${classes.formControl} ${classes.margin}`}>
                  <Autocomplete
                    onChange={(event, newval) => {
                      onInvoiceAddressSelectChange(newval.id)
                    }}
                    options={deliveryAddresses}
                    disableClearable
                    getOptionLabel={(address) =>
                      `${address.name}, ${address.street}, ${address.street2}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <TranslationLabel name='searchDeliveryAddresses' />
                        }
                        variant='outlined'
                        fullWidth
                      />
                    )}
                  />
                </FormControl>

                {/* New address */}
                <Controller
                  control={control}
                  name='hasNewAddress'
                  render={({ onChange, onBlur, value, name, ref }) => (
                    <FormControlLabel
                      className={`${classes.margin} ${classes.padd}`}
                      control={
                        <Checkbox
                          name={name}
                          color='primary'
                          onBlur={onBlur}
                          checked={value}
                          inputRef={ref}
                          onChange={(e) =>
                            resetDeliveryAdress(e.target.checked)
                          }
                        />
                      }
                      label={
                        <TranslationLabel name='orderDetailsHasNewAddress' />
                      }
                    />
                  )}
                />
              </div>

              <Divider
                light
                className={classes.hr}
              />

              <>
                <Grid item>
                  <FormControl fullWidth>
                    <Controller
                      name='deliveryName'
                      control={control}
                      defaultValue=''
                      render={({ onChange, onBlur, value, name, ref }) => (
                        <TextField
                          inputRef={ref}
                          value={value}
                          onBlur={onBlur}
                          onChange={(e) => onChange(e.target.value)}
                          name={name}
                          disabled={addressFormLocked}
                          className={classes.margin}
                          helperText={
                            fieldsErrors.deliveryName
                              ? fieldsErrors.deliveryName.message
                              : null
                          }
                          label={<TranslationLabel name='deliveryname' />}
                          error={checkObject(fieldsErrors.deliveryName)}
                          variant='outlined'
                        />
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: (
                            <TranslationLabel name='formFieldIsRequired' />
                          ),
                        },
                        maxLength: {
                          value: 100,
                          message: (
                            <TranslationLabel name='formFieldHasTooManyCharacters' />
                          ),
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <Controller
                      name='deliveryStreetName1'
                      control={control}
                      defaultValue=''
                      // value={checkout.orderDetails.deliveryStreetName1}
                      render={({ onChange, onBlur, value, name, ref }) => (
                        <TextField
                          inputRef={ref}
                          value={value}
                          onBlur={onBlur}
                          onChange={(e) => onChange(e.target.value)}
                          name={name}
                          disabled={addressFormLocked}
                          className={classes.margin}
                          helperText={
                            fieldsErrors.deliveryStreetName1
                              ? fieldsErrors.deliveryStreetName1.message
                              : null
                          }
                          label={
                            <TranslationLabel name='deliveryStreetName1' />
                          }
                          error={checkObject(fieldsErrors.deliveryStreetName1)}
                          variant='outlined'
                        />
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: (
                            <TranslationLabel name='formFieldIsRequired' />
                          ),
                        },
                        maxLength: {
                          value: 60,
                          message: (
                            <TranslationLabel name='formFieldHasTooManyCharacters' />
                          ),
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <Controller
                      name='deliveryStreetName2'
                      control={control}
                      defaultValue=''
                      render={({ onChange, onBlur, value, name, ref }) => (
                        <TextField
                          inputRef={ref}
                          value={value}
                          onBlur={onBlur}
                          onChange={(e) => onChange(e.target.value)}
                          name={name}
                          disabled={addressFormLocked}
                          className={classes.margin}
                          id='deliveryStreetName2'
                          helperText={
                            fieldsErrors.deliveryStreetName2
                              ? fieldsErrors.deliveryStreetName2.message
                              : null
                          }
                          label={
                            <TranslationLabel name='deliveryStreetName2' />
                          }
                          error={checkObject(fieldsErrors.deliveryStreetName2)}
                          variant='outlined'
                        />
                      )}
                      rules={{
                        maxLength: {
                          value: 60,
                          message: (
                            <TranslationLabel name='formFieldHasTooManyCharacters' />
                          ),
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <Grid
                      item
                      container
                      direction='row'>
                      <Controller
                        name='deliveryZipCode'
                        control={control}
                        defaultValue=''
                        render={({ onChange, onBlur, value, name, ref }) => (
                          <TextField
                            inputRef={ref}
                            value={value}
                            onBlur={onBlur}
                            onChange={(e) => onChange(e.target.value)}
                            name={name}
                            disabled={addressFormLocked}
                            className={classes.margin}
                            id='deliveryZipCode'
                            helperText={
                              fieldsErrors.deliveryZipCode
                                ? fieldsErrors.deliveryZipCode.message
                                : null
                            }
                            label={<TranslationLabel name='deliveryZipCode' />}
                            error={checkObject(fieldsErrors.deliveryZipCode)}
                            variant='outlined'
                          />
                        )}
                        rules={{
                          required: {
                            value: !addressFormLocked,
                            message: (
                              <TranslationLabel name='formFieldIsRequired' />
                            ),
                          },
                        }}
                      />
                      <Controller
                        name='deliveryCity'
                        control={control}
                        defaultValue=''
                        render={({ onChange, onBlur, value, name, ref }) => (
                          <TextField
                            inputRef={ref}
                            value={value}
                            onBlur={onBlur}
                            onChange={(e) => onChange(e.target.value)}
                            name={name}
                            disabled={addressFormLocked}
                            className={classes.margin}
                            id='deliveryCity'
                            helperText={
                              fieldsErrors.deliveryCity
                                ? fieldsErrors.deliveryCity.message
                                : null
                            }
                            label={<TranslationLabel name='deliveryCity' />}
                            error={checkObject(fieldsErrors.deliveryCity)}
                            variant='outlined'
                          />
                        )}
                        rules={{
                          required: {
                            value: !addressFormLocked,
                            message: (
                              <TranslationLabel name='formFieldIsRequired' />
                            ),
                          },
                        }}
                      />
                    </Grid>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <Controller
                      name='deliveryCountry'
                      control={control}
                      defaultValue=''
                      render={({ onChange, onBlur, value, name, ref }) => (
                        <>
                          <InputLabel
                            style={{
                              marginLeft: '25px',
                              backgroundColor: 'white',
                            }}
                            id='deliveryCountryLabel'>
                            {<TranslationLabel name='deliveryCountry' />}
                          </InputLabel>
                          <Select
                            inputRef={ref}
                            value={value}
                            onBlur={onBlur}
                            onChange={(e) => {
                              onChange(e.target.value)
                            }}
                            name={name}
                            disabled={addressFormLocked}
                            className={classes.margin}
                            id='deliveryCountry'
                            labelId='deliveryCountryLabel'
                            label={<TranslationLabel name='deliveryCountry' />}
                            helperText={
                              fieldsErrors.deliveryCountry
                                ? fieldsErrors.deliveryCountry.message
                                : null
                            }
                            error={checkObject(fieldsErrors.deliveryCountry)}
                            variant='outlined'>
                            {countries.map((el) => (
                              <MenuItem value={el.code}>{el.name}</MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: (
                            <TranslationLabel name='formFieldIsRequired' />
                          ),
                        },
                        maxLength: {
                          value: 10,
                          message: (
                            <TranslationLabel name='formFieldHasTooManyCharacters' />
                          ),
                        },
                      }}
                    />
                  </FormControl>
                </Grid>

                <Divider
                  light
                  className={classes.hr}
                />
              </>
            </div>
          }

          <Grid item>
            <FormControl fullWidth>
              <Controller
                control={control}
                name='shippingDate'
                onChange={(date) => {
                  setValue('shippingDate', date.toJSON())
                  return { value: date } //important to update the controller value after change else state is updated and the controller will not render
                }}
                render={({ onChange, onBlur, value, name, ref }) => (
                  <DatePicker
                    autoOk
                    error={checkObject(fieldsErrors.shippingDate)}
                    inputRef={ref}
                    inputVariant='outlined'
                    label={<TranslationLabel name='desiredShippingdate' />}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    disablePast={true}
                    className={classes.margin}
                    //shippingDate cannot be set on campaign baskets, as they can have different dates on each product
                    readOnly={basketModel.basket.tradeFairCampaignId}
                    InputProps={{
                      inputProps: {
                        readOnly: true,
                      },
                    }}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: <TranslationLabel name='formFieldIsRequired' />,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <Controller
                name='customerRef'
                control={control}
                defaultValue=''
                render={({ onChange, onBlur, value, name, ref }) => (
                  <TextField
                    inputRef={ref}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.value)}
                    name={name}
                    className={classes.margin}
                    inputProps={{
                      maxLength: 30,
                    }}
                    id='customerRef'
                    helperText={
                      fieldsErrors.customerRef
                        ? fieldsErrors.customerRef.message
                        : null
                    }
                    variant='outlined'
                    label={<TranslationLabel name='customerRef' />}
                    error={checkObject(fieldsErrors.customerRef)}
                  />
                )}
              />
            </FormControl>
          </Grid>
          {/* <Grid item>
              <FormControl fullWidth>
                <Controller
                  name="requisitionNo"
                  control={control}
                  defaultValue=""
                  render={({ onChange, onBlur, value, name, ref }) => (
                    <TextField
                      inputRef={ref}
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => onChange(e.target.value)}
                      name={name}
                      className={classes.margin}
                      id="requisitionNo"
                      inputProps={{
                        maxLength: 20
                      }}
                      helperText={
                        fieldsErrors.requisitionNo
                          ? fieldsErrors.requisitionNo.message
                          : null
                      }
                      variant="outlined"
                      label={<TranslationLabel name="requisitionNo" />}
                      error={checkObject(fieldsErrors.requisitionNo)}
                    />
                  )}
                />
              </FormControl>
            </Grid> */}

          <Grid item>
            <FormControl fullWidth>
              <Controller
                name='attention'
                control={control}
                defaultValue=''
                render={({ onChange, onBlur, value, name, ref }) => (
                  <TextField
                    inputRef={ref}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.value)}
                    name={name}
                    className={classes.margin}
                    id='attention'
                    helperText={
                      fieldsErrors.attention
                        ? fieldsErrors.attention.message
                        : null
                    }
                    label={<TranslationLabel name='attention' />}
                    error={checkObject(fieldsErrors.attention)}
                    variant='outlined'
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl fullWidth>
              <Controller
                name='mobilePhoneNo'
                control={control}
                defaultValue=''
                render={({ onChange, onBlur, value, name, ref }) => (
                  <TextField
                    inputRef={ref}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.value)}
                    name={name}
                    className={classes.margin}
                    id='mobilePhoneNo'
                    helperText={
                      fieldsErrors.mobilePhoneNo
                        ? fieldsErrors.mobilePhoneNo.message
                        : null
                    }
                    variant='outlined'
                    label={<TranslationLabel name='mobilePhoneNo' />}
                    error={checkObject(fieldsErrors.mobilePhoneNo)}
                    inputProps={{ maxLength: 20 }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl fullWidth>
              <Controller
                name='emailForTracking'
                render={({ onChange, onBlur, value, name, ref }) => (
                  <TextField
                    inputRef={ref}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.value)}
                    name={name}
                    className={classes.margin}
                    id='emailForTracking'
                    inputProps={{
                      maxLength: 80,
                    }}
                    helperText={
                      fieldsErrors.emailForTracking
                        ? fieldsErrors.emailForTracking.message
                        : null
                    }
                    variant='outlined'
                    label={<TranslationLabel name='emailForTracking' />}
                    error={checkObject(fieldsErrors.emailForTracking)}
                  />
                )}
                control={control}
                defaultValue=''
                rules={{
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: <TranslationLabel name='notValidEmail' />,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <Controller
              name='comment'
              control={control}
              defaultValue=''
              render={({ onChange, onBlur, value, name, ref }) => (
                <OutlinedInputField
                  multiline
                  rowsMax={4}
                  rows={3}
                  value={value}
                  name={name}
                  onBlur={onBlur}
                  onChange={(e) => onChange(e.target.value)}
                  label={<TranslationLabel name='comment' />}
                  inputRef={ref}
                  fullWidth
                  maxLength={200}
                />
              )}
              rules={{
                maxLength: {
                  value: 200,
                  message: (
                    <TranslationLabel name='formFieldHasTooManyCharacters' />
                  ),
                },
              }}
            />
          </Grid>
          <Grid
            container
            direction='column'>
            <div className={classes.btns}>
              <Button
                onClick={goBack}
                variant='contained'
                disableElevation
                className={`${classes.btn}`}>
                <TranslationLabel name='continueShopping' />
              </Button>
              <Button
                type='submit'
                variant='contained'
                disableElevation
                className={`${classes.btn} ${classes.btnNext}`}>
                <TranslationLabel name='toConfirmation' />
                <ArrowForwardIcon
                  style={{ marginLeft: 5 }}
                  fontSize='small'
                />
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export default OrderDetails
