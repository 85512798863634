import axios from 'axios'
import ApiUrls from './ApiUrls'

export default function MasterItemDataStore(baseStore) {
  const getCustomerTemplates = function (customerId) {
    return baseStore.get('MasterDataTemplate/customer/' + customerId)
  }

  const productSearch = function (searchObj) {
    return baseStore.post('MasterDataSearch/Product', searchObj)
  }

  // Customer ADD
  const addCustomer = function (customer) {
    return baseStore.post('/MasterDataCustomer', { name: customer.name })
  }

  // Customer UPDATE
  const updateCustomer = function (customer) {
    return baseStore.put('/MasterDataCustomer/' + customer.id, {
      name: customer.name,
    })
  }

  // Customer Detail ADD
  const addCustomerDetail = function (detail) {
    return baseStore.post('/MasterDataCustomer/Detail/', detail)
  }

  // Customer Detail UPDATE
  const updateCustomerDetail = function (detail) {
    return baseStore.put('/MasterDataCustomer/Detail/' + detail.id, detail)
  }

  // Customer Detail DELETE
  const deleteCustomerDetail = function (detail) {
    return baseStore.del('/MasterDataCustomer/Detail/' + detail.id)
  }

  // Template ADD
  const addTemplate = function (data) {
    return baseStore.post('/MasterDataTemplate', data)
  }

  // Template COPY
  const copyTemplate = function (data) {
    return baseStore.post('/MasterDataTemplate/copyFromTemplate', data)
  }

  // Template UPDATE
  const editTemplate = function (data) {
    return baseStore.put(`/MasterDataTemplate/${data.id}`, {
      name: data.name,
      defaultLanguageCode: data.defaultLanguageCode,
      standard: data.standard,
    })
  }

  // Template DELETE
  const deleteTemplate = function (data) {
    return baseStore.del(`/MasterDataTemplate/${data.id}`)
  }

  // Template Field ADD
  const addTemplateField = function (data) {
    return baseStore.post('/MasterDataTemplate/field', data)
  }

  // Template Field UPDATE
  const updateTemplateField = function (data) {
    return baseStore.put(`/MasterDataTemplate/field/${data.id}`, data)
  }

  // Template field DELETE
  const deleteTemplateField = function (data) {
    return baseStore.del(`/MasterDataTemplate/field/${data.id}`)
  }

  // Template field GET data
  const readTemplateFieldData = function () {
    return baseStore.get('/MasterDataTemplate/DataField')
  }

  // Update DataField
  const updateDataField = function (id, data) {
    return baseStore.put(`/MasterDataTemplate/DataField/${id}`, data)
  }

  // Language
  const getLanguages = function () {
    return baseStore.get('Language')
  }

  //special axios instance for these, as they require special headers, should be fixed later

  const API = axios.create({
    baseURL: ApiUrls.dataUrl,
  })

  const productSearchExport = function (searchObj) {
    return API.post('MasterDataSearch/Product/Excel', searchObj, {
      headers: {
        responseType: 'arraybuffer',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    })
  }

  const getFilterFromExcelFile = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('excelFile', e.target.files[0])
    return API.post('MasterDataSearch/product/excel/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
      },
    })
  }

  return {
    getLanguages,
    getCustomerTemplates,
    productSearch,
    productSearchExport,
    getFilterFromExcelFile,

    // Customer
    addCustomer,
    updateCustomer,

    // Customer Detail
    addCustomerDetail,
    updateCustomerDetail,
    deleteCustomerDetail,

    // Template
    addTemplate,
    copyTemplate,
    editTemplate,
    deleteTemplate,

    // Template field
    addTemplateField,
    updateTemplateField,
    deleteTemplateField,

    // Datafield
    updateDataField,
    readTemplateFieldData,
  }
}
