import React from 'react'
import { StoreProvider } from './global/store'
import Content from './components/ui/Content'
import Navigation from './components/Navigation'
import './resources/css/app.css'

export default function MasterItemDataAppRoot() {
  return (
    <StoreProvider>
      <Navigation />
      <Content />
    </StoreProvider>
  )
}
