import Button from '@material-ui/core/Button'
import Icon from '@material-ui/icons/PictureAsPdf'
import TranslationLabel from '../../../core/components/TranslationLabel'
import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  btnIcon: {
    marginRight: theme.spacing(1),
  },
}))

export default function PrintButton(props) {
  const classes = useStyles()
  return (
    <Button
      className={classes.button}
      onClick={props.onClick}>
      <Icon className={classes.btnIcon} />
      <TranslationLabel name='downloadOrPrint' />
    </Button>
  )
}
