// REACT_APP_API = local || dev || test || staging || prod
let api = process.env.REACT_APP_API

let dataApi = {
  local: 'http://localhost:60248/api/',
  dev: 'https://b2b-api-dev.rdg.dk/api/',
  test: 'https://b2b-api-test.rdg.dk/api/',
  prod: 'https://b2b-api.rdg.dk/api/',
  staging: 'https://b2b-api-staging.rdg.dk/api/',
}

let hubApi = {
  local: 'https://portal-sales-api-test.rdg.dk/api',
  dev: 'https://portal-sales-api-test.rdg.dk/api',
  test: 'https://portal-sales-api-test.rdg.dk/api',
  prod: 'https://portal-sales-api.rdg.dk/api/',
  staging: 'https://portal-sales-api-staging.rdg.dk/api/',
}

let hubImage = {
  local: 'http://localhost:60248',
  dev: 'https://b2b-api-dev.rdg.dk',
  test: 'https://b2b-api-test.rdg.dk',
  prod: 'https://b2b-api.rdg.dk',
  staging: 'https://b2b-api-staging.rdg.dk',
}

export default {
  dataUrl: dataApi[api],
  hubUrl: hubApi[api],
  hubImageUrl: hubImage[api],
}
