import { useContext, useEffect, useState } from 'react'
import { CoreContext } from '../../../../core/context/CoreContext'

export function useReturnServiceModel() {
  const defaultOrderDetails = {
    hasNewAddress: false,

    pickupPallets: '',
    pickupPackages: '',

    pickupAddressId: '',
    pickupName: '',
    pickupStreet1: '',
    pickupStreet2: '',
    pickupZipCode: '',
    pickupCity: '',
    pickupCountry: '',

    pickupContactPerson: '',
    comment: '',
  }

  const [isLoading, setIsLoading] = useState(false)
  const [basket, setBasket] = useState({ entries: [] })
  const [orderDetailsValidated, setOrderDetailsValidated] = useState(false)
  const [orderDetails, setOrderDetails] = useState(defaultOrderDetails)
  const [addressFormLocked, setAddressFormLocked] = useState(true)
  const [isInitialized, setIsInitialized] = useState(false)
  const [useAnotherAddress, setUseAnotherAddress] = useState(false)

  const core = useContext(CoreContext)

  function multiAdd(entries, callback) {
    setIsLoading(true)

    let basketEntries = []
    for (let i = 0; i < entries.length; i++) {
      let obj = {
        productNo: entries[i].productNo,
        quantity: entries[i].quantity,
        returnReasonId: entries[i].returnReasonId,
        userId: core.user.userId,
        customerNo: core.user.selectedCustomer.accountNo,
      }
      basketEntries.push(obj)
    }

    core.dataStore
      .addMultipleRMAEntries(basketEntries)
      .then((res) => {
        if (res.status === 200) {
          callback(res.data)
        } else {
          core.snackbar('warning', res.statusText)
        }
      })
      .then(() => load())
      .catch((error) => {
        core.snackbar('warning', error.toString())
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function add(itemId, qty, callback) {
    const itemAlreadyExists = basket.entries.some(
      (item) => item.productNo === itemId,
    )

    if (!itemAlreadyExists) {
      setIsLoading(true)
      core.dataStore
        .addRMABasketEntry(
          itemId,
          qty,
          core.user.userId,
          core.user.selectedCustomer.accountNo,
        )
        .then((res) => {
          if (res.status === 200) {
            setBasket(res.data)
            if (callback) {
              callback()
            }
          } else {
            core.snackbar('warning', res.statusText)
          }
        })
        .catch((error) => {
          core.snackbar('warning', error.toString())
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      const item = basket.entries.find((item) => item.productNo === itemId)
      updateEntry(item.basketEntryId, item.quantity + qty, callback)
    }
  }

  function updateThis(basketEntryId, entryChanges, callback) {
    setIsLoading(true)
    core.dataStore
      .updateRMABasketEntry(
        basketEntryId,
        entryChanges,
        core.user.userId,
        core.user.selectedCustomer.accountNo,
      )
      .then((res) => {
        if (res.status === 200) {
          setBasket(res.data)
          if (callback) callback()
        } else {
          core.snackbar('warning', res.statusText)
        }
      })
      .catch((error) => {
        core.snackbar('warning', error.toString())
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function updateEntry(
    basketEntryId,
    quantity,
    returnReasonId = null,
    callback,
  ) {
    if (quantity < 1) {
      remove(basketEntryId, callback)
    } else {
      updateThis(basketEntryId, { quantity, returnReasonId }, callback)
    }
  }

  function clearBasket() {
    setIsLoading(true)
    core.dataStore
      .deleteRMABasket(basket.id)
      .then((res) => {
        setBasket({ entries: [] })
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function remove(basketEntryId, callback) {
    setIsLoading(true)
    core.dataStore
      .removeRMABasketEntry(
        basketEntryId,
        core.user.selectedCustomer.accountNo,
        core.user.userId,
      )
      .then((res) => {
        if (res.status === 200) {
          setBasket(res.data)

          if (callback) {
            callback()
          }
        } else {
          core.snackbar('warning', res.statusText)
        }
      })
      .catch((error) => {
        core.snackbar('warning', error.toString())
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function load() {
    setIsLoading(true)
    if (core.user.selectedCustomer.accountNo == '') {
      return
    }
    core.dataStore
      .getRMABasket(core.user.selectedCustomer.accountNo, core.user.userId)
      .then((res) => {
        if (res.status === 200) {
          setBasket(res.data)
        } else {
          core.snackbar('warning', res.statusText)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function submit(orderDetails, callback) {
    core.dataStore
      .archiveRMABasket({
        userId: core.user.userId,
        customerNo: core.user.selectedCustomer.accountNo,
        pickupPallets: orderDetails.pickupPallets,
        pickupPackages: orderDetails.pickupPackages,
      })
      .then((x) => {
        if (x.status === 200) {
          localStorage.setItem('lastRMAOrderNoId', x.data.rmaOrderNo)
          submitOrderToAxapta(orderDetails, x.data.rmaOrderNo, callback)
          resetOrderDetails()
        }
      })
      .catch((error) => {
        console.log(error)
        core.snackbar('warning', error.toString())
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function submitOrderToAxapta(orderDetails, rmaId, callback) {
    setIsLoading(true)

    const orderLines = basket.entries.map((item) => {
      const { itemId, quantity, returnReasonId } = item
      return {
        productNo: itemId,
        quantity,
        returnReasonId,
      }
    })

    console.log('orderLines', orderLines)

    const orderHeaderDto = {
      ...orderDetails,
      rmaId,
      salesTaker: core.user.userId,
      customerNo: core.user.selectedCustomer.accountNo,
      pickupName: core.user.selectedCustomer.accountName,
      RmaOrderLines: orderLines,
    }

    core.dataStore
      .createRMA(orderHeaderDto)
      .then((x) => {
        if (x.status === 200) {
          console.log(x)
          if (x.data.status) {
            //reset hook
            setBasket({ entries: [] })
            //reload basket
            load()
            if (callback) {
              callback(x.data.recid)
            }
          } else {
            core.snackbar(
              'error',
              'The B2B service did not return an order number',
            )
          }
        }
      })
      .catch((error) => core.snackbar('warning', error.toString()))
      .finally(() => {
        setIsLoading(false)
      })
  }

  function getCount() {
    return basket.entries.length
  }

  function validateOrderDetails(formData, callback) {
    setIsLoading(true)
    return core.dataStore
      .validateRMA(formData)
      .then((x) => {
        if (x.status === 200 && x.data) {
          setOrderDetails(x.data)
          setOrderDetailsValidated(true)
          if (typeof callback === 'function') {
            callback()
          }
        }
      })
      .catch((error) => core.snackbar('warning', error.toString()))
      .finally(() => {
        setIsLoading(false)
      })
  }

  function resetOrderDetails(params) {
    setOrderDetails(defaultOrderDetails)
    setOrderDetailsValidated(false)
  }

  useEffect(() => {
    load()
  }, [core.user.selectedCustomer.accountNo])

  return {
    isLoading,
    setIsLoading,
    count: getCount(),
    basket,
    add,
    multiAdd,
    updateEntry,
    remove,
    submit,
    orderDetails,
    setOrderDetails,
    validateOrderDetails,
    orderDetailsValidated,
    setOrderDetailsValidated,
    resetOrderDetails,
    addressFormLocked,
    setAddressFormLocked,
    clearBasket,
    isInitialized,
    setIsInitialized,
    useAnotherAddress,
    setUseAnotherAddress,
  }
}
