import React, { createContext } from 'react'
import { useProductsModel } from '../pages/Products/ProductsModel'

export const ProductsContext = createContext(null)

export default function ProductsProvider({ children }) {
  const products = useProductsModel()

  return (
    <ProductsContext.Provider value={products}>
      {children}
    </ProductsContext.Provider>
  )
}
