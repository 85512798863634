import React, { useContext } from 'react'

import classNames from 'classnames'
import Drawer from '@material-ui/core/Drawer'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { CoreContext } from '../../context/CoreContext'
import { makeStyles } from '@material-ui/core/styles'

export default function HubDrawer(props) {
  const { drawerWidth = 240, marginTop = 64, closedWidth = 70 } = props
  let dWidth = drawerWidth
  let drawerMargin = marginTop
  let drawerWidthClosed = closedWidth
  const useStyles = makeStyles((theme) => ({
    drawer: {
      width: drawerWidthClosed,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerPaper: {
      width: drawerWidthClosed,
    },
    drawerOpen: {
      width: dWidth,
      marginTop: drawerMargin,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: drawerWidthClosed,
      marginTop: drawerMargin,
    },
  }))

  const classes = useStyles()
  const core = useContext(CoreContext)
  const matches = useMediaQuery(core.theme.breakpoints.up('sm'))

  if (!matches) {
    return (
      <Drawer
        variant='temporary'
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: props.open,
          [classes.drawerClose]: !props.open,
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: props.open,
            [classes.drawerClose]: !props.open,
          }),
        }}
        open={props.open}
        onClose={props.flip}>
        {props.children}
      </Drawer>
    )
  }

  return (
    <Drawer
      variant='permanent'
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: props.open,
        [classes.drawerClose]: !props.open,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: props.open,
          [classes.drawerClose]: !props.open,
        }),
      }}
      open={props.open}>
      {props.children}
    </Drawer>
  )
}
