export default function StatisticStore(base) {
  let baseStore = base

  function getStatistics(accountName, currency) {
    let rs = baseStore.get(`/sales/${accountName}/${currency}`)
    return rs
  }

  return {
    getStatistics,
  }
}
