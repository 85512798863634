export default function FindNextShippingDate(date) {
  let daysToAdd = 1 //by default return tomorrow
  if (date.getDay() === 5) {
    //fri
    daysToAdd += 2
  } else if (date.getDay() === 6) {
    //sat
    daysToAdd++
  }
  date.setDate(date.getDate() + daysToAdd)
  return date
}
