import React, { useState } from 'react'

import '../../resources/css/overrides.css'

import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  outlinedInputFieldWithIcon: {
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  loader: {
    borderRadius: '10px 10px 0 0',
  },
})

function PasswordIcon(showPassword, onClick) {
  return {
    endAdornment: (
      <InputAdornment position='end'>
        <IconButton
          aria-label='Toggle password visibility'
          onClick={() => onClick()}
          href={void 0}>
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    ),
  }
}

function LoadingIcon(isLoading, icon) {
  return {
    endAdornment: (
      <InputAdornment
        position='end'
        className='outlinedInputFieldWithIcon'>
        {isLoading ? (
          <CircularProgress
            size={30}
            thickness={5}
          />
        ) : (
          <>{icon}</>
        )}
      </InputAdornment>
    ),
  }
}

function OutlinedInputField(props) {
  const [showPassword, setShowPassword] = useState(false)
  const { password } = props
  const { error } = props
  const {
    autocomplete,
    isLoading,
    onEnter,
    icon,
    id,
    type,
    fullWidth,
    required,
    label,
    value,
    htmlFor,
    disabled,
    classes,
    maxLength,
    ...rest
  } = props

  const inputProps = (password) =>
    password
      ? PasswordIcon(showPassword, togglePassword)
      : LoadingIcon(isLoading, icon)

  function togglePassword() {
    setShowPassword(!showPassword)
  }

  function handleChange(e) {
    props.onChange(e)
  }

  function catchEnter(e) {
    if (onEnter !== undefined && e.key === 'Enter') onEnter()
  }

  return (
    <div className={classes.root}>
      <TextField
        key={id}
        id={`outlined-adornment-weight-${id}`}
        variant='outlined'
        className={classNames(classes.margin, classes.textField)}
        InputProps={inputProps(password)}
        inputProps={{ maxLength }}
        onKeyPress={catchEnter}
        type={!password || showPassword ? (type ? type : 'text') : 'password'}
        autoComplete={autocomplete ? 'on' : 'off'}
        error={error}
        fullWidth={fullWidth}
        required={required}
        label={label}
        value={value}
        onChange={handleChange}
        htmlFor={htmlFor}
        disabled={disabled}
        {...rest}
      />
    </div>
  )
}

export default withStyles(styles)(OutlinedInputField)
