import React, { createContext } from 'react'
import { useCheckoutModel } from '../pages/Basket/CheckoutModel'

export const CheckoutContext = createContext(null)

export default function CheckoutProvider({ children }) {
  const checkout = useCheckoutModel()

  return (
    <CheckoutContext.Provider value={checkout}>
      {children}
    </CheckoutContext.Provider>
  )
}
