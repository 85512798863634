import { useContext, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { CoreContext } from '../../../../core/context/CoreContext'

export function useProductsModel() {
  const core = useContext(CoreContext)
  let location = useLocation()
  let history = useHistory()

  const pageSize = 24
  const urlParamsAccept = [
    'brand',
    'mainColor',
    'category',
    'subCategory',
    'news',
    'query',
    'scrollTo',
    'page',
  ]
  const isLargeScreen = window.matchMedia(
    `(min-width: ${core.theme.breakpoints.values.sm}px)`,
  ).matches
  const lastChosenViewMode =
    window.localStorage.getItem('productsListViewMode') || 'list'

  //todo customerId not set when first search is run
  const accountNo = core.user.selectedCustomer.accountNo
  const language = core.user.languageCode

  const [isInitialized, setIsInitialized] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [listViewMode, _setListViewMode] = useState(
    isLargeScreen ? lastChosenViewMode : 'grid',
  )
  const [allFacets, setAllFacets] = useState(undefined)
  const [onlyCampaign, _setOnlyCampaign] = useState(false)
  const [onlyArchive, _setOnlyArchive] = useState(false)

  const setListViewMode = (viewMode) => {
    _setListViewMode(viewMode)
    window.localStorage.setItem('productsListViewMode', viewMode)
  }

  // entire search state
  const [searchCollection, setSearchCollection] = useState({})

  const initialSelectedFactes = {
    brand: [],
    mainColor: [],
    subCategory: [],
    category: [],
    news: [],
  }
  const [selectedFacetValues, setSelectedFacetValues] = useState(
    initialSelectedFactes,
  )
  const [query, setQuery] = useState('')
  const [sortByField, setSortByField] = useState('BrandId')
  const [campaign, _setCampaign] = useState(
    JSON.parse(localStorage.getItem('campaign')) || '{}',
  )
  const [campaigns, setCampaigns] = useState([])

  const isFairApp = window.location.pathname.split('/')[1] === 'fair-app'

  const clearProducts = () => {
    setSearchCollection({ ...searchCollection, products: [] })
  }

  const setCampaign = (campaign) => {
    if (!campaign) {
      return
    }
    localStorage.setItem('campaign', JSON.stringify(campaign))
    _setCampaign(campaign)
  }

  // Product search triggers:
  function init() {
    if (!accountNo) {
      return
    }
    //get facet values
    if (!allFacets) {
      getAllFacetValues()
    }

    //Trigger search with or without urlparams
    let initalParamsFromQueryString = getSelectedFacetValuesFromURL()
    if (Object.keys(initalParamsFromQueryString).length > 0) {
      let options = {
        ...getSearchOptions(),
        query: initalParamsFromQueryString.hasOwnProperty('query')
          ? initalParamsFromQueryString.query[0]
          : '',
        selectedFacetValues: {
          brand: initalParamsFromQueryString.hasOwnProperty('brand')
            ? initalParamsFromQueryString.brand
            : [],
          mainColor: initalParamsFromQueryString.hasOwnProperty('mainColor')
            ? initalParamsFromQueryString.mainColor
            : [],
            subCategory: initalParamsFromQueryString.hasOwnProperty(
            'subCategory',
          )
            ? initalParamsFromQueryString.subCategory
            : [],
            category: initalParamsFromQueryString.hasOwnProperty(
            'category',
          )
            ? initalParamsFromQueryString.category
            : [],
          news: initalParamsFromQueryString.hasOwnProperty('news')
            ? initalParamsFromQueryString.news
            : [],
        },
        pageSize: initalParamsFromQueryString.hasOwnProperty('page')
          ? initalParamsFromQueryString.page[0] * pageSize
          : pageSize,
      }

      setQuery(
        initalParamsFromQueryString.hasOwnProperty('query')
          ? initalParamsFromQueryString.query[0]
          : '',
      )

      search(
        options,
        initalParamsFromQueryString.hasOwnProperty('page')
          ? initalParamsFromQueryString.page[0] * 1
          : 1,
        initalParamsFromQueryString.hasOwnProperty('scrollTo')
          ? initalParamsFromQueryString.scrollTo[0] * 1
          : 0,
      )
    } else {
      search()
    }
  }

  function getCampaigns() {
    //get campaigns
    if (!campaigns.length) {
      core.dataStore
        .getCampaigns(core.user.selectedCustomer.accountNo)
        .then((res) => {
          if (res.data) {
            setCampaigns(sortCampaigns(res.data))
            setCampaign(
              res.data?.find(
                (el) =>
                  el.campaignId ===
                  JSON.parse(localStorage.getItem('campaign') || '{}')
                    .campaignId,
              ) || {},
            )
          }
        })
    }
  }

  const sortCampaigns = (arr) => {
    return arr.sort((a, b) => {
      if (a.name > b.name) return 1
      if (a.name < b.name) return -1
      return 0
    })
  }

  const setCampaignOrArchive = (facet, facetValue) => {
    let options = {
      ...getSearchOptions(),
      selectedFacetValues: { ...selectedFacetValues },
      query
    }

    if (facet === 'campaign') {
      _setOnlyArchive(false)
      _setOnlyCampaign(facetValue)

      options.onlyCampaign = facetValue
      options.onlyArchive = false
    } else if (facet === 'archive') {
      _setOnlyCampaign(false)
      _setOnlyArchive(facetValue)

      options.onlyArchive = facetValue
      options.onlyCampaign = false
    }

    search(options);
  }

  function toggleFacetValue(facet, facetValue) {
    //Update selectedFacetValues
    let _selectedFacetValues = { ...selectedFacetValues }
    _selectedFacetValues[facet] = addIfNotExistsRemoveIfItDoes(
      _selectedFacetValues[facet] || [],
      facetValue,
    )
    setSelectedFacetValues(_selectedFacetValues)

    // Trigger search
    let options = {
      ...getSearchOptions(),
      selectedFacetValues: _selectedFacetValues,
      query,
    }
    search(options)
  }

  function handleSearchQueryChange(query) {
    setQuery(query)
    // Trigger search
    let options = { ...getSearchOptions(), selectedFacetValues, query: query }
    search(options)
  }

  function handleSortByChange(value) {
    setSortByField(value)
    let options = {
      ...getSearchOptions(),
      selectedFacetValues: selectedFacetValues,
      sortByField: value,
    }
    search(options)
  }

  function reset() {
    setQuery('')
    search({ ...getSearchOptions(), query: '' })
  }

  function increment() {
    if (isLoading || !hasMore) {
      return
    }

    // Trigger search
    let options = {
      ...getSearchOptions(),
      selectedFacetValues,
      query,
      pageNumber: searchCollection.pageNumber + 1,
    }
    search(options)
  }

  function searchExcel(callback) {
    core.dataStore.searchExcel({...getSearchOptions(), selectedFacetValues, query})
      .then(response => {
        if (response.status === 200) {
          callback(response)
        } else {
          console.log('warning', response.statusText)
          callback(undefined)
        }
      })
      .catch((e) => {
        console.log('warning', e.toString())
      })
  }

  function search(options = getSearchOptions(), pageNumber = 1, scrollTo = 0) {
    setIsLoading(true)
    // choose between normal and compaign search
    const endpoint = isFairApp
      ? core.dataStore.campaignProductSearch
      : core.dataStore.searchProducts

    endpoint(options)
      .then((response) => {
        if (response.status === 200) {
          setHasMore(!response.data.isLastPage)

          const products = response.data.products.map((item) => {
            return {
              ...item,
              quantity: item.colli
            }
          })

          if (response.data.pageNumber > 1) {
            setSearchCollection({
              ...response.data,
              products: [
                ...searchCollection.products,
                ...products,
              ],
            })
          } else {
            setSearchCollection({
              ...response.data,
              products: [
                ...products
              ],
              pageNumber: pageNumber,
            })
          }

          setSelectedFacetValues(response.data.selectedFacetValues)

          setIsInitialized(true)
          updateUrl(response.data)

          if (scrollTo !== 0) {
            document
              .querySelector('#product-scroller')
              .parentNode.scrollTo(0, scrollTo)
          }
        } else {
          console.log('warning', response.statusText)
        }
      })
      .catch((e) => {
        console.log('warning', e.toString())
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // Other:

  function getSelecteFacetValuesCount() {
    let count = 0
    Object.values(selectedFacetValues).forEach(
      (facet) => (count += facet.length),
    )

    if (query != "") {
      count++;
    }

    return count
  }

  function getProductById(itemId) {
    setIsLoading(true)
    return core.dataStore
      .getProductById(accountNo, itemId, language, core.user.userId)
      .then((res) => {
        if (res.status === 200) {
          return res.data
        } else {
          return null
        }
      })
      .catch((e) => {})
      .finally(() => {
        setIsLoading(false)
      })
  }

  function getReturnProductById(itemId) {
    setIsLoading(true)
    return core.dataStore
      .getReturnProductById(accountNo, itemId, language)
      .then((res) => {
        if (res.status === 200) {
          return res.data
        } else {
          return null
        }
      })
      .catch((e) => {})
      .finally(() => {
        setIsLoading(false)
      })
  }

  function getProductsByIds(ids) {
    return core.dataStore
      .searchProducts({ ...getSearchOptions(), SearchOnlyInTheseProducts: ids })
      .then((res) => {
        return res
      })
  }

  // Internal helpers:

  function getSearchOptions() {
    return {
      userId: core.user.userId,
      customerId: accountNo,
      selectedFacetValues: {
        brand: [],
        mainColor: [],
        subCategory: [],
        category: [],
        news: [],
      },
      languageCode: language,
      pageSize: pageSize,
      pageNumber: 1,
      query,
      sortByField,
      descendingSortOrder: false,
      campaignId: isFairApp ? campaign.campaignId : undefined,
      onlyCampaign,
      onlyArchive,
      initColi: true
    }
  }

  function addIfNotExistsRemoveIfItDoes(array, value) {
    if (!value.length) {
      return []
    }

    return array.indexOf(value) === -1
      ? [...array, value]
      : array.filter((i) => i !== value)
  }

  function updateUrl(sfv) {
    var urlParams = new URLSearchParams()

    Object.entries(sfv.selectedFacetValues).forEach((entry) => {
      let key = entry[0]
      let arr = entry[1]
      if (arr.length > 0) {
        urlParams.append(key, arr)
      }
    })

    if (sfv.query !== '') {
      urlParams.append('query', sfv.query)
    }

    urlParams.sort()

    var searchQuery = '?' + urlParams.toString()
    if (searchQuery !== '?') {
      history.replace({
        pathname: location.pathname,
        search: searchQuery,
        state: { isActive: true },
      })
    } else {
      history.replace({
        pathname: location.pathname,
        search: '',
        state: { isActive: true },
      })
    }
  }

  function getAllFacetValues() {
    core.dataStore
      .getAllFacetValues({
        customerId: accountNo,
        languageCode: language,
      })
      .then((res) => {
        if (res.status === 200) {
          setAllFacets(res.data)
        }
      })
      .catch(console.log)
  }

  function getSelectedFacetValuesFromURL() {
    let params = {}
    let searchParams = new URLSearchParams(window.location.search)
    searchParams.forEach((value, key) => {
      key = decodeURI(key)
      if (urlParamsAccept.indexOf(key) !== -1) {
        value = decodeURI(value)
        params[key] = value.split(',')
      }
    })
    return params
  }

  const gotoProduct = () => {
    if (location.pathname == '/sales/products') {
      var page = searchCollection.pageNumber
      var urlParams = new URLSearchParams(location.search)
      var scrollerPosition =
        document.querySelector('#product-scroller').parentNode.scrollTop
      console.log(scrollerPosition)

      urlParams.append('page', page)
      urlParams.append('scrollTo', scrollerPosition)

      urlParams.sort()

      var searchQuery = '?' + urlParams.toString()
    }

    history.replace({
      pathname: location.pathname,
      search: searchQuery,
      state: { isActive: true },
    })
  }

  return {
    setQuery,
    init,
    getCampaigns,
    isLoading,
    isInitialized,
    searchCollection,
    selectedFacetValues,
    query,
    sortByField,
    toggleFacetValue,
    handleSearchQueryChange,
    getSelecteFacetValuesCount,
    handleSortByChange,
    increment,
    searchExcel,
    hasMore,
    reset,
    getProductById,
    getProductsByIds,
    getReturnProductById,
    listViewMode,
    setListViewMode,
    allFacets,
    campaign,
    setCampaign,
    campaigns,
    setCampaigns,
    clearProducts,
    onlyCampaign,
    onlyArchive,
    gotoProduct,
    setCampaignOrArchive,
  }
}
