import React, { useContext } from 'react'
import ReactDOM from 'react-dom'
import { CoreContext } from '../context/CoreContext'
import { Typography, Button, makeStyles } from '@material-ui/core'
import TranslationLabel from '../components/TranslationLabel'

const useStyles = makeStyles((theme) => ({
  modalBackground: {
    top: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: '1000000000000000',
    backgroundColor: 'black',
    opacity: 0.5,
  },
  modalBodyWrapper: {
    border: '1px solid grey',
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '1000000000000001',
    backgroundColor: 'white',
    minWidth: '350px',
    padding: '20px',
    borderRadius: '3px',
  },
  headline: {
    textAlign: 'center',
    marginBottom: '15px',
  },
  message: {
    marginBottom: '10px',
  },
  buttons: {
    textAlign: 'right',
  },
}))

export default function Modal() {
  const core = useContext(CoreContext)
  const { headline, message } = core.modal
  const classes = useStyles()

  return core.modal.headline || core.modal.message
    ? ReactDOM.createPortal(
        <div>
          <div
            onClick={() => core.setModal({})}
            className={classes.modalBackground}></div>
          <div className={classes.modalBodyWrapper}>
            <Typography
              className={classes.headline}
              variant='h4'
              component='h1'>
              {headline}
            </Typography>
            <Typography
              className={classes.message}
              variant='subtitle1'
              component='p'>
              {message}
            </Typography>
            <div className={classes.buttons}>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => core.setModal({})}>
                <TranslationLabel name='ok' />
              </Button>
            </div>
          </div>
        </div>,
        document.getElementById('modals'),
      )
    : null
}
