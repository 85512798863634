import React, { useContext, useState, useRef, useEffect  } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, Grid, TextField, Button } from '@material-ui/core'

import { CategoryContext } from '../context/CategoryContext'
import { ProductsContext } from '../context/ProductsContext'
import { BasketContext } from '../../../core/context/BasketContext'

import priceDtoHelper from '../../../shared/PriceDtoHelper'
import InriverImage from '../../../core/components/InriverImage'
import TranslationLabel from '../../../core/components/TranslationLabel'

import StockItem from './StockItem'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles((theme) => ({
  imgWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  imgWrapperInner: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  label: {
    textTransform: 'uppercase',
    fontSize: 11,
    position: 'absolute',
    top: 5,
    left: 7,
    backgroundColor: 'rgba(255, 255, 255, .6)',
    padding: '1px 3px',
    color: theme.palette.grey[600],
  },
  labelDiscount: {
    left: 'auto',
    right: 7,
    fontWeight: 'bold',
    fontSize: 12,
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    margin: '0 10px',
    display: 'block',

    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    },
  },
  small: {
    fontSize: 13,
  },
  double: {
    display: 'flex',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[200],
    paddingBottom: 5,
    marginTop: 5,
    padding: '0 10px',
    flexWrap: 'wrap',
  },
  box: {
    height: '100%',
    borderWidth: 1,
    borderColor: theme.palette.grey[200],
    borderStyle: 'solid',
  },
  head: {
    position: 'relative',
    height: 0,
    overflow: 'hidden',
    paddingTop: '71.5%',
  },
  info: {
    marginTop: 'auto',
  },
  quantityOptionsHeader: {
    display: 'block',
    marginRight: '15px'
  },
  quantityOptionsCell: {
    display:'block',
    whiteSpace:'nowrap'
  },
  quantityOptions: { display:'flex',fontWeight:'400',lineHeight:'1.5',letterSpacing:'0.00938em',padding:'5px 10px',fontSize:'13px',margin: '10px 0'},
  priceNormal: {
    fontSize: 12,
    color: theme.palette.grey[500],
    display: 'block',
  },
  priceText: {
    fontSize: 12,
    color: theme.palette.grey[500],
    display: 'inline-block',
    marginLeft: 10,
  },
  currency: {
    position: 'absolute',
    top: '-16px',
    left: '62px',
    color: theme.palette.grey[600],
    fontSize: 12,
  },
  priceYours: {
    fontSize: 16,
    fontWeight: 'bold',
    display: 'inline-block',
  },
  btn: {
    color: 'white',
    backgroundColor: 'black',
    flexGrow: 1,
    height: 40,
    marginLeft: '5px',

    '&:hover': {
      backgroundColor: theme.palette.grey[800],
    },
  },
}))

export default function(props) {
  const { product = {}, showRetailPrice } = props
  const classes = useStyles()
  const categories = useContext(CategoryContext)
  const productsModel = useContext(ProductsContext)
  const basket = useContext(BasketContext)
  const [currentPrice, setCurrentPrice] = useState(priceDtoHelper.findPrice(props.product.colli, props.product))
  const [quantity, setQuantity] = useState(props.product.colli)
  const inputRef = useRef(null)

  useEffect(() => {
    if (quantity === '') {
      return
    }

    if (quantity <= 0) {
      handleChangeQuantity({
        target: {
          value:
            (props.product.priceDtos[0] && props.product.priceDtos[0].minQuantity) || 1,
        },
      })
    }
  }, [quantity])

  const handleChangeQuantity = (e) => {
    let val = e.target.value
    let price = priceDtoHelper.findPrice(val, props.product)

    setQuantity(val)
    setCurrentPrice(price)
  }

  const addToBasket = (e) => {
    basket.add({
      itemId: props.product.productNo,
      qty: priceDtoHelper.findNextAllowedNumber(props.product, quantity),
      campaignId: null,
      requestedShippingDate: null,
    })
  }

  const onBlurHandler = () => {
    const allowedQuantity = priceDtoHelper.findNextAllowedNumber(props.product, quantity)
    setQuantity(allowedQuantity)
    setCurrentPrice(priceDtoHelper.findPrice(allowedQuantity, props.product))
  }

  const getBrandLabel = (id) => {
    if (!categories) {
      return null
    }
    const brandItem =
      categories.brands && categories.brands.find((el) => el.id === id)
    return brandItem && brandItem.label
  }

  const renderColiQuntity = () => {
    var price = props.product.priceDtos.find(e => e.priceType == priceDtoHelper.priceEnum.Archive || e.priceType == priceDtoHelper.priceEnum.Campaign)

    if (!price) {
      return props.product.colli;
    }

    return price.minQuantity;
  }

  const renderColiName = () => {
    var price = props.product.priceDtos.find(e => e.priceType == priceDtoHelper.priceEnum.Archive || e.priceType == priceDtoHelper.priceEnum.Campaign)

    if (!price) {
      return (
        <span className={classes.quantityOptionsHeader}>
          <TranslationLabel name='salesColliQuantity' />
        </span>
      )
    }

    if (price.priceType == priceDtoHelper.priceEnum.Campaign) {
      return (
        <span className={classes.quantityOptionsHeader} style={{color: 'green'}}>
          <TranslationLabel name='campaignPrice' />
        </span>
      )
    }
    else {
      return (
        <span className={classes.quantityOptionsHeader} style={{color: 'red'}}>
          <TranslationLabel name='ArchivePrice' />
        </span>
      );
    }
  }

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={6}
      lg={3}
      xl={3}>
      <Grid
        container
        direction='column'
        className={classes.box}>
        <Grid
          item
          className={classes.head}>
          <div className={classes.imgWrapper}>
            <Link
              to={`/sales/products/item/${product.productNo}`}
              onClick={productsModel.gotoProduct}
              className={classes.imgWrapperInner}>
              <InriverImage
                className={classes.img}
                productNo={product.productNo}
                displayConfig='SalesSite_Large'
                alt={product.description}
                showImage={product.showImage}
              />
            </Link>
          </div>

          <span className={classes.label}>
            {getBrandLabel(product.brandNo)}
          </span>

          {priceDtoHelper.hasDiscount(currentPrice) && (
              <span className={`${classes.label} ${classes.labelDiscount}`}>
                -{currentPrice.discountPercentage}%
              </span>
            )}
        </Grid>
        <Grid item>
          <Link
            to={`/sales/products/item/${product.productNo}`}
            onClick={productsModel.gotoProduct}
            className={classes.title}>
            {product.description}
          </Link>
        </Grid>

        <Grid
          item
          className={classes.info}>
          <div className={classes.double}>
            <span className={classes.small}>
              <TranslationLabel name='productNumber' />
              <span> {product.productNo}</span>
            </span>

            <StockItem
              text={product.inventoryText}
              status={product.inventoryStatus}
            />
          </div>
        </Grid>

        <Grid container xxl={6}>
          <Grid item>
            <div className={classes.quantityOptions}>
              <div>
                {props.product.colli !== 1 && (<span className={classes.quantityOptionsHeader}><TranslationLabel name='splitColliQuantity' /></span>)}
                {renderColiName()}
                <span className={classes.quantityOptionsHeader}>Brownbox</span>
              </div>
              <div style={{textAlign: 'end'}}>
                {props.product.colli !== 1 && (<span className={classes.quantityOptionsCell}>1-{props.product.colli-1} <TranslationLabel name='productsUnit' /></span>)}
                <span className={classes.quantityOptionsCell}>{renderColiQuntity()} <TranslationLabel name='productsUnit' /></span>
                <span className={classes.quantityOptionsCell}>{props.product.brownBox.numberOfItemsInBox} <TranslationLabel name='productsUnit' /></span>
              </div>
            </div>
          </Grid>
          <Grid item style={{textAlign: 'right', padding: '5px 10px', flexGrow: 1, alignSelf: 'center'}}>
              {!props.showRetailPrice && (
                <>
                  {priceDtoHelper.hasDiscount(currentPrice) && (
                    <del className={classes.priceNormal}>{currentPrice.grossPrice}</del>
                  )}
                  <span className={classes.priceYours}>{currentPrice.netPrice}</span>
                </>
              )}

              {props.showRetailPrice && (
                <span className={classes.priceYours}>
                  {props.product.recommendedRetailPrice}
                </span>
              )}
          </Grid>
        </Grid>

        <Grid item style={{padding: '5px 10px', display: 'flex'}}>
          <TextField
            onBlur={onBlurHandler}
            size='small'
            type='number'
            value={quantity}
            onChange={handleChangeQuantity}
            className={classes.quantity}
            style={{width: '60px'}}
            InputProps={{
              inputProps: {
                step: props.product.colli || 1,
              },
            }}
            inputRef={inputRef}
            onClick={() => {inputRef.current.select(0, -1)}}
            label={<TranslationLabel name='numberOf' />}
            variant='outlined'
          />

          <Button
            disabled={priceDtoHelper.hasNoPrice(props.product)}
            variant='contained'
            disableElevation
            className={classes.btn}
            onClick={addToBasket}>
            <AddIcon />
          </Button>

        </Grid>
      </Grid>
    </Grid>
  )
}
