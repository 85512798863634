import React, { useContext, useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { formatCurrency } from '../../../libraries/CurrencyTransformer'
import { BasketContext } from '../../../../../core/context/BasketContext'
import { CoreContext } from '../../../../../core/context/CoreContext'
import { makeStyles, Dialog, DialogTitle, Button } from '@material-ui/core'
import TranslationLabel from '../../../../../core/components/TranslationLabel'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  field: {
    marginRight: '70px',
  },
  total: {
    justifyContent: 'space-between',
    fontWeight: 700,
    padding: '5px 0',
    borderTop: '1px solid #dedede',
    marginTop: 10,
  },
  deleteButton: {
    width: 'fit-content',
    marginBottom: 10,
  },
}))
export default function BasketSummary(props) {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const basketModel = useContext(BasketContext)
  const core = useContext(CoreContext)
  const currency = core.user.selectedCustomer.currency
  const classes = useStyles()

  const { hideEmptyBasketButton, close, showMarkup } = props

  const showEmptyBasketButton =
    basketModel.basket.entries.length && !hideEmptyBasketButton

  const emptyBasket = (e) => {
    basketModel.deleteBasket(basketModel.basket.id)
    setConfirmationModalOpen(false)
    close(e)
  }

  const handleBasketPreviewClick = (e) => {
    if (basketModel.basketType == 0) {
      core.history.push('/sales/basketOverview')
    } else if (basketModel.basketType == 1) {
      core.history.push('/fair-app/basketOverview')
    }
    close(e)
  }

  useEffect(() => {
    //load with markup on confirmation page
    const isConfirmationPage =
      window.location.href.split('/')[
        window.location.href.split('/').length - 1
      ] === 'confirmation'
    basketModel.load(isConfirmationPage)
  }, [])

  return (
    <Grid
      container
      direction='column'
      className={classes.root}>
      <Dialog
        open={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}>
        <DialogTitle>
          <TranslationLabel name='emptyBasketConfirmationHeadline' />
          <div style={{ marginTop: 50, textAlign: 'center' }}>
            <Button
              style={{ float: 'left' }}
              onClick={() => setConfirmationModalOpen(false)}
              variant='contained'
              color='primary'>
              <TranslationLabel name='cancel' />
            </Button>
            <Button
              style={{ float: 'right' }}
              onClick={emptyBasket}
              variant='contained'
              color='secondary'>
              <TranslationLabel name='confirmEmptyBasket' />
            </Button>
          </div>
        </DialogTitle>
      </Dialog>
      {!!showEmptyBasketButton && (
        <div style={{ flexDirection: 'row' }}>
          <Button
            onClick={() => setConfirmationModalOpen(true)}
            variant='contained'
            color='secondary'
            className={classes.deleteButton}>
            <TranslationLabel name='emptyBasket' />
          </Button>
          <Button
            onClick={handleBasketPreviewClick}
            className={classes.deleteButton}
            color='primary'>
            <TranslationLabel name='seeBasketOverview' />
          </Button>
        </div>
      )}
      <Grid
        item
        container
        justify='space-between'>
        <div className={classes.field}>
          <TranslationLabel name='subTotal' />
        </div>
        <div>{formatCurrency(basketModel.basket.amount, currency)}</div>
      </Grid>
      {showMarkup && !!basketModel.basket.markupAmount && (
        <Grid
          item
          container
          justify='space-between'>
          <div>
            <TranslationLabel name='markupAmount' />
          </div>
          <div>{formatCurrency(basketModel.basket.markupAmount, currency)}</div>
        </Grid>
      )}
      {!!basketModel.basket.vatAmount && (
        <Grid
          item
          container
          justify='space-between'>
          <div>
            <TranslationLabel name='vatAmount' />
          </div>
          <div>{formatCurrency(basketModel.basket.vatAmount, currency)}</div>
        </Grid>
      )}
      <Grid
        item
        container
        className={classes.total}>
        <div>
          <TranslationLabel name='orderAmount' />
        </div>
        <div>{formatCurrency(basketModel.basket.totalAmount, currency)}</div>
      </Grid>
    </Grid>
  )
}
