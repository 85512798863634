import axios from 'axios'
import React from 'react'

export default function BaseStore(httpClient) {
  const cancelToken = React.useRef(null)
  cancelToken.current = {}

  function setHttpClient(hc) {
    httpClient = hc
  }

  function post(url, obj) {
    return httpClient.post(url, obj)
  }

  function put(url, obj) {
    return httpClient.put(url, obj)
  }

  function get(url, obj) {
    return httpClient.get(url, obj)
  }

  function del(url) {
    return httpClient.delete(url)
  }

  async function stall(stallTime = 3000) {
    await new Promise((resolve) => setTimeout(resolve, stallTime))
  }

  function postSingleton(url, obj) {
    // will cancel pending request when sending a new one.
    if (cancelToken.current[url]) {
      cancelToken.current[url].cancel('')
    }
    const token = axios.CancelToken.source()
    cancelToken.current[url] = token
    return httpClient.post(url, obj, { cancelToken: token.token })
  }

  function getSingleton(url, requestIdentifier) {
    // will cancel pending request when sending a new one.
    if (cancelToken.current[requestIdentifier]) {
      cancelToken.current[requestIdentifier].cancel('')
    }
    const token = axios.CancelToken.source()
    cancelToken.current[requestIdentifier] = token
    return httpClient.get(url, { cancelToken: token.token })
  }

  return {
    setHttpClient,
    post,
    postSingleton,
    getSingleton,
    put,
    get,
    del,
    stall,
    httpClient,
  }
}
