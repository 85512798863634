import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backgroundToday: {
    background: theme.palette.primary.main,
  },
  backgroundOther: {
    background: '#3c69c2',
  },
  row: {
    maxHeight: 70,
    borderBottom: '1px solid darkgrey',
    cursor: 'pointer',
  },
  leftContainer: {
    width: 120,
    height: '65px',
    color: 'white',
    padding: 5,
    paddingBottom: 0,
    display: 'inline-block',
    boxSizing: 'border-box',
  },
  rightContainer: {
    boxSizing: 'border-box',
    width: 'calc(100% - 120px)',
    display: 'inline-block',
    height: '65px',
    verticalAlign: 'top',
  },
  day: {
    height: '100%',
    textAlign: 'center',
    fontSize: '16px',
    margin: '5px',
  },
  month: {
    textAlign: 'center',
  },
  icon: {
    verticalAlign: 'text-top',
    width: 18,
    height: 18,
    marginRight: '5px',
    marginLeft: '0px',
  },
  title: {
    'color': theme.palette.grey['800'],
    'lineHeight': '1rem',
    'margin': 10,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  appointmentInfoLine: {
    margin: '0',
    color: theme.palette.grey['600'],
  },
  phoneNumberContainer: {
    display: 'inline-block',
    marginLeft: 10,
  },
  subjectContainer: {
    marginLeft: 10,
    display: 'inline-block',
  },
  modal: {
    backgroundColor: 'white',
    width: '100%',
    maxWidth: '500px',
    margin: 'auto',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    padding: '25px',
    paddingTop: 10,
    textAlign: 'center',
    outline: 'none',
  },
  modalInfoLine: {
    color: theme.palette.grey['800'],
    width: '100%',
    marginBottom: 10,
    fontSize: 16,
    lineHeight: '32px',
  },
  appointmentInfoKey: {
    textAlign: 'left',
    width: '120px',
    display: 'inline-block',
    verticalAlign: 'top',
  },
  appointmentInfoValue: {
    textAlign: 'left',
    display: 'inline-block',
    width: 'calc(100% - 120px)',
    minWidth: '200px',
  },
  modalInfoBody: {
    width: '100%',
    maxWidth: '600px',
    margin: 'auto',
    marginBottom: '50px',
  },
  modalHeadline: {
    marginBottom: 20,
    marginTop: 20,
  },
  timePicker: {
    width: '180px',
  },
  disabled: {
    color: theme.palette.grey['800'],
  },
  filtersContainer: {
    padding: '5px 15px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  scrollable: {
    overflowY: 'scroll',
    height: '253px',
  },
  bold: {
    fontWeight: 700,
  },
  status1: {
    marginRight: '5px',
    color: 'blue',
  },
  status2: {
    marginRight: '5px',
    color: 'green',
  },
  status3: {
    marginRight: '5px',
    color: 'darkorange',
  },
  buttons: {
    width: '100%',
    height: '40px',
    padding: '0 30px',
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
  },
  cancelButton: {
    margin: 3,
    color: 'darkorange',
  },
  finishButton: {
    margin: 3,
    color: 'green',
  },
  validationLine: {
    color: 'red',
    marginLeft: '110px',
    textAlign: 'left',
    fontSize: '14px',
    display: 'inline-block',
    width: 'calc(100% - 110px)',
  },
}))
