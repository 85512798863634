import React, { useContext, useEffect, useState } from 'react'
import Modal from '@material-ui/core/Modal'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import { CoreContext } from '../../../../core/context/CoreContext'
import DatePicker from '../../../../shared/DatePicker'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers'
import { useStyles } from './styles'
import { statusEnum, viewModeEnum } from './enums'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check'

const ModalWithAppointment = (props) => {
  const getFormattedDate = (isoString) => {
    const date = new Date(isoString)
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toJSON()
      .split('.')[0]
  }

  const getFormattedDatePlusTwoHours = (isoString) => {
    const date = new Date(isoString)
    return new Date(
      date.getTime() - date.getTimezoneOffset() * 60000 + 2 * 60 * 60000,
    )
      .toJSON()
      .split('.')[0]
  }

  const isValidDate = (date) => {
    return (
      date &&
      Object.prototype.toString.call(date) === '[object Date]' &&
      !isNaN(date)
    )
  }

  const core = useContext(CoreContext)
  const { changeAppointment, createAppointment } = core.dataStore
  const { OPEN, CANCELLED, FINISHED } = statusEnum
  const { CUSTOMERVIEW, SALESVIEW, EDIT, CREATE } = viewModeEnum
  const classes = useStyles()
  const [appointment, setAppointment] = useState(
    props.appointment || {
      customerNo: '',
      start: getFormattedDate(new Date().toISOString()),
      end: getFormattedDate(new Date().toISOString()),
      type: 1,
      status: OPEN,
      title: '',
      description: '',
      customerContact: '',
      rdgContactUserId: '',
      rdgContactDisplayName: '',
    },
  )
  const [viewMode, setViewMode] = useState(
    props.viewMode !== undefined ? props.viewMode : SALESVIEW,
  )
  const { appointmentTypes, onClose } = props

  useEffect(() => {
    if (viewMode === CREATE) {
      setAppointment({
        ...appointment,
        rdgContactUserId: core.user.userSetting.userId,
        rdgContactDisplayName: core.user.userSetting.displayName,
        customerNo: core.user.selectedCustomer.accountNo,
        customerName: core.user.selectedCustomer.accountName,
      })
    }
  }, [core.user.userSetting.userId, core.user.selectedCustomer])

  const onStartChange = (date) => {
    if (!isValidDate(date)) {
      return
    }
    //set the day of the end date to the same day as the start date + 2 hours
    setAppointment({
      ...appointment,
      start: getFormattedDate(date),
      end: getFormattedDatePlusTwoHours(date),
    })
  }

  const isEndBeforeStart =
    new Date(appointment.start).getTime() > new Date(appointment.end).getTime()

  const onEndChange = (date) => {
    if (!isValidDate(date)) {
      return
    }
    setAppointment({ ...appointment, end: getFormattedDate(date) })
  }
  const onPhoneChange = (e) => {
    setAppointment({ ...appointment, customerPhone: e.target.value })
  }
  const onTitleChange = (e) => {
    setAppointment({ ...appointment, title: e.target.value })
  }
  const onDescriptionChange = (e) => {
    setAppointment({ ...appointment, description: e.target.value })
    //changing the description is possible on the SALESVIEW mode
    if (viewMode === SALESVIEW) {
      changeAppointment({ ...appointment, description: e.target.value })
    }
  }
  const onContactChange = (e) => {
    setAppointment({ ...appointment, customerContact: e.target.value })
  }
  const onTypeChange = (e) => {
    setAppointment({ ...appointment, type: e.target.value })
  }

  const onSubmitEdit = () => {
    changeAppointment(appointment).then(() => {
      setViewMode(SALESVIEW)
      onClose()
    })
  }
  const cancelAppointment = () => {
    changeAppointment({ ...appointment, status: CANCELLED }).then(() => {
      onClose()
    })
  }
  const finishAppointment = () => {
    changeAppointment({ ...appointment, status: FINISHED }).then(() => {
      onClose()
    })
  }
  const onCreateAppointment = () => {
    createAppointment(appointment).then(() => {
      onClose()
    })
  }

  const Buttons = () => {
    switch (viewMode) {
      case CUSTOMERVIEW:
        return (
          <div className={classes.buttons}>
            <Button
              onClick={() => onClose()}
              variant='contained'
              color='primary'
              className={classes.rightButton}>
              <TranslationLabel name='close' />
            </Button>
          </div>
        )
      case SALESVIEW:
        return (
          <div className={classes.buttons}>
            <Button
              className={classes.leftButton}
              onClick={() => onClose()}
              variant='contained'
              color='secondary'>
              <TranslationLabel name='cancel' />
            </Button>
            <Button
              onClick={() => setViewMode(EDIT)}
              variant='contained'
              color='primary'
              className={classes.rightButton}>
              <TranslationLabel name='editAppointment' />
            </Button>
          </div>
        )
      case EDIT:
        return (
          <div className={classes.buttons}>
            <Button
              onClick={() => {
                setAppointment(props.appointment)
                setViewMode(SALESVIEW)
              }}
              className={classes.leftButton}
              variant='contained'
              color='secondary'>
              <TranslationLabel name='back' />
            </Button>
            <Button
              className={classes.rightButton}
              disabled={isEndBeforeStart}
              onClick={onSubmitEdit}
              variant='contained'
              color='primary'>
              <TranslationLabel name='save' />
            </Button>
          </div>
        )
      case CREATE:
        return (
          <div className={classes.buttons}>
            <Button
              onClick={props.onClose}
              variant='contained'
              color='secondary'
              className={classes.leftButton}>
              <TranslationLabel name='cancel' />
            </Button>
            <Button
              onClick={onCreateAppointment}
              variant='contained'
              color='primary'
              disabled={isEndBeforeStart}
              className={classes.rightButton}>
              <TranslationLabel name='createAppointment' />
            </Button>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <Modal {...props}>
      <div className={classes.modal}>
        <Typography
          className={classes.modalHeadline}
          variant='h4'>
          <TranslationLabel name='appointment' />
        </Typography>
        <div className={classes.modalInfoBody}>
          <div className={classes.modalInfoLine}>
            <span className={classes.appointmentInfoKey}>
              <TranslationLabel name='customer' />:
            </span>
            <TextField
              InputProps={{
                disableUnderline: true,
                classes: {
                  disabled: classes.disabled,
                },
              }}
              fullWidth
              disabled
              className={classes.appointmentInfoValue}
              value={`${appointment.customerNo} - ${appointment.customerName}`}
              multiline
            />
          </div>
          <div className={classes.modalInfoLine}>
            <span className={classes.appointmentInfoKey}>
              <TranslationLabel name='type' />:
            </span>
            <span className={classes.appointmentInfoValue}>
              <Select
                disableUnderline={viewMode < EDIT}
                fullWidth
                disabled={viewMode < EDIT}
                value={appointment.type}
                onChange={onTypeChange}
                IconComponent={() => null}
                classes={classes.disabled}
                style={{ color: 'black' }}>
                {appointmentTypes.map((type) => (
                  <MenuItem value={type.type}>{type.typeText}</MenuItem>
                ))}
              </Select>
            </span>
          </div>
          <div className={classes.modalInfoLine}>
            <span className={classes.appointmentInfoKey}>
              <TranslationLabel name='contact' />:
            </span>
            <span className={classes.appointmentInfoValue}>
              <TextField
                InputProps={{
                  disableUnderline: viewMode < EDIT,
                  classes: {
                    disabled: classes.disabled,
                  },
                }}
                inputProps={{
                  maxLength: 100,
                }}
                fullWidth
                onChange={onContactChange}
                disabled={viewMode < EDIT}
                value={
                  viewMode === CUSTOMERVIEW
                    ? appointment.rdgContactDisplayName
                    : appointment.customerContact
                }
              />
            </span>
          </div>
          <div className={classes.modalInfoLine}>
            <span className={classes.appointmentInfoKey}>
              <TranslationLabel name='phone' />:
            </span>
            <span className={classes.appointmentInfoValue}>
              <TextField
                InputProps={{
                  disableUnderline: viewMode < EDIT,
                  classes: {
                    disabled: classes.disabled,
                  },
                }}
                inputProps={{
                  maxLength: 20,
                }}
                fullWidth
                onChange={onPhoneChange}
                disabled={viewMode < EDIT}
                value={appointment.customerPhone}
              />
            </span>
          </div>
          <div className={classes.modalInfoLine}>
            <span className={classes.appointmentInfoKey}>
              <TranslationLabel name='date' />:
            </span>
            <span className={classes.appointmentInfoValue}>
              <DatePicker
                autoOk
                onChange={onStartChange}
                className={classes.timePicker}
                disabled={viewMode < EDIT}
                InputProps={{
                  disableUnderline: viewMode < EDIT,
                  classes: {
                    disabled: classes.disabled,
                  },
                }}
                value={appointment.start}
                fullWidth
              />
            </span>
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={classes.modalInfoLine}>
              <span className={classes.appointmentInfoKey}>
                <TranslationLabel name='start' />:
              </span>
              <span className={classes.appointmentInfoValue}>
                <KeyboardTimePicker
                  onChange={onStartChange}
                  fullWidth
                  ampm={false}
                  className={classes.timePicker}
                  disabled={viewMode < EDIT}
                  value={appointment.start}
                  InputProps={{
                    disableUnderline: viewMode < EDIT,
                    classes: {
                      disabled: classes.disabled,
                    },
                  }}
                />
              </span>
            </div>
            <div className={classes.modalInfoLine}>
              <span className={classes.appointmentInfoKey}>
                <TranslationLabel name='until' />:
              </span>
              <span className={classes.appointmentInfoValue}>
                <KeyboardTimePicker
                  onChange={onEndChange}
                  ampm={false}
                  className={classes.timePicker}
                  disabled={viewMode < EDIT}
                  value={appointment.end}
                  error={isEndBeforeStart}
                  fullWidth
                  InputProps={{
                    disableUnderline: viewMode < EDIT,
                    classes: {
                      disabled: classes.disabled,
                    },
                  }}
                />
              </span>
            </div>
          </MuiPickersUtilsProvider>
          {isEndBeforeStart && (
            <div className={classes.validationLine}>
              <TranslationLabel name='endTimeMustBeAfterStarTime' />
            </div>
          )}
          <div className={classes.modalInfoLine}>
            <span className={classes.appointmentInfoKey}>
              <TranslationLabel name='title' />:
            </span>
            <span className={classes.appointmentInfoValue}>
              <TextField
                InputProps={{
                  disableUnderline: viewMode < EDIT,
                  classes: {
                    disabled: classes.disabled,
                  },
                }}
                inputProps={{
                  maxLength: 100,
                }}
                onChange={onTitleChange}
                disabled={viewMode < EDIT}
                value={appointment.title}
                fullWidth
              />
            </span>
          </div>
          {viewMode != CUSTOMERVIEW && (
            <div className={classes.modalInfoLine}>
              <span className={classes.appointmentInfoKey}>
                <TranslationLabel name='description' />:
              </span>
              <span className={classes.appointmentInfoValue}>
                <TextField
                  multiline
                  InputProps={{
                    classes: {
                      disabled: classes.disabled,
                    },
                  }}
                  fullWidth
                  onChange={onDescriptionChange}
                  disabled={viewMode === CUSTOMERVIEW}
                  value={appointment.description}
                />
              </span>
            </div>
          )}
          <div className={classes.modalInfoLine}>
            <span className={classes.appointmentInfoKey}></span>
            <div className={classes.appointmentInfoValue}>
              {appointment.status === OPEN ? (
                viewMode === SALESVIEW && (
                  <>
                    <Button
                      onClick={cancelAppointment}
                      className={classes.cancelButton}
                      startIcon={<DeleteIcon />}
                      variant='outlined'
                      color='primary'>
                      <TranslationLabel name='cancelAppointment' />
                    </Button>
                    <Button
                      onClick={finishAppointment}
                      className={classes.finishButton}
                      startIcon={<CheckIcon />}
                      variant='outlined'
                      color='primary'>
                      <TranslationLabel name='finishAppointment' />
                    </Button>
                  </>
                )
              ) : (
                <p
                  className={
                    classes[`status${appointment.status}`] + ' ' + classes.bold
                  }>
                  {appointment.statusText}
                </p>
              )}
            </div>
          </div>
        </div>
        <Buttons />
      </div>
    </Modal>
  )
}

export default ModalWithAppointment
