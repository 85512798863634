import React, { useState, useContext, useEffect } from "react";
import InfiniteScroll from 'react-infinite-scroller'
import {
  makeStyles,
  TextField,
  InputAdornment,
  LinearProgress,
  FormControl,
  FormControlLabel,
  Switch,
  Button,
  Drawer,
  MenuItem,
  Select,
  CircularProgress,
  Typography,
  Grid
} from "@material-ui/core";

import { CoreContext } from '../../../../core/context/CoreContext'
import { ProductsContext } from '../../context/ProductsContext'

import ProductSearchBarFacets from './ProductSearchBarFacets'
import EmptyState from '../../components/EmptyState'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import GridViewItem from '../../components/GridViewItem'
import TableView from '../../components/TableView'
import OpenOrdersModal from './OpenOrdersModal'

import SearchIcon from '@material-ui/icons/Search'
import MoreIcon from '@material-ui/icons/More';
import GridOnIcon from '@material-ui/icons/GridOn'
import ListIcon from '@material-ui/icons/List'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  productsPageRoot: {
    width: 'calc(100% + 32px)',
    margin: '-8px 20px 0 -16px',
    flexGrow: 1,
    background: 'white',
    display: 'flex',
    flexFlow: 'column',
    height: 'calc(100% - 31px)',
    position: 'relative',

    [theme.breakpoints.up('sm')]: {
      margin: '-17px 20px 0 -16px',
    },
  },
  ProductsPageHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: '#EAEAEA',
    padding: '10px 15px',

    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column-reverse'
    },
  },
  ProductsPageSearch: {
    background: '#fff',
    borderRadius: 4,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 10
    },
  },
  textField: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    '&::-webkit-search-decoration,::-webkit-search-cancel-button,::-webkit-search-results-button,::-webkit-search-results-decoration': {
      display: 'none'
    }
  },
  ProductsPageFacets: {
    marginLeft: -10
  },
  stickyloader: {
    width: '100%',
    visibility: 'hidden',
    position: 'absolute'
  },
  loaderVisible: {
    visibility: 'visible',
  },
  resetButton: {
    display: 'inline-block',
    border: '1px solid lightgrey',
    marginRight: 5,
    marginBottom: 5,
    borderRadius: 2,
    padding: 5,
  },
  resetButtonText: {
    verticalAlign: 'middle',
  },
  greyedOutResetButtonText: {
    color: theme.palette.grey['500'],
  },
  resetButtonIcon: {
    verticalAlign: 'middle',
    marginLeft: 5,
    width: 15,
    height: 15,
  },
  ProductsPageSubHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 15px',
    borderBottom: '1px solid rgba(0, 0, 0, .12)',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
    },
  },
  ProductsPageHeaderResults: {
    display: 'flex',
    alignItems: 'center',
  },
  ProductsPageSettings: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',

    [theme.breakpoints.down('md')]: {
      marginBottom: 10,
      width: '100%'
    },
  },
  ProductsPageSettingsLast: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
    },
  },
  ProductsPageHeaderResultsLast: {
    marginLeft: 10,
    paddingLeft: 10,
    borderLeft: '2px solid rgba(0, 0, 0, .12)'
  },
  cursor: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  drawer: {
    padding: '20px 10px',
    width: 300
  },
  moreOptionsRow: {
    width: '100%',
    marginBottom: '20px',
    paddingBottom: '20px',
    borderBottom: '1px solid rgba(0, 0, 0, .12)'
  },
  scrollableProducts: {
    marginTop: 25,
    overflowY: 'scroll',
    flex: '1 1 auto',
  },
}))

export default function ProductsPageV2() {
  const classes = useStyles()
  const core = useContext(CoreContext)
  const productsModel = useContext(ProductsContext)
  const [openOrdersModal, setOpenOrdersModal] = useState(undefined)
  const [showRetailPrice, setShowRetailPrice] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [drawerState, setDrawerState] = useState({
    moreOptions: false,
    filters: false
  })

  useEffect(() => {
    if (core.user.selectedCustomer.accountNo) {
      productsModel.init()
    }
  }, [core.user.selectedCustomer.accountNo])

  useEffect(() => {
    if (productsModel.getSelecteFacetValuesCount() == 0) {
      setDrawerState({ ...drawerState, ['filters']: false })
    }
  }, [productsModel.selectedFacetValues])

  const downloadExcelOfItems = (e) => {
    e.preventDefault();

    setUploadLoading(true);

    productsModel.searchExcel((data) => {
      if (data === undefined) {
        setUploadLoading(false);

        core.snackbar('warning', 'Something went wrong when trying to download excel')

        return
      }

      const href = URL.createObjectURL(data.data)
      const date = new Date()
      const link = document.createElement('a')

      link.href = href
      link.setAttribute('download', `Products_${date.getFullYear()}-${date.getDate() >= 9 ? date.getDate() : '0' + date.getDate()}-${date.getMonth() >= 9 ? date.getMonth() : '0' + date.getMonth()}_${date.getHours() >= 9 ? date.getHours() : '0' + date.getHours()}_${date.getMinutes() >= 9 ? date.getMinutes() : '0' + date.getMinutes()}_${date.getSeconds() >= 9 ? date.getSeconds() : '0' + date.getSeconds()}.xlsm`)

      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(href)

      setUploadLoading(false)
    });
  }

  const getFacetLabelByCategoryAndId = (category, id) => {
    if (!productsModel.allFacets || !productsModel.allFacets.find((el) => el.name === category)) {
      return
    }

    let facetLabel = ''
    let count = productsModel
      .searchCollection
      .facets
      .find((el) => el.name === category)
      .facetValues.find((el) => el.id === id)?.count ?? 0

    if (category === 'news') {
      facetLabel = (<span><TranslationLabel name='news' /> ({count})</span>)
    } else if (category === 'archive') {
      facetLabel = (<span><TranslationLabel name='archive' /> ({count})</span>)
    } else {
      const label = productsModel.allFacets.find((el) => el.name === category).facetValues.find((el) => el.id === id)
      facetLabel = label && `${label.name} (${count})`
    }

    return (
      <>
        <span className={count ? classes.resetButtonText : classes.greyedOutResetButtonText}>
          {facetLabel}
        </span>
        <CloseIcon className={classes.resetButtonIcon} />
      </>
    )
  }

  const getArrayOfFacets = (selectedFacetValues) => {
    let facets = []
    for (const category in selectedFacetValues) {
      selectedFacetValues[category].forEach((el) => {
        facets.push({ category, value: el })
      })
    }
    return facets
  }

  return (
    <div className={classes.productsPageRoot}>
      <OpenOrdersModal
        openOrdersProduct={productsModel.searchCollection?.products?.find((el) => el && el.productNo == openOrdersModal)}
        setOpenOrdersModal={setOpenOrdersModal}
      />

      <div className={classes.ProductsPageHeader}>
        <div className={classes.ProductsPageFacets}>
          <ProductSearchBarFacets
            facets={productsModel.searchCollection.facets}
            query={productsModel.searchCollection.query}
            selected={productsModel.selectedFacetValues}
            change={productsModel.toggleFacetValue}
          />
        </div>

        <div className={classes.ProductsPageSearch}>
          <TextField
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                productsModel.handleSearchQueryChange(productsModel.query)
              }
            }}
            placeholder={core.dictionary.getLabel('searchProducts')}
            type='search'
            className={classes.textField}
            variant='outlined'
            onChange={(e) => productsModel.setQuery(e.target.value)}
            value={productsModel.query}
            size='small'
            InputProps={{
              startAdornment: <SearchIcon />,
              endAdornment: <CloseIcon
                onClick={() => {
                  productsModel.setQuery('')
                  productsModel.handleSearchQueryChange('')
                }}
                className={classes.searchIcon} />
            }}
          />
        </div>
      </div>

      <div className={classes.ProductsPageSubHeader}>
        <div className={classes.ProductsPageHeaderResults}>
          <div>
            <Typography>
              <TranslationLabel name='displaying' /> {productsModel.searchCollection.totalNumberOfResults ?? 0} <TranslationLabel name='results' />
            </Typography>
          </div>

          {(productsModel.getSelecteFacetValuesCount() > 0) && (
            <div className={classes.ProductsPageHeaderResultsLast}>
              <Typography className={classes.cursor} onClick={() => setDrawerState({ ...drawerState, ['filters']: true })}>
                  <TranslationLabel name='editAndRemoveFilters' /> ({productsModel.getSelecteFacetValuesCount()})
              </Typography>
              <Drawer
                anchor="right"
                open={drawerState['filters']}
                onClose={() => setDrawerState({ ...drawerState, ['filters']: false })}>
                  <div className={classes.drawer}>
                    <Button
                      size='small'
                      onClick={() => productsModel.reset()}
                      className={classes.resetButton}>
                      <span className={classes.resetButtonText}>
                        <TranslationLabel name='resetFilters' />
                        <CloseIcon className={classes.resetButtonIcon} />
                      </span>
                    </Button>

                    {getArrayOfFacets(productsModel.selectedFacetValues).map((el) => (
                      <Button
                        size='small'
                        key={el.value + el.category}
                        onClick={() => productsModel.toggleFacetValue(el.category, el.value)}
                        className={classes.resetButton}>
                        {getFacetLabelByCategoryAndId(el.category, el.value)}
                      </Button>
                    ))}

                    {productsModel.query != "" && (
                      <Button
                        size='small'
                        onClick={() => {
                          productsModel.setQuery("")
                          productsModel.handleSearchQueryChange("")
                        }}
                        className={classes.resetButton}>
                        <>
                          <span className={classes.resetButtonText}>
                            {productsModel.query}
                          </span>
                          <CloseIcon className={classes.resetButtonIcon} />
                        </>
                      </Button>
                    )}
                  </div>
              </Drawer>
            </div>
          )}
        </div>

        <div className={classes.ProductsPageSettings}>
          {core.user.userSetting.showArchiveCheckbox && (
            <FormControl>
              <FormControlLabel
                label={<TranslationLabel name='showArchive' />}
                control={
                  <Switch
                    checked={productsModel.onlyArchive}
                    onChange={() => productsModel.setCampaignOrArchive('archive', !productsModel.onlyArchive)}
                    color='primary'
                  />
                }
              />
            </FormControl>
          )}

          <FormControl>
            <FormControlLabel
              label={<TranslationLabel name='campaign' />}
              control={
                <Switch
                  checked={productsModel.onlyCampaign}
                  onChange={() => productsModel.setCampaignOrArchive('campaign', !productsModel.onlyCampaign)}
                  name='campaign'
                  color='primary'
                />
              }
            />
          </FormControl>

          <FormControl>
            <FormControlLabel
              label={<TranslationLabel name='newsOnly' />}
              control={
                <Switch
                  checked={productsModel.selectedFacetValues['news'][0] === 'True'}
                  onChange={() => productsModel.toggleFacetValue('news', productsModel.allFacets.find((x) => x.name === 'news')?.facetValues[1]?.id)}
                  name='newsOnly'
                  color='primary'
                />
              }
            />
          </FormControl>

          <FormControl>
            <FormControlLabel
              label={<TranslationLabel name='showRetailPrice' />}
              control={
                <Switch
                  checked={showRetailPrice}
                  onChange={() => setShowRetailPrice(!showRetailPrice)}
                  color='primary'
                />
              }
            />
          </FormControl>

          <MoreIcon className={`${classes.cursor} ${classes.ProductsPageSettingsLast}`}  onClick={() => setDrawerState({ ...drawerState, ['moreOptions']: true })}/>
          <Drawer
            anchor="right"
            open={drawerState['moreOptions']}
            onClose={() => setDrawerState({ ...drawerState, ['moreOptions']: false })}>
              <div className={classes.drawer}>
                {core.user.userSetting.downloadExcelOfItems && (
                  <div className={classes.moreOptionsRow}>
                    <Typography>Download din søgning til excel</Typography>
                    <Button
                        variant='contained'
                        color='primary'
                        component='span'
                        style={{width: '100%'}}
                        onClick={downloadExcelOfItems}>
                          <span style={{ visibility: uploadLoading ? 'hidden' : 'visible' }} ><TranslationLabel name='downloadExcelOfItems' /></span>
                          <CircularProgress style={{color: "#fff", position: 'absolute', left: 'calc(50% - 10px)', top: "calc(50% - 10px)", width: '20px', height: '20px', display: uploadLoading ? 'inline' : 'none' }} />
                    </Button>
                  </div>
                )}

                <div className={classes.moreOptionsRow}>
                  <Typography>
                    <TranslationLabel name='sortBy' />
                  </Typography>
                  <FormControl
                    variant='outlined'
                    className={classes.formControl}
                    size='small'
                    style={{width: '100%'}}>
                    <Select
                      value={productsModel.searchCollection.sortField || 'BRAND'}
                      onChange={(event) => productsModel.handleSortByChange(event.target.value)}
                      className={classes.selectEmpty}>
                      <MenuItem value='Name'>
                        <TranslationLabel name='title'></TranslationLabel>
                      </MenuItem>
                      <MenuItem value='Id'>
                        <TranslationLabel name='itemNo'></TranslationLabel>
                      </MenuItem>
                      <MenuItem value='BrandId'>
                        <TranslationLabel name='brand'></TranslationLabel>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>


                <div className={classes.moreOptionsRow}>
                  <Typography>
                    Vælg visning form
                  </Typography>
                  <FormControl
                    variant='outlined'
                    className={classes.formControl}
                    size='small'
                    style={{width: '100%'}}>
                    <Select
                      value={productsModel.listViewMode}
                      onChange={(event) => productsModel.setListViewMode(event.target.value)}
                      className={classes.selectEmpty}>
                      <MenuItem value='list'>
                        <span style={{display: 'flex', alignItems: 'center'}}><ListIcon style={{paddingRight: 10}} /> List</span>
                      </MenuItem>
                      <MenuItem value='grid'>
                        <span style={{display: 'flex', alignItems: 'center'}}><GridOnIcon style={{paddingRight: 10}} /> Grid</span>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
          </Drawer>
        </div>
      </div>

      <LinearProgress className={`${classes.stickyloader} ${productsModel.isLoading && classes.loaderVisible}`} />

      {productsModel.searchCollection.products && (
        <div className={classes.scrollableProducts}>
          <InfiniteScroll
            loadMore={productsModel.increment}
            hasMore={productsModel.hasMore}
            threshold={100}
            initialLoad={false}
            useWindow={false}
            id={'product-scroller'}>
              {productsModel.listViewMode === 'list' ? (
                <TableView
                  products={productsModel.searchCollection.products}
                  showRetailPrice={showRetailPrice}
                  listKey={'productNo'}
                  setOpenOrdersModal={setOpenOrdersModal}
                />
              ) : (
                <Grid
                  container
                  spacing={2}
                  style={{padding: '0 10px', margin: 0, width: '100%'}}>
                  {productsModel.searchCollection.products.map((product) => (
                    <GridViewItem
                      product={product}
                      key={product.productNo}
                      showRetailPrice={showRetailPrice}
                    />
                  ))}
                </Grid>
              )}
          </InfiniteScroll>
        </div>
      )}

      {!productsModel.isLoading && productsModel.searchCollection.totalNumberOfResults === 0 && (
          <EmptyState
            main='productSearchEmptyMain'
            sub='productSearchEmptySub'
          />
        )}
    </div>
  );
}
