import { useContext, useEffect, useState } from 'react'
import { CoreContext } from '../../../core/context/CoreContext'

export function useOrderModel() {
  const core = useContext(CoreContext)
  const accountNo = core.user.selectedCustomer.accountNo

  const [isInitialized, setIsInitialized] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [ordersCount, setOrdersCount] = useState(0) //until the api can provide a count
  const [lookingInRMA, setLookingInRMA] = useState(false)

  function search() {
    if (lookingInRMA) {
      return searchRMA()
    }
    if (searchInput === '') {
      load()
    } else {
      if (isLoading) return
      setIsLoading(true)

      core.dataStore
        .searchOrders(accountNo, searchInput)
        .then((res) => {
          if (res.status === 200) {
            setOrders(res.data)
            setOrdersCount(res.data?.length)
          } else core.snackbar('warning', res.statusText)
        })
        .catch((e) => {
          core.snackbar('warning', e.toString())
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  function load() {
    if (isLoading || !accountNo) {
      return
    }

    setIsLoading(true)
    core.dataStore
      .getOrders(accountNo)
      .then((res) => {
        if (res.status === 200) {
          setOrders(res.data)
          setOrdersCount(res.data?.length)
          setIsInitialized(true)
        } else core.snackbar('warning', res.statusText)
      })
      .catch((e) => {
        core.snackbar('warning', e.toString())
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function searchRMA() {
    if (searchInput === '') {
      loadRMA()
    } else {
      if (isLoading) return
      setIsLoading(true)
      core.dataStore
        .searchRMAOrders(accountNo, searchInput)
        .then((res) => {
          if (res.status === 200) {
            setOrders(res.data)
            setOrdersCount(res.data?.length)
          } else core.snackbar('warning', res.statusText)
        })
        .catch((e) => {
          core.snackbar('warning', e.toString())
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  function loadRMA() {
    if (isLoading || !accountNo) {
      return
    }
    setIsLoading(true)
    core.dataStore
      .getRMAOrders(accountNo)
      .then((res) => {
        if (res.status === 200) {
          setOrders(res.data)
          setOrdersCount(res.data?.length)
          setIsInitialized(true)
        }
      })
      .catch((err) => console.log(err.toString()))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    search(searchInput)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput, core.user.selectedCustomer.accountNo, lookingInRMA])

  return {
    ordersCount,
    customer: accountNo,
    isLoading,
    isInitialized,
    orders,
    setSearchInput,
    lookingInRMA,
    setLookingInRMA,
  }
}
