import { useContext, useEffect, useState } from 'react'
import { CoreContext } from '../../../core/context/CoreContext'

export function useInvoiceModel() {
  const core = useContext(CoreContext)
  const accountNo = core.user.selectedCustomer.accountNo

  const [isLoading, setIsLoading] = useState(false)
  const [invoices, setInvoices] = useState([])
  const [page, setPage] = useState(1)
  const [offset] = useState(25)
  const [eofCount, setEofCount] = useState(Infinity) //until the api can provide a count
  const [invoicesCount, setInvoicesCount] = useState(0) //until the api can provide a count

  function reset() {
    setInvoicesCount(0)
    setInvoices([])
    setPage(1)
  }

  function setSearchInput(input) {
    search(input)
  }

  function search(txt) {
    if (txt === '') {
      load(1)
      loadInvoicesCount()
    } else {
      if (isLoading) return
      setIsLoading(true)
      setPage(1)
      core.dataStore
        .searchInvoices(accountNo, txt)
        .then((res) => {
          if (res.status === 200) {
            setInvoices(res.data)
            setInvoicesCount(res.data?.length)
            setEofCount(1)
          } else core.snackbar('warning', res.statusText)
        })
        .catch((e) => {
          core.snackbar('warning', e.toString())
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  function loadInvoicesCount() {
    if (!accountNo) {
      return
    }
    core.dataStore
      .getInvoicesCount(accountNo)
      .then((res) => {
        console.log('invoices res', res.status, res.data)
        if (res.status === 200) {
          const count = res.data
          setInvoicesCount(count)
          setEofCount(Math.ceil(count / offset))
        } else core.snackbar('warning', res.statusText)
      })
      .catch((e) => {
        core.snackbar('warning', e.toString())
      })
  }

  function load(page) {
    if (isLoading || !accountNo) return
    setIsLoading(true)

    core.dataStore
      .getInvoices(accountNo, page, offset)
      .then((res) => {
        if (res.status === 200) {
          let data = Array.isArray(res.data) ? res.data : []
          setInvoices(data)
        } else core.snackbar('warning', res.statusText)
      })
      .catch((e) => {
        core.snackbar('warning', e.toString())
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function incrementPage() {
    if (hasNext() && !isLoading) {
      setInvoices([])
      load(page + 1)
      setPage(page + 1)
    }
  }
  function decrementPage() {
    if (hasPrev() && !isLoading) {
      setInvoices([])
      load(page - 1)
      setPage(page - 1)
    }
  }

  function hasPrev() {
    return page > 1
  }

  function hasNext() {
    return eofCount > page
  }

  useEffect(() => {
    if (accountNo) {
      reset()
      load(page)
      loadInvoicesCount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNo])

  return {
    customer: accountNo,
    isLoading,
    invoices,
    page,
    incrementPage,
    decrementPage,
    hasNext,
    hasPrev,
    invoicesCount,
    eofCount,
    setSearchInput
  }
}
