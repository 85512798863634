import React, { useEffect, useContext, useRef, useState } from 'react'
import {
  Grid,
  Card,
  CardHeader,
  Button,
  LinearProgress,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  CircularProgress,
  CardContent,
} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import ProductNumberInput from '../components/ProductNumberInput'
import SelectedChips from '../components/SelectedChips'
import Message from '../components/Message'
import { CoreContext } from '../../../core/context/CoreContext'
import TranslationLabel from '../../../core/components/TranslationLabel'
import DatePicker from '../../../shared/DatePicker'
import urls from '../../../core/data/ApiUrls'
import InfiniteScroll from 'react-infinite-scroller'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    flexGrow: 1,
    margin: '-15px',
    padding: theme.spacing(3),
    background: 'white',
  },
  header: {
    margin: '30px 0 15px',
  },
  hr: {
    margin: '30px 0 20px',
  },
  tabbar: {
    paddingLeft: '10px',
  },
  progress: {
    width: '100%',
    height: 5,
    textAlign: 'center',
  },
  btn: {
    margin: '30px 0 5px',
    minWidth: 150,
  },
  downloadLink: {
    display: 'none',
  },
  formControl: {
    marginTop: 12,
    width: '100%',
    maxWidth: 360,
  },
  h3: {
    margin: '25px 0 0',
  },
  p: {
    margin: 0,
  },
  allOrNoneWrapper: {
    padding: '4px 16px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  inlineLabel: {
    display: 'inline-flex',
    marginRight: 10,
    fontSize: 13,
    color: grey,
  },
  tableHeader: {
    fontWeight: 700,
    verticalAlign: 'bottom',
    whiteSpace: 'nowrap',
  },
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 12 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  variant: 'menu',
  getContentAnchorEl: null,
}

function SearchPage() {
  const classes = useStyles()
  const core = useContext(CoreContext)
  const { isEditMode } = core.dictionary

  const [showMessage, setShowMessage] = useState(false)
  const [templates, setTemplates] = useState([])
  const [selctedTemplate, setSelectedTemplage] = useState({})
  const [lastPriceDate, setLastPriceDate] = useState()
  const [pricesFrom, setPricesFrom] = useState(new Date().toISOString())

  const [selectedFacetValues, setSelectedFacetValues] = useState({})
  const [allFacets, setAllFacets] = useState([])

  const [productNumbers, setProductNumbers] = useState([])

  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [pageNumber, setPageNumber] = useState(1)
  const [excelLoading, setExcelLoading] = useState(false)
  const [totalNumberOfResults, setTotalNumberOfResults] = useState(0)

  const scroller = useRef()

  const [table, setTable] = useState({
    headers: [],
    rows: [],
  })

  function getMasterDataFacets(facets) {
    core.dataStore.getMasterDataFacets().then((res) => {
      const usersAllowedFacets = res.data
        .filter((el) => core.user.userSetting.masterDataFacets.includes(el.id))
        .map((el) => el.facetName)

      var searchoptions = {...getSearchOptions(), selectedFacetValues: facets}
      core.masterItemDataStore
        .productSearch(searchoptions)
        .then((res) => {
          setAllFacets(res.data.facets.filter((el) => usersAllowedFacets.find((str) => str == el.name)))
        })
    })
  }

  useEffect(() => {
    if (!core.user.selectedCustomer.accountNo) {
      return
    }

    function getMasterDataDatesByUserType() {
      core.dataStore
        .getMasterDataDatesByUserType(core.user.userSetting.userTypeId)
        .then((res) => {
          setLastPriceDate(res.data)
          getMasterDataFacets({})
        })
    }

    core.masterItemDataStore
      .getCustomerTemplates(core.user.selectedCustomer.accountNo)
      .then((res) => {
        if (res.data.length > 0) {
          setTemplates(res.data)
          setSelectedTemplage(res.data[0])

          getMasterDataDatesByUserType()
        } else {
          setShowMessage(true)
        }
      })
  }, [core.user.selectedCustomer.accountNo])

  const handleTemplateChange = (e) => {
    setSelectedTemplage(e.target.value)

    search({
      ...getSearchOptions(),
      templateId: e.target.value.id,
    })
  }

  const checkAll = (facetName) => {
    const facts = allFacets
      .find((el) => el.name == facetName)
      .facetValues.map((el) => el.id)

    const selectedFacets = {
      ...selectedFacetValues,
      [facetName]: facts,
    };

    setSelectedFacetValues(selectedFacets)
    getMasterDataFacets(selectedFacets)
  }

  const checkNone = (facetName) => {
    const selectedFacets = {
      ...selectedFacetValues,
      [facetName]: [],
    };

    setSelectedFacetValues(selectedFacets)
    getMasterDataFacets(selectedFacets);
  }

  const isChecked = (name, value) => {
    return (
      selectedFacetValues[name] !== undefined &&
      selectedFacetValues[name].indexOf(value) !== -1
    )
  }

  const handleFacetChange = (facet, facetValue) => {
    const allSelctedFacets = { ...selectedFacetValues }
    const arrayOfSelectedFacets = allSelctedFacets[facet.name] || []
    allSelctedFacets[facet.name] = isChecked(facet.name, facetValue.id)
      ? arrayOfSelectedFacets.filter((el) => el !== facetValue.id)
      : arrayOfSelectedFacets.concat(facetValue.id)


    setSelectedFacetValues(allSelctedFacets)

    getMasterDataFacets(allSelctedFacets)
  }

  const getSortedFacetValues = (facet) => {
    if (facet.name === 'launchYear') {
      return facet.facetValues.sort((a, b) =>
        parseInt(a.name) > parseInt(b.name) ? -1 : 1,
      )
    }
    return facet.facetValues
  }

  const handleProductNumberChange = (productNumbers) => {
    setProductNumbers(productNumbers)
  }

  const getSearchOptions = () => {
    return {
      customerId: core.user.selectedCustomer.accountNo,
      userId: core.user.userId,
      languageCode: core.user.languageCode,
      pageSize: 100,
      pageNumber: 1,
      templateId: selctedTemplate.id,
      searchOnlyInTheseProducts: productNumbers,
      descendingSortOrder: false,
      sortByField: 'BrandId',
      selectedFacetValues: selectedFacetValues,
      pricesFrom: pricesFrom,
    }
  }

  const search = (options = getSearchOptions()) => {
    setLoading(true)

    core.masterItemDataStore
      .productSearch(options)
      .then((res) => {
        setHasMore(res.data.pageNumber != res.data.pagesInAll)
        setPageNumber(res.data.pageNumber)
        setTotalNumberOfResults(res.data.totalNumberOfResults)

        if (res.data.pageNumber > 1) {
          setTable({
            ...table,
            rows: [...table.rows, ...res.data.resultTemplate.rows],
          })
        } else {
          setTable({ ...res.data.resultTemplate })
        }

        core.dataStore.getMasterDataFacets().then((res1) => {
          const usersAllowedFacets = res1.data
            .filter((el) => core.user.userSetting.masterDataFacets.includes(el.id))
            .map((el) => el.facetName)

          setAllFacets(res.data.facets.filter((el) => usersAllowedFacets.find((str) => str == el.name)))
        })

        setLoading(false)
      })
      .catch((e) => {
        console.log('warning', e.toString())
      })
  }

  const scrollToTop = () => {
    if (scroller.current) {
      scroller.current.scrollTo(0, 0)
    }
  }

  const handleDateChange = (date) => {
    setPricesFrom(date.toISOString())
    scrollToTop()

    search({
      ...getSearchOptions(),
      pricesFrom: date.toISOString(),
    })
  }

  const handleChipRemove = (_selectedFacets) => {
    setSelectedFacetValues(_selectedFacets)
    scrollToTop()

    search({
      ...getSearchOptions(),
      selectedFacetValues: _selectedFacets,
    })
  }

  const handleSearchClick = () => {
    scrollToTop()
    search()
  }

  const clearSearch = () => {
    setSelectedFacetValues({})
    setProductNumbers([])

    scrollToTop()

    setTable({
      headers: [],
      rows: [],
    })

    core.dataStore.getMasterDataFacets().then((res) => {
      const usersAllowedFacets = res.data
        .filter((el) => core.user.userSetting.masterDataFacets.includes(el.id))
        .map((el) => el.facetName)
      core.masterItemDataStore
        .productSearch({
          ...getSearchOptions(),
          selectedFacetValues: {},
          searchOnlyInTheseProducts: []
        })
        .then((res) => {
          setAllFacets(res.data.facets.filter((el) => usersAllowedFacets.find((str) => str == el.name)))
        })
    })
  }

  const handleExportChange = (callback) => {
    fetch(urls.dataUrl + 'MasterDataSearch/Product/Excel', {
      method: 'POST',
      headers: {
        'authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({
        ...getSearchOptions(),
        pageSize: 100000,
        page: 1,
      }),
    })
      .then((res) => res.blob())
      .then((data) => {
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(data)
        a.download = 'Products.xlsx'
        a.click()
        a.remove()
      })
      .finally(() => callback())
  }

  const onLoadMore = () => {
    if (loading || !hasMore) {
      return
    }

    let options = {
      ...getSearchOptions(),
      pageNumber: pageNumber + 1,
    }

    search(options)
  }

  return (
    <div className={classes.pageRoot}>
      <Grid
        container
        spacing={4}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={3}
          xl={2}>
          {!!templates.length && (
            <FormControl
              variant='outlined'
              style={{
                marginTop: 12,
                width: '100%',
                maxWidth: 360,
              }}>
              <InputLabel id='templateSelect'>
                <TranslationLabel name='selectTemplate' />
              </InputLabel>
              <Select
                labelId='templateSelect'
                id='templateSelecter'
                value={selctedTemplate}
                onChange={handleTemplateChange}
                name='selectTemplate'
                variant='outlined'
                initialvalue={<TranslationLabel name='selectTemplate' />}>
                {templates.map((template) => {
                  return (
                    <MenuItem
                      value={template}
                      key={template.id}>
                      {template.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          )}
        </Grid>
        {isEditMode ||
        core.dictionary.getLabel('templateExplanationText').toLowerCase() !==
          'ikke i brug' ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={6}
            xl={4}>
            <p style={{ color: 'red' }}>
              <TranslationLabel name='templateExplanationText' />
            </p>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>

      <Grid
        container
        spacing={2}>
        <Grid
          item
          xs={12}>
          <h3 className={classes.h3}>
            <TranslationLabel name='facetSelectorTitle' />
          </h3>
        </Grid>
        {allFacets.map((facet) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={2}
            key={facet.name}>
            <FormControl
              variant='outlined'
              className={classes.formControl}>
              <InputLabel id={facet.name + '-checkbox-label'}>
                <TranslationLabel name={facet.name} />
              </InputLabel>
              <Select
                labelId={facet.name + '-checkbox-label'}
                id={facet.name + '-checkbox'}
                multiple
                value={facet.facetValues}
                renderValue={() => {
                  const numberOfSelected = selectedFacetValues[facet.name]
                    ? selectedFacetValues[facet.name].length
                    : 0
                  return (
                    <span>
                      <TranslationLabel name={facet.name} />{' '}
                      {!!numberOfSelected && `(${numberOfSelected})`}
                    </span>
                  )
                }}
                MenuProps={MenuProps}
                name={facet.name}>
                <div
                  className={classes.allOrNoneWrapper}
                  key={facet.name + 'all'}
                  value={''}>
                  <Button onClick={() => checkAll(facet.name)}>
                    <TranslationLabel name='all' />
                  </Button>
                  <Button onClick={() => checkNone(facet.name)}>
                    <TranslationLabel name='none' />
                  </Button>
                </div>
                {getSortedFacetValues(facet).map((facetValue) => (
                  <MenuItem
                    onClick={() => handleFacetChange(facet, facetValue)}
                    key={facetValue.name}
                    value={facetValue.name}>
                    <Checkbox checked={isChecked(facet.name, facetValue.id)} />
                    <ListItemText
                      primary={`${facetValue.name} (${facetValue.count})`}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ))}

        <ProductNumberInput
          style={{ display: 'inline-block' }}
          onProductNumberChange={handleProductNumberChange}
          value={productNumbers.toString()}
          core={core}
        />

        <FormControl
          variant='outlined'
          style={{
            marginTop: 12,
            width: '100%',
            maxWidth: 360,
          }}>
          <DatePicker
            disablePast
            maxDate={lastPriceDate}
            autoOk
            label={<TranslationLabel name='pricesFrom' />}
            inputVariant='outlined'
            value={pricesFrom}
            InputProps={{ readOnly: true }}
            onChange={handleDateChange}
          />
        </FormControl>

        <Grid
          item
          xs={12}>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            onClick={handleSearchClick}
            className={classes.btn}>
            <TranslationLabel name='searchButton' />
          </Button>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            onClick={clearSearch}
            style={{ marginLeft: 16 }}
            className={classes.btn}>
            <TranslationLabel name='clearSearchResults' />
          </Button>
        </Grid>

        {/* Selected items */}
        <SelectedChips
          selectedFacets={selectedFacetValues}
          onChipRemove={handleChipRemove}
        />

        {loading && (
          <LinearProgress
            className={classes.progress}
            color='primary'
          />
        )}

        {table.rows.length > 0 && (
          <Grid
            item
            xs={12}>
            <Card variant='outlined'>
              <CardHeader
                title={
                  <span>
                    {totalNumberOfResults}{' '}
                    <TranslationLabel name='resultTitle' />
                  </span>
                }
                action={
                  <div>
                    {templates.length > 0 && table.rows.length > 0 && (
                      <>
                        <Button
                          onClick={() => {
                            setExcelLoading(true)
                            handleExportChange(() => setExcelLoading(false))
                          }}
                          variant='contained'
                          color='primary'
                          disableElevation
                          disabled={excelLoading}>
                          {excelLoading ? (
                            <>
                              <TranslationLabel name='excelDownloadText' />
                              <CircularProgress style={{ marginLeft: 10 }} />
                            </>
                          ) : (
                            <TranslationLabel name='exportButton' />
                          )}
                        </Button>
                      </>
                    )}
                  </div>
                }></CardHeader>
              <CardContent>
                <div>
                  <TableContainer
                    style={{
                      overflow: 'auto',
                      maxWidth: '100%',
                      maxHeight: 'calc(100vh - 300px)',
                    }}
                    ref={scroller}>
                    <InfiniteScroll
                      pageStart={0}
                      loadMore={onLoadMore}
                      hasMore={hasMore}
                      threshold={100}
                      initialLoad={false}
                      useWindow={false}>
                      <Table
                        stickyHeader
                        size='small'>
                        <TableHead>
                          <TableRow>
                            {table.headers.map((field, index) => (
                              <TableCell
                                className={classes.tableHeader}
                                key={`th${index}-${field.text}`}>
                                <TranslationLabel name={field.text} />
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {table.rows.map((row, index1) => (
                            <TableRow key={`tr${row.rowNo}-${index1}`}>
                              {row.rowFields.map((field, index) => (
                                <TableCell
                                  className={classes.tableCell}
                                  style={{ maxWidth: 150 }}
                                  key={`td${row.rowNo}_${index}`}>
                                  {field.value}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </InfiniteScroll>
                  </TableContainer>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}

        {showMessage && (
          <Message
            type='info'
            text={<TranslationLabel name='noTemplateErrorText' />}
            title={<TranslationLabel name='noTemplateErrorTitle' />}
            onCloseMessage={(e) => setShowMessage(false)}></Message>
        )}
      </Grid>
    </div>
  )
}

export default SearchPage
