import React, { useContext, useEffect, useState } from 'react'
//Theme
import {
  makeStyles,
  MuiThemeProvider,
  useTheme,
} from '@material-ui/core/styles'
//Components
import HubRouter from '../components/HubRouter'
import FullScreenLoader from '../components/FullScreenLoader'
import { CoreContext } from '../context/CoreContext'
import Backdrop from '@material-ui/core/Backdrop/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  main: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    color: 'black',
    margin: 0,
    top: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

export default function Hub(props) {
  const classes = useStyles()
  const core = useContext(CoreContext)
  const theme = useTheme()
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => core.setWidth(theme.breakpoints)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [core])

  return (
    <MuiThemeProvider theme={core.theme}>
      <div className={classes.main}>
        {core.isLoading ? (
          <FullScreenLoader />
        ) : (
          <>
            <HubRouter
              width={width}
              key={2}
            />
            {core.user.isLoading ? (
              <Backdrop
                className={classes.backdrop}
                open={core.user.isLoading}>
                <CircularProgress color='inherit' />
              </Backdrop>
            ) : null}
          </>
        )}
      </div>
    </MuiThemeProvider>
  )
}
