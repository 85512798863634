import React, { useContext } from 'react'

import { makeStyles } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'

import BasketReturnServiceListViewItem from './BasketReturnServiceListViewItem'

import { ReturnServiceContext } from '../../../context/ReturnServiceContext'

import EmptyState from '../../../components/EmptyState'

const useStyles = makeStyles((theme) => ({
  basketListViewRoot: {},
  progressContainer: {
    height: '4px',
  },
}))

export default function BasketReturnServiceListView(props) {
  const { onUpdate, isMiniBasket = false, disabled } = props
  const classes = useStyles()
  const returnServiceModel = useContext(ReturnServiceContext)

  function handleDelete(basketEntryId) {
    returnServiceModel.remove(basketEntryId)
  }

  return (
    <div className={classes.basketListViewRoot}>
      <div className={classes.progressContainer}>
        {returnServiceModel.isLoading ? <LinearProgress /> : null}
      </div>
      {returnServiceModel.basket.entries.length > 0 ? (
        <>
          {returnServiceModel.basket.entries.map((data) => (
            <BasketReturnServiceListViewItem
              key={`blvi-${data.itemId}`}
              data={data}
              onDelete={handleDelete}
              onUpdate={onUpdate}
              isMiniBasket={isMiniBasket}
              disabled={disabled}
            />
          ))}
        </>
      ) : (
        <EmptyState />
      )}
    </div>
  )
}
