import React, { useState, useEffect, useContext } from 'react'
import { CoreContext } from '../../../context/CoreContext'
import SalesAppSettings from './SalesAppSettings'
import MasterDataSettings from './MasterDataSettings'
import HubSettings from './HubSettings'
import { makeStyles } from '@material-ui/core'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

const useStyles = makeStyles(() => ({
  selectedTab: {
    color: 'blue',
  },
}))

export default function SettingsManagement() {
  const core = useContext(CoreContext)
  const [selectedTab, setSelectedTab] = useState(1)
  const [allApps, setAllApps] = useState([])
  const classes = useStyles()

  useEffect(() => {
    core.dataStore
      .getApps()
      .then((rs) => {
        setAllApps(rs.data)
      })
      .catch(() => {})
  }, [selectedTab])

  const selectedApp = () => {
    switch (selectedTab) {
      case 1:
        return <HubSettings />
      case 2:
        return <SalesAppSettings />
      case 6:
        return <MasterDataSettings />
      default:
        return <></>
    }
  }

  const TabHeadline = (props) => {
    return (
      <Tab
        className={selectedTab === props.id ? classes.selectedTab : ''}
        onClick={() => setSelectedTab(props.id)}
        label={props.title}
      />
    )
  }

  return (
    <>
      <Tabs>
        {allApps.map((app) => (
          <TabHeadline
            key={app?.id}
            {...app}
          />
        ))}
      </Tabs>
      {selectedApp()}
    </>
  )
}
