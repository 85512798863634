export default function findNextAllowedNumber(product, number) {
  if (number < 1) {
    return 0
  }
  const { minimumQuantity, multiplesQuantity } = product

  const increment = multiplesQuantity || 1
  if (number <= minimumQuantity) {
    return minimumQuantity
  }
  return Math.ceil(number / increment) * increment
}
