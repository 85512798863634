import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  list: {
    width: 450,
    maxWidth: '100vw',
    height: '100%',
    padding: theme.spacing(2),
    overflowY: 'scroll',

    [theme.breakpoints.up('md')]: {
      overflowY: 'auto',
    },
  },
  root: {
    padding: '10px',
  },
  sub: {
    color: theme.palette.grey[600],
    borderBottom: '1px solid #ddd',
    marginTop: 0,
    paddingBottom: 10,
  },
  btns: {
    display: 'flex',
    padding: theme.spacing(1),
  },
  btn: {
    width: 'auto',
    height: 40,
    margin: 5,
    minWidth: 100,
  },
  btnNext: {
    'color': 'white',
    'backgroundColor': 'black',
    'width': '100%',

    '&:hover': {
      backgroundColor: theme.palette.grey[800],
    },
  },
  summary: {
    padding: theme.spacing(2),
  },
}))
