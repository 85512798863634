import React, { useContext } from 'react'
//Context

//Material UI
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import AddCircle from '@material-ui/icons/AddCircle'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TranslationLabel from '../../../../../core/components/TranslationLabel'
import { ReturnServiceContext } from '../../../context/ReturnServiceContext'
import InriverImage from '../../../../../core/components/InriverImage'

const useStyles = makeStyles((theme) => ({
  listItemRoot: {
    padding: '10px 0px',
    borderBottom: '1px solid #cecece',
  },
  txt: {
    marginLeft: 10,
    marginRight: 10,
  },
  title: {
    fontWeight: 700,
  },
  productNo: {
    fontSize: 14,
  },
  price: {
    minWidth: 150,
    marginLeft: 'auto',
    textAlign: 'right',
  },
  old: {
    color: theme.palette.grey[500],
    fontSize: 11,
  },
  new: {
    fontSize: 14,
    fontWeight: 700,
  },
  row: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  quantity: {
    fontWeight: 700,
    fontSize: 13,
    textAlign: 'center',
    minWidth: 20,
  },
  buttonItemWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 34,
    justifyContent: 'flex-start',
    width: '100%',
    paddingLeft: 10,
  },
  delete: {
    marginLeft: 'auto',
  },
  imgWrapper: {
    display: 'block',
    marginRight: 6,
    marginTop: 10,
    height: 50,
    width: 50
  },
  img: {
    maxHeight: 60,
    maxWidth: '100%',
  },
}))

export default function BasketReturnServiceListViewItem(props) {
  const { data, onDelete, disabled } = props
  const classes = useStyles()
  const returnServiceModel = useContext(ReturnServiceContext)

  const handleReturnReasonChange = (e) => {
    const returnReasonId = e.target.value
    returnServiceModel.updateEntry(
      data.basketEntryId,
      data.quantity,
      returnReasonId,
    )
  }

  return (
    <div className={classes.listItemRoot}>
      <Grid
        container
        className={classes.row}>
        <Grid item>
          <Link
            to={`/sales/products/item/${data.itemId}`}
            color='blue'
            className={classes.imgWrapper}>
            <InriverImage
              className={classes.img}
              productNo={data.itemId}
              showImage={data.showImage}
              alt={data.itemName}
            />
          </Link>
        </Grid>
        <Grid
          style={{ width: '100%' }}
          item>
          <Link
            to={`/sales/products/item/${data.itemId}`}
            color='blue'
            className={classes.title}>
            {data.itemName}
          </Link>
          <div className={classes.productNo}>
            <TranslationLabel name='productNumberShort' />
            <span> {data.itemId}</span>
          </div>
          <div>
            <Select
              disableUnderline
              value={data.returnReasonId}
              onChange={handleReturnReasonChange}>
              {data.returnReasons.map((reason) => (
                <MenuItem value={reason.id}>{reason.label}</MenuItem>
              ))}
            </Select>
          </div>
          <Grid container>
            <Grid
              item
              className={classes.buttonItemWrapper}>
              <BasketQuantitySelection
                className={classes.quantityWrapper}
                entryId={data.basketEntryId}
                qty={data.quantity}
                returnReasonId={data.returnReasonId}
                isReadOnly={disabled}
              />
              {!disabled && (
                <IconButton
                  size='small'
                  className={classes.delete}
                  edge='end'
                  aria-label='Delete'
                  onClick={() => onDelete(data.basketEntryId)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

function BasketQuantitySelection(props) {
  const { entryId, qty, isReadOnly, returnReasonId } = props
  const classes = useStyles()
  const returnServiceModel = useContext(ReturnServiceContext)

  function handleIncrement() {
    if (isReadOnly) return
    returnServiceModel.updateEntry(entryId, qty + 1, returnReasonId)
  }

  function handleDecrement() {
    if (isReadOnly) return
    returnServiceModel.updateEntry(entryId, qty + -1, returnReasonId)
  }

  function handleManualSelectQuantity() {
    if (isReadOnly) return
    alert('Manual quantity selection not implemented yet.')
  }

  return !isReadOnly ? (
    <>
      <IconButton
        size='small'
        aria-label='update'
        onClick={handleDecrement}
        className={classes.qtyBtn}>
        <RemoveCircle fontSize='small' />
      </IconButton>
      <div
        className={classes.quantity}
        onClick={handleManualSelectQuantity}>
        {qty}
      </div>
      <IconButton
        size='small'
        aria-label='update'
        onClick={handleIncrement}>
        <AddCircle fontSize='small' />
      </IconButton>
    </>
  ) : (
    <div
      style={{ marginLeft: '10px' }}
      onClick={handleManualSelectQuantity}>
      <TranslationLabel name='quantity' />: {qty}
    </div>
  )
}
