import React, { useEffect, useState } from 'react'
import { findApp } from '../../../core/settings/hubRoutes'
import TranslationLabel from '../../../core/components/TranslationLabel'
import { ListItem, ListItemText } from '@material-ui/core'

export default function AppListItem(props) {
  const [app, setApp] = useState({
    id: '',
    title: '',
    description: '',
    path: '',
  })
  const { appId } = props

  function loadApp() {
    let rs = findApp(appId)
    if (rs) setApp(rs)
  }

  useEffect(() => {
    if (appId) {
      loadApp()
    }
  }, [appId])

  return (
    app.id && (
      <ListItem
        button
        component='a'
        href={app.path}>
        <ListItemText primary={<TranslationLabel name={app.id} />} />
      </ListItem>
    )
  )
}
