import React from 'react'

export default function Feedback() {
  return (
    <>
      <iframe
        title='feedback'
        src='https://forms.monday.com/forms/embed/58a52ccb4aea1e54b0e58a5f810f8fbe?r=use1'
        width='100%'
        height='90%'
        style={{
          border: 0,
          boxShadow: '5px 5px 56px 0px rgba(0,0,0,0.25)',
        }}></iframe>
    </>
  )
}
