import React, { createContext } from 'react'
import { useBasketModel } from '../settings/BasketModel'

export const BasketContext = createContext(null)

export default function BasketProvider(props) {
  const basket = useBasketModel(props.basketId)
  console.log(props.basketId)

  return (
    <BasketContext.Provider value={basket}>{props.children}</BasketContext.Provider>
  )
}
