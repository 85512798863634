import React, { useState, useContext } from 'react'
import { useStore } from '../../global/store'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core'
import currencies from '../../global/currencies'
import { CoreContext } from '../../../../core/context/CoreContext'
import TranslationLabel from '../../../../core/components/TranslationLabel'

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',

    '& > *': {
      margin: theme.spacing(1),
      marginBottom: 20,
    },
  },
  field: {
    width: 'calc(100% - 16px)',
  },
  fieldHalf: {
    width: '50%',
  },
  spacing: {
    margin: '10px 25px 20px',
  },
}))

function CurrencyDialog(props) {
  const core = useContext(CoreContext)
  const { state } = useStore()
  const classes = useStyles()
  const [data, setData] = useState(() => {
    switch (props.type) {
      case 'delete':
        return props.data
      case 'update':
        return props.data
      default:
        return {
          axCustomerNo: '',
          segmentationCustomer: false,
          currencyCode: currencies[0],
        }
    }
  })

  const handleCurrencyChange = (e) => {
    setData({ ...data, currencyCode: e.target.value })
  }

  const handleAxCustomerNoChange = (e) => {
    setData({ ...data, axCustomerNo: e.target.value })
  }

  const toggleCheckedSegmentationCustomer = (e) => {
    let newValue = !data.segmentationCustomer

    setData({ ...data, segmentationCustomer: newValue })
  }

  const onBeforeClose = (data) => {
    // If no details has segmentationcustomer=true -> Set first detail to segmentationCustomer=true
    let any = false
    state.selectedCustomer.customerDetails.forEach((detail) => {
      if (!any && detail.id === data.id) {
        any = data.segmentationCustomer
      } else if (!any) {
        any = detail.segmentationCustomer
      }
    })

    if (!any) {
      core.masterItemDataStore
        .updateCustomerDetail({
          ...state.selectedCustomer.customerDetails[0],
          segmentationCustomer: true,
        })
        .then((r) => {
          props.onClose('customer')
        })
    } else {
      props.onClose('customer')
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let form = e.currentTarget
    const isValid = form.reportValidity()
    if (isValid) {
      if (props.type === 'delete') {
        core.masterItemDataStore
          .deleteCustomerDetail(data)
          .then((r) => onBeforeClose(data))
      } else {
        // Max one currency has segmentaionCustomer = true
        if (data.segmentationCustomer) {
          for (
            let i = 0;
            i < state.selectedCustomer.customerDetails.length;
            i++
          ) {
            const item = state.selectedCustomer.customerDetails[i]
            if (item.id !== data.id && item.segmentationCustomer) {
              core.masterItemDataStore.updateCustomerDetail({
                ...item,
                segmentationCustomer: false,
              })
            }
          }
        }

        if (props.type === 'update') {
          core.masterItemDataStore
            .updateCustomerDetail(data)
            .then((r) => onBeforeClose(data))
        } else {
          core.masterItemDataStore
            .addCustomerDetail({
              ...data,
              customerId: state.selectedCustomer.id,
            })
            .then((r) => onBeforeClose(data))
        }
      }
    }
  }

  return (
    <form
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit}>
      <DialogContent className={classes.root}>
        <TextField
          id='customerAx'
          label='AX Customer no'
          defaultValue={data.axCustomerNo}
          variant='outlined'
          disabled={props.type === 'delete'}
          onChange={handleAxCustomerNoChange}
          inputProps={{ maxLength: 20 }}
          className={classes.field}
          required
        />

        <FormControl
          variant='outlined'
          className={classes.fieldHalf}>
          <InputLabel id='customer-select-outlined-label'>
            Currency code
          </InputLabel>
          <Select
            labelId='customer-select-outlined-label'
            id='customer-select-outlined'
            value={data.currencyCode}
            disabled={props.type === 'delete'}
            onChange={handleCurrencyChange}
            required>
            {currencies.map((currency) => {
              return (
                <MenuItem
                  key={`cc-${currency}`}
                  value={currency}>
                  {currency}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <FormControlLabel
          className={classes.field}
          control={
            <Switch
              disabled={props.type === 'delete'}
              color='primary'
              checked={data.segmentationCustomer}
              onChange={toggleCheckedSegmentationCustomer}
            />
          }
          label='Segmentaion Customer'
        />
      </DialogContent>

      <DialogActions className={classes.spacing}>
        <Button
          color='default'
          variant='contained'
          disableElevation
          onClick={() => props.onClose()}>
          <TranslationLabel name='cancel' />
        </Button>
        <Button
          type='submit'
          color='primary'
          variant='contained'
          disableElevation>
          <TranslationLabel name={props.type} />
        </Button>
      </DialogActions>
    </form>
  )
}

export default CurrencyDialog
