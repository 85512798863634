import { useEffect, useState } from 'react'

//Settings handlers
import History from './history'

//Data
import DataStoreHandler from '../data/DataStoreHandler'
import ReactGA from 'react-ga'

//Themes
import Theme from '../theme/rdg-theme'
import DarkTheme from '../theme/dark-theme'
import { useDictionaryModel } from '../languages/Dictionary'
import { CustomerQuantityEnum } from '../../shared/Enums/CustomersEnum'

export function useCoreModel(snackbar) {
  const [isInitialized, setIsInitialized] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [theme, setTheme] = useState(Theme)
  const [width, setWidth] = useState()
  const [isAuthenticated, setisAuthenticated] = useState(false)
  const [title, setTitle] = useState()
  const [modal, setModal] = useState({ headline: '', message: '' })
  const [showLabelNames, setShowLabelNames] = useState(false)
  const [isExpanded, setIsExpanded] = useState(true)

  //Data, Stores & HttpClientHandler
  const dataStoreHandler = DataStoreHandler()

  //Core models
  const userModel = useUserModel(snackbar, dataStoreHandler)
  const dictionaryModel = useDictionaryModel(
    userModel,
    dataStoreHandler.dataStore,
  )
  const history = History

  function authenticateLogin(loginForm) {
    return new Promise((resolve, reject) => {
      let status = null
      dataStoreHandler.hubStore
        .login(loginForm.userName, loginForm.password)
        .then((response) => {
          status = response
          if (response.status === 200) {
            let userId = response.data.userId
            localStorage.setItem('accessToken', response.data.accessToken.token)
            localStorage.setItem('refreshToken', response.data.refreshToken)
            localStorage.setItem('userId', userId)
            setisAuthenticated(true)
            const setLoad = () => setIsLoading(false)
            userModel.load(userId, setLoad)
            resolve(true)
            //callback(true);
            //}
          } //else sessionModel.incrementLogin();
        })
        .catch((error) => {
          console.log(error)
          status = error.response?.status
          setisAuthenticated(false)
          reject(false)
        })
        .finally(() => {
          if (status === null)
            snackbar(
              'error',
              'Network error! Could not connect to the database.',
            )
          else if (status === 401)
            snackbar(
              'error',
              'Unauthorized! The user/password combination was not valid.',
            )
          else if (status === 500)
            snackbar(
              'error',
              'Internal server error! Contact support@rdg.dk if the problem persists.',
            )
        })
    })
  }

  function logout() {
    setisAuthenticated(false)
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userId')
    dataStoreHandler.hubStore
      .logout()
      .catch((error) => {
        console.warn(error)
      })
      .finally(() => {
        History.push('/login')
      })
  }

  function useSetTheme() {
    setTheme(theme === Theme ? DarkTheme : Theme) //Hack - doesn't actually set the theme, it flips between dark and normal.
    snackbar('info', 'Theme was changed')
  }

  function getUserApps() {
    let arr = []
    if (userModel.userSetting.apps) arr = userModel.userSetting.apps
    return arr
  }

  function setTitles(title) {
    document.title = `RDG Portal | ${title}`
    setTitle(title)
  }

  useEffect(() => {
    if (isInitialized)
    {
      return
    }

    setIsInitialized(true)

    // Check if user is authenticated
    let refreshToken = localStorage.getItem('refreshToken')
    let accessToken = localStorage.getItem('accessToken')
    let userId = localStorage.getItem('userId')

    if (refreshToken && accessToken && userId) {
      dataStoreHandler.hubStore
        .authenticated()
        .then((response) => {
          const setLoad = (success) => {
            if (success) {
              setisAuthenticated(true)
              setIsLoading(false)
            } else {
              snackbar('warning', 'Session could not be authenticated')
              setisAuthenticated(false)
              setIsLoading(false)
            }
          }
          userModel.load(userId, setLoad)
        })
        .catch((error) => {
          let isExpired =
            error.response?.status === 440 && error.response.data?.tokenExpired
          if (isExpired) {
            setIsInitialized(false)
            return
          }
          snackbar('warning', error.toString())
          setIsLoading(false)
        })
        .finally(() => {})
    } else {
      //history.push("/login"); todo: investigate - why redirect here?
      setIsLoading(false)
    }
  }, [
    snackbar,
    setIsInitialized,
    isInitialized,
    userModel,
    dataStoreHandler,
    history,
  ])

  useEffect(() => {
    setIsExpanded(getDrawerStatus())

    const mm = window.matchMedia("(max-width: 1366px)");
    mm.addEventListener('change', () => {
      if (mm.matches) {
        setIsExpanded(false)
      } else {
        setIsExpanded(getDrawerStatus())
      }
    })

    if (mm.matches) {
      setIsExpanded(false)
    }
  }, [])

  function getDrawerStatus() {
    let o = localStorage.getItem('openDrawer')
    return JSON.parse(o) === true
  }

  function expandDrawer(value) {
    setIsExpanded(value)
    localStorage.setItem('openDrawer', JSON.stringify(value))
  }

  return {
    isInitialized,
    isLoading,
    user: userModel,
    logout,
    history,
    theme,
    width,
    setWidth,
    snackbar,
    dictionary: dictionaryModel,
    hubStore: dataStoreHandler.hubStore,
    dataStore: dataStoreHandler.dataStore,
    statisticStore: dataStoreHandler.statisticStore,
    masterItemDataStore: dataStoreHandler.masterItemDataStore,
    authenticateLogin,
    setTheme: useSetTheme,
    getUserApps,
    isAuthenticated: isAuthenticated,
    setTitles,
    title,
    modal,
    setModal,
    showLabelNames,
    setShowLabelNames,
    isExpanded,
    expandDrawer
  }
}
export function useUserModel(snackbar, dataStoreHandler) {
  const [isInitialized, setIsInitialized] = useState(false)
  const [userId, setUserId] = useState(0)
  const [userName, setUserName] = useState('')
  const [displayName, setDisplayName] = useState('Unregistered user')
  const [selectedCustomer, setSelectedCustomer] = useState({
    accountNo: '',
    accountName: '',
    accountType: '',
    currency: '',
    Phone: '',
    Email: '',
  })
  const [basketUserId, setBasketUserId] = useState(0)
  const [language, setLanguage] = useState(0)
  const [languageCode, setLanguageCode] = useState()
  const [userSetting, setUserSetting] = useState({
    apps: [],
    primaryAppId: 1,
    selectedCustomerAccount: null,
    salesPersonAgentInitials: null,
    employeePurchaseAccount: null,
    samplesPurchaseAccount: null,
    languageManagement: false,
    HelpLinkManagement: false,
    customerUserManagement: false,
    allUserManagement: false,
    userTypeId: 1,
  })

  const [isLoading, setIsLoading] = useState(false)

  const { ONEORNONE, TWO, MANY } = CustomerQuantityEnum

  const [hasMultipleAccounts, setHasMultipleAccounts] = useState(ONEORNONE)

  function resetCustomer() {
    load(userId)
  }

  function handleChangeCustomer(acc, basketUserId) {
    acc.id = null
    if (!acc) {
      snackbar('warning', 'Invalid account!')
      return
    }

    setIsLoading(true)

    dataStoreHandler.hubStore
      .updateSelectedCustomerAccount({
        userId: userId,
        accountNo: acc.accountNo,
        accountName: acc.accountName,
      })
      .then(() => window.location.reload())
      .catch(() => snackbar('warning', 'Failed to set selected customer account no in Database'))
  }

  function loadSelectedCustomer(accountNo) {
    if (!accountNo) {
      setIsLoading(false)
      setSelectedCustomer({ noCustomer: true })
      return
    }
    dataStoreHandler.dataStore
      .getCustomerByAccountNo(accountNo)
      .then((res) => {
        if (res.status === 200) {
          setSelectedCustomer(res.data)
          setIsLoading(false)
        }
      })
      .catch((e) => {
        console.log(e.toString())
      })
  }

  function handleChangeLanguage(languageId, languageCode) {
    if (!userId) return

    dataStoreHandler.hubStore
      .updateUserLanguage({ userId, languageId })
      .then((x) => {
        if (x.status === 200) {
          setLanguage(languageId)
          setLanguageCode(languageCode)
        }
      })
      .catch((error) => {
        console.log(error)
        snackbar('warning', 'Failed to switch language.')
      })
      .finally(() => {})
  }

  function handleChangePrimaryApp(id, callback) {
    if (userId === 0) return

    let s = userSetting
    s.primaryAppId = id

    dataStoreHandler.hubStore
      .updateUser(s)
      .then((x) => {
        if (x.status === 200) {
          setUserSetting(s)
          if (callback) callback(id)
        }
      })
      .catch((error) => {
        if (callback) callback(id)
        console.log(error)
        snackbar('warning', 'Failed to update settings..')
      })
      .finally(() => {})
  }

  function selectedCustomerToString() {
    return (
      selectedCustomer.accountNo + ' (' + selectedCustomer.accountName + ')'
    )
  }

  function load(userId, callback) {
    if (!userId) return null
    setIsLoading(true)

    dataStoreHandler.hubStore
      .getLoggedInUser()
      .then((x) => {
        let rs = x
        if (x.status === 200) {
          if (rs.data) {
            const {
              userId,
              userName,
              languageId,
              languageCode,
              displayName,
              selectedCustomerAccount,
              salesPersonAgentInitials,
              employeePurchaseAccount,
              samplesPurchaseAccount,
            } = rs.data

            setUserId(userId)
            setUserName(userName)
            setBasketUserId(userId)
            setDisplayName(displayName)
            setUserSetting(rs.data)
            setLanguage(languageId)
            setLanguageCode(languageCode)
            loadSelectedCustomer(selectedCustomerAccount)
            setIsInitialized(true)

            if (salesPersonAgentInitials) {
              setHasMultipleAccounts(MANY)
            } else {
              //if no initials
              if (employeePurchaseAccount && samplesPurchaseAccount) {
                setHasMultipleAccounts(TWO)
              } else {
                setHasMultipleAccounts(ONEORNONE)
              }
            }

            ReactGA.set({ userId: userId })
            if (callback) callback(true)
          } else {
            if (callback) callback(false)
            return false
          }
        }
      })
      .catch(function (error) {
        throw error
      })
      .finally(() => setIsLoading(false))
  }
  return {
    isInitialized,
    isLoading,
    userId,
    basketUserId,
    account: userName,
    displayName,
    selectedCustomer,
    language,
    languageCode,
    hasMultipleAccounts,
    resetCustomer,
    setLanguage: handleChangeLanguage,
    setPrimaryApp: handleChangePrimaryApp,
    userSetting,
    load: load,
    setCustomer: handleChangeCustomer,
    selectedCustomerToString,
  }
}
