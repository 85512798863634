import React from 'react'

import Default from '@material-ui/icons/Warning'
import Exit from '@material-ui/icons/ExitToApp'
import Search from '@material-ui/icons/Search'
import Dashboard from '@material-ui/icons/Dashboard'
import Admin from '@material-ui/icons/SupervisedUserCircle'
import Basket from '@material-ui/icons/ShoppingBasket'
import User from '@material-ui/icons/AccountCircle'
import Switch from '@material-ui/icons/SwitchCamera'
import Gallery from '@material-ui/icons/PhotoAlbum'
import Graph from '@material-ui/icons/GraphicEqOutlined'
import Home from '@material-ui/icons/Home'
import Product from '@material-ui/icons/Shop'
import Invoice from '@material-ui/icons/Wallpaper'
import Settings from '@material-ui/icons/Settings'
import Highlight from '@material-ui/icons/Highlight'
import QuickEntry from '@material-ui/icons/Dialpad'
import Orders from '@material-ui/icons/AssignmentLate'
import Invoices from '@material-ui/icons/AssignmentTurnedIn'
import Statistics from '@material-ui/icons/BarChart'
import Help from '@material-ui/icons/Help'
import Customers from '@material-ui/icons/People'
import Translate from '@material-ui/icons/Translate'
import Hub from '@material-ui/icons/Apps'
import RemoveShoppingCart from '@material-ui/icons/RemoveShoppingCart'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'

const getIcon = (name) => {
  if (name) {
    const icon = name.toLowerCase()
    switch (icon) {
      case 'feedback':
        return <AnnouncementIcon />
      case 'exit':
        return <Exit />
      case 'dashboardpage':
        return <Dashboard />
      case 'admin':
        return <Admin />
      case 'basket':
        return <Basket />
      case 'homepage':
        return <Home />
      case 'products':
        return <Product />
      case 'invoice':
        return <Invoice />
      case 'graph':
        return <Graph />
      case 'gallery':
        return <Gallery />
      case 'switch':
        return <Switch />
      case 'search':
        return <Search />
      case 'user':
        return <User />
      case 'settings':
        return <Settings />
      case 'quickentry':
        return <QuickEntry />
      case 'orders':
        return <Orders />
      case 'invoices':
        return <Invoices />
      case 'statistics':
        return <Statistics />
      case 'help':
        return <Help />
      case 'customers':
        return <Customers />
      case 'translate':
        return <Translate />
      case 'hub':
        return <Hub />
      case 'returnservice':
        return <RemoveShoppingCart />
      case 'productsearch':
        return <Highlight />
      case 'videopage':
        return <PlayCircleOutlineIcon />
      case 'basketoverview':
        return <ShoppingBasketIcon />
      default:
        return <Default />
    }
  }
}

export default { getIcon: getIcon }
