import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import CancelIcon from '@material-ui/icons/Cancel'
import Fab from '@material-ui/core/Fab/Fab'

const icon = (v) => {
  if (v === 'add') return <AddIcon />
  else if (v === 'submit') return <CheckIcon />
  else if (v === 'cancel') return <CancelIcon />
  else return null
}

export const FloatingActionButton = (props) => {
  const { variant } = props
  return (
    <Fab
      color='primary'
      aria-label={variant}
      className='fab'
      onClick={(e) => props.onClick(e)}
      style={{
        position: 'fixed',
        right: '15px',
        bottom: '60px',
        zIndex: '1000',
      }}>
      {icon(variant)}
    </Fab>
  )
}
