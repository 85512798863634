import React, { useState, useRef, useContext } from 'react'

import TranslationLabel from '../../../../core/components/TranslationLabel'
import useStyles from '../SearchPage/itemStyles'
import { TextField } from '@material-ui/core'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { ProductDescription } from '../../../sales-web-app/pages/Products/ViewModes/ListViewItem'
import { CoreContext } from '../../../../core/context/CoreContext'

export const ListViewItem = React.memo((props) => {
  const core = useContext(CoreContext)
  const { product, campaignId, refreshProducts } = props
  const { colli, minimumQuantity } = product
  const [sorting, setSorting] = useState(product.sorting)
  const classes = useStyles()
  const inputRef = useRef(null)
  const [loading, setLoading] = useState(false)

  const [currentPrice, setCurrentPrice] = useState(
    findPrice(product.priceDtos[0].minQuantity),
  )

  const hasDiscount = currentPrice.discountPercentage > 0

  const changeSortIndex = (e) => {
    setSorting(e.target.value)
  }

  const onBlurHandler = () => {
    setLoading(true)
    core.dataStore
      .setSortingOnCampaignProduct({
        campaignId,
        itemId: product.productNo,
        sorting,
      })
      .then((res) => {
        refreshProducts()
        setLoading(false)
      })
      .catch((err) => {
        alert('couldnt update product')
        setLoading(false)
      })
  }

  const onArrowUp = () => {
    if (!loading && sorting >= 2) {
      setLoading(true)
      core.dataStore
        .setSortingOnCampaignProduct({
          campaignId,
          itemId: product.productNo,
          sorting: sorting - 1,
        })
        .then(() => {
          refreshProducts()
          setLoading(false)
        })
        .catch((err) => {
          alert('couldnt update product')
          setLoading(false)
        })
    }
  }

  const onArrowDown = () => {
    if (!loading) {
      setLoading(true)
      core.dataStore
        .setSortingOnCampaignProduct({
          campaignId,
          itemId: product.productNo,
          sorting: sorting + 1,
        })
        .then(() => {
          refreshProducts()
          setLoading(false)
        })
        .catch(() => {
          alert('couldnt update product')
          setLoading(false)
        })
    }
  }

  function findPrice(qty) {
    let _currentPrice = {
      minQuantity: 0,
    }
    product.priceDtos.forEach((price) => {
      if (
        price.minQuantity <= qty &&
        price.minQuantity > _currentPrice.minQuantity
      ) {
        _currentPrice = price
      }
    })
    return _currentPrice
  }

  const hasNoPrice = !product.priceDtos.length

  return (
    <div className={classes.listBox}>
      <ProductDescription product={product} />
      {/* Price, Quantity and Buy button */}
      <div className={classes.quantityAndPriceWrapper}>
        <div className={classes.quantityInfos}>
          <p className={classes.quantityInfo}>
            <TranslationLabel name='colli' /> {colli}
          </p>
          <p className={classes.quantityInfo}>
            <TranslationLabel name='minQuantity' /> {minimumQuantity}
          </p>
        </div>
        <div className={`${classes.prices} ${classes.listPrices}`}>
          {/* Normal price */}
          {hasDiscount ? (
            <del className={classes.priceNormal}>{currentPrice.grossPrice}</del>
          ) : (
            <span className={classes.priceNormal}>
              <TranslationLabel name='price' />
            </span>
          )}

          {/* Your price */}
          <div>
            <span className={classes.priceYours}>{currentPrice.netPrice}</span>
            <span className={classes.priceText}>
              {hasDiscount ? (
                <span>
                  <TranslationLabel name='price' />
                </span>
              ) : (
                ''
              )}
              <TranslationLabel name='productsQuantityPrefix' />
              {`${product.quantity || currentPrice.minQuantity} `}
              <TranslationLabel name='productsUnit' />
            </span>
          </div>
        </div>
      </div>
      <div className={classes.cta}>
        <TextField
          disabled={hasNoPrice}
          size='small'
          type='number'
          value={sorting}
          onChange={changeSortIndex}
          className={classes.quantity}
          label={<TranslationLabel name='sortIndex' />}
          InputLabelProps={{ shrink: true }}
          inputRef={inputRef}
          onClick={() => {
            inputRef.current.select(0, -1)
          }}
          onBlur={onBlurHandler}
          variant='outlined'
        />
        <div style={{ marginTop: -5, marginLeft: 20 }}>
          <div
            onClick={onArrowUp}
            className={classes.sortingButton}>
            <ArrowDropUpIcon
              onClick={() => {}}
              style={{ fill: 'black' }}
            />
          </div>
          <div
            onClick={onArrowDown}
            className={classes.sortingButton}>
            <ArrowDropDownIcon
              onClick={() => {}}
              style={{ fill: 'black' }}
            />
          </div>
        </div>
      </div>
    </div>
  )
})
