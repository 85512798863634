import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'

import Clear from '@material-ui/icons/Clear'
import OutlinedInputField from '../../../apps/sales-web-app/components/ui/OutlinedInputField'
import AutoSuggest from './AutoSuggest'

const styles = () => ({
  loader: {
    borderRadius: '10px 10px 0 0',
  },
})

let timeOut

class SearchForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchInput: props.initialValue || '',
      results: [],
      currentResults: 0,
      displayResults: false,
      anchorParentEl: null,
      anchorEl: null,
      open: false,
      isLoading: false,
      delay: true,
    }
  }

  handleResult = (rs, val) => {
    const { handleResult, displayResults } = this.props
    if (!displayResults) handleResult(rs, val)
    this.setState({
      isLoading: false,
      displayResults: displayResults && rs.length > 0,
      results: rs,
    })
  }

  handle = (e) => {
    window.clearTimeout(timeOut)
    const { currentTarget } = e
    const { onEmptyTerm, items, onSearch, async } = this.props
    const val = e.target.value
    const returnResult = (rs) => this.handleResult(rs, val)

    this.setState(
      {
        isLoading: val.length > 1 || val.length === 0,
        searchInput: val,
        displayResults: false,
        delay: true,
        anchorEl: currentTarget,
      },
      () => {
        if (items) {
          if (val.length > 0) {
            timeOut = window.setTimeout(() => {
              onSearch(val).then((rs) => returnResult(rs.data))
            }, 300)
          } else if (val.length === 0) {
            if (onEmptyTerm) {
              timeOut = window.setTimeout(() => {
                onEmptyTerm().then((rs) => returnResult(rs.data))
              }, 300)
            } else returnResult([])
          }
        } else {
          if (val.length > 0) {
            timeOut = window.setTimeout(() => {
              if (async) {
                onSearch(val).then((rs) => returnResult(rs.data))
              } else {
                returnResult(onSearch(val))
              }
              this.setState({
                isLoading: false,
              })
            }, 150)
          } else returnResult([])
        }
      },
    )
  }

  handleSelect = (obj) => {
    this.props.onSelect(obj)
    this.setState({
      displayResults: false,
      searchInput: this.props.primary(obj),
    })
  }
  handleClear = () => {
    this.props.onSelect({})
    this.setState({ displayResults: false, searchInput: '' })
  }

  render() {
    const { displayResults } = this.props
    const { isLoading } = this.state

    return (
      <div>
        <OutlinedInputField
          value={this.state.searchInput}
          onChange={this.handle}
          icon={
            <Clear
              style={{ cursor: 'pointer' }}
              onClick={this.handleClear}
            />
          }
          isLoading={isLoading}
          htmlFor={this.props.htmlFor}
          label={this.props.label}
          fullWidth={this.props.fullWidth}
          required={this.props.required}
          error={this.props.error}
        />

        {displayResults ? (
          <AutoSuggest
            open={this.state.displayResults}
            anchorEl={this.state.anchorEl}
            items={this.state.results}
            primary={this.props.primary}
            onSelect={this.handleSelect}
          />
        ) : null}
      </div>
    )
  }
}

export default withStyles(styles)(SearchForm)
