import { useContext } from 'react'
import routeSettings from './routes'
import { CoreContext } from '../../../core/context/CoreContext'

const GetNavItems = () => {
  const core = useContext(CoreContext)
  const { canChangeSortOrder } = core.user.userSetting
  const upperNavigation = [
    navItem('productSearch', 1, 'productSearch'),
    navItem('orders', 2, 'orders'),
    navItem('statistics', 3, 'statistics'),
    navItem('help', 2, 'help'),
  ]

  if (canChangeSortOrder) {
    upperNavigation.push(navItem('sorting', 4, 'sorting'))
  }

  return {
    upperNavigation: upperNavigation,
  }
}

const navItem = (id, index, labelKey) => {
  return {
    id: id,
    route: routeSettings.getRoute(id),
    index: index,
    labelKey: labelKey,
  }
}

export default GetNavItems
