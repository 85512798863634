import React from 'react'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Monkey from '../../../core/images/mini-abe.png'
import Typography from '@material-ui/core/Typography/Typography'
import TranslationLabel from '../../../core/components/TranslationLabel'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  image: {
    maxHeight: '250px',
    maxWidth: '258px',
    filter: 'grayscale(100%)',
    width: '100%',
    height: '100%',
  },
  centerGrid: {
    alignItems: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
}))

export default function EmptyState(props) {
  const classes = useStyles()
  const { main = 'emptyStateMain', sub = 'emptyStateSub' } = props
  return (
    <div className={classes.root}>
      <Grid
        container
        direction='column'
        className={classes.centerGrid}>
        <Grid
          item
          xs={6}>
          <img
            className={classes.image}
            src={Monkey}
            alt='Empty state'
          />
        </Grid>
        <Grid
          item
          xs={6}>
          <Typography
            variant='h5'
            component='h3'
            className={classes.textCenter}>
            <TranslationLabel name={main} />
          </Typography>

          <Typography
            variant='subtitle1'
            className={classes.textCenter}>
            <TranslationLabel name={sub} />
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}
