import React, { useState } from 'react'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import AppointmentList from '../../components/appointmentList/AppointmentList'
import DashboardWidgetContainer from '../../components/DashboardWidgetContainer'
import DashboardMessage from './DashboardMessage'
import NumberOfAppointments from './NumberOfAppointments'
import { Button } from '@material-ui/core'
import CustomerNote from './CustomerNote'
import BlockedCustomers from './BlockedCustomers'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1400,
    marginLeft: 'auto',
    marginRight: 'auto',
    color: 'black',
  },
  content: {
    maxWidth: 1400,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: 'calc(100% - 55px)',
  },
  titleScreen: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 1400,
  },
  dashTabs: {
    flexGrow: 1,
    marginBottom: '10px',
  },
  switchContainer: {
    display: 'flex',
    width: '100%',
    flex: 1,
    justifyContent: 'flex-end',
    fontSize: 12,
  },
  userContainer: {
    display: 'flex',
    height: 'calc(100% - 55px)',
    padding: '15px',
  },
  userLeftContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
  },
  userRightContainer: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  userIcon: {
    color: theme.palette.primary.main,
    fontSize: 70,
  },
  progress: {
    width: '90%',
    height: 5,
    marginTop: '20px',
    margin: 'auto',
  },
}))

export default function DashboardPage() {
  const classes = useStyles()
  const [showCustomerNote, setShowCustomerNote] = useState(false)
  const [showBlockedCustomers, setShowBlockedCustomers] = useState(false)

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container>
          <>
            <Grid
              item
              xs={12}
              md={6}>
              <DashboardWidgetContainer
                title={<TranslationLabel name='numberOfAppointments' />}
                content={<NumberOfAppointments />}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <DashboardWidgetContainer
                title={<TranslationLabel name='futureAppointments' />}
                content={<AppointmentList />}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <DashboardWidgetContainer
                title={
                  <>
                    <TranslationLabel name='blockedCustomers' />
                    <Button
                      style={{ marginLeft: 5 }}
                      variant='outlined'
                      color='primary'
                      onClick={() =>
                        setShowBlockedCustomers(!showBlockedCustomers)
                      }>
                      {showBlockedCustomers ? (
                        <TranslationLabel name='hide' />
                      ) : (
                        <TranslationLabel name='show' />
                      )}
                    </Button>
                  </>
                }
                content={showBlockedCustomers ? <BlockedCustomers /> : <></>}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <DashboardWidgetContainer
                title={<TranslationLabel name='internalDashboardTitle' />}
                content={
                  <DashboardMessage messageLabelName='internalDashboardMessage' />
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <DashboardWidgetContainer
                title={
                  <>
                    <TranslationLabel name='customerNote' />
                    <Button
                      style={{ marginLeft: 5 }}
                      variant='outlined'
                      color='primary'
                      onClick={() => setShowCustomerNote(!showCustomerNote)}>
                      {showCustomerNote ? (
                        <TranslationLabel name='hide' />
                      ) : (
                        <TranslationLabel name='show' />
                      )}
                    </Button>
                  </>
                }
                content={showCustomerNote ? <CustomerNote /> : <></>}
              />
            </Grid>
          </>
        </Grid>
      </div>
    </div>
  )
}
