import routeSettings from './routes'

const navItem = (id, index, labelKey) => {
  return {
    id: id,
    route: routeSettings.getRoute(id),
    index: index,
    labelKey: labelKey,
  }
}

export default {
    upperNavigation: [navItem('link', 1, 'link'), navItem('Help', 2, 'help')]
}
