import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'

//Components
import LoadingBarBox from '../components/ui/LoadingBarBox'
import OutlinedInputField from '../../apps/sales-web-app/components/ui/OutlinedInputField'
import { CoreContext } from '../context/CoreContext'

//Material UI
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Logo from '../../core/images/rosendahl-design-group-logo.png'
import TranslationLabel from '../components/TranslationLabel'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { Link } from 'react-router-dom'

const styles = (theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3,
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  marginTop: {
    marginTop: '45vh',
    transform: 'translateY(-50%)',
  },
  logo: {
    width: 'auto',
    height: '100px',
    filter: 'brightness(0.5)',
    padding: theme.spacing(1),
    marginBottom: 20,
  },
  main: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundColor: '#312926',
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundImage: 'url(/images/login-background.jpg)',
  },
  root: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
})

function SignIn(props) {
  const core = useContext(CoreContext)

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [displayName, setDisplayName] = useState('')
  const [displayNameError, setDisplayNameError] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [successfullySignedUp, setSuccessfullySignedUp] = useState(false)

  const { classes, match } = props

  //userId is base64 encoded, so decode before sending
  const userId = atob(match.params.id)
  const language = match.params.language

  function handleChangeDisplayName(e) {
    setDisplayNameError(false)
    setDisplayName(e.target.value)
  }

  function handleChangeEmail(e) {
    setEmailError(false)
    setEmail(e.target.value)
  }

  const languages = {
    'en-gb': 1,
    'en-us': 1,
    'da-DK': 2,
    'sv': 3,
    'nb-no': 4,
    'de': 5,
  }

  function submit() {
    if (!email) {
      return setEmailError(true)
    }
    if (!displayName) {
      return setDisplayNameError(true)
    }
    setIsLoading(true)
    core.hubStore
      .signupUser({
        selectedCustomer: userId,
        language: languages[language] || 1,
        displayName,
        email,
      })
      .then(() => {
        setSuccessfullySignedUp(true)
      })
      .catch((err) => {
        console.log(err)
        core.setModal({
          headline: 'An error occured',
          message: 'Contact customer service for further assistance',
        })
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <main className={classes.main}>
      <div className={classes.root}>
        <LoadingBarBox
          className={classes.marginTop}
          isLoading={isLoading}>
          <Paper className={classes.paper}>
            <img
              alt='complex'
              src={Logo}
              className={classes.logo}
            />

            {successfullySignedUp ? (
              <SuccessfulSignup />
            ) : (
              <>
                <OutlinedInputField
                  id='userNameOutlinedInputField'
                  htmlFor='email'
                  label='Email'
                  value={email}
                  onChange={handleChangeEmail}
                  onEnter={submit}
                  icon={<AccountCircle />}
                  required
                  fullWidth
                  error={emailError}
                />

                <OutlinedInputField
                  id='displayNameOutlinedInputField'
                  htmlFor='displayname'
                  label='Your Name'
                  value={displayName}
                  onChange={handleChangeDisplayName}
                  onEnter={submit}
                  icon={<AccountCircle />}
                  required
                  fullWidth
                  error={displayNameError}
                />

                <Button
                  key='loginSubmit'
                  type='submit'
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  onClick={submit}
                  href={void 0}
                  fullWidth>
                  Sign Up
                </Button>

                <Link
                  to='/login'
                  style={{ marginTop: '10px', textDecoration: 'underline' }}>
                  Already have an account?
                </Link>
              </>
            )}
          </Paper>
        </LoadingBarBox>
      </div>
    </main>
  )
}

function SuccessfulSignup() {
  return (
    <>
      <CheckCircleOutlineIcon style={{ color: 'green', margin: 'auto' }} />
      <Typography
        variant='h5'
        component='h1'>
        Succces
      </Typography>
      <Typography variant='subtitle1'>
        Check your email for login information
      </Typography>
      <Link
        style={{ color: 'blue' }}
        to='/login'>
        Log In
      </Link>
    </>
  )
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SignIn)
