import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ReturnServiceContext } from '../../../context/ReturnServiceContext'
import { CoreContext } from '../../../../../core/context/CoreContext'

import Grid from '@material-ui/core/Grid'
import OutlinedInputField from '../../../components/ui/OutlinedInputField'
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Button from '@material-ui/core/Button'
import TranslationLabel from '../../../../../core/components/TranslationLabel'

import { useForm, Controller } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 400,
    marginBottom: 20,
  },
  margin: {
    margin: theme.spacing(1),
  },
  hr: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  padd: {
    paddingTop: 6,
  },
  btns: {
    display: 'flex',
    padding: theme.spacing(1),
  },
  btn: {
    width: 'auto',
    height: 40,
    marginTop: 30,
    marginRight: 10,
    minWidth: 100,
  },
  btnNext: {
    'color': 'white',
    'backgroundColor': 'black',
    'width': '100%',
    'maxWidth': 300,
    'marginRight': 0,
    'marginLeft': 'auto',

    '&:hover': {
      backgroundColor: theme.palette.grey[800],
    },
  },
}))

const firstCountriesInSelect = ['DNK', 'DEU', 'NOR', 'SWE']

function ReturnServiceOrderDetails(props) {
  const history = useHistory()
  const core = useContext(CoreContext)
  const checkout = useContext(ReturnServiceContext)
  const classes = useStyles()
  const { advanceStep } = props
  const [deliveryAddresses, setDeliveryAddresses] = useState([])

  const [countries, setCountries] = useState([])

  useEffect(() => {
    if (core.user.language) {
      core.dataStore
        .getCustomerDeliveryCountries(core.user.language)
        .then((res) => {
          const countries = res?.data?.result?.sort((a, b) => {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0
          })
          const firstCountries = firstCountriesInSelect.map((el) =>
            countries.find((country) => country.code === el),
          )

          setCountries([...firstCountries, ...countries])
        })
        .catch(() => {})
    }
  }, [core.user.language])

  const {
    handleSubmit,
    control,
    errors: fieldsErrors,
    setValue,
    clearErrors,
    setError,
  } = useForm({ defaultValues: checkout.orderDetails })

  const {
    addressFormLocked,
    setAddressFormLocked,
    setIsLoading,
    setIsInitialized,
    isInitialized,
    validateOrderDetails,
    useAnotherAddress,
    setUseAnotherAddress,
  } = checkout

  let checkObject = (obj) => (obj ? true : false)

  function onSubmit(formData) {
    if (!Number(formData.pickupPallets) && !Number(formData.pickupPackages)) {
      setError('pickupPallets', {
        type: 'focus',
        message: <TranslationLabel name='pickupPalletsError' />,
      })
      setError('pickupPackages', {
        type: 'focus',
        message: <TranslationLabel name='pickupPackagessError' />,
      })
      return
    }
    validateOrderDetails(
      {
        ...formData,
        //send 0 instead of empty strings
        pickupPackages: formData.pickupPackages || 0,
        pickupPallets: formData.pickupPallets || 0,
      },
      advanceStep,
    )
  }

  function resetDeliveryAdress(isChecked) {
    if (isChecked) {
      setValue('pickupName', '')
      setValue('pickupStreet1', '')
      setValue('pickupStreet2', '')
      setValue('pickupZipCode', '')
      setValue('pickupCity', '')
      setValue('pickupCountry', '')

      //reset select
      setValue('deliveryAddressId', '')
      setAddressFormLocked(false)
    } else {
      let id = deliveryAddresses[0].id
      setValue('deliveryAddressId', id)
      onInvoiceAddressSelectChange(id)
    }

    setValue('hasNewAddress', isChecked)

    clearErrors([
      'pickupName',
      'pickupStreet1',
      'pickupStreet2',
      'pickupZipCode',
      'pickupCity',
      'pickupCountry',
    ])
  }

  function onInvoiceAddressSelectChange(id) {
    setAddressFormLocked(false)
    let address = deliveryAddresses.find((x) => x.id === id)
    setAdresse(address)
    setAddressFormLocked(true)
  }

  function setAdresse(address) {
    setValue('pickupName', address.name)
    setValue('pickupStreet1', address.street)
    setValue('pickupStreet2', address.street2)
    setValue('pickupZipCode', address.zip)
    setValue('pickupCity', address.city)
    setValue('pickupCountry', address.country)
    setValue('deliveryAddressId', address.id)
  }

  useEffect(() => {
    function load() {
      core.dataStore
        .getCustomerAddressesByAccountNo(core.user.selectedCustomer.accountNo)
        .then((x) => {
          if (x.status === 200) {
            setDeliveryAddresses(x.data)
            if (!isInitialized) {
              let address = x.data[0]
              if (addressFormLocked) {
                setAdresse(address)
                setValue('deliveryAddressId', address.id)
              }
              setIsInitialized(true)
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    if (core.isInitialized) {
      //redirect if no customer accountNo set,
      //could be if basket is accessed directly from browser refresh
      if (!core.user.selectedCustomer.accountNo) {
        history.push('/sales/returnService')
      } else {
        load()
      }
    }
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        direction='row'
        justify='center'>
        <Grid
          item
          xs={12}
          md={9}
          lg={6}>
          <h1>
            1. <TranslationLabel name='returnOrderDetails' />
          </h1>
          <Typography variant='subtitle1'>
            <TranslationLabel name='returnOrderPalletsAndPackagesExplanation' />
          </Typography>

          <Grid item>
            <FormControl fullWidth>
              <Controller
                name='pickupPallets'
                control={control}
                defaultValue=''
                render={({ onChange, onBlur, value, name, ref }) => (
                  <TextField
                    type='number'
                    inputRef={ref}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => {
                      clearErrors()
                      var value =
                        e.target.value < 0
                          ? (e.target.value = 0)
                          : e.target.value

                      value =
                        e.target.value > 5
                          ? (e.target.value = 5)
                          : e.target.value

                      return onChange(Number(value))
                    }}
                    name={name}
                    className={classes.margin}
                    id='pickupPallets'
                    helperText={
                      fieldsErrors.pickupPallets
                        ? fieldsErrors.pickupPallets.message
                        : null
                    }
                    variant='outlined'
                    label={<TranslationLabel name='returnPallets' />}
                    error={checkObject(fieldsErrors.pickupPallets)}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl fullWidth>
              <Controller
                name='pickupPackages'
                control={control}
                defaultValue=''
                render={({ onChange, onBlur, value, name, ref }) => (
                  <TextField
                    type='number'
                    inputRef={ref}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => {
                      clearErrors()
                      var value =
                        e.target.value < 0
                          ? (e.target.value = 0)
                          : e.target.value

                      value =
                        e.target.value > 20
                          ? (e.target.value = 20)
                          : e.target.value

                      return onChange(Number(value))
                    }}
                    name={name}
                    className={classes.margin}
                    id='pickupPackages'
                    helperText={
                      fieldsErrors.pickupPackages
                        ? fieldsErrors.pickupPallets.message
                        : null
                    }
                    variant='outlined'
                    label={<TranslationLabel name='returnPackages' />}
                    error={checkObject(fieldsErrors.pickupPackages)}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Divider
            light
            className={classes.hr}
          />

          {
            <div>
              <div>
                <Typography variant='subtitle1'>
                  <TranslationLabel name='returnOrderAddressExplanation' />
                </Typography>
                {/* Select address */}
                <FormControl
                  variant='outlined'
                  className={`${classes.formControl} ${classes.margin}`}>
                  <InputLabel id='demo-simple-select-outlined-label'>
                    <TranslationLabel name='selectReturnAddress' />
                  </InputLabel>
                  <Controller
                    name='deliveryAddressId'
                    control={control}
                    render={({ onChange, onBlur, value, name, ref }) => (
                      <Select
                        disabled={!addressFormLocked}
                        labelId='demo-simple-select-outlined-label'
                        id='demo-simple-select-outlined'
                        name={name}
                        onBlur={onBlur}
                        value={value}
                        onChange={(e) => {
                          onInvoiceAddressSelectChange(e.target.value)
                          return onChange(e.target.value)
                        }}>
                        {/* alphabetical list */}
                        {deliveryAddresses
                          .sort((a, b) =>
                            a.name < b.name ? -1 : Number(a.name > b.name),
                          )
                          .map((address) => (
                            <MenuItem
                              key={address.id}
                              value={address.id}>
                              {address.name +
                                ', ' +
                                address.street +
                                ', ' +
                                address.street2}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </FormControl>

                {/* New address */}
                <Controller
                  control={control}
                  name='hasNewAddress'
                  render={({ onChange, onBlur, value, name, ref }) => (
                    <FormControlLabel
                      className={`${classes.margin} ${classes.padd}`}
                      control={
                        <Checkbox
                          name={name}
                          color='primary'
                          onBlur={onBlur}
                          checked={useAnotherAddress}
                          inputRef={ref}
                          onChange={(e) => {
                            setUseAnotherAddress(e.target.checked)
                            resetDeliveryAdress(e.target.checked)
                          }}
                        />
                      }
                      label={
                        <TranslationLabel name='orderReturnDetailsHasNewAddress' />
                      }
                    />
                  )}
                />
              </div>

              <Divider
                light
                className={classes.hr}
              />

              <>
                <Grid item>
                  <FormControl fullWidth>
                    <Controller
                      name='pickupName'
                      control={control}
                      defaultValue=''
                      render={({ onChange, onBlur, value, name, ref }) => (
                        <TextField
                          inputRef={ref}
                          value={value}
                          onBlur={onBlur}
                          onChange={(e) => onChange(e.target.value)}
                          name={name}
                          disabled={addressFormLocked}
                          className={classes.margin}
                          helperText={
                            fieldsErrors.deliveryName
                              ? fieldsErrors.deliveryName.message
                              : null
                          }
                          label={<TranslationLabel name='returnname' />}
                          inputProps={{ maxLength: 100 }}
                          error={checkObject(fieldsErrors.deliveryName)}
                          variant='outlined'
                        />
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: (
                            <TranslationLabel name='formFieldIsRequired' />
                          ),
                        },
                        maxLength: {
                          value: 100,
                          message: (
                            <TranslationLabel name='formFieldHasTooManyCharacters' />
                          ),
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <Controller
                      name='pickupStreet1'
                      control={control}
                      defaultValue=''
                      render={({ onChange, onBlur, value, name, ref }) => (
                        <TextField
                          inputRef={ref}
                          value={value}
                          onBlur={onBlur}
                          onChange={(e) => onChange(e.target.value)}
                          name={name}
                          disabled={addressFormLocked}
                          className={classes.margin}
                          helperText={
                            fieldsErrors.deliveryStreetName1
                              ? fieldsErrors.deliveryStreetName1.message
                              : null
                          }
                          inputProps={{ maxLength: 60 }}
                          label={<TranslationLabel name='returnStreetName1' />}
                          error={checkObject(fieldsErrors.deliveryStreetName1)}
                          variant='outlined'
                        />
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: (
                            <TranslationLabel name='formFieldIsRequired' />
                          ),
                        },
                        maxLength: {
                          value: 60,
                          message: (
                            <TranslationLabel name='formFieldHasTooManyCharacters' />
                          ),
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <Controller
                      name='pickupStreet2'
                      control={control}
                      defaultValue=''
                      render={({ onChange, onBlur, value, name, ref }) => (
                        <TextField
                          inputRef={ref}
                          value={value}
                          onBlur={onBlur}
                          onChange={(e) => onChange(e.target.value)}
                          name={name}
                          disabled={addressFormLocked}
                          className={classes.margin}
                          id='deliveryStreetName2'
                          helperText={
                            fieldsErrors.deliveryStreetName2
                              ? fieldsErrors.deliveryStreetName2.message
                              : null
                          }
                          inputProps={{ maxLength: 60 }}
                          label={<TranslationLabel name='returnStreetName2' />}
                          error={checkObject(fieldsErrors.deliveryStreetName2)}
                          variant='outlined'
                        />
                      )}
                      rules={{
                        maxLength: {
                          value: 60,
                          message: (
                            <TranslationLabel name='formFieldHasTooManyCharacters' />
                          ),
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <Grid
                      item
                      container
                      direction='row'>
                      <Controller
                        name='pickupZipCode'
                        control={control}
                        defaultValue=''
                        render={({ onChange, onBlur, value, name, ref }) => (
                          <TextField
                            inputRef={ref}
                            value={value}
                            onBlur={onBlur}
                            onChange={(e) => onChange(e.target.value)}
                            name={name}
                            disabled={addressFormLocked}
                            className={classes.margin}
                            id='deliveryZipCode'
                            helperText={
                              fieldsErrors.deliveryZipCode
                                ? fieldsErrors.deliveryZipCode.message
                                : null
                            }
                            inputProps={{ maxLength: 10 }}
                            label={<TranslationLabel name='returnZipCode' />}
                            error={checkObject(fieldsErrors.deliveryZipCode)}
                            variant='outlined'
                          />
                        )}
                        rules={{
                          required: {
                            value: useAnotherAddress,
                            message: (
                              <TranslationLabel name='formFieldIsRequired' />
                            ),
                          },
                        }}
                      />
                      <Controller
                        name='pickupCity'
                        control={control}
                        defaultValue=''
                        render={({ onChange, onBlur, value, name, ref }) => (
                          <TextField
                            inputRef={ref}
                            value={value}
                            onBlur={onBlur}
                            onChange={(e) => onChange(e.target.value)}
                            name={name}
                            disabled={addressFormLocked}
                            className={classes.margin}
                            id='deliveryCity'
                            helperText={
                              fieldsErrors.deliveryCity
                                ? fieldsErrors.deliveryCity.message
                                : null
                            }
                            inputProps={{ maxLength: 60 }}
                            label={<TranslationLabel name='returnCity' />}
                            error={checkObject(fieldsErrors.deliveryCity)}
                            variant='outlined'
                          />
                        )}
                        rules={{
                          required: {
                            value: useAnotherAddress,
                            message: (
                              <TranslationLabel name='formFieldIsRequired' />
                            ),
                          },
                        }}
                      />
                    </Grid>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <Controller
                      name='pickupCountry'
                      control={control}
                      defaultValue=''
                      render={({ onChange, onBlur, value, name, ref }) => (
                        <>
                          <InputLabel
                            style={{
                              marginLeft: '25px',
                              backgroundColor: 'white',
                            }}
                            id='deliveryCountryLabel'>
                            {<TranslationLabel name='returnCountry' />}
                          </InputLabel>
                          <Select
                            inputRef={ref}
                            value={value}
                            onBlur={onBlur}
                            onChange={(e) => onChange(e.target.value)}
                            name={name}
                            disabled={addressFormLocked}
                            className={classes.margin}
                            id='deliveryCountry'
                            labelId='deliveryCountryLabel'
                            helperText={
                              fieldsErrors.deliveryCountry
                                ? fieldsErrors.deliveryCountry.message
                                : null
                            }
                            inputProps={{ maxLength: 10 }}
                            label={<TranslationLabel name='returnCountry' />}
                            error={checkObject(fieldsErrors.deliveryCountry)}
                            variant='outlined'>
                            {countries.map((el) => (
                              <MenuItem value={el.code}>{el.name}</MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: (
                            <TranslationLabel name='formFieldIsRequired' />
                          ),
                        },
                        maxLength: {
                          value: 10,
                          message: (
                            <TranslationLabel name='formFieldHasTooManyCharacters' />
                          ),
                        },
                      }}
                    />
                  </FormControl>
                </Grid>

                <Divider
                  light
                  className={classes.hr}
                />
              </>
            </div>
          }
          <Typography variant='subtitle1'>
            <TranslationLabel name='returnOrderReqNoEtcExplanation' />
          </Typography>
          <Grid item>
            <FormControl fullWidth>
              <Controller
                name='pickupContactPerson'
                control={control}
                defaultValue=''
                render={({ onChange, onBlur, value, name, ref }) => (
                  <TextField
                    inputRef={ref}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.value)}
                    name={name}
                    className={classes.margin}
                    id='pickupContactPerson'
                    helperText={
                      fieldsErrors.pickupContactPerson
                        ? fieldsErrors.pickupContactPerson.message
                        : null
                    }
                    inputProps={{ maxLength: 60 }}
                    label={<TranslationLabel name='pickupContactPerson' />}
                    error={checkObject(fieldsErrors.pickupContactPerson)}
                    variant='outlined'
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <Controller
              name='requisitionNo'
              control={control}
              defaultValue=''
              render={({ onChange, onBlur, value, name, ref }) => (
                <OutlinedInputField
                  rowsMax={4}
                  rows={3}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  onChange={onChange}
                  label={<TranslationLabel name='requisitionNo' />}
                  inputProps={{ maxLength: 30 }}
                  inputRef={ref}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              name='comment'
              control={control}
              defaultValue=''
              render={({ onChange, onBlur, value, name, ref }) => (
                <OutlinedInputField
                  multiline
                  rowsMax={4}
                  rows={3}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  onChange={onChange}
                  label={<TranslationLabel name='returnComment' />}
                  inputProps={{ maxLength: 200 }}
                  inputRef={ref}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Typography variant='subtitle1'>
            <TranslationLabel name='returnOrderSubmitExplanation' />
          </Typography>
          <Grid
            container
            direction='column'>
            <div className={classes.btns}>
              <Button
                type='submit'
                variant='contained'
                disableElevation
                className={`${classes.btn} ${classes.btnNext}`}>
                <TranslationLabel name='returnConfirmation' />
                <ArrowForwardIcon
                  style={{ marginLeft: 5 }}
                  fontSize='small'
                />
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export default ReturnServiceOrderDetails
