import { useEffect, useState } from 'react'

export function useDictionaryModel(user, dataStore) {
  const [dictionary, setDictionary] = useState([])
  const [isEditMode, setIsEditMode] = useState(false)

  function getLabel(key) {
    let rs = dictionary[key]
    return rs ? rs : key
  }

  function load() {
    if (user.userId === 0 || user.language === 0) return
    dataStore
      .getDictionary(user.language)
      .then((x) => {
        if (x.status === 200) {
          setDictionary(x.data.dictionary)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {})
  }

  function useSetIsEditMode(value) {
    setIsEditMode(value)
    console.log(value)
  }

  useEffect(() => {
    load()
  }, [user.language])

  return {
    getLabel,
    isEditMode,
    useSetIsEditMode,
    load,
  }
}
