import { makeStyles } from '@material-ui/core'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import React from 'react'

const useStyles2 = makeStyles(() => ({
  fieldItem: {
    color: '#5F6368',
    display: 'flex',
    borderBottom: '1px dashed #767676',
    fontSize: '16px',
    marginBottom: '5px',
  },
  fieldItemName: {
    fontWeight: 'normal',
    marginRight: '3px',
  },
  fieldItemValue: {},
}))
export default function FieldItemHeader(props) {
  const classes = useStyles2()
  const { label, name, value } = props

  return (
    <div className={classes.fieldItem}>
      <div className={classes.fieldItemName}>
        {label ? <TranslationLabel name={label} /> : { name }}
      </div>
      <div className={classes.fieldItemValue}>{value}</div>
    </div>
  )
}
