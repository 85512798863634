import React, { useState, useEffect, useContext } from 'react'
import { CoreContext } from '../../../../core/context/CoreContext'
import { TextField } from '@material-ui/core'
import { useDebounce } from 'use-lodash-debounce'

export default function CustomerNote() {
  const core = useContext(CoreContext)
  const [note, setNote] = useState('')
  const debouncedValue = useDebounce(note, 300)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (core.user.selectedCustomer.accountNo)
      core.dataStore
        .getCustomerNotes(core.user.selectedCustomer.accountNo)
        .then((res) => {
          setNote(res.data.note)
          setLoading(false)
        })
  }, [core.user.selectedCustomer.accountNo])

  useEffect(() => {
    if (!core.user.selectedCustomer.accountNo || loading) return
    core.dataStore.postCustomerNote(core.user.selectedCustomer.accountNo, note)
  }, [debouncedValue])

  const updateNote = (e) => {
    setNote(e.target.value)
  }

  return (
    <div style={{ height: 302, overflowY: 'scroll' }}>
      <TextField
        value={note}
        multiline
        variant='outlined'
        fullWidth
        InputProps={{ style: { paddingTop: 2 } }}
        onChange={updateNote}
      />
    </div>
  )
}
