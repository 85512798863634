import React, { useState, useEffect, useContext } from 'react'
import { CoreContext } from '../../../context/CoreContext'
import DatePicker from '../../../../shared/DatePicker'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  LinearProgress,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import TranslationLabel from '../../../components/TranslationLabel'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

const useStyles = makeStyles(() => ({
  customTooltip: {
    fontSize: 14,
  },
}))

export default function MasterDataAvailableDateSettings(props) {
  const core = useContext(CoreContext)
  const classes = useStyles()
  const [userGroups, setUserGroup] = useState([])
  const [dates, setDates] = useState([])
  const [loading, setLoading] = useState(false)
  const [init, setInit] = useState(true)

  const today = getTimeMinusOffset(new Date())
  const [newDate, setNewDate] = useState([])

  function getTimeMinusOffset(date) {
    return (
      new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toJSON()
        .split('T')[0] + 'T00:00:00'
    )
  }

  const reloadIntroDates = () => {
    setLoading(true)

    core.dataStore.GetMasterDataItemAvailableDate().then((res) => {
      setDates(res.data.reverse())
    })

    setLoading(false)
  }

  const convertToPostModel = (dates) => {
    const postModel = []

    dates.dataAvailableDate.forEach((item) => {
      postModel.push({
        introductionDate: dates.introductionDate,
        userTypeGroupId: item.userTypeGroupId,
        availableDate: item.availableDate,
      })
    })

    return postModel
  }

  const onDateChange = (date) => {
    setLoading(true)

    console.log(date)

    core.dataStore
      .UpdateMasterDataItemAvailableDate(convertToPostModel(date))
      .then(() => {
        reloadIntroDates()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const addIntroDate = () => {
    setLoading(true)

    core.dataStore
      .CreateMasterDataItemAvailableDate(convertToPostModel(newDate))
      .then(() => {
        reloadIntroDates()
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        core.setModal({
          headline: <TranslationLabel name='introDateError' />,
          message: <TranslationLabel name='introDateMessage' />,
        })
      })
  }

  const deleteDates = (introductionDate) => {
    setLoading(true)

    core.dataStore
      .DeleteMasterDataItemAvailableDate(introductionDate)
      .then(() => {
        reloadIntroDates()
      })
  }

  useEffect(() => {
    core.dataStore
      .GetUserTypesGroup()
      .then((res) => {
        setUserGroup(res.data)

        var newDate = {
          introductionDate: today,
          dataAvailableDate: [],
        }
        res.data.forEach((item) => {
          newDate.dataAvailableDate.push({
            userTypeGroupId: item.userTypeGroupId,
            availableDate: today,
          })
        })

        setNewDate(newDate)
        setInit(false)
      })
      .catch(() => {
        setLoading(false)
        setInit(false)
      })

    reloadIntroDates()
  }, [])

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <TableContainer component={Paper}>
          {loading ? <LinearProgress /> : <div style={{ height: 5 }} />}
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TranslationLabel name='introductionDatePriceAvailable' />
                </TableCell>
                {userGroups.map((userGroup) => (
                  <TableCell>
                    <Tooltip
                      title={
                        core.dictionary.isEditMode
                          ? `hintTextUserGroup-${userGroup.type}`
                          : core.dictionary.getLabel(
                              `hintTextUserGroup-${userGroup.type}`,
                            )
                      }
                      placement='bottom-start'
                      classes={{ tooltip: classes.customTooltip }}>
                      <Typography>{userGroup.type}</Typography>
                    </Tooltip>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dates.map((dateLine) => (
                <TableRow key={dateLine.introductionDate}>
                  <TableCell>
                    <DatePicker
                      InputProps={{ readOnly: true }}
                      disabled={true}
                      value={dateLine.introductionDate}
                    />
                  </TableCell>

                  {dateLine.dataAvailableDate.map((item) => (
                    <>
                      {userGroups.map((u) => (
                        <>
                          {u.userTypeGroupId == item.userTypeGroupId ? (
                            <TableCell>
                              <DatePicker
                                InputProps={{ readOnly: true }}
                                onChange={(date) => {
                                  const editDate = { ...dateLine }

                                  for (
                                    let i = 0;
                                    i < editDate.dataAvailableDate.length;
                                    i++
                                  ) {
                                    if (
                                      editDate.dataAvailableDate[i]
                                        .userTypeGroupId == item.userTypeGroupId
                                    ) {
                                      editDate.dataAvailableDate[
                                        i
                                      ].availableDate = getTimeMinusOffset(date)
                                    }
                                  }

                                  onDateChange(editDate)
                                }}
                                value={item.availableDate}
                              />
                            </TableCell>
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                    </>
                  ))}

                  <TableCell align='right'>
                    <DeleteForeverIcon
                      color='secondary'
                      style={{ cursor: 'pointer' }}
                      onClick={() => deleteDates(dateLine.introductionDate)}
                    />
                  </TableCell>
                </TableRow>
              ))}

              <TableRow key={newDate.id}>
                <TableCell>
                  <TranslationLabel
                    style={{ fontSize: 16 }}
                    name='addDate'
                  />
                </TableCell>
              </TableRow>

              {!init ? (
                <TableRow style={{ borderTop: '2px solid blue' }}>
                  <TableCell>
                    <DatePicker
                      InputProps={{ readOnly: true }}
                      onChange={(date) => {
                        const editDate = { ...newDate }
                        editDate.introductionDate = getTimeMinusOffset(date)

                        for (
                          let i = 0;
                          i < editDate.dataAvailableDate.length;
                          i++
                        ) {
                          editDate.dataAvailableDate[i].availableDate =
                            getTimeMinusOffset(date)
                        }

                        setNewDate(editDate)
                      }}
                      value={newDate.introductionDate}
                    />
                  </TableCell>

                  {newDate.dataAvailableDate.map((dataAvailableDate) => (
                    <TableCell>
                      <DatePicker
                        InputProps={{ readOnly: true }}
                        onChange={(date) => {
                          const editDate = { ...newDate }

                          for (
                            let i = 0;
                            i < editDate.dataAvailableDate.length;
                            i++
                          ) {
                            if (
                              editDate.dataAvailableDate[i].userTypeGroupId ==
                              dataAvailableDate.userTypeGroupId
                            ) {
                              editDate.dataAvailableDate[i].availableDate =
                                getTimeMinusOffset(date)
                            }
                          }

                          setNewDate(editDate)
                        }}
                        value={dataAvailableDate.availableDate}
                      />
                    </TableCell>
                  ))}

                  <TableCell align='right'>
                    <AddCircleOutlineIcon
                      color='primary'
                      style={{ cursor: 'pointer' }}
                      onClick={() => addIntroDate()}
                    />
                  </TableCell>
                </TableRow>
              ) : (
                <LinearProgress />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </MuiPickersUtilsProvider>
    </>
  )
}
