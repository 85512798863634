const admin = {
  allUserManagement: true,
  languageManagement: true,
  helpLinkManagement: false,
  settingsAdministration: true,
  canCreateNewsLetterUser: true,
  showBackordersOnProduct: true,
  apps: [1, 2, 6, 7, 12],
  primaryAppId: 1,
  isEnabled: true,
  newsLetter: false,
  showInventory: true,
  showArchiveCheckbox: true,
  downloadExcelOfItems: true,
  masterDataFacets: [1, 2, 5, 6, 7, 8, 9, 10, 13, 14, 15, 16, 17],
  templateManagement: true,
  videoManagement: true,
}

const internal = {
  allUserManagement: false,
  languageManagement: false,
  helpLinkManagement: false,
  settingsAdministration: false,
  canCreateNewsLetterUser: true,
  showBackordersOnProduct: true,
  apps: [1, 2, 6, 12],
  primaryAppId: 1,
  isEnabled: true,
  newsLetter: false,
  showInventory: true,
  showArchiveCheckbox: false,
  downloadExcelOfItems: true,
  masterDataFacets: [1, 2, 5, 6, 7, 8, 9, 10, 13, 14, 15, 16, 17],
  templateManagement: false,
  videoManagement: false,
}

const customer = {
  allUserManagement: false,
  languageManagement: false,
  helpLinkManagement: false,
  settingsAdministration: false,
  canCreateNewsLetterUser: true,
  showBackordersOnProduct: true,
  apps: [1, 2, 6, 12],
  primaryAppId: 1,
  isEnabled: true,
  newsLetter: true,
  showInventory: false,
  showArchiveCheckbox: false,
  masterDataFacets: [1, 2, 6, 8, 10, 13, 14, 15, 16, 17],
  templateManagement: false,
  videoManagement: false,
}

const salesAgent = {
  allUserManagement: false,
  languageManagement: false,
  helpLinkManagement: false,
  settingsAdministration: false,
  canCreateNewsLetterUser: true,
  showBackordersOnProduct: true,
  apps: [1, 2, 6, 12],
  primaryAppId: 1,
  isEnabled: true,
  newsLetter: true,
  showInventory: true,
  showArchiveCheckbox: false,
  masterDataFacets: [1, 2, 6, 8, 10, 13, 14, 15, 16, 17],
  templateManagement: false,
  videoManagement: false,
}

const salesPerson = {
  allUserManagement: true,
  languageManagement: false,
  helpLinkManagement: false,
  settingsAdministration: false,
  canCreateNewsLetterUser: true,
  showBackordersOnProduct: true,
  apps: [1, 2, 6, 7, 12],
  primaryAppId: 1,
  isEnabled: true,
  newsLetter: true,
  showInventory: true,
  showArchiveCheckbox: true,
  masterDataFacets: [1, 2, 5, 6, 7, 8, 9, 10, 13, 14, 15, 16, 17],
  templateManagement: false,
  videoManagement: false,
}

const distributor = {
  allUserManagement: false,
  languageManagement: false,
  helpLinkManagement: false,
  settingsAdministration: false,
  canCreateNewsLetterUser: true,
  showBackordersOnProduct: true,
  apps: [1, 2, 6, 12],
  primaryAppId: 1,
  isEnabled: true,
  newsLetter: false,
  showInventory: false,
  showArchiveCheckbox: false,
  masterDataFacets: [1, 2, 6, 8, 10, 13, 14, 15, 16, 17],
  templateManagement: false,
  videoManagement: false,
}

const merchandiser = {
  allUserManagement: false,
  languageManagement: false,
  helpLinkManagement: false,
  settingsAdministration: false,
  canCreateNewsLetterUser: true,
  showBackordersOnProduct: true,
  apps: [1, 2, 6, 7, 12],
  primaryAppId: 1,
  isEnabled: true,
  newsLetter: true,
  showInventory: true,
  showArchiveCheckbox: true,
  masterDataFacets: [1, 2, 5, 6, 7, 8, 9, 10, 13, 14, 15, 16, 17],
  templateManagement: false,
  videoManagement: false,
}

const marketing = {
  allUserManagement: false,
  languageManagement: false,
  helpLinkManagement: false,
  settingsAdministration: false,
  canCreateNewsLetterUser: true,
  showBackordersOnProduct: false,
  apps: [1, 12],
  primaryAppId: 1,
  isEnabled: true,
  newsLetter: true,
  showInventory: false,
  showArchiveCheckbox: false,
  masterDataFacets: [1, 2, 6, 8, 10, 13, 14, 15, 16, 17],
  templateManagement: false,
  videoManagement: false,
}

//note: the userTypeId's go from 1 (admin) to 8. They will be indexed by their id, so the order in this array is crucial
export default function getDefaultUserSettings() {
  return [
    admin,
    internal,
    customer,
    salesAgent,
    salesPerson,
    distributor,
    merchandiser,
    marketing,
  ]
}
