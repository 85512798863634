import React, { useContext, useState } from 'react'
//theme
import { withStyles } from '@material-ui/core'

//Components
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import TranslationLabel from '../components/TranslationLabel'
import FormSection from '../components/forms/FormSection'
import FormHeader from '../components/forms/FormHeader'
import { CoreContext } from '../context/CoreContext'
import LanguageSelection from '../components/selections/LanguageSelection'
import PrimaryAppSelection from '../components/selections/PrimaryAppSelection'

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    padding: theme.spacing(2),
    flexGrow: 1,
  },
})

function UserSettingsPage() {
  const core = useContext(CoreContext)
  const [newsLetter, setNewsLetter] = useState(core.user.userSetting.newsLetter)

  return (
    <>
      <FormSection>
        <FormHeader title={<TranslationLabel name='language' />} />
        <LanguageSelection />
        {core.user.userSetting.languageManagement ? (
          <FormControlLabel
            control={
              <Switch
                checked={core.dictionary.isEditMode}
                color='primary'
                onChange={() => {
                  core.dictionary.useSetIsEditMode(!core.dictionary.isEditMode)
                }}
              />
            }
            label={<TranslationLabel name='editMode' />}
          />
        ) : null}
      </FormSection>
      <FormSection>
        <FormHeader title={<TranslationLabel name='newsLetter' />} />
        <Switch
          checked={newsLetter}
          color='primary'
          onChange={() => {
            core.hubStore
              .changeNewsLetter(
                core.user.userId,
                !core.user.userSetting.newsLetter,
              )
              .then(() => {
                setNewsLetter(!core.user.userSetting.newsLetter)
              })
          }}
        />
      </FormSection>

      <FormSection>
        <FormHeader title={<TranslationLabel name='primaryApp' />} />
        <PrimaryAppSelection />
      </FormSection>

      <FormSection>
        <FormHeader title={<TranslationLabel name='current-debtor' />} />
        {core.user.selectedCustomer.accountNo}
      </FormSection>
    </>
  )
}
export default withStyles(styles)(UserSettingsPage)
