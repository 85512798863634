import React, { useContext, useState } from 'react'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import { CoreContext } from '../../../../core/context/CoreContext'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import UserIcon from '@material-ui/icons/PersonOutlined'
import CustomerIcon from '@material-ui/icons/AccountBalance'
import AppointmentList from '../../components/appointmentList/AppointmentList'
import DashboardWidgetContainer from '../../components/DashboardWidgetContainer'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import { FieldItemRow } from '../Products/DetailedItemTemplate'
import DashboardMessage from '../../pages/Dashboard/DashboardMessage'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1120,
    marginLeft: 'auto',
    marginRight: 'auto',
    color: 'black',
  },
  content: {
    maxWidth: 1120,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: 'calc(100% - 55px)',
  },
  titleScreen: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 1120,
  },
  dashTabs: {
    flexGrow: 1,
    marginBottom: '10px',
  },
  leftContainer: {},
  rightContainer: {},
  switchContainer: {
    display: 'flex',
    width: '100%',
    flex: 1,
    justifyContent: 'flex-end',
    fontSize: 12,
  },
  userContainer: {
    display: 'flex',
    height: 'calc(100% - 55px)',
    padding: '15px',
  },
  userLeftContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
  },
  userRightContainer: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  userIcon: {
    color: theme.palette.primary.main,
    fontSize: 70,
  },
  progress: {
    width: '90%',
    height: 5,
    marginTop: '20px',
    margin: 'auto',
  },
  editProfileButton: {
    float: 'right',
    height: 32,
  },
}))

export default function DashboardPage() {
  const core = useContext(CoreContext)
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container>
          <>
            <Grid
              item
              xs={12}
              md={6}>
              <DashboardWidgetContainer
                title={<TranslationLabel name='selectedCustomer' />}
                height={230}
                content={
                  <div className={classes.userContainer}>
                    <div className={classes.userLeftContainer}>
                      <FieldItemRow
                        label='accountNo'
                        value={core.user.selectedCustomer.accountNo}
                      />
                      <FieldItemRow
                        label='accountName'
                        value={core.user.selectedCustomer.accountName}
                      />
                      <FieldItemRow
                        label='currency'
                        value={core.user.selectedCustomer.currency}
                      />
                      <FieldItemRow
                        label='phone'
                        value={core.user.selectedCustomer.phone}
                      />
                      <FieldItemRow
                        label='email'
                        value={core.user.selectedCustomer.email}
                      />
                    </div>

                    <div className={classes.userRightContainer}>
                      <CustomerIcon className={classes.userIcon} />
                    </div>
                  </div>
                }
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}>
              <DashboardWidgetContainer
                title={
                  <span>
                    <TranslationLabel name='accountInfo' />
                    <Button
                      className={classes.editProfileButton}
                      onClick={() => core.history.push('/hub/settings')}
                      variant='outlined'>
                      <EditIcon />
                    </Button>
                  </span>
                }
                height={230}
                content={
                  <div className={classes.userContainer}>
                    <div className={classes.userLeftContainer}>
                      <FieldItemRow
                        label='userName'
                        value={core.user.userSetting.userName}
                      />
                      <FieldItemRow
                        label='displayName'
                        value={core.user.userSetting.displayName}
                      />
                      <FieldItemRow
                        label='language'
                        value={core.user.languageCode}
                      />
                      <FieldItemRow
                        label='email'
                        value={core.user.userSetting.email}
                      />
                    </div>

                    <div className={classes.userRightContainer}>
                      <UserIcon className={classes.userIcon} />
                    </div>
                  </div>
                }
              />
            </Grid>
          </>
          <>
            <Grid
              item
              xs={12}
              md={6}>
              <div className={classes.rightContainer}>
                <DashboardWidgetContainer
                  title={<TranslationLabel name='futureAppointments' />}
                  content={<AppointmentList />}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}>
              <DashboardWidgetContainer
                title={<TranslationLabel name='externalHomePageTitle' />}
                content={
                  <DashboardMessage messageLabelName='externalHomePageMessage' />
                }
              />
            </Grid>
          </>
        </Grid>
      </div>
    </div>
  )
}
