import React, { useContext, useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core'
import BasketListViewItem from './BasketListViewItem'
import { BasketContext } from '../../../../../core/context/BasketContext'
import LinearProgress from '@material-ui/core/LinearProgress'
import EmptyState from '../../../components/EmptyState'
import { ProductsContext } from '../../../context/ProductsContext'

const useStyles = makeStyles((theme) => ({
  basketListViewRoot: {},
  progressContainer: {
    height: '4px',
  },
}))

export const sortEnum = {
  PRODUCTNO: 'PRODUCTNO',
  DELIVERY: 'DELIVERY',
}

export default function BasketListView(props) {
  const { isMiniBasket = false, disabled, sortBy } = props
  const classes = useStyles()
  const basketModel = useContext(BasketContext)
  const productsModel = useContext(ProductsContext)
  const [validDateFrom, setValidDateFrom] = useState('')
  const [validDateTo, setValidDateTo] = useState('')

  const basketCampaignId = basketModel.basket.tradeFairCampaignId

  function getTimeMinusOffset(date) {
    return (
      new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toJSON()
        .split('T')[0] + 'T00:00:00'
    )
  }

  const sortEntries = (entries) => {
    if (sortBy === sortEnum.PRODUCTNO) {
      return entries
        .filter((el) => el)
        .sort((a, b) => {
          if (a.itemId < b.itemId) return -1
          if (a.itemId > b.itemId) return 1
          return 0
        })
    } else if (sortBy === sortEnum.DELIVERY) {
      return entries
        .filter((el) => el)
        .sort((a, b) => {
          if (a.requestedShippingDate < b.requestedShippingDate) return -1
          if (a.requestedShippingDate > b.requestedShippingDate) return 1
          return 0
        })
    }
    return entries
  }

  useEffect(() => {
    productsModel.getCampaigns()
  }, [])

  useEffect(() => {
    if (basketCampaignId && productsModel.campaigns.length)
      setValidDateFrom(
        productsModel.campaigns.find((el) => el.campaignId === basketCampaignId)
          ?.deliveryValidFrom,
      )
    setValidDateTo(
      productsModel.campaigns.find((el) => el.campaignId === basketCampaignId)
        ?.deliveryValidTo,
    )
  }, [basketCampaignId, productsModel.campaigns])

  function removeItem(id) {
    basketModel.remove(id)
  }

  function updateShippingDate(date, data) {
    const formattedDate = getTimeMinusOffset(date)
    //if date changes such that 2 items with same itemId now have same deliverydate aswell, then delete one and combine their quantities

    const duplicate = basketModel.basket.entries.find(
      (item) =>
        item.itemId === data.itemId &&
        item.requestedShippingDate === formattedDate &&
        item.basketEntryId !== data.basketEntryId,
    )

    if (duplicate) {
      data.quantity += duplicate.quantity
      basketModel.remove(duplicate.basketEntryId)
    }
    basketModel.updateEntry({ ...data, requestedShippingDate: formattedDate })
  }

  return (
    <div className={classes.basketListViewRoot}>
      <div className={classes.progressContainer}>
        {basketModel.isLoading ? <LinearProgress /> : null}
      </div>
      {basketModel.basket.entries.length > 0 ? (
        <>
          {sortEntries(basketModel.basket.entries).map((data, i) => (
            <BasketListViewItem
              isCampaignItem={!!basketCampaignId}
              validDateFrom={validDateFrom}
              validDateTo={validDateTo}
              key={`${data.itemId}-${i}`}
              data={data}
              onDelete={removeItem}
              updateShippingDate={updateShippingDate}
              isMiniBasket={isMiniBasket}
              disabled={disabled}
            />
          ))}
        </>
      ) : (
        <EmptyState />
      )}
    </div>
  )
}
