import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import TranslationLabel from '../../../core/components/TranslationLabel'

export default function PageTitle(props) {
  const { label, core, hidden } = props

  useEffect(() => {
    core.setTitles(core.dictionary.getLabel(label))
  }, [])

  return (
    hidden != true && (
      <div>
        <Typography
          variant='h4'
          component='h2'>
          <TranslationLabel name={label} />
        </Typography>
      </div>
    )
  )
}
