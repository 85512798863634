import React, { createContext } from 'react'
import { useOrderModel } from '../model/OrdersModel'

export const OrderContext = createContext(null)

export default function OrderProvider({ children }) {
  const model = useOrderModel()

  return <OrderContext.Provider value={model}>{children}</OrderContext.Provider>
}
