import React, { Fragment, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography/Typography'
import Button from '@material-ui/core/Button/Button'
import SadSmiley from '@material-ui/icons/MoodBad'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20%',
  },
  bar: {
    height: '100%',
    width: '100%',
  },
})

const threshold = 10

export default function FullScreenLoader() {
  const [time, setTime] = useState(0)

  const classes = useStyles()

  function reload() {
    window.location.reload()
  }

  useEffect(() => {
    const handleStart = setTimeout(() => {
      if (time > threshold) return
      setTime(time + 1)
    }, 3000)

    return () => clearTimeout(handleStart)
  }, [])

  return (
    <div className={classes.root}>
      {time < threshold ? (
        <Fragment>
          <Typography
            variant='overline'
            display='block'
            align='center'
            gutterBottom>
            Loading.. {time > 0 ? 'Attempt ' + time + ' of ' + threshold : null}
          </Typography>
          <div className={classes.bar}>
            <LinearProgress />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div>
            <SadSmiley style={{ fontSize: '60px' }} />
          </div>
          <Typography
            variant='overline'
            display='block'
            align='center'
            gutterBottom>
            Loading failed. press here to reload the page.
          </Typography>
          <Button
            variant='contained'
            color='primary'
            onClick={reload}>
            Reload
          </Button>
        </Fragment>
      )}
    </div>
  )
}
