import React, { useState, useContext, useEffect, useRef } from 'react'
import Modal from '@material-ui/core/Modal'
import {
  Card,
  FormControlLabel,
  Switch,
  Button,
  makeStyles,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import TranslationLabel from '../../../core/components/TranslationLabel'
import { CoreContext } from '../../../core/context/CoreContext'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  headerSection: {
    width: '100%',
    marginTop: '25px',
    marginBottom: '25px',
    textAlign: 'center',
  },
  modal: {
    width: 600,
    maxWidth: '100%',
    height: 800,
    position: 'relative',
    margin: 'auto',
    marginTop: '64px',
    backgroundColor: 'white',
    textAlign: 'center',
    paddingTop: 25,
  },
  inputfield: {
    display: 'block',
    width: '90%',
    margin: 'auto',
    marginTop: 10,
  },
  searchField: {
    width: 200,
    marginRight: 10,
  },
  submitButton: {
    marginTop: 20,
  },
  deleteButton: {
    marginTop: 20,
    marginRight: 100,
  },
  headerButton: {
    margin: 8,
  },
  modalVideoPreview: {
    border: 'none',
    marginTop: 10,
  },
  paginationButton: {
    margin: 20,
  },
}))

//all videos are loaded to begin with, and then searching is done by filtering the list

export default function VideoPage(props) {
  const classes = useStyles()
  const core = useContext(CoreContext)
  const [allVideos, setAllVideos] = useState([])
  const [searchBrand, setSearchBrand] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [page, setPage] = useState(0)

  const [editMode, setEditMode] = useState(false)
  const [videoUploadOpen, setVideoUploadOpen] = useState(false)
  const [videoId, setVideoId] = useState('')
  const [title, setTitle] = useState('')
  const [brand, setBrand] = useState('')
  const [id, setId] = useState('')
  const [keywords, setKeywords] = useState('')
  const [hidden, setHidden] = useState(false)
  const [showHidden, setShowHidden] = useState(false)
  const [relatedProducts, setRelatedProducts] = useState([])
  const [brands, setBrands] = useState([''])
  const [videosPerPage, _] = useState(
    window.innerWidth > 1000 ? 9 : window.innerWidth > 600 ? 4 : 1,
  )
  const isVideoAdmin = core.user.userSetting.videoManagement
  const [showEditIcon, setShowEditIcon] = useState(isVideoAdmin)
  const { drawerExpanded } = props

  useEffect(() => {
    core.dataStore
      .getBrands()
      .then((res) => {
        setBrands(res.data)
      })
      .catch(() => {})
  }, [])

  useEffect(() => {
    core.dataStore
      .getYoutubeVideos()
      .then((res) => {
        setAllVideos(res.data)
      })
      .catch(() => {})
  }, [])

  const videosFilter = (video) => {
    if (!showHidden && video.hidden) {
      return false
    }
    if (showHidden && !video.hidden) {
      return false
    }
    //if brand is chosen then filter other brands out
    if (searchBrand?.id) {
      if (video.brand != searchBrand?.id) {
        return false
      }
    }
    //if search is filled out then filter non-matches out
    if (searchTerm) {
      if (!video.keywords.toLowerCase().includes(searchTerm.toLowerCase())) {
        if (!video.title.toLowerCase().includes(searchTerm.toLowerCase())) {
          return false
        }
      }
    }
    //return the rest
    return true
  }

  const clearInputFields = () => {
    setVideoId('')
    setTitle('')
    setBrand('')
    setKeywords('')
    setRelatedProducts([])
    setId('')
    setHidden(false)
  }

  const editVideo = (id) => {
    const video = allVideos.find((el) => el.id === id)
    setId(id)
    setVideoId(video.youtubeId)
    setTitle(video.title)
    setBrand(
      brands.find((brand) => brand.id == video.brand) || {
        label: 'None',
        id: '',
      },
    )
    setKeywords(video.keywords)
    setRelatedProducts(video.relatedProducts)
    setHidden(video.hidden)
    setVideoUploadOpen(true)
    setEditMode(true)
  }

  const submit = (e) => {
    e.preventDefault()
    if (editMode) {
      submitEdit()
    } else {
      submitVideo()
    }
  }

  const submitVideo = () => {
    const newVideo = {
      youtubeId: videoId,
      title,
      brand: brand?.id,
      keywords,
      relatedProducts,
      hidden,
    }
    core.dataStore.addYoutubeVideo(newVideo).then((res) => {
      //if succesful then add video in frontend aswell
      setAllVideos([...allVideos, res.data])
    })
    clearInputFields()
    setVideoUploadOpen(false)
    setEditMode(false)
  }

  const submitEdit = () => {
    const newVideo = {
      id,
      youtubeId: videoId,
      title,
      brand: brand?.id,
      keywords,
      relatedProducts,
      hidden,
    }
    core.dataStore.editYoutubeVideo(newVideo).then(() => {
      //if successful then update the video in the frontend aswell
      setAllVideos(
        allVideos.map((el) => {
          if (el.id === id) {
            return newVideo
          }
          return el
        }),
      )
      clearInputFields()
      setVideoUploadOpen(false)
      setEditMode(false)
    })
  }

  const onVideoIdChange = (e) => {
    const newId = e.target.value
    if (newId.includes('https://youtu.be/')) {
      setVideoId(newId.split('youtu.be/')[1])
    } else {
      setVideoId(newId)
    }
  }

  const deleteVideo = () => {
    core.dataStore.deleteYoutubeVideo(id).then((res) => {
      //if succesful then delete video in frontend aswell
      setAllVideos(allVideos.filter((el) => el.id != id))
      clearInputFields()
      setVideoUploadOpen(false)
      setEditMode(false)
    })
  }

  const handlePageChange = (page) => {
    setPage(page)
  }

  return (
    <>
      <div className={classes.headerSection}>
        <FormControl
          variant='outlined'
          className={classes.select}>
          <InputLabel id='brand'>
            <TranslationLabel name='brand' />
          </InputLabel>
          <Select
            labelId='brand'
            value={searchBrand}
            style={{ width: 130, marginRight: 10 }}
            onChange={(e) => {
              setSearchBrand(e.target.value)
              setPage(0)
            }}>
            {[{ id: '', label: 'All' }, ...brands].map((brand, i) => {
              return (
                <MenuItem
                  key={'a' + i}
                  value={brand}>
                  {brand.label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <TextField
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value)
            setPage(0)
          }}
          variant='outlined'
          fullWidth
          autoFocus
          label={<TranslationLabel name='searchVideos' />}
          className={classes.searchField}
        />
        {isVideoAdmin && (
          <Button
            color='primary'
            variant='contained'
            className={classes.headerButton}
            onClick={() => setVideoUploadOpen(!videoUploadOpen)}>
            <TranslationLabel name='addVideo' />
          </Button>
        )}
        {isVideoAdmin && (
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={showEditIcon}
                onChange={() => setShowEditIcon(!showEditIcon)}
              />
            }
            label={<TranslationLabel name='showEditIcon' />}
          />
        )}
        {isVideoAdmin && (
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={showHidden}
                onChange={() => setShowHidden(!showHidden)}
              />
            }
            label={<TranslationLabel name='showHiddenVideos' />}
          />
        )}
      </div>

      <Modal
        open={videoUploadOpen}
        onClose={() => {
          setVideoUploadOpen(false)
          clearInputFields()
          setEditMode(false)
        }}>
        <div className={classes.modal}>
          <Typography
            variant='h4'
            className={classes.modalHeadline}>
            {editMode ? (
              <TranslationLabel name='editVideoHeadline' />
            ) : (
              <>
                <TranslationLabel name='addVideoHeadline' />
                <p
                  style={{
                    textDecoration: 'underline',
                    color: 'blue',
                    fontSize: 14,
                  }}>
                  <Link to='/video-app/help'>
                    <TranslationLabel name='addVideoHelpLink' />
                  </Link>
                </p>
              </>
            )}
          </Typography>
          <form onSubmit={submit}>
            <TextField
              value={videoId}
              onChange={onVideoIdChange}
              variant='outlined'
              fullWidth
              label={<TranslationLabel name='videoId' />}
              className={classes.inputfield}
            />
            {videoId && (
              <div
                style={{
                  width: 420,
                  height: 280,
                  position: 'relative',
                  margin: 'auto',
                  marginBottom: 25,
                }}>
                <iframe
                  id={videoId}
                  className={classes.modalVideoPreview}
                  title={title}
                  src={`https://www.youtube-nocookie.com/embed/${videoId}?enablejsapi=1&rel=0`}
                  allow='fullscreen; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  style={{
                    left: 0,
                    top: 0,
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                  }}
                  frameBorder='0'
                  allowFullScreen></iframe>
              </div>
            )}
            <TextField
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              variant='outlined'
              fullWidth
              label={<TranslationLabel name='title' />}
              className={classes.inputfield}
            />
            <FormControl
              variant='outlined'
              className={classes.inputfield}>
              <InputLabel id='brand'>
                <TranslationLabel name='brand' />
              </InputLabel>
              <Select
                fullWidth
                labelId='brand'
                value={brand}
                onChange={(e) => {
                  setBrand(e.target.value)
                }}>
                {[{ id: '', label: 'None' }, ...brands].map((brand, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={brand}>
                      {brand.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <TextField
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
              variant='outlined'
              fullWidth
              label={<TranslationLabel name='keywords' />}
              className={classes.inputfield}
            />
            <FormControlLabel
              className={classes.field}
              control={
                <Switch
                  color='primary'
                  checked={hidden}
                  onChange={() => setHidden(!hidden)}
                />
              }
              label={<TranslationLabel name='hideVideo' />}
            />
            <div>
              {editMode && (
                <Button
                  color='secondary'
                  variant='contained'
                  onClick={deleteVideo}
                  className={classes.deleteButton}>
                  <TranslationLabel name='deleteVideo' />
                </Button>
              )}
              <Button
                type='submit'
                color='primary'
                variant='contained'
                className={classes.submitButton}>
                {editMode ? (
                  <TranslationLabel name='editVideo' />
                ) : (
                  <TranslationLabel name='addVideo' />
                )}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <div style={{ textAlign: 'center' }}>
        <div style={{ maxWidth: 1400, margin: 'auto' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat( ${Math.sqrt(
                videosPerPage,
              )}, minmax(200px, 1fr)`,
              gridTemplateRows: `repeat( ${Math.sqrt(videosPerPage)}, 1fr`,
              columnGap: '10px',
              rowGap: '10px',
            }}>
            {allVideos
              .filter(videosFilter)
              .slice(page * videosPerPage, (page + 1) * videosPerPage)
              .map((el) => (
                <VideoCard
                  {...el}
                  drawerExpanded={drawerExpanded}
                  key={el.id}
                  isVideoAdmin={isVideoAdmin}
                  editVideo={editVideo}
                  showEditIcon={showEditIcon}
                />
              ))}
          </div>
          <div>
            <Button
              className={classes.paginationButton}
              disabled={page < 1}
              onClick={() => handlePageChange(page - 1)}
              variant='outlined'>
              {'<'}
            </Button>
            <Typography
              style={{ display: 'inline-block' }}
              variant='subtitle1'>
              <TranslationLabel
                variables={[
                  page + 1,
                  Math.ceil(
                    allVideos.filter(videosFilter).length / videosPerPage,
                  ),
                ]}
                name='pageXofX'
              />
            </Typography>
            <Button
              className={classes.paginationButton}
              disabled={
                (page + 1) * videosPerPage >=
                allVideos.filter(videosFilter).length
              }
              onClick={() => handlePageChange(page + 1)}
              variant='outlined'>
              {'>'}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

const VideoCard = (props) => {
  const { youtubeId, title, editVideo, id, isVideoAdmin, showEditIcon } = props
  const vidRef = useRef(null)
  const aspectRatio = 9 / 16

  const resizeVideo = () => {
    vidRef.current.style.height =
      Math.floor(vidRef.current.offsetWidth * aspectRatio) + 'px'
  }

  useEffect(() => {
    resizeVideo()
    window.addEventListener('resize', resizeVideo, false)
    return () => {
      window.removeEventListener('resize', resizeVideo, false)
    }
  }, [vidRef])

  return (
    <Card
      ref={vidRef}
      style={{
        position: 'relative',
      }}>
      <iframe
        id={youtubeId}
        style={{
          postion: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
        }}
        title={title}
        src={`https://www.youtube-nocookie.com/embed/${youtubeId}?enablejsapi=1&rel=0`}
        allowFullScreen
        allow='fullscreen; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        frameBorder='0'></iframe>
      {isVideoAdmin && showEditIcon && (
        <EditIcon
          onClick={() => editVideo(id)}
          style={{
            position: 'absolute',
            bottom: 50,
            right: 5,
            zIndex: 5,
            color: 'white',
            cursor: 'pointer',
          }}
        />
      )}
    </Card>
  )
}
