import React, { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import ShoppingBasket from '@material-ui/icons/ShoppingBasket'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'

import NavigationDrawer from '../../../core/components/navigation/NavigationDrawer'
import Topbar from '../../../core/components/navigation/Topbar'
import MiniBasket from '../pages/Basket/components/MiniBasket'
import MiniBasketReturnService from '../pages/Basket/components/MiniBasketReturnService'

import { CoreContext } from '../../../core/context/CoreContext'
import { BasketContext } from '../../../core/context/BasketContext'
import { ReturnServiceContext } from '../context/ReturnServiceContext'

import getNavigationSettings from '../config/navigation'

import IconFinder from '../libraries/IconFinder'

const useStyles = makeStyles((theme) => ({
  facebook2: {
    color: '#6798e5',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
}))

function Navigation(props) {
  const classes = useStyles()
  const core = useContext(CoreContext)
  const basket = useContext(BasketContext)
  const returnService = useContext(ReturnServiceContext)
  const [open, setOpen] = useState(false)
  const [openReturnService, setOpenReturnService] = useState(false)

  const location = useLocation()
  const showReturnBasketHideBasket = location.pathname.includes(
    '/sales/returnService',
  )

  function handleClickMiniBasket(val) {
    setOpen(val)
  }

  function handleClickMiniBasketReturnService(val) {
    setOpenReturnService(val)
  }

  return (
    <>
      <Topbar {...props}>
        {showReturnBasketHideBasket ? (
          <IconButton
            color='inherit'
            onClick={() => handleClickMiniBasketReturnService(true)}>
            {!returnService.isLoading ? (
              <Badge
                badgeContent={returnService.count}
                color='primary'>
                {IconFinder.getIcon('returnservice')}
              </Badge>
            ) : (
              <CircularProgress
                variant='indeterminate'
                disableShrink
                className={classes.facebook2}
                size={24}
                thickness={4}
              />
            )}
          </IconButton>
        ) : (
          <IconButton
            color='inherit'
            onClick={() => handleClickMiniBasket(true)}>
            {!basket.isLoading ? (
              <Badge
                badgeContent={basket.count}
                color='secondary'>
                <ShoppingBasket />
              </Badge>
            ) : (
              <CircularProgress
                variant='indeterminate'
                disableShrink
                className={classes.facebook2}
                size={24}
                thickness={4}
              />
            )}
          </IconButton>
        )}
      </Topbar>

      <NavigationDrawer
        upperItems={getNavigationSettings().upperNavigation}
      />

      <MiniBasketReturnService
        open={openReturnService}
        onClick={handleClickMiniBasketReturnService}
      />

      <MiniBasket
        open={open}
        onClick={handleClickMiniBasket}
      />
    </>
  )
}

export default Navigation
