//Uses the JS Internationalization  API to format currency based on input.
export function formatCurrency(value, currency = 'DKK', locales = 'da-DK') {
  let rs = value + ' ' + currency
  //console.log(currency);
  if (typeof value === 'string') {
    value = Number(value.replace(',', '.'))
  }
  try {
    let formatter = new Intl.NumberFormat(locales, {
      style: 'currency',
      currency: currency,
    })
    rs = formatter.format(value)
  } catch (e) {
    console.log(e)
  }
  return rs
}
