import React, { createContext } from 'react'
import { useCoreModel } from '../settings/Core'
import { useSnackbar } from 'notistack'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/core/SvgIcon/SvgIcon'

function SnackbarHandler() {
  //Snackbar setup
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const action = (key) => (
    <IconButton
      onClick={() => closeSnackbar(key)}
      aria-label='Clear'
      color='secondary'>
      <ClearIcon />
    </IconButton>
  )
  return (variant, message) => enqueueSnackbar(message, { action, variant })
}

export const CoreContext = createContext(null)

export default function CoreProvider({ children }) {
  const core = useCoreModel(SnackbarHandler())

  return <CoreContext.Provider value={core}>{children}</CoreContext.Provider>
}
