import React, { useContext, useState } from 'react'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import { Switch } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { CoreContext } from '../../../../../core/context/CoreContext'
import TranslationLabel from '../../../../../core/components/TranslationLabel'

import BasketListView, { sortEnum } from './BasketListView'
import BasketSummary from './BasketSummary'
import MiniBasketButtons from './MiniBasketButtons'

import { BasketContext } from '../../../../../core/context/BasketContext'

import useStyles from './shared/miniBasketStyles'

export default function TemporaryDrawer(props) {
  const classes = useStyles()
  const core = useContext(CoreContext)
  const basketModel = useContext(BasketContext)
  const basket = basketModel.basket
  const [sortBy, setSortBy] = useState(sortEnum.PRODUCTNO)

  const { open, onClick } = props

  function closeDrawer(event) {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    onClick(false)
  }

  function productCountLabel() {
    return (
      <span>
        {basket.entries.length + ' '}
        {basket.entries.length === 1 ? (
          <TranslationLabel name='product' />
        ) : (
          <TranslationLabel name='products' />
        )}
      </span>
    )
  }

  function handleGotoCheckout() {
    if (core.user.selectedCustomer.blocked) {
      core.setModal({
        headline: <TranslationLabel name='blockedCustomerHeadline' />,
        message: <TranslationLabel name='blockedCustomerMessage' />,
      })
      return
    }

    onClick(false)

    if (basketModel.basketType == 0) {
      core.history.push('/sales/basket')
    } else if (basketModel.basketType == 1) {
      core.history.push('/fair-app/basket')
    }
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={closeDrawer}
      className={classes.root}>
      <div className={classes.list}>
        <Typography
          variant='h4'
          component='h2'>
          <TranslationLabel name='basket' />
        </Typography>
        {basket.campaignName && (
          <Typography variant='subtitle1'>
            {`(${basket.campaignName})`}
          </Typography>
        )}
        <p>{productCountLabel()}</p>
        {basket.campaignId && (
          <div style={{ textAlign: 'right', marginTop: '-40px' }}>
            <FormControlLabel
              control={
                <Switch
                  value={sortBy === sortEnum.PRODUCTNO}
                  onChange={() =>
                    setSortBy(
                      sortBy === sortEnum.PRODUCTNO
                        ? sortEnum.DELIVERY
                        : sortEnum.PRODUCTNO,
                    )
                  }
                  color='primary'
                />
              }
              label={<TranslationLabel name='sortByDeliveryDate' />}
              labelPlacement='start'
            />
          </div>
        )}
        <hr style={{ opacity: '0.7' }} />
        <BasketListView
          sortBy={sortBy}
          isMiniBasket={true}
        />
      </div>
      <div className={classes.summary}>
        <BasketSummary close={closeDrawer} />
      </div>
      <MiniBasketButtons
        onSubmit={handleGotoCheckout}
        close={closeDrawer}
        submitLabel={'toCheckout'}
      />
    </Drawer>
  )
}
