import React, { useEffect, useState } from 'react'
import DOMPurify from 'dompurify'

import { useContext } from 'react'
import { CoreContext } from '../context/CoreContext'
import {
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import LinkIcon from '@material-ui/icons/Link'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles((theme) => ({
  ico: {
    'color': 'white',
    'background': theme.palette.primary.main,
    'marginLeft': 10,
    'transform': 'translateY(-5px)',

    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  icoSuccess: {
    'color': 'white',
    'background': theme.palette.success.main,
    'marginLeft': 10,
    'transform': 'translateY(-5px)',

    '&:hover': {
      background: theme.palette.success.main,
    },
  },
  input: {
    width: 250,
    marginBottom: 20,
  },
  end: {
    margin: '20px 0',
  },
}))

export default function TranslationLabel(props) {
  const classes = useStyles()
  const core = useContext(CoreContext)
  const { name, children, variables, onClose, type } = props
  const [modalOpen, setModalOpen] = useState(false)
  const [groups, setGroups] = useState([])
  const value = core.dictionary.getLabel(name)

  function handleSetModalOpen(type) {
    core.dataStore.getLabelGroups().then((response) => {
      setGroups(response.data)
      setModalOpen(type)
    })
  }

  function closeModal() {
    setModalOpen(false)
    onClose && onClose()
  }

  function createOutputString(value, variables) {
    const variablesOrEmptyArray = variables || []
    let output = value
    while (output.match(/{}/)) {
      output = output.replace(/{}/, variablesOrEmptyArray.shift())
    }
    return output
  }

  return (
    <>
      {core.dictionary.isEditMode ? (
        <>
          {createOutputString(value, variables)}
          {children}
          <EditLabel
            setModalOpen={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleSetModalOpen('label')
            }}
            open={modalOpen === 'label'}
            selected={name}
            core={core}
            classes={classes}
            onClose={closeModal}
            groups={groups}
            name={name}
            showLabelNames={core.showLabelNames}
            type='label'
          />
          {type === 'link' && (
            <EditLabel
              setModalOpen={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleSetModalOpen('link')
              }}
              open={modalOpen === 'link'}
              selected={name + '-link'}
              core={core}
              classes={classes}
              onClose={closeModal}
              groups={groups}
              name={name + '-link'}
              showLabelNames={core.showLabelNames}
              type='link'
            />
          )}
        </>
      ) : (
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(createOutputString(value, variables)),
          }}>
          {children}
        </span>
      )}
    </>
  )
}

function EditLabel(props) {
  const {
    selected,
    open,
    setModalOpen,
    onClose,
    core,
    classes,
    groups,
    name,
    showLabelNames,
    type,
  } = props
  const [translations, setTranslations] = useState([])
  const [id, setId] = useState(undefined)
  const [selectedGroup, setSelectedGroup] = useState('')
  const [defaultValue, setDefaultValue] = useState('')
  const [languages, setLanguages] = useState([])

  function handleOnClose() {
    onClose()
    core.dictionary.load()
  }

  function load() {
    core.dataStore
      .getLabelByKey(selected)
      .then((x) => {
        if (x.status === 200) {
          setId(x.data)
          if (open) {
            core.dataStore.getLabels().then((res) => {
              const label = res.data.find((el) => el.label.id === x.data)
              setTranslations(label.translations)
              setDefaultValue(label?.label?.defaultValue)
              setSelectedGroup(label?.label?.labelGroup)
            })
          }
        }
      })
      .catch((e) => {})
    core.dataStore
      .getLanguages()
      .then((res) => {
        if (res.status === 200) {
          setLanguages(res.data)
        }
      })
      .catch(() => {})
  }

  const translationValueChange = (value, languageId) => {
    const index = translations.findIndex((el) => el.languageId == languageId)
    const newTranslations = [...translations]
    if (index > -1) {
      newTranslations[index].value = value
    } else {
      newTranslations.push({ languageId, value })
    }
    setTranslations(newTranslations)
  }

  useEffect(() => {
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  function addLabel() {
    core.dataStore
      .createLabel({
        key: selected,
        labelGroup: selectedGroup,
        defaultValue: defaultValue,
      })
      .then((response) => {
        core.snackbar('succes', 'Label was created')
        handleOnClose()
      })
      .catch((e) => {
        core.snackbar('Error', 'Label was not created')
      })
  }

  function updateLabel() {
    core.dataStore
      .updateLabel({
        id,
        defaultValue,
        key: selected,
        LabelGroup: selectedGroup,
        Translations: translations,
      })
      .then((res) => {
        handleOnClose()
      })
  }

  return id === undefined ? (
    <>
      <Tooltip
        open={showLabelNames || undefined}
        title={name}>
        <IconButton
          color='primary'
          variant='contained'
          size='small'
          aria-label='Translate'
          onClick={setModalOpen}
          className={classes.ico}>
          {type === 'link' ? (
            <LinkIcon fontSize='small' />
          ) : (
            <AddIcon fontSize='small' />
          )}
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleOnClose}
        aria-labelledby='form-dialog-edit-label'>
        <DialogContent>
          <h3>{`Add Label for key "${selected}"`}</h3>
          <div>
            <TextField
              className={classes.input}
              id='defv'
              label='Default value'
              variant='outlined'
              value={defaultValue}
              onChange={(e) => setDefaultValue(e.target.value)}
            />
          </div>
          <FormControl
            variant='outlined'
            className={classes.input}>
            <InputLabel id='groselab'>Group</InputLabel>
            <Select
              labelId='groselab'
              id='grosel'
              value={selectedGroup}
              onChange={(e) => {
                setSelectedGroup(e.target.value)
              }}
              label='Group'>
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {groups.length > 0 &&
                groups.map((group) => (
                  <MenuItem value={group}>{group}</MenuItem>
                ))}
            </Select>
          </FormControl>
          <div className={classes.end}>
            <Button
              variant='contained'
              color='primary'
              disabled={selectedGroup === '' || defaultValue === ''}
              onClick={addLabel}>
              Add Label
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <>
      <Tooltip
        open={showLabelNames || undefined}
        onClick={setModalOpen}
        title={selected}>
        <IconButton
          color='secondary'
          variant='contained'
          size='small'
          aria-label='Translate'
          className={classes.icoSuccess}>
          {type === 'link' ? (
            <LinkIcon fontSize='small' />
          ) : (
            <AddIcon fontSize='small' />
          )}
        </IconButton>
      </Tooltip>
      <Dialog
        size={'md'}
        open={open}
        onClose={handleOnClose}
        aria-labelledby='form-dialog-edit-label'>
        <DialogContent style={{ maxWidth: '100%', width: 900 }}>
          <h3>{`Changle label for key "${selected}"`}</h3>
          <div>
            <TextField
              className={classes.input}
              id='defv'
              label='Default value'
              variant='outlined'
              value={defaultValue}
              onChange={(e) => setDefaultValue(e.target.value)}
            />
          </div>
          <FormControl
            variant='outlined'
            className={classes.input}>
            <InputLabel id='groselab'>Group</InputLabel>
            <Select
              labelId='groselab'
              id='grosel'
              value={selectedGroup}
              onChange={(e) => {
                setSelectedGroup(e.target.value)
              }}
              label='Group'>
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {groups.length > 0 &&
                groups.map((group) => (
                  <MenuItem value={group}>{group}</MenuItem>
                ))}
            </Select>
          </FormControl>
          {languages.length &&
            languages.map((lang) => {
              return (
                <>
                  <TextField
                    label={`${lang.title} Translation`}
                    onChange={(e) =>
                      translationValueChange(e.target.value, lang.id)
                    }
                    value={
                      translations?.find((el) => el.languageId == lang.id)
                        ?.value || ''
                    }
                    variant='outlined'
                    fullWidth
                    style={{ marginTop: 10 }}
                    multiline
                  />
                </>
              )
            })}
          <div className={classes.end}>
            <Button
              variant='contained'
              color='primary'
              onClick={updateLabel}>
              Update Label
            </Button>
            <Button
              style={{ float: 'right' }}
              variant='contained'
              color='primary'
              onClick={handleOnClose}>
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export function translationLink(dictionary, name) {
  const value = dictionary.getLabel(name + '-link')
  return value
}
