import React, { useContext, useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CustomerIcon from '@material-ui/icons/AccountBox'
import SubjectIcon from '@material-ui/icons/Subject'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import { CoreContext } from '../../../../core/context/CoreContext'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Tooltip from '@material-ui/core/Tooltip'
import { statusEnum, viewModeEnum, days, months } from './enums'
import ModalWithAppointment from './ModalWithAppointment'
import { useStyles } from './styles'
import { Select, MenuItem, FormControl } from '@material-ui/core'

export default function AppointmentList() {
  const { OPEN, FUTURE } = statusEnum
  const statusOptions = Object.keys(statusEnum).map((el) => ({
    id: statusEnum[el],
    label: el + '-statuslabel',
  }))
  const classes = useStyles()
  const core = useContext(CoreContext)
  const { userId, language } = core.user
  const { getUserAppointments, getCustomerAppointments, getAppointmentTypes } =
    core.dataStore
  const [status, setStatus] = useState(statusOptions[FUTURE])
  const [appointments, setAppointments] = useState([])
  const [openAppointment, setOpenAppointment] = useState(-1)
  const [showAllCustomers, setShowAllCustomers] = useState(false)
  const [showCreateAppointment, setShowCreateAppointment] = useState(false)
  const [appointmentTypes, setAppointmentTypes] = useState([])
  const modalAppointment = appointments[openAppointment]
  const { CREATE, CUSTOMERVIEW, SALESVIEW } = viewModeEnum

  const isHomePage = window.location.pathname.split('/').indexOf('home') !== -1
  const getAppointments = () => {
    if (isHomePage) {
      getCustomerAppointments(
        language,
        core.user.selectedCustomer.accountNo,
        OPEN,
      )
        .then((res) => {
          if (res.data) {
            setAppointments(res.data)
          }
        })
        .catch(console.log)
    } else {
      if (showAllCustomers) {
        getUserAppointments(language, userId, status.id, showAllCustomers)
          .then((res) => {
            if (res.data) {
              setAppointments(res.data)
            }
          })
          .catch(console.log)
      } else {
        getCustomerAppointments(
          language,
          core.user.selectedCustomer.accountNo,
          status.id,
        )
          .then((res) => {
            if (res.data) {
              setAppointments(res.data)
            }
          })
          .catch(console.log)
      }
    }
  }
  useEffect(() => {
    getAppointments()
  }, [language, status.id, userId, getUserAppointments, showAllCustomers])

  useEffect(() => {
    getAppointmentTypes(language, userId).then((res) => {
      if (res.data) {
        setAppointmentTypes(res.data)
      }
    })
  }, [language, userId])

  const openCreateAppointment = () => {
    setShowCreateAppointment(true)
  }
  const closeCreateAppointment = () => {
    setShowCreateAppointment(false)
    getAppointments()
  }

  const changeCustomer = (customerNo) => {
    core.user.setCustomer({ accountNo: customerNo })
  }

  return (
    <>
      {modalAppointment && (
        <ModalWithAppointment
          open={!!modalAppointment}
          onClose={() => {
            setOpenAppointment(-1)
            //reload appointments when closing modal on SALESVIEW, cus they might have edited the appointment
            if (!isHomePage) {
              getAppointments()
            }
          }}
          appointment={modalAppointment}
          appointmentTypes={appointmentTypes}
          getAppointments={getAppointments}
          viewMode={isHomePage ? CUSTOMERVIEW : SALESVIEW}
        />
      )}
      {showCreateAppointment && (
        <ModalWithAppointment
          appointmentTypes={appointmentTypes}
          open={showCreateAppointment}
          onClose={closeCreateAppointment}
          viewMode={CREATE}
          getAppointments={getAppointments}
        />
      )}
      <div>
        {!isHomePage && (
          <div className={classes.filtersContainer}>
            <div>
              <FormControl size='small'>
                <Select
                  variant='outlined'
                  id='customer-select-outlined'
                  onChange={(e) =>
                    setStatus(
                      statusOptions.find((el) => el.id === e.target.value),
                    )
                  }
                  value={status.id}>
                  {statusOptions.map((status) => (
                    <MenuItem
                      key={status.id}
                      value={status.id}>
                      {<TranslationLabel name={status.label} />}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <FormControlLabel
              label={<TranslationLabel name='showAllCustomers' />}
              control={
                <Checkbox
                  color='primary'
                  checked={showAllCustomers}
                  onChange={() => setShowAllCustomers(!showAllCustomers)}
                />
              }
            />
            <Button
              color='primary'
              variant='contained'
              onClick={openCreateAppointment}>
              <TranslationLabel name='newAppointment' />
            </Button>
          </div>
        )}
        <div className={classes.scrollable}>
          {appointments
            .sort((a, b) => new Date(a.start) - new Date(b.start))
            .map((item, key) => (
              <div
                key={key}
                onClick={() => setOpenAppointment(key)}>
                <AppointmentLine
                  changeCustomer={changeCustomer}
                  isHomePage={isHomePage}
                  {...item}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

export const AppointmentLine = (item) => {
  const classes = useStyles()
  const appointmentDate = new Date(item.start)
  const startDate = appointmentDate.getDate()
  const startHour = appointmentDate.getHours()
  const startMin =
    appointmentDate.getMinutes() < 9
      ? '0' + appointmentDate.getMinutes()
      : appointmentDate.getMinutes()
  const startWeekDay = days[appointmentDate.getDay()]
  const startMonth = months[appointmentDate.getMonth()]
  const isToday = () => {
    const today = new Date()
    return (
      today.getDate() === startDate &&
      today.getMonth() === appointmentDate.getMonth() &&
      today.getFullYear() === appointmentDate.getFullYear()
    )
  }

  const { changeCustomer, isHomePage } = item

  const AppointmentTitle = () => {
    return isHomePage ? (
      <Typography
        noWrap
        className={classes.title}
        variant='subtitle1'>
        <span>
          <CustomerIcon className={classes.icon} />
          {item.rdgContactDisplayName}
        </span>
      </Typography>
    ) : (
      <Typography
        noWrap
        className={classes.title}
        variant='subtitle1'>
        <Tooltip title={<TranslationLabel name='changeCustomer' />}>
          <span
            onClick={(e) => {
              e.stopPropagation()
              changeCustomer(item.customerNo)
            }}>
            <CustomerIcon className={classes.icon} />
            {`${item.customerNo} - ${item.customerName} - ${item.rdgContactDisplayName}`}
          </span>
        </Tooltip>
      </Typography>
    )
  }

  return (
    <div className={classes.row}>
      <div
        className={
          classes.leftContainer +
          ' ' +
          (isToday() ? classes.backgroundToday : classes.backgroundOther)
        }>
        <div>
          <Typography
            className={classes.day}
            variant='h6'>
            {isToday() ? (
              <TranslationLabel name='today' />
            ) : (
              <TranslationLabel name={startWeekDay} />
            )}{' '}
            <span>{startHour + ':' + startMin}</span>
          </Typography>
        </div>
        <div className={classes.month}>
          <Typography variant='subtitle2'>
            <span>
              {startDate} <TranslationLabel name={startMonth} />
            </span>
          </Typography>
        </div>
      </div>
      <div className={classes.rightContainer}>
        <AppointmentTitle />
        <span>
          <div className={classes.subjectContainer}>
            <Typography
              className={classes.appointmentInfoLine}
              variant='subtitle2'
              noWrap>
              <span className={classes[`status${item.status}`]}>
                {item.statusText}
              </span>
              <SubjectIcon className={classes.icon} />
              {item.title}
            </Typography>
          </div>
        </span>
      </div>
    </div>
  )
}
