import React, { useEffect, useState, useContext } from 'react'
import { useStore } from '../global/store'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  TextField,
  CardHeader,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  LinearProgress,
} from '@material-ui/core'
import DialogWrapper from '../components/Dialogs/DialogWrapper'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowUp from '@material-ui/icons/ArrowUpward'
import ArrowDown from '@material-ui/icons/ArrowDownward'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import { CoreContext } from '../../../core/context/CoreContext'
import TranslationLabel from '../../../core/components/TranslationLabel'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    flexGrow: 1,
    margin: '-15px',
    padding: theme.spacing(3),
    background: 'white',
  },
  buttons: {
    '& > *': {
      margin: '8px 8px 0 8px',
    },
  },
  progress: {
    width: '100%',
    height: 5,
    textAlign: 'center',
  },
  addButton: {
    margin: '20px 10px',
  },
  tableHeader: {
    fontWeight: 700,
    verticalAlign: 'bottom',
    whiteSpace: 'nowrap',
  },
  container: {
    maxHeight: 'calc(100vh - 300px)',
    maxWidth: 'calc(100vw - 345px)',
  },
  formControl: {
    marginTop: 40,
    width: '100%',
    maxWidth: 360,
  },
  smallGrid: {
    maxWidth: 800,
  },
  actionCell: {
    width: 130,
  },
  tableButton: {
    'transform': 'translateY(-8px)',
    'backgroundColor': theme.palette.primary.main,
    'color': 'white',
    'zIndex': 1,
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  tableButtonWrapper: {
    textAlign: 'center',
  },
  arrow: {
    verticalAlign: 'middle',
    padding: 5,
    cursor: 'pointer',
  },
  dragging: {
    opacity: 0.5,
  },
  dragOver: {
    'position': 'relative',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '50%',
      borderBottom: '2px solid blue',
      zIndex: 1,
    },
  },
}))

export default function TemplatePage() {
  const core = useContext(CoreContext)
  const { state, dispatch } = useStore()
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const [dialog, setDialog] = useState({
    title: '',
    name: '',
    type: '',
    data: '',
  })

  useEffect(() => {
    const draggables = document.querySelectorAll('.drag')
    if (!draggables) {
      return
    }
    const addDragClass = (r) => {
      r.classList.add(classes.dragging)
    }

    const dragOver = (e, row) => {
      e.preventDefault()
      draggables.forEach((el) => {
        el.classList.remove(classes.dragOver)
      })
      row.classList.add(classes.dragOver)
    }

    const dragEnd = (row) => {
      const startRow = document
        .querySelector(`.${classes.dragging}`)
        ?.id.split('-')[1]
      const endRow = document
        .querySelector(`.${classes.dragOver}`)
        ?.id.split('-')[1]
      row.classList.remove(classes.dragging)
      draggables.forEach((row) => row.classList.remove(classes.dragOver))

      const field = state.selectedTemplate.templateFields[startRow]
      updateTemplateFieldAndReload({
        ...field,
        columnNo: Number(endRow) + 1,
      })
    }

    draggables.forEach((row) => {
      row.addEventListener('dragend', () => dragEnd(row))
    })
    draggables.forEach((row) => {
      row.addEventListener('dragstart', () => addDragClass(row))
    })
    draggables.forEach((row) => {
      row.addEventListener('dragover', (e) => dragOver(e, row))
    })
    return () => {
      draggables.forEach((row) => {
        row.removeEventListener('dragend', () => dragEnd(row))
      })
      draggables.forEach((row) => {
        row.removeEventListener('dragstart', () => addDragClass(row))
      })
      draggables.forEach((row) => {
        row.removeEventListener('dragover', (e) => dragOver(e, row))
      })
    }
  }, [state])

  const isStandardTemplate = state.selectedTemplate?.standard
  const canEditStandard = core.user.userSetting.templateManagement
  const canEdit = canEditStandard || !isStandardTemplate

  useEffect(() => {
    if (core.user.selectedCustomer.accountNo) {
      core.masterItemDataStore
        .getCustomerTemplates(core.user.selectedCustomer.accountNo)
        .then((res) => {
          if (res.data.length > 0) {
            dispatch({ type: 'SET_TEMPLATES', payload: res.data })
            dispatch({ type: 'SET_TEMPLATE', payload: res.data[0] })
          }
        })
    }
  }, [core.user.selectedCustomer.accountNo])

  const handleTemplateChange = (event) => {
    dispatch({ type: 'SET_TEMPLATE', payload: event.target.value })
  }

  // Template dialogs
  const onNewTemplate = () => {
    setDialog({
      title: <TranslationLabel name='newTemplate' />,
      name: 'template',
      type: 'create',
      data: {
        fromTemplate: state.selectedTemplate,
      },
    })
  }

  const onUpdateTemplate = () => {
    setDialog({
      title: <TranslationLabel name='editTemplate' />,
      name: 'template',
      type: 'update',
      data: state.selectedTemplate,
    })
  }

  const onDeleteTemplate = () => {
    setDialog({
      title: <TranslationLabel name='deleteTemplate' />,
      name: 'template',
      type: 'delete',
      data: state.selectedTemplate,
    })
  }

  const onCopyTemplate = () => {
    setDialog({
      title: <TranslationLabel name='copyTemplate' />,
      name: 'template',
      type: 'copy',
      data: {
        fromTemplate: state.selectedTemplate,
      },
    })
  }

  // Template columns
  const onCreateTemplateColumn = () => {
    setDialog({
      title: <TranslationLabel name='createColumn' />,
      name: 'templateColumn',
      type: 'create',
      data: { templateId: state.selectedTemplate.id },
    })
  }

  const onEditTemplateColumn = (index) => {
    setDialog({
      title: <TranslationLabel name='editColumn' />,
      name: 'templateColumn',
      type: 'update',
      data: { ...state.selectedTemplate.templateFields[index], index },
    })
  }

  const onDeleteTemplateColumn = (index) => {
    setDialog({
      title: <TranslationLabel name='deleteColumn' />,
      name: 'templateColumn',
      type: 'delete',
      data: { ...state.selectedTemplate.templateFields[index], index },
    })
  }

  const moveTemplateField = (field, direction) => {
    if (
      (direction == 'UP' && field.columnNo <= 1) ||
      (direction == 'DOWN' &&
        field.columnNo >= state.selectedTemplate.templateFields.length)
    ) {
      return
    }
    const directionIsUp = direction == 'UP'
    updateTemplateFieldAndReload({
      ...field,
      columnNo: field.columnNo + (directionIsUp ? -1 : 1),
    })
  }

  const updateTemplateFieldAndReload = (field) => {
    setLoading(true)
    core.masterItemDataStore.updateTemplateField(field).then(() => {
      closeDialog('template')
      setLoading(false)
    })
  }

  const closeDialogWithoutReloading = () => {
    setDialog({ title: '', name: '', type: '', data: '' })
  }

  const closeDialog = (reset) => {
    core.masterItemDataStore
      .getCustomerTemplates(core.user.selectedCustomer.accountNo)
      .then((res) => {
        dispatch({ type: 'SET_TEMPLATES', payload: res.data })
        let selectedTemplate = res.data.filter(
          (c) => c.id === state.selectedTemplate.id,
        )
        dispatch({ type: 'SET_TEMPLATE', payload: selectedTemplate[0] || '' })
      })
    setDialog({ title: '', name: '', type: '', data: '' })
  }

  return (
    <div className={classes.pageRoot}>
      <Grid
        container
        spacing={4}>
        <Grid
          item
          xs={12}>
          <CardHeader
            title={core.user.selectedCustomer.accountName}
            action={
              <div className={classes.buttons}>
                <Button
                  size='small'
                  onClick={(e) => onNewTemplate()}
                  variant='contained'
                  disableElevation>
                  <TranslationLabel name='newTemplate' />
                </Button>
              </div>
            }></CardHeader>

          {/* Customers template select */}
          <Grid
            container
            spacing={4}>
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              lg={4}
              mb={2}>
              <FormControl
                variant='outlined'
                className={classes.formControl}>
                <InputLabel id='customer-select-outlined-label'>
                  <TranslationLabel name='selectTemplate' />
                </InputLabel>
                <Select
                  labelId='customer-select-outlined-label'
                  id='data-template-outlined'
                  value={state.selectedTemplate}
                  onChange={(e) => handleTemplateChange(e)}
                  name='dataTemplate'>
                  {state.templates.map((template) => {
                    return (
                      <MenuItem
                        value={template}
                        key={template.id}>
                        {template.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {state.loading || loading ? (
        <LinearProgress
          className={classes.progress}
          color='primary'
        />
      ) : (
        <div style={{ height: '5px' }}></div>
      )}

      {/* Template */}
      {state.selectedTemplate?.name && (
        <Grid
          container
          spacing={4}>
          <Button
            variant='contained'
            color='primary'
            className={classes.addButton}
            startIcon={<AddIcon />}
            onClick={onCreateTemplateColumn}>
            <TranslationLabel name='addColumn' />
          </Button>

          <Grid
            item
            xs={12}>
            <CardHeader
              title={state.selectedTemplate.name}
              action={
                <div className={classes.buttons}>
                  {canEdit && (
                    <Button
                      size='small'
                      onClick={(e) => onUpdateTemplate()}
                      variant='contained'
                      disableElevation>
                      <TranslationLabel name='editTemplate' />
                    </Button>
                  )}
                  <Button
                    size='small'
                    onClick={onCopyTemplate}
                    variant='contained'
                    disableElevation>
                    <TranslationLabel name='copyTemplate' />
                  </Button>
                  {canEdit && (
                    <Button
                      size='small'
                      onClick={(e) => onDeleteTemplate()}
                      variant='contained'
                      disableElevation>
                      <TranslationLabel name='deleteTemplate' />
                    </Button>
                  )}
                </div>
              }></CardHeader>
            <TableContainer>
              <Table
                stickyHeader
                size='small'
                className={state.loading ? 'table--loading' : ''}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeader}>
                      <TranslationLabel name='column' />
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      <TranslationLabel name='inputField' />
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      <TranslationLabel name='outputField' />
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      <TranslationLabel name='maxLength' />
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      <TranslationLabel name='decimalSeparator' />
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      <TranslationLabel name='thousandSeparator' />
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      <TranslationLabel name='language' />
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      <TranslationLabel name='currency' />
                    </TableCell>
                    <TableCell
                      className={`${classes.tableHeader}  ${classes.actionCell}`}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(state.selectedTemplate.templateFields || [])
                    .sort((a, b) => a.columnNo - b.columnNo)
                    .map((field, index) => {
                      return (
                        <TableRow
                          draggable='true'
                          className='drag'
                          id={'drag-' + index}
                          key={`${Math.random()}-${field.id + field.columnNo}`}>
                          <TableCell style={{ minWidth: 120 }}>
                            {canEdit && (
                              <ArrowUp
                                onClick={() => moveTemplateField(field, 'UP')}
                                className={classes.arrow}
                              />
                            )}
                            <TextField
                              disabled={!canEdit}
                              defaultValue={field.columnNo}
                              style={{ width: 25, display: 'inline-block' }}
                              inputProps={{
                                style: { textAlign: 'center' },
                              }}
                              InputProps={{
                                disableUnderline: true,
                              }}
                              onBlur={(e) => {
                                if (e.target.value > 0) {
                                  updateTemplateFieldAndReload({
                                    ...field,
                                    columnNo: e.target.value,
                                  })
                                } else {
                                  e.target.value = field.columnNo
                                }
                              }}
                            />
                            {canEdit && (
                              <ArrowDown
                                onClick={() => moveTemplateField(field, 'DOWN')}
                                className={classes.arrow}
                              />
                            )}
                          </TableCell>
                          <TableCell>{field.displayName}</TableCell>
                          <TableCell>{field.headerText}</TableCell>
                          <TableCell>{field.maxFieldLength}</TableCell>
                          <TableCell>
                            {field.noDecimalSeperator ? (
                              <TranslationLabel name='no' />
                            ) : (
                              <TranslationLabel name='Yes' />
                            )}
                          </TableCell>
                          <TableCell>
                            {field.noThousandSeperator ? (
                              <TranslationLabel name='no' />
                            ) : (
                              <TranslationLabel name='Yes' />
                            )}
                          </TableCell>
                          <TableCell>{field.languageCode}</TableCell>
                          <TableCell>{field.currencyCode}</TableCell>
                          <TableCell>
                            {canEdit && (
                              <>
                                <IconButton
                                  aria-label='delete'
                                  onClick={(e) => onEditTemplateColumn(index)}>
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  aria-label='delete'
                                  onClick={(e) =>
                                    onDeleteTemplateColumn(index)
                                  }>
                                  <DeleteIcon />
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}

      {/* Dialogs */}
      {dialog.name !== '' && (
        <DialogWrapper
          close={closeDialog}
          closeDialogWithoutReloading={closeDialogWithoutReloading}
          name={dialog.name}
          title={dialog.title}
          data={dialog.data}
          type={dialog.type}></DialogWrapper>
      )}
    </div>
  )
}
