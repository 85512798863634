import React, { useState, useContext } from 'react'
import { Route } from 'react-router-dom'
import { CoreContext } from '../../../context/CoreContext'
import UserSearchView from './UserSearchView'
import UserFormView from './UserFormView'

export default function UsersManager() {
  const core = useContext(CoreContext)
  const [selected, setSelected] = useState(-1)

  function handleSelect(r) {
    setSelected(r)
  }
  return (
    <>
      <Route
        exact
        path={`/hub/admin/users`}
        render={() => (
          <UserSearchView
            core={core}
            select={handleSelect}
          />
        )}
      />

      <Route
        exact
        path={`/hub/admin/users/create`}
        render={() => (
          <UserFormView
            core={core}
            mode='create'
          />
        )}
      />

      <Route
        exact
        path={`/hub/admin/users/edit`}
        render={() => (
          <UserFormView
            core={core}
            mode='edit'
            selected={selected}
          />
        )}
      />
    </>
  )
}
