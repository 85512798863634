import TemplatePage from '../pages/TemplatePage'
import SearchPage from '../pages/SearchPage'
import DataFields from '../pages/DataFields'
import HelpPage from '../../../core/components/HelpPage'

const root = '/master-item-data'

const routes = [
  {
    id: 'Search',
    path: root,
    component: SearchPage,
    accessLevel: 0,
    exact: true,
  },
  {
    id: 'Templates',
    path: root + '/templates',
    component: TemplatePage,
    accessLevel: 0,
    exact: true,
  },
  {
    id: 'Help',
    path: root + '/help',
    component: HelpPage,
    accessLevel: 0,
    exact: true,
  },
  {
    id: 'DataFields',
    path: root + '/DataFields',
    component: DataFields,
    accessLevel: 0,
    exact: true,
  },
]

export default {
  getRoute: id => routes.find((i) => i.id === id),
  root: root,
  routes: routes
}
