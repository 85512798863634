import { Redirect, Route } from "react-router-dom";
import React from "react";

export const ProtectedRoute = ({ component: Component, isAuth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? <Component {...props} {...rest} /> : <Redirect to='/login' />
      }
    />
  );
};

export const ComponentRoute = ({
  component: Component,
  componentId,
  ...rest
}) => (
  <Route {...rest} render={(props) => <Component {...props} {...rest} />} />
);
