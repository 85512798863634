import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.grey['700'],
    color: 'white',
    padding: '10px',
  },
}))

export default function SimpleTable(props) {
  const { header, rows, footer } = props
  const classes = useStyles()
  if (!header || !rows) {
    return null
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          {header.fields.map((headline, i) => {
            return (
              <TableCell
                className={classes.header}
                key={headline.value + i}
                align={i ? 'right' : 'left'}>
                {headline.value}
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => {
          return (
            <TableRow key={Math.random()}>
              {row.fields.map((field, i) => (
                <TableCell
                  key={field.value + i}
                  align={i ? 'right' : 'left'}>
                  {field.value}
                </TableCell>
              ))}
            </TableRow>
          )
        })}
        {footer && (
          <TableRow>
            {footer.fields.map((field, i) => {
              return (
                <TableCell
                  key={field.value + i}
                  align={i ? 'right' : 'left'}>
                  {field.value}
                </TableCell>
              )
            })}
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
