import ProductSearch from '../pages/SearchPage/SearchPage'
import OrdersPage from '../../../apps/sales-web-app/pages/Orders/OrdersPage'
import DetailedOrderView from '../../sales-web-app/pages/Orders/DetailedOrderView'
import StatisticsPage from '../../../apps/sales-web-app/pages/Statistics/StatisticsPage'
import HelpPage from '../../../core/components/HelpPage'
import BasketOverview from '../../../core/pages/BasketOverview/BasketOverview'
import SortingPage from '../pages/SortingPage'
import BasketPage from '../../sales-web-app/pages/Basket/BasketPage'

const root = '/fair-app'

const routes = [
  {
    id: 'productSearch',
    path: `${root}/`,
    component: ProductSearch,
    exact: true,
  },
  {
    id: 'orders',
    path: `${root}/orders`,
    component: OrdersPage,
    exact: true,
  },
  {
    id: 'DetailedOrderView',
    path: root + '/orders/:id',
    component: DetailedOrderView,
  },
  {
    id: 'statistics',
    path: `${root}/statistics`,
    component: StatisticsPage,
    exact: true,
  },
  {
    id: 'sorting',
    path: `${root}/sorting`,
    component: SortingPage,
    exact: true,
  },
  {
    id: 'help',
    path: `${root}/help`,
    component: HelpPage,
    exact: true,
  },
  {
    id: 'BasketOverview',
    path: root + '/basketOverview',
    component: BasketOverview,
  },
  {
    id: 'Basket',
    path: root + '/basket',
    component: BasketPage,
    accessLevel: 0,
    exact: true,
  },
  {
    id: 'BasketReceipt',
    path: root + '/basket/receipt',
    component: BasketPage,
    accessLevel: 0,
    exact: true,
  },
  {
    id: 'BasketConfirmation',
    path: root + '/basket/confirmation',
    component: BasketPage,
    accessLevel: 0,
  },
]

export default {
  getRoute: id => routes.find((i) => i.id === id),
  root: root,
  routes: routes
}
