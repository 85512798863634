import React, { useContext, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Button, LinearProgress, makeStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from "@material-ui/icons/Close"
import TableView from '../../components/TableView'

import TranslationLabel from '../../../../core/components/TranslationLabel'
import TextField from '@material-ui/core/TextField'
import { Typography } from '@material-ui/core'
import EmptyState from '../../components/EmptyState'
import { CoreContext } from '../../../../core/context/CoreContext'
import { BasketContext } from '../../../../core/context/BasketContext'
import OpenOrdersModal from '../Products/OpenOrdersModal'
import priceDtoHelper from '../../../../shared/PriceDtoHelper'

const useStyles = makeStyles((theme) => ({
  productsPageRoot: {
    flexGrow: 1,
    margin: 0,
    background: 'white',
  },
  stickyloader: {
    margin: 'auto',
    position: 'sticky',
    zIndex: 1,
    width: '100%',
    visibility: 'hidden',
  },
  loaderVisible: {
    visibility: 'visible',
  },
  stickyScrollButton: {
    'position': 'fixed',
    'top': 80,
    'left': '50%',
    'transform': 'translateX(-50%)',
    'zIndex': 100,
    'backgroundColor': 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  buttons: {
    margin: 10,
  },
  seeProductsButton: {
    marginRight: 20,
  },
  inputField: {
    margin: '10px 0px 10px 0px',
    '&::-webkit-search-decoration,::-webkit-search-cancel-button,::-webkit-search-results-button,::-webkit-search-results-decoration': {
      display: 'none'
    }
  },
  searchIcon: {
    fill: theme.palette.grey['700'],
    cursor: 'pointer'
  },
}))

export default function SearchPage() {
  const classes = useStyles()
  const core = useContext(CoreContext)
  const basketModel = useContext(BasketContext)
  const [query, setQuery] = useState('')
  const [products, setProducts] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showSelected, setShowSelected] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [hasMoreResults, setHasMoreResults] = useState(false)

  const [openOrdersModal, setOpenOrdersModal] = useState(undefined)

  function handleQueryChange(e) {
    setQuery(e.target.value)
    setPageNumber(1)
  }

  const keypressHandler = (e) => {
    if (e.keyCode === 13) {
      //for the enter key
      search()
      setShowSelected(false)
    }
  }

  const showSelectedHandler = () => {
    setShowSelected(!showSelected)
    setPageNumber(1)
  }

  const addToBasket = () => {
    basketModel.multiAdd(
      selectedProducts.map((el) => {
        return { ...el, qty: priceDtoHelper.findNextAllowedNumber(el, el.quantity || 0) }
      }),
      () => {
        setSelectedProducts([])
        setProducts(
          products.map((product) => {
            return { ...product, quantity: '' }
          }),
        )
      },
    )
  }

  const addColiToBasket = (product) => {
    setSelectedProducts(selectedProducts.filter((el) => el.productNo !== product.productNo))
  }

  const onQuantityChange = (productNo, quantity) => {
    const changingProduct = products.findIndex((el) => el.productNo === productNo)
    const changingSelectedProduct = selectedProducts.findIndex((el) => el.productNo === productNo)

    if (changingProduct + changingSelectedProduct === -2) {
      return console.log('changed quantity on product not in products lists')
    }

    if (changingSelectedProduct > -1) {
      let newSelectedProducts = [...selectedProducts]
      if (quantity == '0' || !quantity) {
        newSelectedProducts = newSelectedProducts.filter((el) => el.productNo !== productNo)
      } else {
        newSelectedProducts[changingSelectedProduct].quantity = quantity
      }

      setSelectedProducts(newSelectedProducts)
    } else if (changingProduct > -1 && quantity > 0) {
      const newSelectedProducts = [...selectedProducts]
      newSelectedProducts.push({ ...products[changingProduct], quantity })
      setSelectedProducts(newSelectedProducts)
    }
  }

  const options = {
    userId: core.user.userId,
    customerId: core.user.selectedCustomer.accountNo,
    selectedFacetValues: {
      brand: [],
      mainColor: [],
      subCategory: [],
      category: [],
      news: [],
    },
    languageCode: core.user.languageCode,
    pageSize: 24,
    pageNumber,
    query: query,
    sortByField: 'BrandId',
    descendingSortOrder: false,
  }

  const search = () => {
    setIsLoading(true)

    if (isLoading) {
      return;
    }

    core.dataStore
      .searchProducts(options)
      .then((res) => {
        if (res && res.data) {
          if (pageNumber === 1) {
            setProducts(
              res.data.products.map((product) => {
                const alreadySelectedProduct = selectedProducts.find(
                  (el) => el.productNo === product.productNo,
                )
                if (alreadySelectedProduct) {
                  product.quantity = alreadySelectedProduct.quantity
                }
                return product
              }),
            )
          } else {
            setProducts(
              products.concat(
                res.data.products.map((product) => {
                  const alreadySelectedProduct = selectedProducts.find(
                    (el) => el.productNo === product.productNo,
                  )
                  if (alreadySelectedProduct) {
                    product.quantity = alreadySelectedProduct.quantity
                  }
                  return product
                }),
              ),
            )
          }
          setHasMoreResults(!res.data.isLastPage)
          setPageNumber(pageNumber + 1)
        }
      })
      .catch(console.log)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div className={classes.productsPageRoot}>
      <OpenOrdersModal
        openOrdersProduct={products?.find((el) => el && el.productNo == openOrdersModal)}
        setOpenOrdersModal={setOpenOrdersModal}
      />

      <Typography
        variant='h4'
        component='h1'>
        <TranslationLabel name='searchPage' />
      </Typography>
      <div style={{ float: 'right' }}>
        <Button
          onClick={showSelectedHandler}
          className={`${classes.seeProductsButton} ${classes.buttons}`}
          variant='contained'
          color='primary'>
          <TranslationLabel
            name={showSelected ? 'backToSearch' : 'seeSelectedProducts'}
          />
        </Button>
        <Button
          onClick={addToBasket}
          className={classes.buttons}
          startIcon={<AddIcon />}
          variant='contained'
          color='primary'>
          <span>
            <TranslationLabel name='add' /> {selectedProducts.length}
            <TranslationLabel name='productsToBasket' />
          </span>
        </Button>
      </div>
      <TextField
        onKeyDown={keypressHandler}
        fullWidth
        label={<TranslationLabel name='searchPageInputLabel' />}
        id='filled-margin-dense'
        type='search'
        variant='outlined'
        value={query}
        onChange={handleQueryChange}
        className={classes.inputField}
        InputProps={{
          startAdornment: <SearchIcon className={classes.searchIcon} />,
          endAdornment: <CloseIcon onClick={() => setQuery('') } className={classes.searchIcon} />,
        }}
      />
      <LinearProgress
        className={`${classes.stickyloader} ${
          isLoading && classes.loaderVisible
        }`}></LinearProgress>

      {/* Product list */}
      <div style={{ marginTop: '50px' }}>
        <InfiniteScroll
          loadMore={search}
          hasMore={hasMoreResults}
          >
          <TableView
            hideHeader={true}
            emptyQuantity={true}
            addColiToBasket={addColiToBasket}
            onQuantityChange={onQuantityChange}
            products={showSelected ? selectedProducts : products}
            setOpenOrdersModal={setOpenOrdersModal}
          />
        </InfiniteScroll>
      </div>

      {/* No products */}
      {!isLoading && products.length === 0 && query && (
        <EmptyState
          main='productSearchEmptyMain'
          sub='productSearchEmptySub'
        />
      )}
    </div>
  )
}
