import React, { useContext, useEffect, useState } from 'react'
import { CoreContext } from '../context/CoreContext'
import TranslationLabel from '../components/TranslationLabel'

const emptyBasketState = {
  entries: [],
  amount: 0,
  id: 0,
  totalAmount: 0,
  vatAmount: 0,
};

export function useBasketModel(basketId) {
  const [isLoading, setIsLoading] = useState(false)
  const [basket, setBasket] = useState(emptyBasketState)

  const core = useContext(CoreContext)
  const isConfirmationPage = window.location.href.split('/')[window.location.href.split('/').length - 1] === 'confirmation'
  const basketType = basketId;

  const differentCampaignsErrorModal = (attemptedCampaign, basketCampaign) => {
    return {
      headline: <TranslationLabel name='productNotAdded' />,
      message: (
        <>
          <TranslationLabel name='youCannotAddMultipleCampaignIdsToTheSameBasket' />
          <br />
          <span>
            <TranslationLabel name='youTriedToAddProductsFrom' />{' '}
            {attemptedCampaign}
          </span>
          <br />
          <span>
            <TranslationLabel name='butYourBasketAlreadyHasProductsFrom' />{' '}
            {basketCampaign}
          </span>
        </>
      ),
    }
  }

  const multiAdd = (entries, callback) => {
    setIsLoading(true)

    const basketEntries = entries.map(item => {
      return {
        itemId: item.productNo,
        quantity: parseInt(item.qty),
        userId: core.user.userId,
        customerNo: core.user.selectedCustomer.accountNo,
        requestedShippingDate: item.requestedShippingDate || null,
        tradeFairCampaignId: item.campaignId,
        basketType: basketId
      }
    })

    core.dataStore
      .addMultipleEntries(basketEntries)
      .then((res) => {
        if (callback) callback(res.data)
        load()
      })
      .catch((res) => {
        core.setModal(
          differentCampaignsErrorModal(
            entries[0]?.campaignId,
            basket.tradeFairCampaignId,
          ),
        )
      })
      .finally(() => setIsLoading(false))
  }

  const add = (item, callback) => {
    const itemAlreadyExists = basket.entries.find((x) => x.productNo === item.itemId)

    if (!itemAlreadyExists) {
      setIsLoading(true)

      core.dataStore
        .addBasketEntry(
          item.itemId,
          parseInt(item.qty),
          core.user.userId,
          core.user.selectedCustomer.accountNo,
          item.campaignId,
          basketId,
          item.requestedShippingDate,
        )
        .then((res) => {
          if (callback) callback(res.data)
          load()
        })
        .catch((res) => {
          core.setModal(
            differentCampaignsErrorModal(item.campaignId, basket.tradeFairCampaignId),
          )
        })
        .finally(() => setIsLoading(false))
    } else {
      updateEntry(itemAlreadyExists.basketEntryId, itemAlreadyExists.quantity + item.qty, callback)
    }
  }

  const update = (changesObj, callback) => {
    setIsLoading(true)

    core.dataStore
      .updateBasketEntry(
        changesObj,
        core.user.userId,
        core.user.selectedCustomer.accountNo,
        isConfirmationPage,
        basketId
      )
      .then((res) => {
        if (callback) callback(res.data)
        load();
      })
      .catch((error) => core.snackbar('warning', error.toString()))
      .finally(() => setIsLoading(false))
  }

  const updateEntry = (changesObj, callback) => changesObj.quantity < 1
      ? remove(changesObj.basketEntryId, callback)
      : update(changesObj, callback)

  const remove = (basketEntryId, callback) => {
    setIsLoading(true)

    core.dataStore
      .removeBasketEntry(basketEntryId)
      .then((res) => {
          if (callback) callback(res.data)
          load()
      })
      .catch((error) => core.snackbar('warning', error.toString()))
      .finally(() => setIsLoading(false))
  }

  const deleteBasket = (basketId) => {
    setIsLoading(true)

    core.dataStore
      .deleteBasket(basketId)
      .then(() => load())
      .catch((res) => core.snackbar('warning', res && res.statusText))
      .finally(setIsLoading(false))
  }

  function load(includeMarkup) {
    if (!core.user.selectedCustomer.accountNo) {
      return
    }

    setIsLoading(true)

    core.dataStore
      .getBasket(
        core.user.selectedCustomer.accountNo,
        core.user.userId,
        basketId,
        !!includeMarkup
      )
      .then((res) => setBasket(res.data))
      .catch((res) => core.snackbar('warning', res.statusText))
      .finally(() => setIsLoading(false))
  }

  const submit = (orderDetails, callback) => {
    orderDetails.tradeFairCampaignId = basket.tradeFairCampaignId
    console.log(orderDetails)
    core.dataStore
      .archiveBasket({
        userId: core.user.userId,
        customerNo: core.user.selectedCustomer.accountNo,
        basketId: basket.id
      })
      .then((x) => {
        localStorage.setItem('lastOrderId', x.data.salesId)
        submitOrderToAxapta({
          ...orderDetails,
          campaignId:
          x.data.campaignId
        }, x.data, callback)
      })
      .catch((error) => core.snackbar('warning', error.toString()))
      .finally(() => setIsLoading(false))
  }

  const submitOrderToAxapta = (orderDetails, salesDto, callback) => {
    setIsLoading(true)

    const orderLines = basket.entries.map((product) => ({
      productNo: product.itemId,
      quantity: product.quantity,
      requestedShippingDate: product.requestedShippingDate,
    }))

    const orderHeaderDto = {
      ...orderDetails,
      ...{
        salesId: salesDto.salesId,
        salesTaker: core.user.userId,
        customerNo: core.user.selectedCustomer.accountNo,
        orderLines: orderLines,
      },
    }

    core.dataStore
      .createOrder(orderHeaderDto)
      .then((x) => {
        if (x.data.status) {
          setBasket({ entries: [] })
          if (callback) callback()
          load()
        } else {
          core.snackbar('error', 'The B2B service did not return an order number')
        }
      })
      .catch((error) => core.snackbar('warning', error.toString()))
      .finally(() => setIsLoading(false))
  }

  const getCount = () => basket.entries.length

  useEffect(() => load(), [core.user.selectedCustomer.accountNo])

  return {
    isLoading,
    count: getCount(),
    basket,
    add,
    multiAdd,
    updateEntry,
    remove,
    submit,
    deleteBasket,
    load,
    basketType
  }
}
