import React from 'react'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import { Typography, Button, Modal, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    backgroundColor: 'white',
    width: '500px',
    maxWidth: '100%',
    margin: 'auto',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    padding: '25px',
    paddingTop: 10,
    textAlign: 'center',
    outline: 'none',
  },
  openOrderModalLine: {
    cursor: 'pointer',
    margin: 5,
  },
}))

const OpenOrdersModal = (props) => {
  const { openOrdersProduct, setOpenOrdersModal } = props
  const classes = useStyles()

  return (
    <Modal
      open={!!openOrdersProduct}
      onClose={() => setOpenOrdersModal(undefined)}>
      <div className={classes.modalWrapper}>
        <Typography
          style={{ marginBottom: 25, marginTop: 15 }}
          variant='h5'>
          <TranslationLabel
            name='openOrdersHeadline'
            variables={[openOrdersProduct?.openOrders.length]}
          />
        </Typography>
        {openOrdersProduct &&
          openOrdersProduct.openOrders.map((order) => (
            <Link to={`/sales/orders/${order.salesId}/false`}>
              <div className={classes.openOrderModalLine}>
                <Typography variant='subtitle1'>
                  <TranslationLabel
                    name='openOrderLine'
                    variables={[
                      order.salesId,
                      order.quantityOrdered,
                      order.confirmedDeliveryDate.split('T')[0],
                    ]}
                  />
                </Typography>
              </div>
            </Link>
          ))}

        <Button
          style={{ marginTop: 20 }}
          variant='outlined'
          color='primary'
          onClick={() => setOpenOrdersModal(undefined)}>
          <TranslationLabel name='ok' />
        </Button>
      </div>
    </Modal>
  )
}

export default OpenOrdersModal
