export default function HubStore(base) {
  let baseStore = base

  // Auth
  function login(username, password) {
    return baseStore.post('/auth/login', { username, password })
  }

  function authenticated() {
    return baseStore.get('/user/authenticated')
  }

  function logout() {
    return baseStore.post('auth/logout')
  }

  function refreshToken(oldAccessToken, oldRefreshToken) {
    return baseStore.post('/auth/refreshtoken', {
      accessToken: oldAccessToken,
      refreshToken: oldRefreshToken,
    })
  }

  //User
  async function getLanguagePack(id) {
    return {}
  }

  function signupUser(obj) {
    return baseStore.post('/User/signup', obj)
  }

  function createUser(obj) {
    console.log(obj)
    return baseStore.post('/user', obj)
  }

  function updateUser(userDto) {
    return baseStore.put('/User', userDto)
  }

  function removeUser(id) {
    return baseStore.del(`/user/${id}`)
  }

  function getAllUsers() {
    let rs = baseStore.get('/user/all')
    return rs
  }

  function getLoggedInUser() {
    let rs = baseStore.get(`/user`)
    return rs
  }

  function getUser(id) {
    let rs = baseStore.get(`/user/${id}`)
    return rs
  }

  function searchUsers(obj) {
    return baseStore.postSingleton(`/user/search`, obj)
  }

  function updateSelectedCustomerAccount(obj) {
    return baseStore.put('/user/SelectedCustomerAccount', obj)
  }

  function updateUserLanguage(obj) {
    return baseStore.put('user/UserLanguage', obj)
  }

  function submitPassword(userId, pw, token) {
    let dto = {
      userId: userId,
      password: pw,
      token: token,
    }
    return baseStore.post(`/auth/submitpassword`, JSON.stringify(dto))
  }

  function requestResetPassword(userName) {
    let dto = {
      userName,
    }
    return baseStore.post(`/auth/resetpassword`, JSON.stringify(dto))
  }

  function requestNewRegistrationMail(userName) {
    let dto = {
      userName,
    }
    return baseStore.post(
      `/auth/newRegistration/resetpassword`,
      JSON.stringify(dto),
    )
  }

  function createNewsletterUser(user) {
    return baseStore.post('/User/NewsLetterSignUp', user)
  }

  function changeNewsLetter(userId, newsLetter) {
    return baseStore.put(
      'User/UserNewsLetter',
      JSON.stringify({ userId, newsLetter }),
    )
  }

  return {
    refreshToken,
    login,
    logout,
    authenticated,
    getLanguagePack,
    createUser,
    signupUser,
    updateUser,
    removeUser,
    getAllUsers,
    getLoggedInUser,
    getUser,
    searchUsers,
    submitPassword,
    requestResetPassword,
    requestNewRegistrationMail,
    updateSelectedCustomerAccount,
    updateUserLanguage,
    createNewsletterUser,
    changeNewsLetter,
  }
}
