import React, { useContext } from 'react'
import { CoreContext } from '../context/CoreContext'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import IconButton from '@material-ui/core/IconButton'
import AppsIcon from '@material-ui/icons/AppsOutlined'
import Popover from '@material-ui/core/Popover'
import AppListItem from '../../apps/hub-portal/components/AppListItem'
import { List } from '@material-ui/core'

export default function GlobalAppNavigationButton(props) {
  const core = useContext(CoreContext)
  return (
    <PopupState
      variant='popover'
      popupId='demo-popup-popover'>
      {(popupState) => (
        <>
          <IconButton
            color='inherit'
            {...bindTrigger(popupState)}>
            <AppsIcon />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}>
            <List component='nav'>
              {core.getUserApps().map((v) => (
                <AppListItem
                  key={v}
                  appId={v}
                />
              ))}
            </List>
          </Popover>
        </>
      )}
    </PopupState>
  )
}
