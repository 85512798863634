import { useContext, useEffect, useState } from 'react'
import { CoreContext } from '../../../../core/context/CoreContext'

export function useCategoryModel() {
  const core = useContext(CoreContext)
  const [isLoading, setIsLoading] = useState(false)
  const [brands, setBrands] = useState([])

  function loadBrands() {
    setIsLoading(true)

    core.dataStore
      .getBrands()
      .then((x) => {
        if (x.status === 200) {
          setBrands(x.data ? x.data : [])
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    loadBrands()
  }, [])

  return {
    isLoading,
    brands,
  }
}
