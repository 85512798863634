import React from 'react'

import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import WarningIcon from '@material-ui/icons/WarningOutlined'

function FormHeader(props) {
  // const classes = props;
  const { title, displayWarning = false } = props
  return (
    <>
      <Typography
        variant='subtitle1'
        style={displayWarning ? { color: 'red' } : null}
        gutterBottom>
        {title} {displayWarning ? <WarningIcon /> : null}
      </Typography>

      <Divider style={{ marginBottom: '10px' }} />
    </>
  )
}

export default FormHeader
