import React, { useContext, useEffect, useState } from 'react'

//Core
import { CoreContext } from '../../context/CoreContext'

//MUI
import Select from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

function LanguageSelection() {
  const core = useContext(CoreContext)
  const [languages, setLanguages] = useState([])

  function loadLanguages() {
    core.dataStore
      .getLanguages()
      .then((x) => {
        if (x.status === 200) {
          setLanguages(x.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {})
  }

  useEffect(() => {
    loadLanguages()
  }, [])

  const selectLanguage = (e) => {
    const index = e.target.value
    core.user.setLanguage(languages[index].id, languages[index].code)
  }

  const selectedLanguage = languages.find(
    (el) => el.id === core.user.language,
  )?.title

  return selectedLanguage ? (
    <FormControl
      variant='outlined'
      style={{ width: '100%' }}>
      <Select
        value={languages.findIndex((el) => el.id === core.user.language)}
        onChange={selectLanguage}
        input={
          <OutlinedInput
            name='currency'
            id='outlined-age-simple'
          />
        }>
        {languages.map((v, i) => (
          <MenuItem
            key={v.id}
            value={i}>
            {v.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : null
}

export default LanguageSelection
