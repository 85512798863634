import React, { useEffect, useState } from 'react'
import { LinearProgress } from '@material-ui/core'
import TranslationLabel from '../../../core/components/TranslationLabel'
import TextField from '@material-ui/core/TextField'
import { useDebounce } from 'use-lodash-debounce'

export default function SimpleSearchField(props) {
  const { onSearch, isLoading, label = 'search' } = props
  const [value, setValue] = useState('')
  const debouncedValue = useDebounce(value, 300)

  function handleInput(evt) {
    const v = evt.target.value
    setValue(v)
  }

  function handleSearch(val) {
    onSearch(val)
  }

  useEffect(() => {
    handleSearch(debouncedValue)
  }, [debouncedValue])

  return (
    <div>
      <TextField
        fullWidth
        label={<TranslationLabel name={label} />}
        id='filled-margin-dense'
        variant='outlined'
        value={value}
        onChange={handleInput}
        autoComplete={'off'}
        style={{ margin: '10px 0px 10px 0px' }}
      />
      {isLoading ? (
        <LinearProgress style={{ margin: '-10px 0px 10px 0px' }} />
      ) : null}
    </div>
  )
}
