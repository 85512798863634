export function formatDate(date) {
  let dateObj = new Date(date)

  let day = dateObj.getDate()
  if (day < 10) {
    day = '0' + day
  }
  let month = dateObj.getMonth() + 1
  if (month < 10) {
    month = '0' + month
  }
  return `${day}-${month}-${dateObj.getFullYear()}`
}
