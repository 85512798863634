import React, { useContext } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import routes from '../config/routes'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { CoreContext } from '../../../core/context/CoreContext'
import NotFound from '../../../core/pages/NotFound'

function ComponentRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Component
          {...props}
          {...rest}
        />
      )}
    />
  )
}

const drawerWidth = 240

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    marginTop: 64,
    flexGrow: 1,
    padding: theme.spacing(2),
    height: 'calc(100% - 64px)',
    backgroundColor: 'white',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 70,
  },
  contentStatic: {
    marginLeft: 0,
  },
})

function Content(props) {
  const { classes } = props
  const core = useContext(CoreContext)
  const matches = useMediaQuery(core.theme.breakpoints.up('sm'))

  return (
    <main
      className={classNames(classes.content, {
        [classes.contentShift]: !core.isExpanded && matches,
        [classes.contentStatic]: !matches,
      })}>
      <Router history={core.history}>
        <Switch>
          {routes.routes.map((r) => (
            <ComponentRoute
              isFairApp
              drawerExpanded={core.isExpanded}
              exact
              path={r.path}
              appId={7}
              key={r.id}
              id={r.id}
              {...r}
            />
          ))}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </main>
  )
}

export default withStyles(styles)(Content)
