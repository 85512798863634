import HubDashboard from '../pages/HubDashboard'

import SalesAppRoot from '../../apps/sales-web-app/SalesAppRoot'
import SalesAppRoutes from '../../apps/sales-web-app/config/routes'

import MasterItemDataAppRoot from '../../apps/master-item-data-app/MasterItemDataAppRoot'
import MasterItemDataAppRoutes from '../../apps/master-item-data-app/config/routes'

import FairAppRoot from '../../apps/fair-app/FairAppRoot'
import FairAppRoutes from '../../apps/fair-app/config/routes'

import VideoAppRoot from '../../apps/video-app/VideoAppRoot'
import VideoAppRoutes from '../../apps/video-app/config/routes'

import ImageBankAppRoot from '../../apps/imagebank-app/ImageBankAppRoot'
import ImageBankAppRoutes from '../../apps/imagebank-app/config/routes'

const hubRoutes = [
  {
    id: 'admin',
    path: '/hub/admin',
    component: null,
    appId: -1,
    exact: false,
  },
  {
    id: 'hub',
    path: '/hub',
    component: HubDashboard,
    appId: 1,
    exact: false,
  },
  {
    id: 'salesApp',
    appId: 2,
    path: SalesAppRoutes.root,
    component: SalesAppRoot,
    routes: SalesAppRoutes.routes,
  },
  {
    id: 'masterItemDataApp',
    appId: 6,
    path: MasterItemDataAppRoutes.root,
    component: MasterItemDataAppRoot,
    routes: MasterItemDataAppRoutes.routes,
  },
  {
    id: 'fairApp',
    appId: 7,
    path: FairAppRoutes.root,
    component: FairAppRoot,
    routes: FairAppRoutes.routes,
  },
  {
    id: 'videoApp',
    appId: 9,
    path: VideoAppRoutes.root,
    component: VideoAppRoot,
    routes: VideoAppRoutes.routes,
  },
  {
    id: 'imageBankApp',
    appId: 12,
    path: ImageBankAppRoutes.root,
    component: ImageBankAppRoot,
    routes: ImageBankAppRoutes.routes,
  },
]

export function findApp(id) {
  return hubRoutes.find((x) => x.appId === id)
}

export function getRoutes(apps) {
  if (!apps) return []
  const rs = hubRoutes.filter((x) => apps.includes(x.appId))
  return rs ? rs : []
}

export default hubRoutes
