import { useContext } from 'react'
import routeSettings from './routes'
import { CoreContext } from '../../../core/context/CoreContext'

const GetNavItems = () => {
  const core = useContext(CoreContext)
  const { showDashboard } = core.user.userSetting

  const navItem = (id, index, labelKey) => {
    return {
      id: id,
      route: routeSettings.getRoute(id),
      index: index,
      labelKey: labelKey,
    }
  }

  const upperNavigation = [
    navItem('HomePage', 1, 'homePage'),
    navItem('Products', 3, 'productsPage'),
    navItem('Search', 4, 'searchPage'),
    navItem('QuickEntry', 5, 'quickEntryPage'),
    navItem('ReturnService', 6, 'returnServicePage'),
    navItem('Invoices', 7, 'invoicesPage'),
    navItem('Orders', 8, 'ordersPage'),
    navItem('RestOrders', 9, 'restOrders'),
    navItem('Statistics', 11, 'statisticsPage'),
    navItem('Help', 12, 'helpPage'),
  ]

  if (showDashboard) {
    upperNavigation.splice(1, 0, navItem('DashboardPage', 2, 'dashboard'))
  }

  return {
    upperNavigation,
  }
}

export default GetNavItems
