export const saveAsCsv = (data, filename) => {
  var encodedUri = encodeURI(
    'data:text/csv;charset=utf-8,' + '\uFEFF' + data.replaceAll('#', ''),
  )

  console.log(encodedUri)
  var link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', filename + '.csv')
  document.body.appendChild(link) // Required for FF

  link.click() // This will download the data file named "my_data.csv".
}
