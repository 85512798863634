import React, { useContext } from 'react'
import { Route } from 'react-router-dom'

import UsersManager from './UserManager/UsersManager'
import UserSettingsPage from '../../pages/UserSettingsPage'
import { withStyles } from '@material-ui/core/styles'

import { CoreContext } from '../../context/CoreContext'
import SettingsManagement from './SettingsManagement/SettingsManagement'
import LanguageManager from './LanguageManager/LanguageManager'

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
    paddingLeft: '0!important',
    paddingRight: '0!important',
    flexGrow: 1,
    backgroundColor: 'transparent',
  },
})

function AdminView(props) {
  const core = useContext(CoreContext)

  return (
    <>
      <Route
        exact
        path={`/hub/admin/settings`}
        render={() => <UserSettingsPage />}
      />
      <Route
        exact
        path={`/hub/admin/settingsManagement`}
        render={() => <SettingsManagement />}
      />

      <Route
        path={`/hub/admin/users`}
        render={() => <UsersManager />}
      />

      <Route
        exact
        path={`/hub/admin/language`}
        render={() => <LanguageManager core={core} />}
      />
    </>
  )
}

export default withStyles(styles)(AdminView)
