import React, { useEffect, useState, useContext } from 'react'
import { useStore } from '../../global/store'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tooltip,
} from '@material-ui/core'
import currencies from '../../global/currencies'
import { CoreContext } from '../../../../core/context/CoreContext'
import TranslationLabel from '../../../../core/components/TranslationLabel'

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',

    '& > *': {
      margin: theme.spacing(1),
      marginBottom: 20,
    },
  },
  field: {
    width: 'calc(100% - 16px)',
  },
  fieldHalf: {
    width: '50%',
  },
  spacing: {
    margin: '10px 25px 20px',
  },
}))

const defaultLanguageCodeToCurrency = {
  'en-GB': 'GBP',
  'da-DK': 'DKK',
  'sv-SE': 'SEK',
  'nb-NO': 'NOK',
  'de-DE': 'EUR',
}

function TemplateColumnDialog(props) {
  const core = useContext(CoreContext)
  const { state, dispatch } = useStore()
  const classes = useStyles()
  const [outputFieldFound, setOutputFieldFound] = useState(false)
  const [data, setData] = useState(() => {
    switch (props.type) {
      case 'delete':
        return props.data
      case 'update':
        return props.data
      default:
        return {
          blankIfZero: true,
          columnNo: getNextColumnNo(state.selectedTemplate.templateFields),
          currencyCode:
            defaultLanguageCodeToCurrency[
              state.selectedTemplate.defaultLanguageCode
            ] || 'DKK',
          dataFrom: '',
          headerText: '',
          languageCode: state.selectedTemplate.defaultLanguageCode,
          maxFieldLength: 0,
          noDecimalSeperator: false,
          noThousandSeperator: false,
          templateId: props.data.templateId,
        }
    }
  })

  const templateField = state.templateFieldData.find(
    (el) => el.name === data.dataFrom,
  )
  const currencyCodeRequired = templateField?.currencyCodeRequired

  useEffect(() => {
    if (state.languages.length === 0) {
      core.dataStore.getLanguages().then((res) => {
        dispatch({ type: 'SET_LANGUAGES', payload: res.data })
      })
    }
    core.masterItemDataStore.readTemplateFieldData().then((res) => {
      const isAdmin =
        core.user.userSetting.userTypeId === 1 || //Admin
        core.user.userSetting.userTypeId === 2 || // TDG internal
        core.user.userSetting.userTypeId === 5 || // sales person
        core.user.userSetting.userTypeId === 7 // Merchandiser
      if (!isAdmin) {
        res.data = res.data.filter((el) => !el.internal)
      }
      dispatch({
        type: 'SET_TEMPLATE_FIELD_DATA',
        payload: res.data.sort((a, b) =>
          a.displayName > b.displayName ? 1 : -1,
        ),
      })
      window.res = res.data
    })
  }, [])

  const handleCurrencyChange = (e) => {
    setData({ ...data, currencyCode: e.target.value })
  }

  const handleLanguageChange = (e) => {
    setData({ ...data, languageCode: e.target.value })
  }

  const handleInputFieldChange = (e) => {
    let val = e.target.value
    setData({ ...data, dataFrom: val })
  }

  const handleTextFieldChange = (e, name) => {
    if (name == 'headerText' && e.target.value.length > 0) {
      if (!outputFieldExists(e.target.value, data.columnNo)) {
        setData({ ...data, [name]: e.target.value })
      }
    } else {
      setData({ ...data, [name]: e.target.value })
    }
  }

  const toggleCheckedDecimalSeperator = () => {
    let newValue = !data.noDecimalSeperator
    setData({ ...data, noDecimalSeperator: newValue })
  }

  const toggleCheckedThousandSeperator = () => {
    let newValue = !data.noThousandSeperator
    setData({ ...data, noThousandSeperator: newValue })
  }

  const toggleBlankIfZero = () => {
    let newValue = !data.blankIfZero
    setData({ ...data, blankIfZero: newValue })
  }

  const outputFieldExists = (val) => {
    console.log(
      state.selectedTemplate.templateFields.filter(
        (el) => el.headerText.toLowerCase() == val.toLowerCase(),
      ).length,
    )
    if (
      state.selectedTemplate.templateFields.filter(
        (el) =>
          el.headerText.toLowerCase() == val.toLowerCase() && el.id != data.id,
      ).length == 0
    ) {
      setOutputFieldFound(false)
      console.log('outputFieldFound: ' + outputFieldFound)
      return false
    } else {
      setOutputFieldFound(true)
      console.log('outputFieldFound: ' + outputFieldFound)
      return true
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let form = e.currentTarget
    const isValid = form.reportValidity()

    if (isValid && !labelIsTaken()) {
      if (props.type === 'delete') {
        core.masterItemDataStore
          .deleteTemplateField(data)
          .then((r) => props.onClose('template'))
      } else if (props.type === 'update') {
        core.masterItemDataStore
          .updateTemplateField(data)
          .then((r) => props.onClose('template'))
      } else {
        console.log(data)
        core.masterItemDataStore
          .addTemplateField(data)
          .then((r) => props.onClose('template'))
      }
    }
  }

  const labelIsTaken = () =>
    props.type == 'create' || props.type == 'update' ? outputFieldFound : false //state.selectedTemplate.templateFields.map(el => el.headerText).includes(data.headerText)

  return (
    <form
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit}>
      <DialogContent className={classes.root}>
        <TextField
          id='columnNo'
          inputProps={{
            min: '1',
          }}
          label={<TranslationLabel name='columnNo' />}
          defaultValue={data.columnNo}
          variant='outlined'
          disabled={props.type === 'delete'}
          onChange={(e) => handleTextFieldChange(e, 'columnNo')}
          type='number'
          required
          className={classes.field}
        />

        <FormControl
          variant='outlined'
          className={classes.fieldHalf}
          required>
          <InputLabel id='dataFrom-label'>
            <TranslationLabel name='inputField' />
          </InputLabel>
          <Select
            required
            labelId='dataFrom-label'
            id='dataFrom'
            value={data.dataFrom}
            disabled={props.type === 'delete'}
            onChange={handleInputFieldChange}>
            <MenuItem value={undefined}>
              <em>
                <TranslationLabel name='none' />
              </em>
            </MenuItem>
            {state.templateFieldData.map((fieldData) => {
              return (
                <MenuItem
                  key={`fd-${fieldData.name}`}
                  value={fieldData.name}>
                  <Tooltip title={fieldData.description}>
                    <div style={{ width: '100%' }}>{fieldData.displayName}</div>
                  </Tooltip>
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <TextField
          id='outputField'
          label={<TranslationLabel name='outputField' />}
          defaultValue={data.headerText}
          variant='outlined'
          disabled={props.type === 'delete'}
          onChange={(e) => handleTextFieldChange(e, 'headerText')}
          error={labelIsTaken()}
          helperText={
            labelIsTaken() ? <TranslationLabel name='outputNameTaken' /> : ''
          }
          className={classes.field}
          required
        />

        <TextField
          id='maxLength'
          label={<TranslationLabel name='maxLength' />}
          defaultValue={data.maxFieldLength}
          variant='outlined'
          type='number'
          disabled={props.type === 'delete'}
          onChange={(e) => handleTextFieldChange(e, 'maxFieldLength')}
          className={classes.field}
        />

        <FormControlLabel
          className={classes.field}
          control={
            <Switch
              disabled={props.type === 'delete'}
              color='primary'
              checked={!data.noDecimalSeperator}
              onChange={toggleCheckedDecimalSeperator}
            />
          }
          label={<TranslationLabel name='decimalSeperator' />}
        />

        <FormControlLabel
          className={classes.field}
          control={
            <Switch
              disabled={props.type === 'delete'}
              color='primary'
              checked={!data.noThousandSeperator}
              onChange={toggleCheckedThousandSeperator}
            />
          }
          label={<TranslationLabel name='thousandSeperator' />}
        />

        <FormControlLabel
          className={classes.field}
          control={
            <Switch
              disabled={props.type === 'delete'}
              color='primary'
              checked={data.blankIfZero}
              onChange={toggleBlankIfZero}
            />
          }
          label={<TranslationLabel name='blankIfZero' />}
        />

        <FormControl
          variant='outlined'
          className={classes.fieldHalf}>
          <InputLabel id='langSelectorLabel'>
            <TranslationLabel name='language' />
          </InputLabel>
          <Select
            labelId='langSelectorLabel'
            id='langSelector'
            value={data.languageCode}
            disabled={props.type === 'delete'}
            onChange={handleLanguageChange}>
            {state.languages.map((lang) => {
              return (
                <MenuItem
                  key={`cc-${lang.code}`}
                  value={lang.code}>
                  {lang.title}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        {currencyCodeRequired && (
          <FormControl
            variant='outlined'
            className={classes.fieldHalf}
            required>
            <InputLabel id='customer-select-outlined-label'>
              <TranslationLabel name='currencyCode' />
            </InputLabel>
            <Select
              labelId='customer-select-outlined-label'
              id='currencySelector'
              value={data.currencyCode}
              disabled={props.type === 'delete'}
              onChange={handleCurrencyChange}>
              {currencies.map((currency) => {
                return (
                  <MenuItem
                    key={`cc-${currency}`}
                    value={currency}>
                    {currency}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}
      </DialogContent>

      <DialogActions className={classes.spacing}>
        <Button
          color='default'
          variant='contained'
          disableElevation
          onClick={() => props.onClose()}>
          <TranslationLabel name='cancel' />
        </Button>
        <Button
          type='submit'
          color='primary'
          variant='contained'
          disabled={labelIsTaken()}
          disableElevation>
          <TranslationLabel name={props.type} />
        </Button>
      </DialogActions>
    </form>
  )
}

function getNextColumnNo(fields) {
  var r = Math.max(...fields.map((el) => el.columnNo)) + 1
  if (r == '-Infinity') {
    r = 1
  }
  return r
}

export default TemplateColumnDialog
