import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import HomeIcon from '@material-ui/icons/Home'
import { useHistory } from 'react-router-dom'
import TranslationLabel from '../../../core/components/TranslationLabel'

import { CoreContext } from '../../context/CoreContext'
import GlobalAppNavigationButton from '../GlobalAppNavigationButton'
import UserBadge from '../UserBadge'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    boxShadow: 'none',
  },
  toolbar: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.appBar.background,
    color: theme.palette.appBar.color,
    boxShadow: 'none',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sectionDesktop: {
    display: 'flex',
  },
  menuButton: {
    marginLeft: 0,
    marginRight: 10,

    [theme.breakpoints.up('sm')]: {
      marginRight: 30,
    },
  },
  title: {
    display: 'none',
    verticalAlign: 'middle',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
    },
  },
  blocked: {
    verticalAlign: 'middle',
    color: 'red',
    display: 'inline-block',
    marginLeft: 5,
  },
  env: {
    paddingLeft: 5,
    fontSize: 20,
    fontWeight: 600,
    color: 'red',
    width: 'fit-content',
  },
}))

function Topbar(props) {
  const classes = useStyles()
  const core = useContext(CoreContext)
  const matches = useMediaQuery(core.theme.breakpoints.up('sm'))
  const history = useHistory()

  const customerInfo = `${core.user.selectedCustomer.accountNo} - ${core.user.selectedCustomer.accountName} (${core.user.selectedCustomer.currency})`
  const customerHeadline = core.user.selectedCustomer.accountNo ? (
    customerInfo
  ) : (
    <TranslationLabel name='noCustomerSelected' />
  )

  const environments = {
    dev: 'Development',
    test: 'Test',
    prod: '',
    staging: 'Staging',
  }

  return (
    <div className={classes.root}>
      <AppBar
        position='fixed'
        className={classNames(classes.appBar, {
          [classes.appBarShift]: core.isExpanded && matches,
        })}>
        <Toolbar className={classes.toolbar}>
          {!core.isExpanded && (
            <IconButton
              className={classes.menuButton}
              color='inherit'
              aria-label='Open drawer'
              onClick={() => core.expandDrawer(!core.isExpanded)}>
              <MenuIcon />
            </IconButton>
          )}
          <div className={classes.env}>
            {environments[process.env.REACT_APP_API]}
          </div>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Typography
              className={classes.title}
              variant='h6'
              color='inherit'
              noWrap>
              {customerHeadline}
            </Typography>
            {core.user.selectedCustomer.blocked && (
              <Typography
                className={classes.blocked}
                variant='h6'
                noWrap>
                <TranslationLabel name='accountLocked' />
              </Typography>
            )}
          </div>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {/* Other - basket etc. */}
            {props.children}

            {/* App list */}
            {core.getUserApps().length > 2 && <GlobalAppNavigationButton />}
          </div>

          {/* Profile menu */}
          <IconButton>
            <HomeIcon
              onClick={() =>
                history.push('/' + window.location.pathname.split('/')[1])
              }
            />
          </IconButton>
          <UserBadge />
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Topbar
