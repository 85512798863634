import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
} from '@material-ui/core'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import TranslationLabel from './../../../components/TranslationLabel'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: 360,
    backgroundColor: theme.palette.background.paper,
    borderBottom: '1px solid #ddd',
    paddingBottom: 0,
  },
  select: {
    width: 300,
    marginBottom: 30,
  },
  nested: {
    marginBottom: 20,
  },
  th: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: '.8em',
    textTransform: 'uppercase',
    color: '#777',
  },
  expandable: {
    backgroundColor: '#F9F9F9',
    borderTop: '1px solid #ddd',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  icon: {
    margin: '6px 0 0 8px',
  },
  input: {
    width: 250,
  },
  cell: {
    paddingTop: 13,
    paddingBottom: 8,
  },
  tcell: {
    height: 62,
    width: 360,
    paddingTop: 13,
    paddingBottom: 8,
  },
  p: {
    display: 'block',
    margin: '10px 0 30px',
  },
  h1: {
    fontSize: 30,
    marginTop: 30,
  },
  collapseAllWrapper: {
    float: 'right',
  },
}))

function LanguageManager(props) {
  const { core } = props
  const [data, setData] = useState(null)
  const classes = useStyles()
  const [open, setOpen] = useState([])
  const [lang, setLang] = useState(1)
  const [languages, setLanguages] = useState([])
  const [openLabelValues, setOpenLabelValues] = useState([])
  const [openDefaultValues, setOpenDefaultValues] = useState([])
  const [loading, setLoading] = useState(false)

  const isAdmin = core.user.userSetting.userTypeId === 1

  // Set expandable panels state
  const toggleOpen = (id) => {
    let idx = open.indexOf(id)
    let arr = [...open]
    if (idx !== -1) {
      arr.splice(idx, 1)
    } else {
      arr.push(id)
    }
    setOpen(arr)
  }

  const openAll = () => {
    setOpen(Object.keys(data))
  }

  const closeAll = () => {
    setOpen([])
  }

  // Set active rows state
  const toggleActivateField = (id) => {
    let idx = openLabelValues.indexOf(id)
    let arr = [...openLabelValues]
    if (idx !== -1) {
      arr.splice(idx, 1)
    } else {
      arr.push(id)
    }
    setOpenLabelValues(arr)
  }

  const toggleOpenDefaultValue = (id) => {
    if (!isAdmin) {
      //only allow admins to change default values
      return
    }
    if (openDefaultValues.includes(id)) {
      setOpenDefaultValues(openDefaultValues.filter((el) => el !== id))
    } else {
      setOpenDefaultValues([...openDefaultValues, id])
    }
  }

  const handleLanguageChange = (e) => {
    setLang(e.target.value)
    setOpenLabelValues([])
    setOpenDefaultValues([])
  }

  const handleBlur = (e, item) => {
    if (e.target.value === getTranslatedLabel(item)?.value) {
      toggleActivateField(item.label.id)
    }
  }

  const handleKeyUpValue = (e, item) => {
    if (e.key === 'Enter') {
      saveLabelValue(item, e.target.value)
    } else if (e.key === 'Escape') {
      toggleActivateField(item.label.id)
    }
  }

  const handleKeyUpDefaultValue = (e, item) => {
    if (e.key === 'Enter') {
      saveLabelDefaultValue(item, e.target.value)
    } else if (e.key === 'Escape') {
      toggleOpenDefaultValue(item.label.id)
    }
  }

  const handleSaveValueClick = (item) => {
    let val = document.getElementById(`labelValue${item.label.id}`).value
    saveLabelValue(item, val)
  }

  const handleSaveDefaultValueClick = (item) => {
    let val = document.getElementById(`labelDefaultValue${item.label.id}`).value
    saveLabelDefaultValue(item, val)
  }

  const saveLabelDefaultValue = (item, value) => {
    const newItem = { ...item }
    newItem.label.defaultValue = value
    updateLabel(item.label)
    toggleOpenDefaultValue(item.label.id)
  }

  const saveLabelValue = (item, value) => {
    let label = getTranslatedLabel(item)
    if (label && label.value) {
      label.value = value
    } else {
      item.translations.push({
        languageId: lang,
        value: value,
      })
    }

    let postBody = { ...item.label }
    postBody.translations = item.translations
    updateLabel(postBody)
    toggleActivateField(item.label.id)
  }

  const updateLabel = (item) => {
    console.log('updating item', item)
    core.dataStore
      .updateLabel(item)
      .then(() => {
        core.snackbar('success', 'Label was updated')
      })
      .catch(() => {})
  }

  const getTranslatedLabel = (item) => {
    return item.translations.find((tl) => tl.languageId === lang)
  }

  useEffect(() => {
    if (loading) {
      return
    }
    setLoading(true)

    core.dataStore.getLabels().then((response) => {
      // Group by labelGroup
      // returns object
      let groups = response.data.reduce((r, a) => {
        r[a.label.labelGroup] = [...(r[a.label.labelGroup] || []), a]
        return r
      }, {})
      setData(groups)
    })
    core.dataStore.getLanguages().then((response) => {
      setLanguages(response.data)
    })
  }, [core.dataStore, setData, setLanguages, setLoading, loading])

  return (
    <div>
      <Typography
        variant='h4'
        component='h1'
        className={classes.h1}>
        <TranslationLabel name='translationManager' />
      </Typography>
      <Typography className={classes.p}>
        <TranslationLabel name='languageManagerSubtitle' />
      </Typography>
      <FormControl
        variant='outlined'
        className={classes.select}>
        <InputLabel id='langSelectorLabel'>
          <TranslationLabel name='language' />
        </InputLabel>
        <Select
          labelId='langSelectorLabel'
          id='langSelector'
          value={lang}
          onChange={handleLanguageChange}>
          {languages.map((language) => {
            return (
              <MenuItem
                key={language.code}
                value={language.id}>
                {language.title}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <div className={classes.collapseAllWrapper}>
        <Button onClick={openAll}>Open All</Button>
        <Button onClick={closeAll}>Collapse All</Button>
      </div>
      <List
        component='nav'
        aria-labelledby='nested-list-subheader'
        className={classes.root}>
        {data !== null &&
          Object.entries(data).map((entry) => {
            return (
              <div key={`g-${entry[0]}`}>
                <ListItem
                  button
                  className={classes.expandable}
                  onClick={() => toggleOpen(entry[0])}>
                  <ListItemText primary={entry[0]} />
                  {open.indexOf(entry[0]) !== -1 ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>

                <Collapse
                  in={open.indexOf(entry[0]) !== -1}
                  timeout='auto'
                  unmountOnExit>
                  <List
                    component='div'
                    disablePadding>
                    <ListItem className={classes.nested}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.th}>Key</TableCell>
                            <TableCell className={classes.th}>
                              Default
                            </TableCell>
                            <TableCell className={classes.th}>
                              Translation
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {entry[1] &&
                            entry[1].map((item) => (
                              <TableRow key={item.label.id}>
                                <TableCell className={classes.cell}>
                                  {item.label.key}
                                </TableCell>
                                {/* <TableCell className={classes.cell}>{item.label.defaultValue}</TableCell> */}
                                <TableCell className={classes.tcell}>
                                  {openDefaultValues.indexOf(item.label.id) ===
                                  -1 ? (
                                    <span
                                      className={isAdmin && classes.link}
                                      onClick={() =>
                                        toggleOpenDefaultValue(item.label.id)
                                      }>
                                      {item.label.defaultValue || 'Not defined'}
                                    </span>
                                  ) : (
                                    <div>
                                      <TextField
                                        className={classes.input}
                                        id={`labelDefaultValue${item.label.id}`}
                                        label='New label value'
                                        variant='outlined'
                                        size='small'
                                        defaultValue={
                                          item.label.defaultValue || ''
                                        }
                                        onKeyUp={(e) =>
                                          handleKeyUpDefaultValue(e, item)
                                        }
                                        autoFocus
                                      />
                                      <IconButton
                                        tabIndex='-1'
                                        aria-label='Add'
                                        size='small'
                                        color='primary'
                                        className={classes.icon}
                                        onClick={(e) =>
                                          handleSaveDefaultValueClick(item)
                                        }>
                                        <CheckCircleRoundedIcon />
                                      </IconButton>
                                      <IconButton
                                        tabIndex='-1'
                                        aria-label='Cancel'
                                        size='small'
                                        color='secondary'
                                        className={classes.icon}
                                        onClick={() =>
                                          toggleOpenDefaultValue(item.label.id)
                                        }>
                                        <CancelRoundedIcon />
                                      </IconButton>
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell className={classes.tcell}>
                                  {openLabelValues.indexOf(item.label.id) ===
                                  -1 ? (
                                    <span
                                      className={classes.link}
                                      onClick={() =>
                                        toggleActivateField(item.label.id)
                                      }
                                      onFocus={() =>
                                        toggleActivateField(item.label.id)
                                      }>
                                      {getTranslatedLabel(item)?.value ||
                                        'Not defined'}
                                    </span>
                                  ) : (
                                    <div>
                                      <TextField
                                        className={classes.input}
                                        id={`labelValue${item.label.id}`}
                                        label='New label value'
                                        variant='outlined'
                                        size='small'
                                        defaultValue={
                                          getTranslatedLabel(item)?.value || ''
                                        }
                                        onKeyUp={(e) =>
                                          handleKeyUpValue(e, item)
                                        }
                                        autoFocus
                                        onBlur={(e) => handleBlur(e, item)}
                                      />
                                      <IconButton
                                        tabIndex='-1'
                                        aria-label='Add'
                                        size='small'
                                        color='primary'
                                        className={classes.icon}
                                        onClick={(e) =>
                                          handleSaveValueClick(item)
                                        }>
                                        <CheckCircleRoundedIcon />
                                      </IconButton>
                                      <IconButton
                                        tabIndex='-1'
                                        aria-label='Cancel'
                                        size='small'
                                        color='secondary'
                                        className={classes.icon}
                                        onClick={() =>
                                          toggleActivateField(item.label.id)
                                        }>
                                        <CancelRoundedIcon />
                                      </IconButton>
                                    </div>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </ListItem>
                  </List>
                </Collapse>
              </div>
            )
          })}
      </List>
    </div>
  )
}

export default LanguageManager
