import React, { createContext } from 'react'
import { useCategoryModel } from '../pages/Products/CategoryModel'

export const CategoryContext = createContext(null)

export default function CategoryProvider({ children }) {
  const category = useCategoryModel()

  return (
    <CategoryContext.Provider value={category}>
      {children}
    </CategoryContext.Provider>
  )
}
