import React, { createContext } from 'react'
import { useReturnServiceModel } from '../pages/ReturnService/ReturnServiceModel'

export const ReturnServiceContext = createContext(null)

export default function ReturnServiceProvider({ children }) {
  const returnService = useReturnServiceModel()

  return (
    <ReturnServiceContext.Provider value={returnService}>
      {children}
    </ReturnServiceContext.Provider>
  )
}
