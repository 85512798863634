import React, { useState, useEffect } from 'react'
import { Switch, Grid, FormControlLabel, Button } from '@material-ui/core'
import TranslationLabel from '../../../components/TranslationLabel'
import FormSection from '../../../components/forms/FormSection'
import FormHeader from '../../../components/forms/FormHeader'

export default function MasterDataTab(props) {
  const { isEditMode, setUserForm, core, userForm, updateUser } = props
  const [facets, setFacets] = useState([])

  useEffect(() => {
    core.dataStore.getMasterDataFacets().then((res) => {
      setFacets(res.data)
    })
  }, [])

  const handleToggleFacet = (facetId) => {
    if (isEditMode) {
      if (userForm.masterDataFacets.includes(facetId)) {
        core.dataStore
          .removeFacetFromUser(userForm.userId, facetId)
          .then((res) => {
            setUserForm({
              ...userForm,
              masterDataFacets: userForm.masterDataFacets.filter(
                (el) => el !== facetId,
              ),
            })
          })
      } else {
        core.dataStore.addFacetToUser(userForm.userId, facetId).then((res) => {
          setUserForm({
            ...userForm,
            masterDataFacets: [...userForm.masterDataFacets, facetId],
          })
        })
      }
    } else {
      if (userForm.masterDataFacets.includes(facetId)) {
        setUserForm({
          ...userForm,
          masterDataFacets: userForm.masterDataFacets.filter(
            (el) => el !== facetId,
          ),
        })
      } else {
        setUserForm({
          ...userForm,
          masterDataFacets: [...userForm.masterDataFacets, facetId],
        })
      }
    }
  }

  const handleToggleTemplateManagement = () => {
    updateUser({
      ...userForm,
      templateManagement: !userForm.templateManagement,
    })
  }

  const selectAllFacets = () => {
    if (isEditMode) {
      //add a facet to user for each facet missing
      const disabledFacets = facets
        .map((el) => el.id)
        .filter((el) => !userForm.masterDataFacets.includes(el))
      disabledFacets.forEach((el) => {
        core.dataStore.addFacetToUser(userForm.userId, el).catch(() => {
          core.setModal({
            headline: 'Something went wrong',
            message: `Couldn't add facet with id ${el}`,
          })
        })
      })
    }
    setUserForm({ ...userForm, masterDataFacets: facets.map((el) => el.id) })
  }

  const deselectAllFacets = () => {
    if (isEditMode) {
      //remove a facet on user for every facet enabled
      const enabledFacets = userForm.masterDataFacets
      enabledFacets.forEach((el) => {
        core.dataStore.removeFacetFromUser(userForm.userId, el).catch(() => {
          core.setModal({
            headline: 'Something went wrong',
            message: `Couldn't remove facet with id ${el}`,
          })
        })
      })
    }
    setUserForm({ ...userForm, masterDataFacets: [] })
  }

  return (
    <Grid
      item
      xs={12}
      sm={12}>
      <FormSection>
        <FormHeader title={<TranslationLabel name='masterDataFacets' />} />
        <Button onClick={selectAllFacets}>
          <TranslationLabel name='selectAllFacets' />
        </Button>
        <Button onClick={deselectAllFacets}>
          <TranslationLabel name='deselectAllFacets' />
        </Button>
        <hr />
        {facets.map((facet) => (
          <FormControlLabel
            key={facet.id}
            control={
              <Switch
                color='primary'
                checked={userForm.masterDataFacets.includes(facet.id)}
                onChange={() => handleToggleFacet(facet.id)}
                inputProps={{
                  'aria-label': 'secondary checkbox',
                }}
              />
            }
            label={<TranslationLabel name={facet.facetName} />}
          />
        ))}
      </FormSection>
      <FormSection>
        <FormHeader
          title={<TranslationLabel name='masterDataUserSettings' />}
        />
        <FormControlLabel
          control={
            <Switch
              color='primary'
              checked={userForm.templateManagement}
              onChange={handleToggleTemplateManagement}
              inputProps={{
                'aria-label': 'secondary checkbox',
              }}
            />
          }
          label={<TranslationLabel name='masterDataTemplateManagement' />}
        />
      </FormSection>
    </Grid>
  )
}
