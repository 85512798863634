import axios from 'axios'
import ApiUrls from './ApiUrls'

// All requests uses request- and response interceptors for handling access token and refresh token
const addAxiosInterceptors = (axiosInstance) => {
  // Add access token to all requests
  axiosInstance.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`
      }
      return config
    },
    (error) => {
      console.log('rejected in request')
      Promise.reject(error)
    },
  )

  // Catch responses with expired errors and renew access token using refresh token
  axiosInstance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      let isExpired = isExpiredAccesTokenError(error)

      if (isExpired) {
        const originalRequest = error.config
        let refreshToken = localStorage.getItem('refreshToken')
        let accessToken = localStorage.getItem('accessToken')

        if (refreshToken && accessToken && !originalRequest._retry) {
          originalRequest._retry = true
          return axios
            .post(`${ApiUrls.hubUrl}/auth/refreshtoken`, {
              accessToken: accessToken,
              refreshToken: refreshToken,
            })
            .then((response) => {
              if (response.status === 200) {
                localStorage.setItem(
                  'accessToken',
                  response.data.accessToken.token,
                )
                localStorage.setItem('refreshToken', response.data.refreshToken)
                console.info('Access Token refreshed!')
                return axios(originalRequest)
              }
            })
            .catch((error) => {
              // Ignore access token errors here
              let isExpiredError = isExpiredAccesTokenError(error)
              if (!isExpiredError) {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('refreshToken')
                localStorage.removeItem('userId')
                window.location = '/login'
              }
              return error
            })
        }
      }
      return Promise.reject(error)
    },
  )
}

function isExpiredAccesTokenError(error) {
  return error.response?.status === 440 && error.response.data?.tokenExpired
}

export default function HttpClientHandler(base, masterData) {
  let httpClient = axios.create({
    baseURL: base,
    headers: masterData
      ? {
          'Content-Type': 'application/json',
          'X-Api-Key': '8ec1c52d-2d10-4ab3-83a1-fbdd7dc6e221',
        }
      : {
          'Content-Type': 'application/json',
        },
  })
  if (!masterData) {
    addAxiosInterceptors(httpClient)
  }

  return {
    httpClient,
  }
}
