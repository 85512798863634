import { useContext } from 'react'
import { CoreContext } from '../../../core/context/CoreContext'
import routeSettings from './routes'

const GetNavItems = () => {
  const core = useContext(CoreContext)
  const { templateManagement } = core.user.userSetting

  const navItem = (id, index, labelKey) => {
    return {
      id: id,
      route: routeSettings.getRoute(id),
      index: index,
      labelKey: labelKey,
    }
  }

  const upperNavigation = [
    navItem('Search', 1, 'Search & Export'),
    navItem('Templates', 2, 'Templates'),
  ]

  if (templateManagement) {
    upperNavigation.push(navItem('DataFields', 3, 'DataFields'))
  }

  upperNavigation.push(navItem('Help', 1, 'help'))

  return {
    upperNavigation: upperNavigation
  }
}
export default GetNavItems
