import { createMuiTheme } from '@material-ui/core'

export default createMuiTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#747474',
    },
    edit: {
      main: '#ffffff',
    },
    background: {
      default: '#747474',
      secondary: '#747474',
    },
    paper: {
      default: '#747474',
    },
  },
})
