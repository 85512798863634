import { Button, TextField, Typography, makeStyles } from '@material-ui/core'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { CoreContext } from '../context/CoreContext'
import TranslationLabel, { translationLink } from './TranslationLabel'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Trash from '@material-ui/icons/Delete'
import HeightIcon from '@material-ui/icons/Height';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    }
  }
}))


const defaultNewLink = {
  translationKey: '',
  isBreakerHeadline: false,
  sortOrder: 1
}
export default function HelpPage(props) {
  const [helpLinks, setHelpLinks] = useState([]);
  const [editLinks, setEditLinks] = useState(false);
  const [newLink, SetNewLink] = useState(defaultNewLink);

  const classes = useStyles()
  const core = useContext(CoreContext)
  const { appId } = props
  const label = 'helpPageLabel' + appId

  useEffect(() => {
    core.dataStore.GetHelpLinks(appId)
      .then(res => setHelpLinks(res.data));

      console.log(helpLinks)
  }, [appId])

  const createNewLink = () => {
    var newLinkClone = {... newLink};

    //If length of helpllinks are 0, set sortOrder to 1 else last item sort order + 1
    newLinkClone.sortOrder = helpLinks.length === 0 ? 1 : helpLinks[helpLinks.length - 1].sortOrder + 1;

    core.dataStore.NewHelpLinks(appId, newLinkClone)
      .then(() => {
        SetNewLink(defaultNewLink);

        core.dataStore.GetHelpLinks(appId)
          .then(res => setHelpLinks(res.data));
      })
  }

  const changeNewLinkBreakHeadline = e => {
    SetNewLink({... newLink, isBreakerHeadline: e.target.checked })
  }

  const changeNewLinkTranslationKey = e => {
    SetNewLink({... newLink, translationKey: e.target.value})
  }

  const deleteLink = link => {
    if(window.confirm("Slet: " + link.translationKey)) {
      core.dataStore.DelHelpLinks(appId, link.id)
        .then(() => {
          core.dataStore.GetHelpLinks(appId)
            .then(res => setHelpLinks(res.data));
        })
    }
  }

  const saveLink = (link) => {
    core.dataStore.UpdateHelpLinks(appId, {... link})
      .then(() => {
        core.dataStore.GetHelpLinks(appId)
          .then(res => setHelpLinks(res.data));
      })
  }

  const dragLink = useRef(null);
  const dragOverLink = useRef(null);

  const handleSort = () => {
    const items = [... helpLinks];
    const draggedItem = items[dragLink.current];
    const remainingItems = items.filter((_, idx) => idx !== dragLink.current);

    const beforeItems = remainingItems.slice(0, dragOverLink.current);
    const afterItems = remainingItems.slice(dragOverLink.current);

    const newOrder = [...beforeItems, draggedItem, ...afterItems];
    newOrder.forEach((item, idx) => {
      item.sortOrder = idx + 1;
    });

    setHelpLinks(newOrder);

    newOrder.forEach((item) => {
      core.dataStore.UpdateHelpLinks(appId, item);
    });

    dragLink.current = null;
    dragOverLink.current = null;
  };

  const EditLinkComponent = ({innerHtml, linkObj, index}) => {
    return (
      <div draggable style={{
        borderTop: '1px solid #333',
        borderBottom: '1px solid #333',
        display: 'flex',
        alignItems: 'center',
        padding: '5px 0',
        marginTop: (linkObj.isBreakerHeadline ? '30px' : '0px'),
        cursor: 'move'}}
        onDragStart={() => (dragLink.current = index)}
        onDragEnter={() => (dragOverLink.current = index)}
        onDragOver={(e) => e.preventDefault()}
        onDragEnd={handleSort}>
        <HeightIcon />
        {innerHtml}

        <TextField
          onKeyDown={e => {
            if (e.keyCode === 13) {
              //for the enter key
              saveLink(linkObj);
            }
          }}
          style={{marginLeft: 'auto', width: '50px'}}
          variant='outlined'
          size='small'
          defaultValue={linkObj.sortOrder}
          onChange={e => linkObj.sortOrder = e.target.value}
        />

        <Button
          onClick={() => deleteLink(linkObj)}
          className={classes.buttons}
          color='primary'>
          <Trash style={{fill: 'red'}} />
        </Button>
      </div>
    )
  }

  return (
    <>
      <div className={classes.pageHeader}>
        <div style={{marginRight: 'auto'}}>
        <Typography variant='h3' component='h1'>
          <TranslationLabel name={'helpPageTitle-app' + appId} />
        </Typography>
        </div>
        <div className={classes.pageHeader}>
          {core.user.userSetting.helpLinkManagement && (<Button
            onClick={() => setEditLinks(!editLinks)}
            className={classes.buttons}
            variant='contained'
            color='primary'>
            {editLinks && ("stop")} <TranslationLabel name='helpLinkEditLinks' />
          </Button>
          )}
        </div>
      </div>

      {/* EDIT FORM */}
      {editLinks && (
        <div style={{
          marginTop: '25px',
          background: '#eee',
          padding: '20px'
        }}>
          <div>
            <TextField
              fullWidth
              id='filled-margin-dense'
              label={<TranslationLabel name='helpLinkTranslationKey' />}
              variant='outlined'
              onChange={changeNewLinkTranslationKey}
              value={newLink.translationKey}
              className={classes.inputField}
              />
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '15px'}}>
              <div>
              <FormControlLabel
                  control={
                    <Switch
                      color='primary'
                      checked={newLink.isBreakerHeadline}
                      onChange={changeNewLinkBreakHeadline}
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                  }
                  label={<TranslationLabel name='helpLinkIsHeadLine' />}
                />
              </div>
              <div>
                <Button
                  onClick={createNewLink}
                  className={classes.buttons}
                  variant='contained'
                  color='primary'>
                  {<TranslationLabel name='helpLinkCreateNewLink' />}
                </Button>
              </div>
            </div>
        </div>
      )}

      {/* HELPTLINK IN EDIT MODE */}
      {editLinks && (
        <>
          {helpLinks.map((helpLink, index) => {
            return (
              <>
                {helpLink.isBreakerHeadline && (
                  <EditLinkComponent
                    key={helpLink.id}
                    linkObj={helpLink}
                    index={index}
                    innerHtml={
                      <Typography variant='h5'>
                        <TranslationLabel name={helpLink.translationKey} />
                      </Typography>
                    } />
                )}

                {!helpLink.isBreakerHeadline && (
                  <EditLinkComponent
                    key={helpLink.id}
                    linkObj={helpLink}
                    index={index}
                    innerHtml={
                      <a target='_blank' rel='noopener noreferrer' href={translationLink(core.dictionary, helpLink.translationKey)}>
                        <Typography
                          style={{ color: 'blue', textDecoration: 'underline' }}
                          variant='subtitle1'
                          component='h1'>
                          <TranslationLabel
                            type='link'
                            name={helpLink.translationKey}
                          />
                        </Typography>
                      </a>
                    } />
                )}
              </>
            )
          })}
        </>
      )}

      {/* HELPLINK IN NON EDIT MODE */}
      {!editLinks && (
        <>
          {helpLinks.map(helpLink => {
            return (
              <>
                {helpLink.isBreakerHeadline && (
                  <Typography variant='h5' style={{marginTop: '20px'}}>
                    <TranslationLabel name={helpLink.translationKey} />
                  </Typography>
                )}

                {!helpLink.isBreakerHeadline && (
                  <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={translationLink(core.dictionary, helpLink.translationKey)}>
                  <Typography
                    style={{ color: 'blue', textDecoration: 'underline' }}
                    variant='subtitle1'
                    component='h1'>
                    <TranslationLabel
                      type='link'
                      name={helpLink.translationKey}
                    />
                  </Typography>
                </a>
                )}
              </>
            )
          })}
        </>
      )}
    </>
  )
}
