import React, { useState, useEffect, useContext } from 'react'
import { CoreContext } from '../../../context/CoreContext'
import DatePicker from '../../../../shared/DatePicker'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  LinearProgress,
} from '@material-ui/core'
import TranslationLabel from '../../../components/TranslationLabel'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import FormSection from '../../../components/forms/FormSection'
import FormHeader from '../../../components/forms/FormHeader'

export default function SalesAppSettings(props) {
  const core = useContext(CoreContext)
  const [dates, setDates] = useState([])
  const [loading, setLoading] = useState(false)
  const today = getTimeMinusOffset(new Date())
  const [newDate, setNewDate] = useState({
    id: 'newDate',
    introductionDate: today,
    itemAvailableB2BShop: today,
    pictureAvailableB2BShop: today,
    newsEndDateB2BShop: today,
  })

  const reloadIntroDates = () => {
    setLoading(true)
    core.dataStore.getIntroDates().then((res) => {
      setDates(res.data.reverse())
    })
    setLoading(false)
  }

  useEffect(() => {
    reloadIntroDates()
  }, [])

  function getTimeMinusOffset(date) {
    return (
      new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toJSON()
        .split('T')[0] + 'T00:00:00'
    )
  }

  const onDateChange = (date, id, dateType) => {
    setLoading(true)
    const dateString = getTimeMinusOffset(date)
    const dateObj = { ...dates.find((el) => el.id === id) }
    dateObj[dateType] = dateString
    core.dataStore
      .editIntroDates(dateObj)
      .then(() => {
        reloadIntroDates()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const onNewDateChange = (date, dateType) => {
    const dateString = getTimeMinusOffset(date)
    const dateObj = { ...newDate }
    dateObj[dateType] = dateString
    setNewDate(dateObj)
  }

  const deleteDates = (id) => {
    setLoading(true)
    core.dataStore.deleteIntroDates(id).then(() => {
      reloadIntroDates()
    })
  }

  const addIntroDate = () => {
    setLoading(true)
    core.dataStore
      .createIntroDates({ ...newDate, id: undefined })
      .then(() => {
        reloadIntroDates()
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        core.setModal({
          headline: <TranslationLabel name='introDateError' />,
          message: <TranslationLabel name='introDateMessage' />,
        })
      })
  }

  const DateCell = (props) => {
    const { dateLine, name, isNewDate } = props
    return (
      <TableCell>
        <DatePicker
          onChange={(date) => {
            isNewDate
              ? onNewDateChange(date, name)
              : onDateChange(date, dateLine.id, name)
          }}
          value={dateLine[name]}
        />
      </TableCell>
    )
  }

  return (
    <>
      <FormSection>
        <FormHeader title={<TranslationLabel name='introDatesHeadline' />} />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TableContainer
            style={{ maxWidth: 1000 }}
            component={Paper}>
            {loading ? <LinearProgress /> : <div style={{ height: 5 }} />}
            <Table aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TranslationLabel name='introductionDate' />
                  </TableCell>
                  <TableCell align='right'>
                    <TranslationLabel name='itemAvailableB2BShop' />
                  </TableCell>
                  <TableCell align='right'>
                    <TranslationLabel name='pictureAvailableB2BShop' />
                  </TableCell>
                  <TableCell align='right'>
                    <TranslationLabel name='newsEndDateB2BShop' />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dates.map((dateLine) => (
                  <TableRow key={dateLine.id}>
                    <DateCell
                      dateLine={dateLine}
                      name='introductionDate'
                    />
                    <DateCell
                      dateLine={dateLine}
                      name='itemAvailableB2BShop'
                    />
                    <DateCell
                      dateLine={dateLine}
                      name='pictureAvailableB2BShop'
                    />
                    <DateCell
                      dateLine={dateLine}
                      name='newsEndDateB2BShop'
                    />

                    <TableCell align='right'>
                      <DeleteForeverIcon
                        color='secondary'
                        style={{ cursor: 'pointer' }}
                        onClick={() => deleteDates(dateLine.id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
                {/* The row for new dates */}
                <TableRow key={newDate.id}>
                  <TableCell>
                    <TranslationLabel
                      style={{ fontSize: 16 }}
                      name='addDate'
                    />
                  </TableCell>
                </TableRow>
                <TableRow style={{ borderTop: '2px solid blue' }}>
                  <DateCell
                    isNewDate
                    dateLine={newDate}
                    name='introductionDate'
                  />
                  <DateCell
                    isNewDate
                    dateLine={newDate}
                    name='itemAvailableB2BShop'
                  />
                  <DateCell
                    isNewDate
                    dateLine={newDate}
                    name='pictureAvailableB2BShop'
                  />
                  <DateCell
                    isNewDate
                    dateLine={newDate}
                    name='newsEndDateB2BShop'
                  />

                  <TableCell align='right'>
                    <AddCircleOutlineIcon
                      color='primary'
                      style={{ cursor: 'pointer' }}
                      onClick={() => addIntroDate()}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </MuiPickersUtilsProvider>
      </FormSection>
    </>
  )
}
