import React, { createContext } from 'react'
import { useInvoiceModel } from '../model/InvoicesModel'

export const InvoiceContext = createContext(null)

export default function InvoiceProvider({ children }) {
  const model = useInvoiceModel()

  return (
    <InvoiceContext.Provider value={model}>{children}</InvoiceContext.Provider>
  )
}
