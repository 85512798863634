import React from 'react'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'
import withStyles from '@material-ui/core/styles/withStyles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Paper from '@material-ui/core/Paper'
import TranslationLabel from '../../../core/components/TranslationLabel'

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    boxShadow: 'none',
  },
  table: {
    fontFamily: theme.typography.fontFamily,
    minWidth: 700,
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: 'initial',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  outlined: {
    boxShadow: 'none',
    padding: theme.spacing(0),
  },
  topBorder: {
    borderTop: '1px solid #000',
    borderRadius: 0,
  },
})

function ResponsiveTable(props) {
  const {
    classes,
    headers,
    rows,
    selected,
    isOutlined,
    topBorder,
    select,
    stickyHeader,
  } = props

  const handleClick = (_, id) => {
    if (!select) return

    let newSelected = id
    if (newSelected === selected) newSelected = -1
    select(newSelected)
  }

  function extractCell(cell) {
    return cell ? (cell.isString ? cell.toString() : cell) : null
  }

  return (
    <Paper
      className={`${classes.root} ${isOutlined ? classes.outlined : ''} ${
        topBorder ? classes.topBorder : ''
      }`}>
      <Table
        stickyHeader={stickyHeader}
        className={classes.table}>
        <TableHead>
          <TableRow>
            {headers.map((h, k) => (
              <TableCell
                key={k}
                align='left'>
                <TranslationLabel name={h} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {
          <TableBody>
            {rows.map((r, k) => {
              return (
                <TableRow
                  hover
                  key={k}
                  onClick={(event) => handleClick(event, r[0])}
                  selected={selected === r[0]}>
                  {r.map((c, k) => (
                    <TableCell
                      key={k}
                      align='left'>
                      {extractCell(c)}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
        }
      </Table>
    </Paper>
  )
}

export default withStyles(styles)(ResponsiveTable)
