import React from 'react'
import Content from './components/Content'
import Navigation from './components/Navigation'

export default function ImageBankAppRoot() {
  return (
    <>
      <Navigation />
      <Content />
    </>
  )
}
