import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Chip } from '@material-ui/core'
import TranslationLabel from '../../../core/components/TranslationLabel'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '20px 0 10px',
  },
  chip: {
    margin: theme.spacing(1),
  },
}))

function SelectedChips(props) {
  const classes = useStyles()
  const [selectedChipsCount, setSelectedChipsCount] = useState(0)

  const handleChipRemove = (type, facetKey, item) => {
    let _selectedFacets
    if (type === 'all') {
      _selectedFacets = {}
    } else {
      _selectedFacets = Object.assign({}, props.selectedFacets)
      let _list = _selectedFacets[facetKey].filter(function (value) {
        return value !== item
      })
      _selectedFacets[facetKey] = _list
    }
    props.onChipRemove(_selectedFacets)
  }

  useEffect(() => {
    if (!props.selectedFacets) {
      return
    }
    let count = 0
    for (let value of Object.values(props.selectedFacets)) {
      count += value.length
    }
    setSelectedChipsCount(count)
  }, [props.selectedFacets])

  return props.selectedFacets ? (
    <div className={classes.root}>
      {Object.keys(props.selectedFacets).map((facetKey, i) => (
        <React.Fragment key={'fk' + i}>
          {props.selectedFacets[facetKey].length > 0 && (
            <React.Fragment>
              <span>
                <TranslationLabel name={facetKey} />:
              </span>
              {props.selectedFacets[facetKey].map((item, t) => (
                <Chip
                  label={item}
                  onDelete={() => handleChipRemove('single', facetKey, item)}
                  className={classes.chip}
                  key={'ch' + t}
                />
              ))}
            </React.Fragment>
          )}
        </React.Fragment>
      ))}
      {selectedChipsCount > 0 && (
        <Chip
          label='Remove all'
          onDelete={() => handleChipRemove('all')}
          className={classes.chip}
          color='primary'
        />
      )}
    </div>
  ) : null
}

export default SelectedChips
