import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import ShippingIcon from '@material-ui/icons/LocalShipping'
import { blue } from '@material-ui/core/colors'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import { CoreContext } from '../../../../core/context/CoreContext'
import { CircularProgress } from '@material-ui/core'
import EmptyState from '../../components/EmptyState'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import PageTitle from '../../components/PageTitle'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  loader: {
    textAlign: 'center',
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  packingSlipSection: {
    padding: '0 25px',
    marginBottom: 10,
  },
}))

export default function InvoiceTracker(props) {
  const classes = useStyles()
  const { onClose, open, salesId } = props
  const [packingSlips, setPackingSlips] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [loadText, setLoadText] = useState('')
  const core = useContext(CoreContext)

  const handleClose = () => {
    onClose(salesId)
  }

  function handleClick(item) {
    if (!item.url) alert('No url was found.')
    else window.open(item.url, '_blank')
  }

  function load() {
    setIsLoading(true)
    return core.dataStore
      .getTrackingBySalesId(salesId)
      .then((res) => {
        if (res.status === 200) {
          let packingSlips = {}
          const trackingObjects = res.data
          trackingObjects.forEach((obj) => {
            if (packingSlips[obj.packingSlipNo]) {
              packingSlips[obj.packingSlipNo].push({
                trackingNumber: obj.trackingNumber,
                url: obj.url,
              })
            } else {
              packingSlips[obj.packingSlipNo] = [
                { trackingNumber: obj.trackingNumber, url: obj.url },
              ]
            }
          })
          setPackingSlips(packingSlips)
        } else {
          core.snackbar('warning', res.statusText)
          return null
        }
      })
      .catch((e) => {
        core.snackbar('warning', e.toString())
        setLoadText(e.toString())
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (open) load()
  }, [salesId, open])

  const TrackingNumbers = ({ list }) => {
    return (
      <div style={{ width: '100%', maxWidth: '400px', minWidth: '200px' }}>
        <List>
          <div style={{ textAlign: 'center', opacity: '0.5' }}>
            <Typography variant='subtitle1'>
              {list.length + ' '}
              <TranslationLabel name='results' />
            </Typography>
          </div>
          {list.map((t, i) => (
            <ListItem
              button
              onClick={() => handleClick(t)}
              key={t}>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <ShippingIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={t.trackingNumber}
                secondary={
                  t.url ? '' : <TranslationLabel name='noTrackingUrl' />
                }
              />
            </ListItem>
          ))}
        </List>
      </div>
    )
  }

  const RenderPackingSlips = () => {
    const slips = []
    for (const packingSlip in packingSlips) {
      slips.push(
        <div className={classes.packingSlipSection}>
          <Typography variant={'h5'}>
            <TranslationLabel name='packingSlipNo' />
            {packingSlip}
          </Typography>
          <TrackingNumbers list={packingSlips[packingSlip]} />
        </div>,
      )
    }
    if (isLoading) {
      return (
        <div className={classes.loader}>
          <CircularProgress style={{ marginBottom: '20px' }} />
          <TranslationLabel name='trackingLoading' />
        </div>
      )
    }

    return slips.length ? (
      slips
    ) : (
      <div style={{ textAlign: 'center' }}>
        <EmptyState
          main='invoiceTrackingMain'
          sub='invoiceTrackingSub'
        />
        {loadText}
      </div>
    )
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}>
      <DialogTitle
        id='simple-dialog-title'
        style={{ textAlign: 'center' }}>
        <PageTitle
          label='trackAndTrace'
          core={core}
        />
        <Typography variant='subtitle1'>
          <TranslationLabel name='deliveryNoteNumber' />
          {' ' + salesId}
        </Typography>
      </DialogTitle>

      <DialogContent
        dividers
        className={classes.content}>
        <RenderPackingSlips />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          color='primary'>
          <TranslationLabel name='closeDialog' />
        </Button>
      </DialogActions>
    </Dialog>
  )
}
