import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  makeStyles,
  Checkbox,
  TablePagination,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid/Grid'
import { FloatingActionButton } from '../FloatingActionButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import FormSection from '../../../components/forms/FormSection'
import TranslationLabel from '../../../components/TranslationLabel'
import { useDebounce } from 'use-lodash-debounce'
import EditIcon from '@material-ui/icons/Edit'
const useStyles = makeStyles((theme) => {})

const apps = ['app2', 'app6', 'app7', 'app9', 'app12']
const appNumbers = [2, 6, 7, 9, 12]
const administration = [
  'allUserManagement',
  'languageManagement',
  'settingsAdministration'
]
const editableAdministration = ['showBackordersOnProduct']
const editableAdministrationLabels = ['showBackOrdersOnProduct'] // seperate one for labels because of a bug with TranslationLabels casing

const headers = [
  'ID',
  'User Name',
  'Selected Customer',
  'Selected Customer Name',
  ...apps,
  ...administration,
  ...editableAdministrationLabels,
  'Archive',
  'Activated',
  'newsLetter',
]
const dataKeys = [
  { value: 'userId', type: 'string' },
  { value: 'userName', type: 'string' },
  { value: 'selectedCustomerAccount', type: 'string' },
  { value: 'selectedCustomerName', type: 'string' },
  //apps are unlike the rest of the values, since they are numbers in an array
  ...appNumbers.map((el) => ({
    value: el,
    type: 'appcheckbox',
    editable: true,
  })),
  ...administration.map((el) => ({
    value: el,
    type: 'checkbox',
    editable: false,
  })),
  ...editableAdministration.map((el) => ({
    value: el,
    type: 'checkbox',
    editable: true,
  })),
  { value: 'showArchiveCheckbox', type: 'checkbox', editable: true},
  { value: 'isEnabled', type: 'checkbox', editable: true },
  { value: 'newsLetter', type: 'checkbox', editable: true },
]

export default function OverviewTab(props) {
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [showActivated, setShowActivated] = useState(1) //whether to search in activated users, 0 = all, 1 = activated = not activated
  const [userType, setUserType] = useState(0)
  const [input, setInput] = useState('')
  const [totalUsers, setTotalUsers] = useState(100)
  const [userTypes, setUserTypes] = useState([])
  const activatedEnum = [
    { value: 0, label: 'All' },
    { value: 1, label: 'Activated' },
    { value: 2, label: 'Inactive' },
  ]
  const debouncedInput = useDebounce(input, 300)
  const [firstRender, setFirstRender] = useState(true)

  const { core, select } = props
  const createPath = '/hub/admin/users/create'
  const editPath = '/hub/admin/users/edit'
  const location = useLocation()

  function create() {
    core.history.push(createPath)
  }

  function edit(id) {
    select(id)

    var urlParams = new URLSearchParams(location.search)
    urlParams.append('pageNumber', page + 1)
    urlParams.append('scrollTo', parseInt(window.scrollY))

    core.history.replace({
      pathname: location.pathname,
      search: '?' + urlParams.toString(),
      state: { isActive: true },
    })

    core.history.push(editPath)
  }

  useEffect(() => {
    core.dataStore.getUserTypes().then((res) => {
      setUserTypes([{ userTypeId: 0, type: 'All' }, ...res.data])
    })
  }, [])

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search)
    if (firstRender) {
      setFirstRender(false)
      if (searchParams.has('input')) {
        setInput(searchParams.get('input'))
      }

      if (searchParams.has('userType')) {
        setUserType(parseInt(searchParams.get('userType')))
      }

      if (searchParams.has('showActivated')) {
        setShowActivated(parseInt(searchParams.get('showActivated')))
      }

      if (searchParams.has('rowsPerPage')) {
        setRowsPerPage(parseInt(searchParams.get('rowsPerPage')))
      }

      if (searchParams.has('pageNumber')) {
        setPage(parseInt(searchParams.get('pageNumber')) - 1)
      }
    }

    search(false, searchParams.has('scrollTo') ? parseInt(searchParams.get('scrollTo')) : 1)
  }, [debouncedInput, userType, showActivated, rowsPerPage, page])

  const toggleCheckBox = (user, setting) => {
    setLoading(true)
    if (typeof setting == 'string') {
      const updatedUserObject = { ...user, [setting]: !user[setting] }
      core.hubStore.updateUser(updatedUserObject).then((res) => {
        setRows(
          rows.map((el) =>
            el.userId === user.userId ? updatedUserObject : el,
          ),
        )
        setLoading(false)
      })
    } else {
      //if setting is a number it is the number of an app, so remove if already in apps array, and add if it isn't

      const newApps = user.apps.find((el) => el == setting)
        ? user.apps.filter((el) => el != setting)
        : user.apps.concat(setting)
      core.hubStore.updateUser({ ...user, apps: newApps }).then((res) => {
        setRows(
          rows.map((el) =>
            el.userId === user.userId ? { ...user, apps: newApps } : el,
          ),
        )
        setLoading(false)
      })
    }
  }

  const search = (firstPage = false, scrollTo = 0) => {
    setLoading(true)
    core.hubStore
      .searchUsers({
        text: input,
        userType,
        showActivated,
        pageSize: rowsPerPage,
        pageNumber: firstPage ? 1 : page + 1,
      })
      .then((res) => {
        if (res.data) {
          setLoading(false)
          setRows(res.data.users)
          setTotalUsers(res.data.totalUsers)
          var urlParams = new URLSearchParams()

          if (input !== '') {
            urlParams.append('input', input)
          }

          if (userType !== 0) {
            urlParams.append('userType', userType)
          }

          if (showActivated !== 1) {
            urlParams.append('showActivated', showActivated)
          }

          if (rowsPerPage !== 10) {
            urlParams.append('rowsPerPage', rowsPerPage)
          }

          if (page !== 0) {
            urlParams.append('pageNumber', page + 1)
          }

          var searchQuery = '?' + urlParams.toString()
          if (searchQuery !== '?') {
            core.history.replace({
              pathname: location.pathname,
              search: searchQuery,
              state: { isActive: true },
            })
          } else {
            core.history.replace({
              pathname: location.pathname,
              search: '',
              state: { isActive: true },
            })
          }

          if (scrollTo !== 0) {
            window.scrollTo(0, scrollTo)
          }
        }
      })
      .catch(() => {})
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const MakeRow = ({user, i}) => {
    const [active, setActive] = useState(false)

    return (
      <>
        <TableCell
          style={{ cursor: 'pointer', color: active ? 'blue' : 'black' }}>
          <EditIcon onClick={() => setActive(!active)} />
        </TableCell>
        {dataKeys.map((key) => (
          <>
            {key.type === 'string' && (
                <TableCell
                  style={{ cursor: 'pointer' }}
                  onClick={() => edit(user.userId)}
                  key={key.value + i}>
                  {user[key.value]}
                </TableCell>
            )}
            {key.type === 'checkbox' && (
                <TableCell key={key.value + i}>
                  <Checkbox
                    disabled={!key.editable || !active}
                    color='primary'
                    checked={user[key.value]}
                    onClick={() => toggleCheckBox(user, key.value)}
                  />
                </TableCell>
            )}
            {key.type === 'appcheckbox' && (
                <TableCell key={key.value + i}>
                  <Checkbox
                    disabled={!key.editable || !active}
                    color='primary'
                    onClick={() => toggleCheckBox(user, key.value)}
                    checked={!!user.apps.find((el) => el == key.value)}
                  />
                </TableCell>
            )}
          </>
        ))}
      </>
    )
  }

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        sm={12}>
        <FormSection>
          <TextField
            onChange={(e) => {
              setInput(e.target.value)
            }}
            value={input}
            fullWidth
            label={<TranslationLabel name='searchUsers' />}
            variant='outlined'
            autoComplete={'off'}
            style={{ margin: '10px 0px 10px 0px' }}
          />
          <FormControl
            variant='outlined'
            style={{ width: 150, marginRight: 10 }}
            size='small'>
            <InputLabel id='statusLabel'>
              <TranslationLabel name='activeStatus'></TranslationLabel>
            </InputLabel>
            <Select
              id='statusLabel'
              InputLabel={
                <TranslationLabel name='activeStatus'></TranslationLabel>
              }
              value={showActivated}
              onChange={(e) => setShowActivated(e.target.value)}
              variant='outlined'>
              {activatedEnum.map((item) => (
                <MenuItem key={item} value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            variant='outlined'
            style={{ width: 150, marginRight: 10 }}
            size='small'>
            <InputLabel id='userTypeLabel'>
              <TranslationLabel name='userType'></TranslationLabel>
            </InputLabel>
            <Select
              id='userTypeLabel'
              InputLabel={<TranslationLabel name='userType'></TranslationLabel>}
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
              variant='outlined'>
              {userTypes.map((item) => (
                <MenuItem key={item} value={item.userTypeId}>{item.type}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormSection>
      </Grid>

      {loading ? <LinearProgress /> : <div style={{ height: 4 }}></div>}

      <Paper>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {headers.map((header, i) => (
                <TableCell key={header + i}>
                  <TranslationLabel name={header} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {
            <TableBody>
              {rows.map((row, i) => {
                return (
                  <TableRow
                    hover
                    key={i}>
                    <MakeRow user={row} i={i} />
                  </TableRow>
                )
              })}
            </TableBody>
          }
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={totalUsers}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FloatingActionButton
        onClick={create}
        variant='add'
      />
    </React.Fragment>
  )
}
