import ImageBankLink from '../pages/ImageBankLink'
import HelpPage from '../../../core/components/HelpPage'
const root = '/imagebank-app'

const routes = [
  {
    id: 'link',
    path: `${root}/`,
    component: ImageBankLink,
    exact: true,
  },
  {
    id: 'Help',
    path: `${root}/help`,
    component: HelpPage,
    exact: true,
  },
]

export default {
  getRoute: id => routes.find((i) => i.id === id),
  root: root,
  routes: routes
}
