import BasketPage from '../pages/Basket/BasketPage'
import ProductsPageV2 from '../pages/Products/ProductsPageV2'
import DetailedProductView from '../pages/Products/DetailedProductView'
import DetailedInvoiceView from '../pages/Invoices/DetailedInvoiceView'
import InvoicesPage from '../pages/Invoices/InvoicesPage'
import OrdersPage from '../pages/Orders/OrdersPage'
import RestOrdersView from '../pages/Orders/RestOrdersView'
import DetailedOrderView from '../pages/Orders/DetailedOrderView'
import DetailedRMAOrderView from '../pages/Orders/DetailedRMAOrderView'
import BasketOverview from '../../../core/pages/BasketOverview/BasketOverview'
import QuickEntryPage from '../pages/QuickEntry/QuickEntryPage'
import DashboardPage from '../pages/Dashboard/DashboardPage'
import HomePage from '../pages/Home/HomePage'
import StatisticsPage from '../pages/Statistics/StatisticsPage'
import ReturnServicePageForm from '../pages/ReturnService/ReturnServicePageForm'
import ReturnServiceCheckoutPage from '../pages/ReturnServiceCheckout/ReturnServiceCheckoutPage'
import SearchPage from '../pages/SearchPage/SearchPage'
import HelpPage from '../../../core/components/HelpPage'

const root = '/sales'

const routes = [
  {
    id: 'Basket',
    path: root + '/basket',
    component: BasketPage,
    accessLevel: 0,
    exact: true,
  },
  {
    id: 'BasketReceipt',
    path: root + '/basket/receipt',
    component: BasketPage,
    accessLevel: 0,
    exact: true,
  },
  {
    id: 'BasketConfirmation',
    path: root + '/basket/confirmation',
    component: BasketPage,
    accessLevel: 0,
  },
  {
    id: 'Products',
    path: root + '/products',
    component: ProductsPageV2,
    accessLevel: 0,
  },
  {
    id: 'Search',
    path: root + '/search',
    component: SearchPage,
  },
  {
    id: 'QuickEntry',
    path: root + '/quickEntry',
    component: QuickEntryPage,
  },
  {
    id: 'DetailedProductView',
    path: root + '/products/item/:id',
    component: DetailedProductView,
  },
  {
    id: 'Orders',
    path: root + '/orders',
    component: OrdersPage,
  },
  {
    id: 'DetailedOrderView',
    path: root + '/orders/:id/:onlyRestOrders',
    component: DetailedOrderView,
  },
  {
    id: 'RestOrders',
    path: root + '/restOrders',
    component: RestOrdersView,
  },
  {
    id: 'DetailedRMAOrderView',
    path: root + '/rmaOrders/:id/:onlyRestOrders',
    component: DetailedRMAOrderView,
  },
  {
    id: 'Invoices',
    path: root + '/invoices',
    component: InvoicesPage,
  },
  {
    id: 'DetailedInvoiceView',
    path: root + '/invoices/:id',
    component: DetailedInvoiceView,
  },
  {
    id: 'BasketOverview',
    path: root + '/basketOverview',
    component: BasketOverview,
  },
  {
    id: 'DashboardPage',
    path: root + '/dashboard',
    component: DashboardPage,
  },
  {
    id: 'HomePage',
    path: root + '/home',
    component: HomePage,
  },
  {
    id: 'Statistics',
    path: root + '/statistics',
    component: StatisticsPage,
  },
  {
    id: 'ReturnService',
    path: root + '/returnService',
    component: ReturnServicePageForm,
  },
  {
    id: 'ReturnServiceCheckout',
    path: root + '/returnService/checkout',
    component: ReturnServiceCheckoutPage,
    accessLevel: 0,
    exact: true,
  },
  {
    id: 'ReturnServiceCheckoutReceipt',
    path: root + '/returnService/checkout/receipt/*',
    component: ReturnServiceCheckoutPage,
    accessLevel: 0,
    exact: true,
  },
  {
    id: 'ReturnServiceCheckoutConfirmation',
    path: root + '/returnService/checkout/confirmation',
    component: ReturnServiceCheckoutPage,
    accessLevel: 0,
  },
  {
    id: 'Help',
    path: root + '/help',
    component: HelpPage,
    exact: true,
  },
]

export default {
  getRoute: id => routes.find((i) => i.id === id),
  root: root,
  routes: routes
}
