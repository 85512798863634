import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  stockItem: {
    fontSize: 10,
    textTransform: 'uppercase',
    color: '#666',
    marginTop: 6,

    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',
    },
  },
  stockItemLink: {
    'textDecoration': 'underline',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  dot: {
    width: 6,
    height: 6,
    backgroundColor: 'green',
    display: 'inline-block',
    borderRadius: 3,
    margin: '0 5px',
    transform: 'translateY(-1px)',
  },
  warning: {
    backgroundColor: 'orange',
  },
  danger: {
    backgroundColor: 'red',
  },
}))

export default function StockItem(props) {
  const classes = useStyles()
  const { text, status = 1 } = props

  const dotClasses = {
    1: classes.dot,
    2: `${classes.dot} ${classes.warning}`,
    3: `${classes.dot} ${classes.danger}`,
  }

  return (
    <div className={classes.stockItem}>
      <span className={dotClasses[status]}></span>
      <span>{text}</span>
    </div>
  )
}
