import React, { useState } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TranslationLabel from '../../../core/components/TranslationLabel'

const useStyles = makeStyles((theme) => ({
  article: {
    margin: '24px 12px 0 12px',
    height: 360,
  },
  wrapper: {
    background: 'white',
    border: '1px solid #dadce0',
    borderRadius: 8,
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  root: {
    width: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',
    height: '100%',
  },
  title: {
    borderBottom: '1px solid #dadce0',
    padding: '12px',
    height: 55,
  },
  content: {
    height: '100%',
  },
  button: {
    margin: 0,
    padding: '12px',
    color: theme.palette.primary.main,
  },
  dialog: {},
}))

export default function DashboardWidgetContainer(props) {
  const { content, title, expanded, height, icon, hideTitle, expandContent } =
    props
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <article
      className={classes.article}
      style={{ height: height ? height : 360 }}>
      <div className={classes.wrapper}>
        <div className={classes.root}>
          {!hideTitle ? (
            <div className={classes.title}>
              <Typography variant='h6'>{title}</Typography>
            </div>
          ) : null}

          <div className={classes.content}>{content}</div>
        </div>

        <div className={classes.expand}>
          <DashboardDialogView
            open={open}
            handleClose={handleClose}
            title={title}
            content={content}
            hideTitle={hideTitle}
            expandContent={expandContent}
          />
        </div>
      </div>
    </article>
  )
}

export function DashboardDialogView(props) {
  const { open, handleClose, title, content, hideTitle, expandContent } = props
  const classes = useStyles()
  return (
    <div>
      <Dialog
        onClose={handleClose}
        className={classes.dialog}
        aria-labelledby='dashboard-dialog'
        open={open}>
        {!hideTitle ? (
          <DialogTitle
            id='dashboard-dialog'
            onClose={handleClose}>
            {title}
          </DialogTitle>
        ) : null}
        <DialogContent dividers>{content}</DialogContent>
        {expandContent ? (
          <DialogContent dividers>{expandContent}</DialogContent>
        ) : null}
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            color='primary'>
            <TranslationLabel name='close' />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
