import React, { useContext, useEffect, useState } from 'react'
import { useDebounce } from 'use-lodash-debounce'

import TranslationLabel, { translationLink } from '../../../../core/components/TranslationLabel'
import { BasketContext } from '../../../../core/context/BasketContext'
import { ProductsContext } from '../../context/ProductsContext'
import EmptyState from '../../components/EmptyState'
import PageTitle from '../../components/PageTitle'

import { LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import TableView from '../../components/TableView'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'
import { CoreContext } from '../../../../core/context/CoreContext'
import findNextAllowedQuantity from '../../libraries/FindNextAllowedQuantity'
import OpenOrdersModal from '../Products/OpenOrdersModal'
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle'
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton'
import Fab from '@material-ui/core/Fab/Fab'

const useStyles = makeStyles((theme) => ({
  submitButton: {
    margin: '10px 0',
    width: 'fit-content',
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    }
  }
}))

export default function QuickEntryPage(props) {
  const classes = useStyles()
  const [entries, setEntries] = useState([])
  const core = useContext(CoreContext)
  const basket = useContext(BasketContext)
  const productsModel = useContext(ProductsContext)
  const [openOrdersModal, setOpenOrdersModal] = useState(undefined)

  const [value, setValue] = useState('')
  const debouncedValue = useDebounce(value, 300)
  const [srcTxt, setSrcTxt] = useState('')
  const [uploadLoading, setUploadLoading] = useState(false)
  const [uploadErrors, setUploadErrors] = useState([])

  function handleInput(evt) {
    const v = evt.target.value
    setValue(v)
  }

  function handleQtyChange(itemId, qty) {
    if (parseInt(qty) < 0) {
      qty = 0
    } else {
      let rs = entries.find((x) => x.productNo === itemId)

      if (!rs) return

      rs.qty = qty
      const rest = entries.filter((x) => x.productNo !== itemId)
      let tmp = [...rest, rs]
      tmp.sort((a, b) =>
        a.productNo > b.productNo ? 1 : b.productNo > a.productNo ? -1 : 0,
      )
      setEntries(tmp)
    }
  }

  function add(item) {
    let temp
    if (entries.find((x) => x.productNo === item.productNo)) {
      temp = entries
      temp.find((x) => x.productNo === item.productNo).qty =
        parseInt(temp.find((x) => x.productNo === item.productNo).qty || 0) +
        item.colli
    } else {
      temp = [{ ...item, qty: item.colli, quantity: item.colli }, ...entries]
    }

    setEntries(temp)
    setValue('')
    setSrcTxt('')
  }

  function remove(itemId) {
    console.log(itemId)
    console.log(entries)
    if (entries.find((x) => x.productNo === itemId)) {
      const rest = entries.filter((x) => x.productNo !== itemId)
      setEntries(rest)
    }
  }

  const handleSubmit = () => {
    const entriesWithAllowedQuantities = entries.map((entry) => {
      return { ...entry, qty: findNextAllowedQuantity(entry, entry.quantity) }
    })

    basket.multiAdd(entriesWithAllowedQuantities, () => {
      setEntries([])
      setUploadErrors([])
    })
  }

  useEffect(() => {
    function search(itemId) {
      setSrcTxt('')

      if (!itemId || itemId === '' || itemId.length < 3) return

      productsModel.getProductById(itemId).then((product) => {
        if (product) {
          setSrcTxt('')
          add(product)
        } else {
          setSrcTxt('No items found.')
        }
      })
    }

    search(debouncedValue)
  }, [debouncedValue])


  function UploadOrder(e) {
    if (!e.target.files[0]) {
      return
    }

    setUploadLoading(true)
    setEntries([])
    setUploadErrors([])

    core.dataStore.getProductByExcel(
      core.user.selectedCustomer.accountNo,
      core.user.languageCode,
      core.user.userId,
      e
    ).then(res => {
      setTimeout(() => {
        const items = res.data.items.map(item => {
          return {
            ...item,
            quantity: item.qty
          }
        })

        setEntries([...items])
        setValue('')
        setUploadLoading(false)

        if (res.data.withErrors) {
          setUploadErrors([...res.data.errors])
        }
      }, 500);
    })

    e.target.value = ''
  }


  const openOrdersProduct = entries.find(
    (el) => el && el.productNo == openOrdersModal,
  )

  return (
    <div className={classes.root}>
      <OpenOrdersModal
        openOrdersProduct={openOrdersProduct}
        setOpenOrdersModal={setOpenOrdersModal}
      />
      <div className={classes.pageHeader}>
        <div style={{marginRight: 'auto'}}>
          <PageTitle
            label='quickEntry'
            core={core}
          />

          <Typography variant='subtitle1'>
            {entries.length + ' '}
            <TranslationLabel name='products' />
          </Typography>
        </div>
        <div className={classes.pageHeader}>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={translationLink(core.dictionary, "Order-Excel-download")}>
            <Typography
              style={{ color: 'blue', textDecoration: 'underline', marginRight: '15px', whiteSpace: 'nowrap', marginBottom: '10px', }}
              variant='subtitle1'
              component='span'>
              <TranslationLabel
                type='link'
                name={"Order-Excel-download"}
              />
            </Typography>
          </a>

          <a
            target='_blank'
            rel='noopener noreferrer'
            href={translationLink(core.dictionary, "Order-Help")}>
            <Typography
              style={{ color: 'blue', textDecoration: 'underline',  marginRight: '15px', whiteSpace: 'nowrap', marginBottom: '10px', }}
              variant='subtitle1'
              component='span'>
              <TranslationLabel
                type='link'
                name={"Order-Help"}
              />
            </Typography>
          </a>

          <input
            className={classes.input}
            style={{ display: 'none' }}
            id='excel-upload'
            multiple
            type='file'
            onChange={UploadOrder}
          />

          <label htmlFor='excel-upload'>
            <Button
              variant='contained'
              color='primary'
              style={{ marginRight: '15px', marginBottom: '10px', whiteSpace: 'nowrap' }}
              component='span'>
                <span style={{ visibility: uploadLoading ? 'hidden' : 'visible' }} ><TranslationLabel name='fromOrderExcel' /></span>
                <CircularProgress style={{color: "#fff", position: 'absolute', left: 'calc(50% - 10px)', top: "calc(50% - 10px)", width: '20px', height: '20px', display: uploadLoading ? 'inline' : 'none' }} />
            </Button>
          </label>

          <Button
            variant='contained'
            color='primary'
            component='span'
            style={{whiteSpace: 'nowrap', marginBottom: '10px',}}
            onClick={handleSubmit}>
            <TranslationLabel name="quickEntryAddAllLinesToBasket" />
          </Button>
        </div>
      </div>

      { uploadErrors.length > 0 && (
        <Alert severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setUploadErrors([]);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle><TranslationLabel name="Order-Excel-Error-Headline" /></AlertTitle>
          <Typography variant='p'><TranslationLabel name="Order-Excel-Error-SubHeadline" /></Typography>
          <ul style={{padding: '0'}}>
            {uploadErrors.map((item) => {
              return (
                <li><TranslationLabel name="Order-Excel-Error-line" /> { item.line } <TranslationLabel name="Order-Excel-Error-Ean" /> {item.query} <TranslationLabel name="Order-Excel-Error-Not-Found" /></li>
              )
            })}
          </ul>
        </Alert>
      )}
      <TextField
        fullWidth
        label={<TranslationLabel name='searchItemNumber' />}
        id='filled-margin-dense'
        variant='outlined'
        inputProps={{
          type: 'number',
          pattern: '[0-9]*',
        }}
        value={value}
        onChange={handleInput}
        autoComplete={'off'}
        style={{ margin: '10px 0px 10px 0px' }}
      />
      {productsModel.isLoading ? (
        <LinearProgress style={{ margin: '-10px 0px 10px 0px' }} />
      ) : null}
      {srcTxt}
      <>
        {entries.length > 0 ? (
          <>
            <TableView
              hideHeader={true}
              products={entries}
              remove={remove}
            />

            <div style={{ textAlign: 'right', width: '100%', height: '50px' }}>
            </div>
          </>
        ) : (
          <EmptyState
            main='quickEntryEmptyStateMainTitle'
            sub='quickEntryEmptyStateSubTitle'
          />
        )}
      </>
    </div>
  )
}
