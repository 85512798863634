import React, { useState, useContext, useEffect } from 'react'
import { useStore } from '../../global/store'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  DialogActions,
  DialogContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import { CoreContext } from '../../../../core/context/CoreContext'
import TranslationLabel from '../../../../core/components/TranslationLabel'

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',

    '& > *': {
      margin: theme.spacing(1),
      marginBottom: 20,
    },
  },
  field: {
    width: 'calc(100% - 16px)',
  },
  fieldHalf: {
    width: '50%',
  },
  spacing: {
    margin: '10px 25px 20px',
  },
}))

function TemplateDialog(props) {
  const core = useContext(CoreContext)
  const { state, dispatch } = useStore()
  const templateOptions = [
    {
      id: '',
      customerId: '',
      name: 'None',
      templateFields: [],
    },
    ...state.templates,
  ]
  const classes = useStyles()
  const [data, setData] = useState(() => {
    switch (props.type) {
      case 'delete':
        return props.data
      case 'update':
        return props.data
      case 'create':
        return {
          name: '',
          customerId: core.user.selectedCustomer.accountNo,
          defaultLanguageCode: 'da-DK',
        }
      default:
        return {
          ...props.data,
          defaultLanguageCode: props.data?.fromTemplate?.defaultLanguageCode,
        }
    }
  })

  useEffect(() => {
    if (state.languages.length === 0) {
      core.dataStore.getLanguages().then((res) => {
        dispatch({ type: 'SET_LANGUAGES', payload: res.data })
      })
    }
  }, [])

  const handleChange = (e) => {
    setData({ ...data, name: e.target.value })
  }

  const reloadTemplatesAndSelectById = (id) => {
    core.masterItemDataStore
      .getCustomerTemplates(core.user.selectedCustomer.accountNo)
      .then((res) => {
        dispatch({ type: 'SET_TEMPLATES', payload: res.data })
        let selectedTemplate = res.data.find((el) => el.id === id)
        console.log('setting selected template to ', selectedTemplate)
        dispatch({
          type: 'SET_TEMPLATE',
          payload: selectedTemplate || res.data[0],
        })
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let form = e.currentTarget
    const isValid = form.reportValidity()
    if (isValid) {
      switch (props.type) {
        case 'create':
          core.masterItemDataStore
            .addTemplate({
              ...data,
              customerNo: core.user.selectedCustomer.accountNo,
            })
            .then((res) => {
              props.close()
              reloadTemplatesAndSelectById(res.data.id)
            })
          break
        case 'copy':
          if (data.fromTemplate.id) {
            core.masterItemDataStore
              .copyTemplate({
                ...data,
                fromTemplate: data.fromTemplate.id,
                customerNo: core.user.selectedCustomer.accountNo,
              })
              .then((res) => {
                dispatch({ type: 'SET_TEMPLATE', payload: res.data })
                props.close()
                reloadTemplatesAndSelectById(res.data.id)
              })
          } else {
          }
          break
        case 'delete':
          core.masterItemDataStore
            .deleteTemplate(data)
            .then((r) => props.onClose('template'))
          break
        default:
          core.masterItemDataStore
            .editTemplate(data)
            .then((r) => props.onClose('template'))
      }
    }
  }

  return (
    <form
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit}>
      <DialogContent className={classes.root}>
        <TextField
          id='templateName'
          label={<TranslationLabel name='templateName' />}
          variant='outlined'
          defaultValue={data.name}
          disabled={props.type === 'delete'}
          onChange={handleChange}
          className={classes.field}
          required
          autoFocus
        />
        {core.user.userSetting.templateManagement &&
          props.type === 'update' && (
            <FormControlLabel
              control={
                <Switch
                  color='primary'
                  checked={data.standard}
                  onChange={() =>
                    setData({ ...data, standard: !data.standard })
                  }
                />
              }
              label='standardTemplate'
            />
          )}
        {props.type === 'copy' && (
          <FormControl
            variant='outlined'
            className={classes.field}>
            <InputLabel id='chooseStartingTemplateLabel'>
              <TranslationLabel name='chooseStartingTemplate' />
            </InputLabel>
            <Select
              variant='outlined'
              labelId='chooseStartingTemplateLabel'
              id='chooseStartingTemplateId'
              value={data.fromTemplate}
              onChange={(e) =>
                setData({
                  ...data,
                  fromTemplate: e.target.value,
                  defaultLanguageCode:
                    e.target.value.defaultLanguageCode || 'da-DK',
                })
              }
              name='dataTemplate'>
              {templateOptions.map((template) => {
                return (
                  <MenuItem
                    value={template}
                    key={template.id}>
                    {template.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}

        {props.type !== 'delete' && (
          <FormControl
            variant='outlined'
            className={classes.field}>
            <InputLabel id='templateDefaultLanguageId'>
              <TranslationLabel name='templateDefaultLanguage' />
            </InputLabel>
            <Select
              variant='outlined'
              labelId='templateDefaultLanguageId'
              value={data.defaultLanguageCode || 'da-DK'}
              onChange={(e) => {
                setData({ ...data, defaultLanguageCode: e.target.value })
              }}>
              {state.languages.map((language) => {
                return (
                  <MenuItem
                    value={language.code}
                    key={language.id}>
                    {language.title}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}
      </DialogContent>

      <DialogActions className={classes.spacing}>
        <Button
          color='default'
          variant='contained'
          disableElevation
          onClick={() => props.onClose()}>
          <TranslationLabel name='cancel' />
        </Button>
        <Button
          type='submit'
          color='primary'
          variant='contained'
          disableElevation>
          <TranslationLabel name={props.type} />
        </Button>
      </DialogActions>
    </form>
  )
}

export default TemplateDialog
