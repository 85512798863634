import React, { useContext, useEffect, useState } from 'react'

//Core lib
import FormSection from '../../../components/forms/FormSection'
import FormHeader from '../../../components/forms/FormHeader'

//Theme
import { withStyles } from '@material-ui/core/styles'

//Components
import OutlinedInputField from '../../../../apps/sales-web-app/components/ui/OutlinedInputField'
import Grid from '@material-ui/core/Grid/Grid'
import SearchForm from '../SearchForm'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import FormGroup from '@material-ui/core/FormGroup/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import FormControl from '@material-ui/core/FormControl/FormControl'
import FormDivider from '../../../components/forms/FormDivider'
import { CoreContext } from '../../../context/CoreContext'
import Button from '@material-ui/core/Button'
import TranslationLabel from '../../../components/TranslationLabel'
import InputLabel from '@material-ui/core/InputLabel'
import MasterDataTab from './MasterDataTab'
import { FloatingActionButton } from '../FloatingActionButton'
import Divider from '@material-ui/core/Divider/Divider'
import LinearProgress from '@material-ui/core/LinearProgress'
import ValidateEmail from '../../../../shared/EmailValidation'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import getDefaultUserSettings from './DefaultUserConfigurations'

//Components
const styles = (theme) => ({
  root: {
    backgroundColor: 'red',
  },
  formPaper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    borderRadius: 0,
  },
  chipBox: {
    marginTop: theme.spacing(1),
  },
  selectedTab: {
    color: 'blue',
  },
  userTypeSelect: {
    marginTop: 10,
  },
})

function UserFormView(props) {
  const { core, mode, selected } = props
  const [userForm, setUserForm] = useState({
    userName: '',
    displayName: '',
    languageId: 1,
    apps: [1, 2, 9, 12], //include the most used by default
    masterDataFacets: [],
    email: '',
    isEnabled: true,
    primaryAppId: 1,
    selectedCustomerAccount: '',
    salesPersonAgentInitials: '',
    employeePurchaseAccount: '',
    samplesPurchaseAccount: '',
    languageManagement: false,
    helpLinkManagement: false,
    customerUserManagement: false,
    settingsAdministration: false,
    showInventory: false,
    showArchiveCheckbox: false,
    allUserManagement: false,
    userTypeId: 3,
    showBackordersOnProduct: true,
    canCreateNewsLetterUser: true,
    newsLetter: false,
    videoManagement: false,
  })

  const [error, setError] = useState() //name of first field with an error, or undefined for no errors
  const [selectedCustomer, setSelectedCustomer] = useState(undefined)
  const [samplesPurchase, setSamplesPurchase] = useState(undefined)
  const [employeePurchase, setEmployeePurchase] = useState(undefined)
  const [agentInitials, setAgentInitials] = useState(undefined)
  const [allApps, setAllApps] = useState([])
  const [selectedTab, setSelectedTab] = useState(1)
  const [loading, setLoading] = useState(false)
  const [userLoaded, setUserLoaded] = useState(false)
  const [userTypes, setUserTypes] = useState([])
  const [showEmployeePurchaseAccount, setShowEmployeePurchaseAccount] = useState(false)
  const [showSalesPersonAgentInitials, setShowSalesPersonAgentInitials] = useState(false)
  const [showSamplesPurchaseAccount, setShowSamplesPurchaseAccount] = useState(false)
  const isEditMode = mode === 'edit'
  const isCreateMode = mode === 'create'
  const appsEnum = {
    GENERAL: 1,
    SALES: 2,
    MASTERDATA: 6,
    TRADEFAIR: 7,
    VIDEO: 9,
  }
  const { GENERAL, SALES, VIDEO, MASTERDATA } = appsEnum

  useEffect(() => {
    if (!isEditMode) {
      setUserForm({
        ...userForm,
        ...getDefaultUserSettings()[userForm.userTypeId - 1],
      })
    }

    if (userTypes) {
      const relevantUserType = userTypes.find((el) => el.userTypeId === userForm.userTypeId)

      if (relevantUserType) {
        setShowEmployeePurchaseAccount(
          (employeePurchase !== undefined || !isEditMode) &&
            relevantUserType.showEmployeePurchaseAccount,
        )
        setShowSalesPersonAgentInitials(
          (agentInitials !== undefined || !isEditMode) &&
            relevantUserType.showSalesPersonAgentInitials,
        )
        setShowSamplesPurchaseAccount(
          (samplesPurchase !== undefined || !isEditMode) &&
            relevantUserType.showSamplesPurchaseAccount,
        )
      }
    }
  }, [
    userTypes,
    userForm.userTypeId,
    agentInitials,
    samplesPurchase,
    employeePurchase,
    isEditMode,
  ])

  function getCustomerLabel(accountNo, setStateFunction) {
    if (!accountNo) {
      return setStateFunction('')
    }

    core.dataStore
      .getCustomerByAccountNo(accountNo)
      .then((res) => {
        setStateFunction(`${res.data.accountNo} - ${res.data.accountName}`)
      })
      .catch(() => {
        setStateFunction('')
      })
  }

  useEffect(() => {
    if (!userForm.userName) {
      return // wait for userForm to be loaded
    }

    getCustomerLabel(userForm.selectedCustomerAccount, setSelectedCustomer)
    getCustomerLabel(userForm.samplesPurchaseAccount, setSamplesPurchase)
    getCustomerLabel(userForm.employeePurchaseAccount, setEmployeePurchase)
    setAgentInitials(userForm.salesPersonAgentInitials)
  }, [userForm])

  useEffect(() => {
    core.dataStore
      .getApps()
      .then((rs) => setAllApps(rs.data))
      .catch(console.log)
  }, [setAllApps]);

  useEffect(() => {
    core.dataStore
      .getUserTypes()
      .then((res) => setUserTypes(res.data))
      .catch(console.log)
  }, [setUserTypes])

  useEffect(() => load(), [core.dataStore])

  function load() {
    if (isEditMode && selected && selected !== -1) {
      core.hubStore
        .getUser(selected)
        .then((x) => {
          if (x.status === 200) {
            let rs = x.data
            if (rs) {
              setUserForm(rs)
              setUserLoaded(true)
            }
          } else alert(x.statusMessage)
        })
        .catch(console.log)
    } else if (selected === -1) core.history.push('/hub/admin/users')
  }

  function submitEdits(userForm) {
    //in userform the backend expects null, not empty strings!
    if (!isEditMode) {
      return
    }
    setLoading(true)

    if (validate(userForm)) {
      core.hubStore
        .updateUser({
          ...userForm,
          selectedCustomerAccount: userForm.selectedCustomerAccount || null,
          salesPersonAgentInitials: userForm.salesPersonAgentInitials || null,
          employeePurchaseAccount: userForm.employeePurchaseAccount || null,
          samplesPurchaseAccount: userForm.samplesPurchaseAccount || null,
        })
        .catch(() => core.snackbar('warning', 'Internal server error when saving user data, try again or contact developer'))
        .finally(() => setLoading(false));
    } else {
      setLoading(false)
    }
  }

  function createUser() {
    //in userform the backend expects null, not empty strings!
    setLoading(true)
    if (validate(userForm)) {
      core.hubStore
        .createUser({
          ...userForm,
          selectedCustomerAccount: userForm.selectedCustomerAccount || null,
          salesPersonAgentInitials: userForm.salesPersonAgentInitials || null,
          employeePurchaseAccount: userForm.employeePurchaseAccount || null,
          samplesPurchaseAccount: userForm.samplesPurchaseAccount || null,
        })
        .then(() => core.history.push('/hub/admin/users'))
        .catch(() => core.snackbar('warning', "Internal server error when saving user data, try again or contact developer"))
        .finally(() => setLoading(false))
    }

    setLoading(false)
  }

  function goToFrontPage() {
    core.history.push('/hub/admin/users')
  }

  let needsCustomerSet = isCreateMode && [1, 2, 3, 4, 5, 6, 7, 8].includes(userForm.userTypeId)
  let needsEmployeeAccountSet = [1, 2, 4, 5, 7].includes(userForm.userTypeId)
  function validate(userForm) {

    if (!userForm.userName) {
      setError('userName')
      core.snackbar("warning", "Username is mandatory!")
    } else if (!userForm.displayName) {
      setError('displayName')
      core.snackbar("warning", "Display name is mandatory!")
    } else if (!ValidateEmail(userForm.email)) {
      setError('email')
      core.snackbar('warning', 'Email is mandatory or is invalid')
    } else if (needsCustomerSet && !userForm.selectedCustomerAccount) {
      setError('selectedCustomerAccount')
      core.snackbar('warning', 'Selecte customer account is mandatory')
    } else if (needsEmployeeAccountSet && !userForm.employeePurchaseAccount) {
      setError('employeePurchaseAccount')
      core.snackbar('warning', 'Employee purchase account is mandatory')
    } else {
      return true
    }
  }

  function updateUser(userForm) {
    setUserForm(userForm)
    submitEdits(userForm)
  }

  function handleChangeUserName(e) {
    e.preventDefault()
    if (error === 'userName') {
      setError(undefined)
    }
    updateUser({ ...userForm, userName: e.target.value })
  }

  function handleChangeEmail(e) {
    e.preventDefault()
    if (error === 'email') {
      setError(undefined)
    }
    updateUser({ ...userForm, email: e.target.value })
  }

  function handleUserTypeChange(e) {
    const userTypeId = e.target.value

    const userFormTemp = {
      ...userForm,
      ...getDefaultUserSettings()[userTypeId -1],
      userTypeId
    }

    needsCustomerSet = isCreateMode && [1, 2, 3, 4, 5, 6, 7, 8].includes(userFormTemp.userTypeId)
    needsEmployeeAccountSet = [1, 2, 4, 5, 7].includes(userFormTemp.userTypeId)

    setUserForm(userFormTemp)
    if (validate(userFormTemp)) {

      submitEdits({
        ...userForm,
        ...getDefaultUserSettings()[userTypeId - 1],
        userTypeId
      })
    }
  }

  function handleChangeDisplayName(e) {
    e.preventDefault()
    if (error === 'displayName') {
      setError(undefined)
    }
    updateUser({ ...userForm, displayName: e.target.value })
  }

  function handleChangeIsEnabled(e) {
    e.preventDefault()
    updateUser({ ...userForm, isEnabled: e.target.checked })
  }

  function handleChangeAllUsermanagement(e) {
    updateUser({ ...userForm, allUserManagement: e.target.checked })
  }

  function handleChangeLanguagemanagement(e) {
    updateUser({ ...userForm, languageManagement: e.target.checked })
  }

  function handleChangeHelpLinkManagement(e) {
    updateUser({ ...userForm, helpLinkManagement: e.target.checked })
  }

  function handleChangeShowInventory(e) {
    updateUser({ ...userForm, showInventory: e.target.checked })
  }

  function handleChangeShowArchiveCheckbox(e) {
    updateUser({ ...userForm, showArchiveCheckbox: e.target.checked })
  }

  function handleChangeDownloadExcelOfItems(e) {
    updateUser({ ...userForm, downloadExcelOfItems: e.target.checked})
  }

  function handleChangeSettingsAdministration(e) {
    updateUser({ ...userForm, settingsAdministration: e.target.checked })
  }

  function handleChangeShowBackordersOnProduct(e) {
    updateUser({ ...userForm, showBackordersOnProduct: e.target.checked })
  }

  function handleChangeVideoManagement(e) {
    updateUser({ ...userForm, videoManagement: e.target.checked })
  }

  function handleChangeCanChangeSortOrder(e) {
    updateUser({ ...userForm, canChangeSortOrder: e.target.checked })
  }

  function handleChangeCreateNewsLetterAccounts(e) {
    updateUser({ ...userForm, canCreateNewsLetterUser: e.target.checked })
  }

  function handleNewsLetterChange(e) {
    updateUser({ ...userForm, newsLetter: e.target.checked })
  }

  function selectSelectedCustomerAccount(s) {
    if (error === 'selectedCustomerAccount') {
      setError(undefined)
    }
    updateUser({
      ...userForm,
      selectedCustomerAccount: s.accountNo,
      selectedCustomerName: s.accountName,
    })
  }

  function selectSalesAgentInitials(initials) {
    //the form calls this handler with {} on clear
    if (JSON.stringify({}) === JSON.stringify(initials)) {
      initials = null
    }
    updateUser({ ...userForm, salesPersonAgentInitials: initials })
  }

  function selectEmployee(s) {
    if (error === 'employeePurchaseAccount') {
      setError(undefined)
    }
    updateUser({
      ...userForm,
      employeePurchaseAccount: s.accountNo,
    })
  }

  function selectSamplesAccount(s) {
    updateUser({
      ...userForm,
      samplesPurchaseAccount: s.accountNo,
    })
  }

  function handleAppSelection(r) {
    let newSelected = userForm.apps.includes(r)
      ? userForm.apps.filter((c) => c !== r)
      : [...userForm.apps, r]

    updateUser({ ...userForm, apps: newSelected })
  }

  function handleSelectStartupApp(e) {
    updateUser({ ...userForm, primaryAppId: e.target.value })
  }

  function handleSelectLanguage(e) {
    updateUser({ ...userForm, languageId: e.target.value })
  }

  return (
    <>
      <Tabs
        indicatorColor='primary'
        value={selectedTab}>
        {userForm.apps.map((app) => (
          <Tab
            value={app}
            className={selectedTab === app ? selectedTab : ''}
            onClick={() => setSelectedTab(app)}
            label={
              app === 1 ? (
                <TranslationLabel name='generalTab' />
              ) : (
                <TranslationLabel name={'app' + app} />
              )
            }
          />
        ))}
      </Tabs>

      <Divider style={{ marginBottom: '10px' }} />

      {loading ? (
        <LinearProgress />
      ) : (
        <Grid
          container
          direction='row'
          alignItems='stretch'
          spacing={1}>
          {selectedTab === GENERAL && (userLoaded || !isEditMode) && (
            <>
              <Grid
                item
                xs={12}
                sm={12}>
                <FormSection>
                  <FormHeader title={<TranslationLabel name='UserProfile' />} />

                  <OutlinedInputField
                    htmlFor={<TranslationLabel name='Username' />}
                    label={<TranslationLabel name='Username' />}
                    type='text'
                    value={userForm.userName}
                    onChange={handleChangeUserName}
                    error={error === 'userName'}
                    disabled={isEditMode}
                    fullWidth
                    required
                    autoComplete='false'
                  />
                  <FormDivider />

                  <OutlinedInputField
                    htmlFor={<TranslationLabel name='Email' />}
                    label={<TranslationLabel name='Email' />}
                    type='text'
                    value={userForm.email}
                    onChange={handleChangeEmail}
                    error={error === 'email'}
                    fullWidth
                    autoComplete='false'
                    required
                  />
                  <FormDivider />

                  <OutlinedInputField
                    htmlFor='Display Name'
                    label='Display Name'
                    type='text'
                    value={userForm.displayName}
                    onChange={handleChangeDisplayName}
                    error={error === 'displayName'}
                    fullWidth
                    required
                    autoComplete='false'
                  />

                  <FormDivider />
                </FormSection>
                <FormSection>
                  <FormControl
                    variant='outlined'
                    style={{ width: '300px' }}>
                    <InputLabel>
                      <TranslationLabel name='userType' />
                    </InputLabel>
                    <Select
                      variant='outlined'
                      onChange={handleUserTypeChange}
                      value={userForm.userTypeId}>
                      {userTypes &&
                        userTypes.map((type) => (
                          <MenuItem value={type.userTypeId}>
                            {type.type}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <FormDivider />
                </FormSection>

                {(isCreateMode || error === 'employeePurchaseAccount' || error === 'selectedCustomerAccount') && (
                  <>
                    {needsCustomerSet && (
                      <FormSection>
                        <FormHeader
                          title={
                            <TranslationLabel name='selectedCustomerAccount' />
                          }
                        />
                        <div
                          style={{
                            width: 'calc(100% - 320px)',
                            display: 'inline-block',
                          }}>
                          <SearchForm
                            label={<TranslationLabel name='Search' />}
                            htmlFor={<TranslationLabel name='Search' />}
                            required={false}
                            displayResults={true}
                            onSelect={selectSelectedCustomerAccount}
                            onSearch={core.dataStore.getCustomersBySearch}
                            primary={(x) => x.accountNo + ' - ' + x.accountName}
                            fullWidth
                            initialValue={
                              userForm.selectedCustomerAccount &&
                              userForm.selectedCustomerAccount +
                                ' - ' +
                                userForm.selectedCustomerName
                            }
                            error={error === 'selectedCustomerAccount'}
                            async
                          />
                        </div>
                        <div
                          style={{ display: 'inline-block', marginLeft: 20 }}>
                          <FormControlLabel
                            control={
                              <Switch
                                color='primary'
                                checked={userForm.showBackordersOnProduct}
                                onChange={handleChangeShowBackordersOnProduct}
                                inputProps={{
                                  'aria-label': 'secondary checkbox',
                                }}
                              />
                            }
                            label={
                              <TranslationLabel name='showBackOrdersOnProduct' />
                            }
                          />
                        </div>
                      </FormSection>
                    )}
                    {needsEmployeeAccountSet && (
                      <FormSection>
                        <FormHeader
                          title={<TranslationLabel name='employeeSearch' />}
                        />
                        <SearchForm
                          label={<TranslationLabel name='Search' />}
                          htmlFor={<TranslationLabel name='Search' />}
                          required={false}
                          displayResults={true}
                          onSelect={selectEmployee}
                          onSearch={core.dataStore.getEmployeesBySearch}
                          primary={(x) => x.accountNo + ' - ' + x.accountName}
                          fullWidth
                          async
                          error={error === 'employeePurchaseAccount'}
                          initialValue={employeePurchase}
                        />
                      </FormSection>
                    )}
                  </>
                )}
                {isEditMode ? (
                  <UserRegistrationStatus
                    email={userForm.email}
                    userName={userForm.userName}
                  />
                ) : null}
                <Grid
                  item
                  xs={12}
                  sm={12}>
                  <FormSection>
                    <FormHeader
                      title={<TranslationLabel name='management' />}
                    />
                    <Grid
                      container
                      direction='row'>
                      <Grid
                        item
                        xs={12}
                        sm={3}>
                        <FormControlLabel
                          control={
                            <Switch
                              color='primary'
                              checked={userForm.allUserManagement}
                              onChange={handleChangeAllUsermanagement}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          }
                          label={<TranslationLabel name='allUserManagement' />}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}>
                        <FormControlLabel
                          control={
                            <Switch
                              color='primary'
                              checked={userForm.languageManagement}
                              onChange={handleChangeLanguagemanagement}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          }
                          label={<TranslationLabel name='languageManagement' />}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}>
                        <FormControlLabel
                          control={
                            <Switch
                              color='primary'
                              checked={userForm.settingsAdministration}
                              onChange={handleChangeSettingsAdministration}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          }
                          label={
                            <TranslationLabel name='settingsAdministration' />
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}>
                        <FormControlLabel
                          control={
                            <Switch
                              color='primary'
                              checked={userForm.canCreateNewsLetterUser}
                              onChange={handleChangeCreateNewsLetterAccounts}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          }
                          label={
                            <TranslationLabel name='createNewsLetterAccounts' />
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}>
                        <FormControlLabel
                          control={
                            <Switch
                              color='primary'
                              checked={userForm.canChangeSortOrder}
                              onChange={handleChangeCanChangeSortOrder}
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          }
                          label={
                            <TranslationLabel name='canChangeSortOrder' />
                          }
                        />
                                              </Grid>
                                              <Grid
                                                  item
                                                  xs={12}
                                                  sm={3}>
                                                  <FormControlLabel
                                                      control={
                                                          <Switch
                                                              color='primary'
                                                              checked={userForm.helpLinkManagement}
                                                              onChange={handleChangeHelpLinkManagement}
                                                              inputProps={{
                                                                  'aria-label': 'secondary checkbox',
                                                              }}
                                                          />
                                                      }
                                                      label={<TranslationLabel name='helpLinkManagement' />}
                                                  />
                                              </Grid>
                    </Grid>
                  </FormSection>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}>
                  <FormSection>
                    <FormHeader title={`Apps`} />
                    <FormControl
                      component='fieldset'
                      error={error}>
                      <FormGroup>
                        {allApps
                          .filter((el) => el.id !== 1)
                          .map((r, k) => (
                            <FormControlLabel
                              key={k}
                              style={{ pointerEvents: "none" }}
                              control={
                                <Checkbox
                                  style={{ pointerEvents: "auto" }}
                                  color='primary'
                                  checked={userForm.apps.includes(r.id)}
                                  onChange={() => handleAppSelection(r.id)}
                                  value={r.id}
                                />
                              }
                              label={<TranslationLabel name={'app' + r.id} />}
                            />
                          ))}
                      </FormGroup>
                    </FormControl>
                  </FormSection>
                </Grid>

                <Grid
                  item
                  xs={12}>
                  <Grid
                    container
                    direction='row'
                    alignItems='stretch'
                    spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={3}>
                      <FormSection>
                        <FormHeader
                          title={<TranslationLabel name='primaryApp' />}
                        />

                        {PrimaryAppSelection(
                          userForm.primaryAppId,
                          handleSelectStartupApp,
                          userForm.apps,
                        )}
                      </FormSection>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}>
                      <FormSection style={{ height: '132px' }}>
                        <FormHeader
                          title={
                            <TranslationLabel name='AccountEnabled'></TranslationLabel>
                          }
                        />

                        <Switch
                          color='primary'
                          checked={userForm.isEnabled}
                          onChange={handleChangeIsEnabled}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </FormSection>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}>
                      <FormSection style={{ height: '132px' }}>
                        <FormHeader
                          title={
                            <TranslationLabel name='newsLetterEnabled'></TranslationLabel>
                          }
                        />
                        <Switch
                          color='primary'
                          checked={userForm.newsLetter}
                          onChange={handleNewsLetterChange}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </FormSection>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}>
                      <FormSection>
                        <FormHeader
                          title={
                            <TranslationLabel name='UserLanguage'></TranslationLabel>
                          }
                        />
                        {
                          <LanguageSelection
                            selected={userForm.languageId}
                            handleChange={handleSelectLanguage}
                          />
                        }
                      </FormSection>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {selectedTab === SALES && (
            <>
              <Grid
                item
                xs={12}
                sm={12}>
                <FormSection>
                  <FormHeader
                    title={<TranslationLabel name='displaySettings' />}
                  />
                  <Grid
                    container
                    direction='row'>
                    <Grid
                      item
                      xs={12}
                      sm={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            color='primary'
                            checked={userForm.showBackordersOnProduct}
                            onChange={handleChangeShowBackordersOnProduct}
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                        }
                        label={
                          <TranslationLabel name='showBackOrdersOnProduct' />
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            color='primary'
                            checked={userForm.showInventory}
                            onChange={handleChangeShowInventory}
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                        }
                        label={<TranslationLabel name='showInventory' />}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            color='primary'
                            checked={userForm.showArchiveCheckbox}
                            onChange={handleChangeShowArchiveCheckbox}
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                        }
                        label={<TranslationLabel name='showArchiveCheckbox' />}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            color='primary'
                            checked={userForm.downloadExcelOfItems}
                            onChange={handleChangeDownloadExcelOfItems}
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                        }
                        label={<TranslationLabel name='downloadExcelOfItems' />}
                      />
                    </Grid>
                  </Grid>
                </FormSection>
                <FormSection>
                  <FormHeader
                    title={<TranslationLabel name='selectedCustomerAccount' />}
                  />
                  {(selectedCustomer !== undefined || !isEditMode) && (
                    <SearchForm
                      label={<TranslationLabel name='Search' />}
                      htmlFor={<TranslationLabel name='Search' />}
                      required={false}
                      displayResults={true}
                      onSelect={selectSelectedCustomerAccount}
                      onSearch={core.dataStore.getCustomersBySearch}
                      primary={(x) => x.accountNo + ' - ' + x.accountName}
                      fullWidth
                      error={error === 'selectedCustomerAccount'}
                      initialValue={
                        userForm.selectedCustomerAccount &&
                        userForm.selectedCustomerAccount +
                          ' - ' +
                          userForm.selectedCustomerName
                      }
                      async
                    />
                  )}
                </FormSection>
              </Grid>

              {showSalesPersonAgentInitials && (
                <Grid
                  item
                  xs={12}
                  sm={12}>
                  <FormSection>
                    <FormHeader
                      title={
                        <TranslationLabel name={'salesPersonAgentInitials'} />
                      }
                    />
                    <SearchForm
                      label={
                        <TranslationLabel name={'salesPersonAgentInitials'} />
                      }
                      htmlFor={
                        <TranslationLabel name={'salesPersonAgentInitials'} />
                      }
                      required={false}
                      displayResults={true}
                      onSearch={core.dataStore.getSellersBySearch}
                      onSelect={selectSalesAgentInitials}
                      primary={(initials) => initials}
                      fullWidth
                      initialValue={agentInitials}
                      async
                    />
                  </FormSection>
                </Grid>
              )}

              {showSamplesPurchaseAccount && (
                <Grid
                  item
                  xs={12}
                  sm={12}>
                  <FormSection>
                    <FormHeader
                      title={
                        <TranslationLabel name={'samplesPurchaseSearch'} />
                      }
                    />

                    <SearchForm
                      label={<TranslationLabel name='Search' />}
                      htmlFor={<TranslationLabel name='Search' />}
                      required={false}
                      displayResults={true}
                      onSelect={selectSamplesAccount}
                      onSearch={core.dataStore.getEmployeesBySearch}
                      primary={(x) => x.accountNo + ' - ' + x.accountName}
                      fullWidth
                      initialValue={samplesPurchase}
                      async
                    />
                  </FormSection>
                </Grid>
              )}

              {showEmployeePurchaseAccount && (
                <Grid
                  item
                  xs={12}
                  sm={12}>
                  <FormSection>
                    <FormHeader
                      title={<TranslationLabel name='employeeSearch' />}
                    />
                    <SearchForm
                      label={<TranslationLabel name='Search' />}
                      htmlFor={<TranslationLabel name='Search' />}
                      required={false}
                      displayResults={true}
                      onSelect={selectEmployee}
                      onSearch={core.dataStore.getEmployeesBySearch}
                      primary={(x) => x.accountNo + ' - ' + x.accountName}
                      fullWidth
                      async
                      initialValue={employeePurchase}
                      error={error === 'employeePurchaseAccount'}
                    />
                  </FormSection>
                </Grid>
              )}
            </>
          )}
          {selectedTab === VIDEO && (
            <Grid
              item
              xs={12}
              sm={12}>
              <FormSection>
                <FormHeader title={<TranslationLabel name='management' />} />
                <Grid
                  container
                  direction='row'>
                  <Grid
                    item
                    xs={12}
                    sm={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          color='primary'
                          checked={userForm.videoManagement}
                          onChange={handleChangeVideoManagement}
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                      }
                      label={<TranslationLabel name='videoManagement' />}
                    />
                  </Grid>
                </Grid>
              </FormSection>
            </Grid>
          )}
          {selectedTab === MASTERDATA && (
            <MasterDataTab
              isEditMode={isEditMode}
              userForm={userForm}
              setUserForm={setUserForm}
              updateUser={updateUser}
              core={core}
            />
          )}
        </Grid>
      )}

      <FloatingActionButton
        onClick={isEditMode ? goToFrontPage : createUser}
        variant={mode === 3 ? null : 'submit'}
      />
    </>
  )
}

export default withStyles(styles)(UserFormView)

function UserRegistrationStatus(props) {
  const core = useContext(CoreContext)

  function handleSendPasswordRecoveryMail() {
    core.hubStore
      .requestResetPassword(props.email)
      .then(() =>
        core.snackbar(
          'success',
          core.dictionary.getLabel('PasswordResetMailSendSucces'),
        ),
      )
      .catch(() =>
        core.snackbar(
          'warning',
          core.dictionary.getLabel('PasswordResetMailSendFailed'),
        ),
      )
  }

  function handleSendAccountRegistrationMail() {
    core.hubStore
      .requestNewRegistrationMail(props.email)
      .then(() =>
        core.snackbar(
          'success',
          core.dictionary.getLabel('RegistrationMailSendSucces'),
        ),
      )
      .catch(() =>
        core.snackbar(
          'warning',
          core.dictionary.getLabel('RegistrationMailSendFailed'),
        ),
      )
  }

  return (
    <Grid
      container
      spacing={2}>
      <Grid
        item
        xs={6}
        sm={6}>
        <FormSection>
          <FormHeader title={<TranslationLabel name='NewUserRegistration' />} />
          <Button
            variant='outlined'
            color='primary'
            onClick={handleSendAccountRegistrationMail}>
            <TranslationLabel name='SendNewUserRegistrationMail' />
          </Button>
        </FormSection>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}>
        <FormSection>
          <FormHeader title={<TranslationLabel name='PasswordRecovery' />} />
          <Button
            variant='outlined'
            color='primary'
            onClick={handleSendPasswordRecoveryMail}>
            <TranslationLabel name='SendPasswordResetMail' />
          </Button>
        </FormSection>
      </Grid>
    </Grid>
  )
}

function LanguageSelection({ selected, handleChange }) {
  const core = useContext(CoreContext)
  const [languages, setLanguages] = useState([])

  useEffect(() => {
    core.dataStore
      .getLanguages()
      .then((x) => {
        if (x.status === 200) {
          setLanguages(x.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [core.hubStore])

  return (
    <FormControl
      variant='outlined'
      style={{ width: '100%' }}>
      <Select
        value={selected ? selected : 1}
        onChange={handleChange}
        input={
          <OutlinedInput
            name='currency'
            id='outlined-age-simple'
          />
        }>
        {languages.map((v) => (
          <MenuItem
            key={v.id}
            value={v.id}>
            {v.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

function PrimaryAppSelection(selected, handleChange, apps) {
  return (
    <FormControl
      variant='outlined'
      style={{ width: '100%' }}>
      <Select
        value={selected ? selected : 1}
        input={
          <OutlinedInput
            name='primaryApp'
            id='outlined-app-simple'
          />
        }
        onChange={handleChange}
        displayEmpty>
        <MenuItem
          value=''
          disabled>
          <TranslationLabel name='selectPrimaryApp' />
        </MenuItem>
        {apps.map((v) => (
          <MenuItem
            key={v}
            value={v}>
            <TranslationLabel name={'app' + v} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
