import React, { useContext } from 'react'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import { Button } from '@material-ui/core'

import { CoreContext } from '../../../../../core/context/CoreContext'
import TranslationLabel from '../../../../../core/components/TranslationLabel'

import BasketReturnServiceListView from './BasketReturnServiceListView'
import MiniBasketButtons from './MiniBasketButtons'
import { ReturnServiceContext } from '../../../context/ReturnServiceContext'

import useStyles from './shared/miniBasketStyles'

export default function TemporaryDrawer(props) {
  const classes = useStyles()
  const core = useContext(CoreContext)
  const returnServiceModel = useContext(ReturnServiceContext)
  const basket = returnServiceModel.basket
  const { open, onClick } = props

  function closeDrawer(event) {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    onClick(false)
  }

  function productCountLabel() {
    return (
      <span>
        {basket.entries.length + ' '}
        {basket.entries.length === 1 ? (
          <TranslationLabel name='product' />
        ) : (
          <TranslationLabel name='products' />
        )}
      </span>
    )
  }

  function handleGotoCheckout() {
    onClick(false)
    core.history.push('/sales/returnService/checkout')
  }

  function emptyBasket() {
    returnServiceModel.clearBasket()
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={closeDrawer}
      className={classes.root}>
      <div className={classes.list}>
        <Typography
          variant='h4'
          component='h2'>
          <TranslationLabel name='returnServiceBasket' />
        </Typography>
        <p className={classes.sub}>{productCountLabel()}</p>
        <BasketReturnServiceListView isMiniBasket={true} />
      </div>

      <Button
        onClick={emptyBasket}
        variant='contained'
        color='secondary'
        style={{ width: 'fit-content', marginLeft: 16, marginBottom: 32 }}>
        <TranslationLabel name='emptyBasket' />
      </Button>
      <MiniBasketButtons
        onSubmit={handleGotoCheckout}
        close={closeDrawer}
        submitLabel={'toReturnCheckout'}
      />
    </Drawer>
  )
}
