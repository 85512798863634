import React, { useContext, useEffect, useState } from 'react'
import { ProductsContext } from '../../apps/sales-web-app/context/ProductsContext'
import { LinearProgress, makeStyles } from '@material-ui/core'
import { CoreContext } from '../context/CoreContext'
import TextField from '@material-ui/core/TextField'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TranslationLabel from './TranslationLabel'
import { CustomerQuantityEnum } from '../../shared/Enums/CustomersEnum'
import ContentScroller from '../../apps/sales-web-app/components/ContentScroller'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  container: {
    //height: '100%'
  },
  list: {
    height: 253, //Static height for dashboard. This could be dynamic if the component should be used in other places
    overflowY: 'scroll',
  },
  button: {
    display: 'inline-block',
    float: 'right',
    marginRight: '10px',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '40%',
    },
  },
  listItem: {
    paddingTop: '3px',
    paddingBottom: '3px',
  },
  customer0: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#d4ffd9',
    },
    'backgroundColor': '#f0fff2',
  },
  customer1: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#b1f0fc',
    },
    'backgroundColor': '#d4f8ff',
  },
  customer2: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f6e8fc',
    },
    'backgroundColor': '#fcf5ff',
  },
  mobileInput: {
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
}))

export default function CustomerSelection(props) {
  const classes = useStyles()
  const core = useContext(CoreContext)
  const productsModel = useContext(ProductsContext)
  const [src, setSrc] = useState('')
  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(0)
  const [hasMore, setHasMore] = useState(false)
  const [contentScroll, setContentScroll] = useState(false)
  const resultsPerPage = 30

  const { TWO } = CustomerQuantityEnum

  //if component is used as a modal, then set a fixed width on the container
  const { handleClose, isModal } = props

  function handleSearch(e) {
    setContentScroll(false)
    const src = e.target.value
    setSrc(src)
    setLoading(true)

    const {
      salesPersonAgentInitials,
      employeePurchaseAccount,
      samplesPurchaseAccount,
    } = core.user.userSetting
    core.dataStore
      .getUserCustomersBySearch(
        salesPersonAgentInitials,
        employeePurchaseAccount,
        samplesPurchaseAccount,
        src,
        resultsPerPage,
      )
      .then((res) => {
        setCustomers(res?.data)
        setLoading(false)
      })
      .catch(() => {})
  }

  function showAllCustomers() {
    //if !contentScroll it means there might be search results in customers from a previous search
    //dont append them to the all customers call
    const appendResults = contentScroll
    if (loading) {
      return
    }
    setLoading(true)
    setSrc('')
    setContentScroll(true)
    const {
      salesPersonAgentInitials,
      employeePurchaseAccount,
      samplesPurchaseAccount,
    } = core.user.userSetting
    core.dataStore
      .getUserCustomers(
        salesPersonAgentInitials,
        employeePurchaseAccount,
        samplesPurchaseAccount,
        pageNumber + 1,
        resultsPerPage,
      )
      .then((res) => {
        if (appendResults) {
          setCustomers([...customers, ...res.data?.customers])
        } else {
          setCustomers(res.data?.customers)
        }
        setPageNumber(res.data?.pageNumber)
        setHasMore(!res.data?.isLastPage)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function handleClick(value) {
    if (productsModel) {
      productsModel.clearProducts()
    }
    core.user.setCustomer(value)
    handleClose && handleClose()
  }

  useEffect(() => {
    const { hasMultipleAccounts } = core.user
    if (hasMultipleAccounts == TWO) {
      showAllCustomers()
    }
  }, [src, core.user.hasMultipleAccounts])

  useEffect(() => {
    //autoFocus doesn't work in the modal, so this accomplishes autoFocus for the input
    if (isModal) {
      //avoid using the same id as the customerSearch on the dashboard
      const input = document.getElementById(String(isModal))
      if (input) {
        input.focus()
      }
    }
  }, [])

  return (
    <div className={classes.root}>
      {
        <>
          <Grid
            container
            direction='column'
            className={classes.container}>
            <div style={{ height: 55, width: '100%' }}>
              <Grid
                container
                style={{ padding: '7px 10px 0px 10px' }}>
                <Grid
                  item
                  xs>
                  <TextField
                    id='standard-full-width'
                    label={<TranslationLabel name='customerSearch' />}
                    variant='standard'
                    onChange={handleSearch}
                    className={classes.mobileInput}
                    value={src}
                    InputProps={{
                      id: String(isModal),
                    }}
                  />
                  {core.user.hasMultipleAccounts != TWO && (
                    <Button
                      className={classes.button}
                      color='primary'
                      variant='contained'
                      onClick={showAllCustomers}>
                      <TranslationLabel name='showAllCustomers' />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
            <Grid item>
              <div
                style={{
                  width: isModal ? 450 : undefined,
                  height: isModal ? 600 : undefined,
                }}
                className={classes.list}>
                {loading ? (
                  <LinearProgress
                    style={{
                      height: 2,
                      width: '90%',
                      margin: 'auto',
                      marginTop: 10,
                    }}
                  />
                ) : (
                  <div style={{ height: 12, margin: 0 }} />
                )}
                {contentScroll ? (
                  <ContentScroller
                    pageStart={pageNumber}
                    loadMore={showAllCustomers}
                    hasMore={hasMore}
                    threshold={70}
                    useWindow={false}
                    initialLoad={false}
                    content={
                      <List>
                        {customers.map((item, index) => (
                          <ListItem
                            button
                            key={index}
                            onClick={() => handleClick(item)}
                            className={
                              classes[`customer${item.accountType}`] +
                              ' ' +
                              classes.listItem
                            }>
                            <ListItemText
                              primary={`${item.accountName} - ${item.accountNo}`}
                            />
                          </ListItem>
                        ))}
                      </List>
                    }></ContentScroller>
                ) : (
                  <List>
                    {customers.map((item, index) => (
                      <ListItem
                        button
                        key={index}
                        onClick={() => handleClick(item)}
                        className={
                          classes[`customer${item.accountType}`] +
                          ' ' +
                          classes.listItem
                        }>
                        <ListItemText
                          primary={`${item.accountName} - ${item.accountNo}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </div>
            </Grid>
          </Grid>
        </>
      }
    </div>
  )
}
