import React from 'react'
import TagIcon from '@material-ui/icons/LocalOfferOutlined'
import TranslationLabel from '../core/components/TranslationLabel'

const priceEnum = {
  Standard: 0,
  Campaign: 1,
  Archive: 2
}

function findPrice(colli, product) {
  let _currentPrice = {};

  product.priceDtos.forEach(price => {
    if (price.minQuantity <= colli && price.minQuantity > 0) {
      _currentPrice = price;
    }
  });

  return _currentPrice;
}

function hasNoPrice(product) {
  return !product.priceDtos.length;
}

function findNextAllowedNumber(product, number) {
  if (number < 1) {
    return 0
  }
  const { minimumQuantity, multiplesQuantity } = product

  const increment = multiplesQuantity || 1
  if (number <= minimumQuantity) {
    return minimumQuantity
  }
  return Math.ceil(number / increment) * increment
}

function hasDiscount(currentPrice) {
  return (currentPrice.priceType == priceEnum.Standard && currentPrice.discountPercentage > 0)
    || (currentPrice.priceType !== priceEnum.Standard);
}

function RenderPriceTag(currentPrice) {
  if (currentPrice.priceType == undefined
    || currentPrice.priceType == priceEnum.Standard) {
    return;
  }

  return (
    <>
      <TagIcon
        style={{
          color: currentPrice.priceType == priceEnum.Campaign ? 'green' : 'red',
          verticalAlign: 'middle',
          width: 16,
          height: 16,
          marginLeft: 6,
        }}
      />
      <span
        style={{
          color: currentPrice.priceType == priceEnum.Campaign ? 'green' : 'red',
          fontSize: 12,
        }}>
        {currentPrice.priceType == priceEnum.Campaign
          ? <TranslationLabel name='campaignPrice' />
          : <TranslationLabel name='ArchivePrice' />
        }
      </span>
    </>
  )
}

function nextPriceTag(quantity, product) {
  const nextPrice = product
    .priceDtos
    .find(el => el.minQuantity > quantity
      && (el.priceType == priceEnum.Archive || el.priceType == priceEnum.Campaign))

    if (!nextPrice) {
      return;
    }

      return (
        <>
          <TagIcon
            style={{
              color: nextPrice.priceType == priceEnum.Campaign ? 'green' : 'red',
              verticalAlign: 'middle',
              width: 20,
              height: 20,
              marginLeft: 6,
            }}
          />
          <span
            style={{
              color: nextPrice.priceType == priceEnum.Campaign ? 'green' : 'red',
            }}>
            {nextPrice.minQuantity}<TranslationLabel name='productsUnit' />+
          </span>
        </>
      )
}

function renderPrice(currentPrice, classes) {
  return (
    <>
      {hasDiscount(currentPrice) ? (
        <div>
          <del className={classes.priceNormal}>{currentPrice.grossPrice}</del>
          { RenderPriceTag(currentPrice) }
        </div>
      ) : (
        <span className={classes.priceNormal}>
          <TranslationLabel name='price' />
          { RenderPriceTag(currentPrice) }
        </span>
      )}
    </>
  )
}

function renderYourPrice(currentPrice, quantity, classes) {
  return (
    <div>
      <span className={classes.priceYours}>{currentPrice.netPrice}</span>
      <span className={classes.priceText}>
        {hasDiscount(currentPrice) ? (
          <span>
            <TranslationLabel name='price' />
          </span>
        ) : (
          ''
        )}{' '}
        <TranslationLabel name='productsQuantityPrefix' />{' '}
        {quantity || 0}
        <TranslationLabel name='productsUnit' />
      </span>
    </div>
  )
}

export default {
  findPrice,
  hasNoPrice,
  findNextAllowedNumber,
  hasDiscount,
  RenderPriceTag,
  nextPriceTag,
  renderPrice,
  renderYourPrice,
  priceEnum
}
