import React, { useContext, useEffect, useState, useRef } from 'react'
import { CoreContext } from '../../../../core/context/CoreContext'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles, Button, TextField } from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import TableChartIcon from '@material-ui/icons/TableChart'
import { saveAsCsv } from '../../../../shared/SaveAsCsv'
import ValidateEmail from '../../../../shared/EmailValidation'

import BackButton from '../../components/BackButton'
import PrintButton from '../../components/PrintButton'
import ReactToPrint from 'react-to-print'

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #e0e0e0',
    backgroundColor: '#F5F5F5',
    overflowX: 'auto',
    padding: 30,
    paddingTop: 10,
    maxWidth: '1042px',
    margin: 'auto',
  },
}))

const emailSendingStatusEnum = {
  SENDING: 'SENDING',
  SENT: 'SENT',
  ERROR: 'ERROR',
  INITIAL: null,
}

function DetailedOrderView(props) {
  const { SENDING, SENT, ERROR, INITIAL } = emailSendingStatusEnum
  const classes = useStyles()
  //on receipt page id comes from props and onlyRestOrders always false, on detailedOrderView it comes from url together with onlyRestOrders
  const salesId = props.salesId || props.computedMatch.params.id
  const onlyRestOrders = props.isReceipt
    ? false
    : props.computedMatch.params.onlyRestOrders
  const core = useContext(CoreContext)
  const [isLoading, setIsLoading] = useState(true)
  const [showEmailField, setShowEmailField] = useState(false)
  const [email, setEmail] = useState(core.user.selectedCustomer.email)
  const [emailSentStatus, setEmailSentStatus] = useState(INITIAL)
  const [order, setOrder] = useState()
  const [highlight, setHighlight] = useState(false)
  const componentRef = useRef(null)

  useEffect(() => {
    setEmail(core.user.selectedCustomer.email)
  }, [core.user.selectedCustomer.email])

  useEffect(() => {
    console.log(props.computedMatch, salesId, onlyRestOrders)
    if (core.user.selectedCustomer.accountNo) {
      core.dataStore
        .getDetailedOrderHtml(
          core.user.selectedCustomer.accountNo,
          salesId,
          onlyRestOrders,
          core.user.language,
        )
        .then((res) => {
          document.getElementById('order').innerHTML = res.data
        })
        .catch((err) => setIsLoading(false))
        .finally(() => {
          setIsLoading(false)
        })

      core.dataStore
        .getOrder(
          core.user.selectedCustomer.accountNo,
          salesId,
          core.user.language,
        )
        .then((res) => {
          setOrder(res.data)
        })
        .catch(() => console.log('getOrder failed'))
    }
  }, [core.user.selectedCustomer.accountNo])

  const displayWarning = (x) => (x === undefined || x.length < 3 || !emailValidated) && highlight ? true : false

  var emailValidated = false
  const sendEmail = () => {
    if (ValidateEmail(email)) {
      emailValidated = true
      setHighlight(false)

      setEmailSentStatus(SENDING)
      core.dataStore
        .emailOrderConfirmation(
          email,
          core.user.language,
          salesId,
          core.user.selectedCustomer.accountNo,
          onlyRestOrders,
        )
        .then(() => setEmailSentStatus(SENT))
        .catch((err) => setEmailSentStatus(ERROR))
    } else {
      core.snackbar('warning', 'Wrong email format!')
      emailValidated = false
      setHighlight(true)
    }
  }

  const formatNumber = (number) => {
    return number.toLocaleString('da-DK')
  }

  const saveCsv = () => {
    if (!order) return
    const rows = []
    //first row is headers
    rows[0] = [
      `${core.dictionary.getLabel('orderNumber')} ${order.salesId}`,
      `${core.dictionary.getLabel('orderDate')} ${
        order.orderDate.split('T')[0]
      }`,
    ]
    rows[1] = [
      'EAN',
      'itemId',
      'itemDescription',
      'expectedDeliveryDate',
      'expectedStockDate',
      'quantity',
      'salesPrice',
    ].map((el) => core.dictionary.getLabel(el))
    order.orderLines.forEach((productLine, i) => {
      const {
        eanNumber,
        itemId,
        itemTitle,
        requestedShippingDate,
        expectedStockDate,
        quantity,
        amount,
      } = productLine
      rows[i + 2] = [
        eanNumber,
        itemId,
        itemTitle,
        requestedShippingDate,
        expectedStockDate,
        quantity,
        formatNumber(amount),
      ]
    })
    rows[rows.length] = [''] //space
    rows[rows.length] = [
      core.dictionary.getLabel('amount'),
      '',
      '',
      '',
      '',
      formatNumber(order.amount),
    ]
    rows[rows.length] = [
      core.dictionary.getLabel('vatAmount'),
      '',
      '',
      '',
      '',
      formatNumber(order.vatAmount),
    ]
    rows[rows.length] = [
      core.dictionary.getLabel('orderAmount'),
      '',
      '',
      '',
      '',
      formatNumber(order.totalAmount),
    ]

    saveAsCsv(
      rows.map((el) => el.join(';')).join('\n'),
      `${core.dictionary.getLabel('order')}${order.salesId}`,
    )
  }

  const EmailField = (
    <>
      <TextField
        style={{ paddingTop: 6, marginLeft: 10, marginRight: 5, width: 250 }}
        size='small'
        autoFocus
        value={email}
        error={displayWarning(email)}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        variant='outlined'
        onClick={() => sendEmail()}>
        <TranslationLabel name='sendEmail'></TranslationLabel>
      </Button>
    </>
  )

  const renderEmailStatus = () => {
    switch (emailSentStatus) {
      case SENT:
        return (
          <>
            <CheckCircleOutlineOutlinedIcon
              style={{ color: 'green', verticalAlign: 'middle', margin: 5 }}
            />
            <span style={{ fontSize: '16px' }}>
              <TranslationLabel name='emailSent' />
            </span>
          </>
        )
      case SENDING:
        return (
          <CircularProgress
            style={{
              height: 20,
              width: 20,
              margin: 5,
              verticalAlign: 'middle',
            }}
          />
        )
      case ERROR:
        return (
          <>
            <ErrorOutlineIcon
              style={{
                height: 20,
                width: 20,
                margin: 5,
                verticalAlign: 'middle',
                color: 'red',
              }}
            />
            <span style={{ fontSize: '16px' }}>
              <TranslationLabel name='emailError' />
            </span>
          </>
        )
      case INITIAL:
      default:
        return null
    }
  }

  return (
    <div className={classes.root}>
      <div
        style={{
          margin: 'auto',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <BackButton
          path={
            props.isFairApp
              ? '/fair-app/orders'
              : onlyRestOrders === 'true'
              ? '/sales/restOrders'
              : '/sales/orders'
          }
        />
        <div>
          <ReactToPrint
            trigger={() => <PrintButton />}
            content={() => componentRef.current}
          />
          <Button onClick={saveCsv}>
            <TableChartIcon style={{ marginRight: '10px' }} />
            <TranslationLabel name='saveCsv' />
          </Button>
          {showEmailField ? (
            <>
              {EmailField} {renderEmailStatus()}
            </>
          ) : (
            <Button onClick={() => setShowEmailField(true)}>
              <MailOutlineIcon style={{ marginRight: '10px' }} />
              <TranslationLabel name='emailOrder' />
            </Button>
          )}
        </div>
      </div>
      {isLoading ? <LinearProgress /> : null}
      <div
        style={{ backgroundColor: 'white' }}
        ref={componentRef}
        id='order'
      />
    </div>
  )
}

export default DetailedOrderView
