import React, { useContext, useState } from 'react'
import { CoreContext } from '../context/CoreContext'
import { makeStyles, Paper } from '@material-ui/core'
import OutlinedInputField from '../../apps/sales-web-app/components/ui/OutlinedInputField'
import Button from '@material-ui/core/Button'
import TranslationLabel from '../components/TranslationLabel'
import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {},
}))
export default function UserPasswordResetCompletionPage(props) {
  const core = useContext(CoreContext)
  const userId = props.match.params.userId
  const token = props.match.params.token
  const classes = useStyles()

  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(false)

  function handleChange(evt) {
    setPassword(evt.target.value)
  }
  function handleChange2(evt) {
    setPassword2(evt.target.value)
  }

  function handleSubmit() {
    if (password === '') {
      setStatus('Password is empty')
      return
    }

    if (password !== password2) {
      setStatus('Password does not match')
      return
    }

    setLoading(true)
    core.hubStore
      .submitPassword(userId, password, token)
      .then((res) => {
        if (res.status === 200) {
          core.history.push('/login')
        }
      })
      .catch((error) => {
        console.log(error.response)
        if (error.response) {
          setStatus(
            <TranslationLabel name='passwordResetFailed'></TranslationLabel>,
          )
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className={classes.root}>
      <div style={{ textAlign: 'center' }}>
        <h2>
          <TranslationLabel name='passwordResetCompletionTitle' />
        </h2>
        {status ? <p style={{ color: 'red' }}>{status}</p> : null}
      </div>
      <Paper className={classes.paper}>
        {loading ? <LinearProgress /> : null}
        <OutlinedInputField
          label='Enter new password'
          onChange={handleChange}
          password
        />
        <OutlinedInputField
          label='Confirm new password'
          onChange={handleChange2}
          password
        />
        <Button
          variant='contained'
          color='primary'
          onClick={handleSubmit}
          fullWidth>
          Submit
        </Button>
      </Paper>
    </div>
  )
}
