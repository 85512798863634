import React from 'react'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { Button } from '@material-ui/core'

import TranslationLabel from '../../../../../core/components/TranslationLabel'

import useStyles from './shared/miniBasketStyles'

export default function MiniBasketButtons(props) {
  const classes = useStyles()
  const { onSubmit, close, submitLabel } = props

  return (
    <div className={classes.btns}>
      <Button
        variant='contained'
        disableElevation
        className={classes.btn}
        onClick={close}>
        <ArrowBackIcon
          fontSize='small'
          style={{ marginRight: 5 }}
        />
        <TranslationLabel name='close' />
      </Button>
      <Button
        variant='contained'
        disableElevation
        className={`${classes.btn} ${classes.btnNext}`}
        onClick={onSubmit}>
        <TranslationLabel name={submitLabel} />
        <ArrowForwardIcon
          style={{ marginLeft: 5 }}
          fontSize='small'
        />
      </Button>
    </div>
  )
}
