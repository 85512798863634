import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import TranslationLabel from '../../../../../core/components/TranslationLabel'
import useStyles from './itemStyles'
import StockItem from '../StockItem'
import InriverImage from '../../../../../core/components/InriverImage'
import { ProductsContext } from '../../../context/ProductsContext'
import priceDtoHelper from '../../../../../shared/PriceDtoHelper'

export const ProductDescription = (props) => {
  const productsModel = useContext(ProductsContext)
  const {
    productNo,
    description,
    brandNo,
    inventoryText,
    inventoryStatus,
    openOrders,
    showImage
  } = props.product

  const showOpenOrder = openOrders?.length > 0
  const classes = useStyles()

  return (
    <>
      <div className={classes.listHead}>
        {/* Image */}
        <div className={classes.imgWrapper}>
          <Link
            to={`/sales/products/item/${productNo}`}
            onClick={productsModel.gotoProduct}
            className={classes.imgWrapperInner}>
            <InriverImage
              className={classes.img}
              productNo={productNo}
              alt={description}
              displayConfig='SalesSite_SmallThumbnail'
              showImage={showImage}
            />
          </Link>
        </div>
      </div>

      <div className={classes.listInfo}>
        {/* Product name  */}
        <Link
          to={`/sales/products/item/${productNo}`}
          className={`${classes.title} ${classes.listTitle}`}
          onClick={productsModel.gotoProduct}>
          <>
            {description}
            {priceDtoHelper.nextPriceTag(props.quantity, props.product)}
          </>
        </Link>

        {/* Brand */}
        <span className={`${classes.label} ${classes.listLabel}`}>
          {brandNo}
        </span>

        {/* Product no */}
        <span className={classes.small}>
          <TranslationLabel name='productNumber' />
          <span> {productNo}</span>
        </span>

        {/* Stock */}
        <div className={classes.listStock}>
          <StockItem
            text={inventoryText}
            status={inventoryStatus}
          />
        </div>
        {showOpenOrder && (
          <div
            onClick={() => props.setOpenOrdersModal(productNo)}
            className={classes.listStock}>
            <span className={classes.listOpenOrders}>
              <TranslationLabel name='openOrders' />
            </span>
          </div>
        )}
      </div>
    </>
  )
}
