import React, { useContext } from 'react'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import logo from '../../../assets/images/RDGLogo.jpg'

import NavItem from './NavItem'
import TranslationLabel from '../TranslationLabel'
import { CoreContext } from '../../context/CoreContext'
import HubDrawer from './HubDrawer'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    boxShadow: 'none',
  },
  grow: {
    flexGrow: 1,
  },
  MuiToolbar: {
    padding: 0,
  },
  menuButton: {
    marginLeft: 0,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0px',
    ...theme.mixins.toolbar,
  },
  search: {
    'position': 'relative',
    'borderRadius': theme.shape.borderRadius,
    'backgroundColor': fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    'marginRight': theme.spacing(2),
    'marginLeft': 0,
    'width': '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  closeIcon: {
    marginLeft: 120,
  },
  logo: {
    display: 'inline-block',
    width: 34,
    height: 30,
    float: 'left',
    marginLeft: 10,
    cursor: 'pointer',

    [theme.breakpoints.up('sm')]: {
      width: 46,
      height: 40,
      marginLeft: 20,
    },
  },
}))

export default function NavigationDrawer(props) {
  const classes = useStyles()
  const { upperItems } = props
  const core = useContext(CoreContext)
  const handleAction = (path) => core.history.location.pathname === path ? null : navigate(path)

  function navigate(path) {
    core.history.push(path)
  }

  return (
    <HubDrawer
      classes={classes}
      open={core.isExpanded}
      marginTop={0}
      closedWidth={70}
      drawerWidth={240}>
      <div className={classes.toolbar}>
        <img
          onClick={() => core.history.push('/hub')}
          src={logo}
          className={classes.logo}
          alt='Rosendahl Design Group'
        />
        <IconButton
          className={classes.closeIcon}
          onClick={() => core.expandDrawer(!core.isExpanded)}>
          <ChevronLeftIcon />
        </IconButton>
      </div>

      <Divider />

      <List>
        {upperItems.map((i) => (
          <NavItem
            text={<TranslationLabel name={i.labelKey} />}
            key={i.index}
            path={i.route.path}
            id={i.id}
            action={() => handleAction(i.route.path)}
            selected={core.history.location.pathname === i.path}
          />
        ))}
      </List>
    </HubDrawer>
  )
}
