import React from 'react'

//Components
import AdminView from '../admin/components/AdminView'

//Theme
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  gridFullHeight: {
    height: '100%',
  },
  tabRoot: {
    flexGrow: 1,
  },
  flex: {
    display: 'flex',
    flex: 1,
  },
  paper: {
    width: '100%',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: '0',
    paddingRight: '0',
    borderRadius: 0,
  },
  cornerButton: {
    right: '15px',
    bottom: '15px',
  },
  title: {
    paddingLeft: '15px',
  },
})

export function AdminPage() {
  return <AdminView />
}

export default withStyles(styles)(AdminPage)
