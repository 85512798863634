import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  // Shared
  imgWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  imgWrapperInner: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  label: {
    textTransform: 'uppercase',
    fontSize: 11,
    position: 'absolute',
    top: 5,
    left: 7,
    backgroundColor: 'rgba(255, 255, 255, .6)',
    padding: '1px 3px',
    color: theme.palette.grey[600],
  },
  labelDiscount: {
    left: 'auto',
    right: 7,
    fontWeight: 'bold',
    fontSize: 12,
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    margin: '0 10px',
    display: 'block',

    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    },
  },
  small: {
    fontSize: 13,
  },
  double: {
    display: 'flex',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[200],
    paddingBottom: 5,
    marginTop: 5,
    padding: '0 10px',
    flexWrap: 'wrap',
  },

  // Tile
  box: {
    height: '100%',
    borderWidth: 1,
    borderColor: theme.palette.grey[200],
    borderStyle: 'solid',
  },
  head: {
    position: 'relative',
    height: 0,
    overflow: 'hidden',
    paddingTop: '71.5%',
  },
  info: {
    marginTop: 'auto',
  },

  // List
  listBox: {
    display: 'flex',
    minHeight: 58,
    borderWidth: 1,
    borderColor: theme.palette.grey[200],
    borderStyle: 'solid',
    position: 'relative',
    marginTop: -1,
  },

  listHead: {
    width: 77,
    height: 55,
    position: 'relative',
  },
  listInfo: {
    marginRight: 'auto',
    marginLeft: 15,
    paddingTop: 6,
  },
  listTitle: {
    margin: '3px 0 -3px',
  },
  listLabel: {
    position: 'static',
    marginRight: 15,
    padding: 0,
    marginTop: -1,
  },
  listStock: {
    display: 'inline-block',
    marginLeft: 15,
    transform: 'translateY(-1px)',
  },
  listOpenOrders: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  listTag: {
    top: 14,
  }
}))
