import HttpClientHandler from './httpClientHandler'
import HubStore from './HubStore'
import BaseStore from './BaseStore'
import DataStore from './DataStore'
import StatisticStore from './StatisticStore'
import MasterItemDataStore from './MasterItemDataStore'
import ApiUrls from './ApiUrls'

export default function DataStoreHandler() {
  const getBaseStore = (baseUrl) => {
    let clientHandler = HttpClientHandler(baseUrl)
    return BaseStore(clientHandler.httpClient)
  }

  const hubStore = HubStore(getBaseStore(ApiUrls.hubUrl))
  const dataStore = DataStore(getBaseStore(ApiUrls.dataUrl))
  const statisticStore = StatisticStore(getBaseStore(ApiUrls.statisticsUrl))
  const masterItemDataStore = MasterItemDataStore(getBaseStore(ApiUrls.dataUrl))

  return {
    hubStore,
    dataStore,
    statisticStore,
    masterItemDataStore,
  }
}
