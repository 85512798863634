import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  // Shared
  imgWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  imgWrapperInner: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  label: {
    textTransform: 'uppercase',
    fontSize: 11,
    position: 'absolute',
    top: 5,
    left: 7,
    backgroundColor: 'rgba(255, 255, 255, .6)',
    padding: '1px 3px',
    color: theme.palette.grey[600],
  },
  labelDiscount: {
    left: 'auto',
    right: 7,
    fontWeight: 'bold',
    fontSize: 12,
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    margin: '0 10px',
    display: 'block',

    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    },
  },
  small: {
    fontSize: 13,
  },
  double: {
    display: 'flex',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[200],
    paddingBottom: 5,
    marginTop: 5,
    padding: '0 10px',
    flexWrap: 'wrap',
  },

  // Tile
  box: {
    height: '100%',
    borderWidth: 1,
    borderColor: theme.palette.grey[200],
    borderStyle: 'solid',
  },
  head: {
    position: 'relative',
    height: 0,
    overflow: 'hidden',
    paddingTop: '71.5%',
  },
  info: {
    marginTop: 'auto',
  },

  // List
  listBox: {
    display: 'flex',
    minHeight: 58,
    borderWidth: 1,
    borderColor: theme.palette.grey[200],
    borderStyle: 'solid',
    position: 'relative',
    marginTop: -1,
  },
  quantityAndPriceWrapper: {
    minWidth: 240,
  },
  listHead: {
    width: 77,
    height: 55,
    position: 'relative',
  },
  listInfo: {
    marginRight: 'auto',
    marginLeft: 15,
    paddingTop: 6,
  },
  listTitle: {
    margin: '3px 0 -3px',
  },
  listLabel: {
    position: 'static',
    marginRight: 15,
    padding: 0,
    marginTop: -1,
  },
  listStock: {
    display: 'inline-block',
    marginLeft: 15,
    transform: 'translateY(-1px)',
  },
  cta: {
    display: 'flex',
    margin: '10px 10px 0',
    [theme.breakpoints.up('md')]: {
      minWidth: 140,
    },
  },
  quantityInfos: {
    marginTop: 6,
    fontSize: 12,
    color: theme.palette.grey[600],
    display: 'inline-block',
  },
  quantityInfo: {
    margin: 3,
  },
  quantity: {
    'width': 60,
    'textAlign': 'center',
    'marginRight': 5,
    'marginBottom': 10,

    '& input': {
      paddingRight: 0,
    },
  },
  incrementButton: {
    'width': 'auto',
    'color': 'white',
    'backgroundColor': 'black',
    'height': 40,
    'marginBottom': 10,

    '&:hover': {
      backgroundColor: theme.palette.grey[800],
    },
  },
  sortingButton: {
    'textAlign': 'center',
    'width': 48,
    'height': 24,
    'cursor': 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  listPrices: {
    borderRight: '1px solid',
    borderRightColor: theme.palette.grey[200],
    minWidth: 160,
    display: 'inline-block',
    padding: '7px 0 9px 50px',
    [theme.breakpoints.down('xs')]: {
      borderRight: 'none',
    },
  },
  productsDescription: {
    [theme.breakpoints.down('sm')]: {
      width: '350px',
    },
  },
  hideOnMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  prices: {
    position: 'relative',
    padding: '10px 0 7px 10px',
  },
  quantitiyInfo: {
    fontSize: 15,
    color: theme.palette.grey[400],
  },
  priceNormal: {
    fontSize: 12,
    color: theme.palette.grey[500],
    minWidth: '50px',
    textAlign: 'center',
    display: 'inline-block',
  },
  priceText: {
    fontSize: 12,
    color: theme.palette.grey[500],
    display: 'inline-block',
    marginLeft: 10,
  },
  currency: {
    position: 'absolute',
    top: '-16px',
    left: '32px',
    color: theme.palette.grey[600],
    fontSize: 12,
    zIndex: 5,
  },
  priceYours: {
    fontSize: 16,
    fontWeight: 'bold',
    minWidth: '50px',
    textAlign: 'right',
    display: 'inline-block',
  },
}))
