import React, { useContext, useEffect, useState, useRef } from 'react'
import { CoreContext } from '../../../../core/context/CoreContext'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles, Button, TextField } from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import TableChartIcon from '@material-ui/icons/TableChart'

import BackButton from '../../components/BackButton'
import PrintButton from '../../components/PrintButton'
import ReactToPrint from 'react-to-print'
import { saveAsCsv } from '../../../../shared/SaveAsCsv'

const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid #e0e0e0',
    backgroundColor: '#F5F5F5',
    overflowX: 'auto',
    padding: 30,
    paddingTop: 10,
    maxWidth: '1042px',
    margin: 'auto',
  },
}))

const emailSendingStatusEnum = {
  SENDING: 'SENDING',
  SENT: 'SENT',
  ERROR: 'ERROR',
  INITIAL: null,
}

function DetailedRMAOrderView(props) {
  const { SENDING, SENT, ERROR, INITIAL } = emailSendingStatusEnum
  const classes = useStyles()
  //On receipt page salesId comes from props and onlyRestOrders = false, but comes from url together with onlyRestOrders on normal rmaOrder page
  const salesId = props.salesId || props.computedMatch.params.id
  const core = useContext(CoreContext)
  const [isLoading, setIsLoading] = useState(true)
  const [showEmailField, setShowEmailField] = useState(false)
  const [email, setEmail] = useState(core.user.selectedCustomer.email)
  const [emailSentStatus, setEmailSentStatus] = useState(INITIAL)
  const [rmaOrder, setRmaOrder] = useState() //only used to save as excel, csv
  const componentRef = useRef(null)

  useEffect(() => {
    setEmail(core.user.selectedCustomer.email)
  }, [core.user.selectedCustomer.email])

  useEffect(() => {
    if (core.user.selectedCustomer.accountNo)
      core.dataStore
        .getRMAOrderDetailed(core.user.selectedCustomer.accountNo, salesId)
        .then((res) => {
          setRmaOrder(res.data)
        })

    core.dataStore
      .getDetailedRMAOrderHtml(
        core.user.selectedCustomer.accountNo,
        salesId,
        core.user.language,
      )
      .then((res) => {
        document.getElementById('order').innerHTML = res.data.value
      })
      .catch((err) => setIsLoading(false))
      .finally(() => {
        setIsLoading(false)
      })
  }, [core.user.selectedCustomer.accountNo])

  const sendEmail = () => {
    setEmailSentStatus(SENDING)
    core.dataStore
      .emailRMAOrderConfirmation(
        email,
        core.user.language,
        salesId,
        core.user.selectedCustomer.accountNo,
      )
      .then(() => setEmailSentStatus(SENT))
      .catch((err) => setEmailSentStatus(ERROR))
  }

  const saveCsv = () => {
    const rows = []
    //first row is headers
    rows[0] = [
      rmaOrder.custAccount,
      `${core.dictionary.getLabel('rmaNumber')} ${rmaOrder.salesId}`,
    ]
    rows[1] = [
      'itemId',
      'itemDescription',
      'returnReason',
      'quantity',
    ].map((el) => core.dictionary.getLabel(el))
    rmaOrder.lines.forEach((productLine, i) => {
      const {
        itemId,
        itemTitle,
        returnReasonName,
        quantity
      } = productLine
      rows[i + 2] = [
        itemId,
        itemTitle,
        returnReasonName,
        quantity
      ]
    })

    saveAsCsv(
      rows.map((el) => el.join(';')).join('\n'),
      `${core.dictionary.getLabel('rmaNumber')}-${rmaOrder.salesId}`,
    )
  }

  const EmailField = (
    <>
      <TextField
        style={{ paddingTop: 6, marginLeft: 10, marginRight: 5, width: 250 }}
        size='small'
        autoFocus
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        variant='outlined'
        onClick={() => sendEmail()}>
        <TranslationLabel name='sendEmail'></TranslationLabel>
      </Button>
    </>
  )

  const renderEmailStatus = () => {
    switch (emailSentStatus) {
      case SENT:
        return (
          <>
            <CheckCircleOutlineOutlinedIcon
              style={{ color: 'green', verticalAlign: 'middle', margin: 5 }}
            />
            <span style={{ fontSize: '16px' }}>
              <TranslationLabel name='emailSent' />
            </span>
          </>
        )
      case SENDING:
        return (
          <CircularProgress
            style={{
              height: 20,
              width: 20,
              margin: 5,
              verticalAlign: 'middle',
            }}
          />
        )
      case ERROR:
        return (
          <>
            <ErrorOutlineIcon
              style={{
                height: 20,
                width: 20,
                margin: 5,
                verticalAlign: 'middle',
                color: 'red',
              }}
            />
            <span style={{ fontSize: '16px' }}>
              <TranslationLabel name='emailError' />
            </span>
          </>
        )
      case INITIAL:
      default:
        return null
    }
  }

  return (
    <div className={classes.root}>
      <div
        style={{
          margin: 'auto',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <BackButton
          path={props.isFairApp ? '/fair-app/orders' : '/sales/orders'}
        />
        <div>
          <ReactToPrint
            trigger={() => <PrintButton />}
            content={() => componentRef.current}
          />
          <Button onClick={saveCsv}>
            <TableChartIcon style={{ marginRight: '10px' }} />
            <TranslationLabel name='saveCsv' />
          </Button>
          {showEmailField ? (
            <>
              {EmailField} {renderEmailStatus()}
            </>
          ) : (
            <Button onClick={() => setShowEmailField(true)}>
              <MailOutlineIcon style={{ marginRight: '10px' }} />
              <TranslationLabel name='emailOrder' />
            </Button>
          )}
        </div>
      </div>
      {salesId.length > 0 ? isLoading ? <LinearProgress /> : null : null}
      <div
        style={{ backgroundColor: 'white' }}
        ref={componentRef}
        id='order'
      />
    </div>
  )
}

export default DetailedRMAOrderView
