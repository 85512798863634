import React, { useContext } from 'react'
import daLocale from 'date-fns/locale/da'
import enLocale from 'date-fns/locale/en-GB'
import deLocale from 'date-fns/locale/de'
import noLocale from 'date-fns/locale/nn'
import svLocale from 'date-fns/locale/sv'
import DateFnsUtils from '@date-io/date-fns'
import TranslationLabel from '../core/components/TranslationLabel'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { CoreContext } from '../core/context/CoreContext'

const locales = {
  'en-GB': enLocale,
  'da-DK': daLocale,
  'sv-SE': svLocale,
  'nb-NO': noLocale,
  'de-DE': deLocale,
}

const DatePicker = (props) => {
  const core = useContext(CoreContext)
  const locale = locales[core.user.languageCode]
  locale.options.weekStartsOn = 1

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={locale}>
      <KeyboardDatePicker
        format='dd/MM/yyyy'
        minDateMessage={<TranslationLabel name='minDateError' />}
        maxDateMessage={<TranslationLabel name='maxDateError' />}
        invalidDateMessage={<TranslationLabel name='invalidDateError' />}
        {...props}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DatePicker
