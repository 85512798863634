import React, { useContext, useEffect } from 'react'
import { CoreContext } from '../../../../../core/context/CoreContext'
import { ReturnServiceContext } from '../../../context/ReturnServiceContext'
import { useHistory } from 'react-router-dom'

import { Grid, makeStyles } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import TranslationLabel from '../../../../../core/components/TranslationLabel'
import Button from '@material-ui/core/Button'
import InriverImage from '../../../../../core/components/InriverImage'
import Typography from '@material-ui/core/Typography'

import { FieldItemRow } from '../../Products/DetailedItemTemplate'

const useStyles = makeStyles((theme) => ({
  btns: {
    display: 'flex',
    padding: theme.spacing(1),
    marginTop: 40,
  },
  btn: {
    width: 'auto',
    minWidth: 120,
    height: 40,
    marginRight: 10,
  },
  btnNext: {
    'color': 'white',
    'backgroundColor': 'black',
    'width': '100%',

    '&:hover': {
      backgroundColor: theme.palette.grey[800],
    },
  },
  returnLine: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: 10,
  },
  returnLineContainer: {
    padding: 5,
    borderBottom: '1px solid lightgrey',
  },
  imgWidth: {
    width: '72px',
  },
}))

function ReturnServiceConfirmation(props) {
  const history = useHistory()
  const core = useContext(CoreContext)
  const checkout = useContext(ReturnServiceContext)
  const classes = useStyles()

  const products = checkout?.basket?.entries

  const { goBack, advanceStep } = props
  const {
    pickupPallets,
    pickupPackages,
    pickupName,
    pickupStreet1,
    pickupStreet2,
    pickupZipCode,
    pickupCity,
    pickupCountry,
    pickupContactPerson,
    comment,
    requisitionNo,
  } = checkout.orderDetails

  function checkoutFlow() {
    checkout.submit(checkout.orderDetails, function (orderId) {
      checkout.resetOrderDetails()
      checkout.setIsInitialized(false)
      advanceStep(orderId)
    })
  }

  useEffect(() => {
    if (core.isInitialized) {
      if (!core.user.selectedCustomer.accountNo) {
        history.push('/sales')
      }
    }
  }, [])

  return (
    <Grid
      container
      direction='row'
      justify='center'>
      <Grid
        item
        xs={12}
        md={9}
        lg={6}>
        <h1>
          2. <TranslationLabel name='returnConfirmation' />
        </h1>
        <h2>
          <TranslationLabel name='informations' />
        </h2>
        <FieldItemRow
          expanded='true'
          label='returnPallets'
          value={pickupPallets}
        />
        <FieldItemRow
          expanded='true'
          label='returnPackages'
          value={pickupPackages}
        />

        <FieldItemRow
          expanded='true'
          label='returnname'
          value={pickupName}
        />
        <FieldItemRow
          expanded='true'
          label='returnStreetName1'
          value={pickupStreet1}
        />
        <FieldItemRow
          expanded='true'
          label='returnStreetName2'
          value={pickupStreet2}
        />
        <FieldItemRow
          expanded='true'
          label='returnZipCode'
          value={pickupZipCode}
        />
        <FieldItemRow
          expanded='true'
          label='returnCity'
          value={pickupCity}
        />
        <FieldItemRow
          expanded='true'
          label='returnCountry'
          value={pickupCountry}
        />

        <FieldItemRow
          expanded='true'
          label='returnAttention'
          value={pickupContactPerson}
        />
        <FieldItemRow
          expanded='true'
          label='requisitionNo'
          value={requisitionNo}
        />
        <FieldItemRow
          expanded='true'
          label='returnComment'
          value={comment}
        />

        <h2 style={{ marginTop: 30 }}>
          <TranslationLabel name='returnServiceBasket' />
        </h2>
        {products.map((product) => (
          <RmaConfirmationProductRow
            key={product.itemId}
            product={product}
          />
        ))}

        <div className={classes.btns}>
          <Button
            variant='contained'
            disableElevation
            className={classes.btn}
            onClick={goBack}>
            <ArrowBackIcon
              fontSize='small'
              style={{ marginRight: 5 }}
            />
            <TranslationLabel name='back' />
          </Button>
          <Button
            variant='contained'
            disableElevation
            className={`${classes.btn} ${classes.btnNext}`}
            onClick={checkoutFlow}>
            <TranslationLabel name='completeReturnOrder' />
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

const RmaConfirmationProductRow = ({ product }) => {
  const { itemId, itemName, quantity, returnReasonId, returnReasons, showImage } = product
  const returnReason = returnReasons.find(
    (el) => el.id === returnReasonId,
  )?.label
  const classes = useStyles()
  return (
    <div className={classes.returnLineContainer}>
      <InriverImage
        className={classes.returnLine + ' ' + classes.imgWidth}
        productNo={itemId}
        showImage={showImage}
      />
      <div
        style={{ width: 'calc(100% - 82px)', marginRight: 0 }}
        className={classes.returnLine}>
        <Typography
          className={classes.returnLine}
          style={{ minWidth: '60px' }}
          variant='subtitle1'>
          {itemId}
        </Typography>
        <Typography
          className={classes.returnLine}
          variant='subtitle1'>
          {itemName}
        </Typography>
        <Typography
          className={classes.returnLine}
          variant='subtitle1'>
          {returnReason}
        </Typography>
        <Typography
          className={classes.returnLine}
          variant='subtitle1'>
          {quantity} <TranslationLabel name='pieces' />
        </Typography>
      </div>
    </div>
  )
}

export default ReturnServiceConfirmation
