import VideoPage from '../pages/VideoPage'
import HelpPage from '../../../core/components/HelpPage'

const root = '/video-app'

const routes = [
  {
    id: 'videoPage',
    path: `${root}/`,
    component: VideoPage,
    exact: true,
  },
  {
    id: 'Help',
    path: `${root}/help`,
    component: HelpPage,
    exact: true,
  }
]

export default {
  getRoute: id => routes.find((i) => i.id === id),
  root: root,
  routes: routes
}
