import React, { useContext } from 'react'
import ResponsiveTable from '../../components/ResponsiveTable'
import EmptyState from '../../components/EmptyState'

import { LinearProgress, Tooltip } from '@material-ui/core'
import PageTitle from '../../components/PageTitle'
import SimpleSearchField from '../../components/SimpleSearchField'
import { OrderContext } from '../../context/OrderContext'
import Typography from '@material-ui/core/Typography'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import * as DateTransformer from '../../libraries/DateTransformer'
import OrderStatusLabel from './OrderStatusLabel'
import Chip from '@material-ui/core/Chip'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { CoreContext } from '../../../../core/context/CoreContext'

export default function OrdersPage(props) {
  const { isFairApp } = props
  const core = useContext(CoreContext)
  const orderModel = useContext(OrderContext)

  const { lookingInRMA, setLookingInRMA } = orderModel
  const ordersUrlPrefix = lookingInRMA ? 'rmaOrders/' : 'orders/'

  let headers
  let dataKeys
  if (isFairApp) {
    headers = ['orderNumber', 'orderDate', 'campaignId', 'orderStatus']
    dataKeys = ['salesId', 'orderDate', 'campaignId', 'orderStatus']
  } else if (lookingInRMA) {
    headers = [
      'orderNumber',
      'returnItemNum',
      'orderDate',
      'customerRef',
      'updatedAt',
      'orderStatus',
    ]
    dataKeys = [
      'salesId',
      'returnItemNum',
      'orderDate',
      'customerRef',
      'updatedAt',
      'orderStatus',
    ]
  } else {
    headers = [
      'orderNumber',
      'orderDate',
      'customerRef',
      'updatedAt',
      'orderStatus',
    ]
    dataKeys = [
      'salesId',
      'orderDate',
      'customerRef',
      'updatedAt',
      'orderStatus',
    ]
  }

  const getOrderNumber = (order) => {
    return (
      <>
        <Link
          to={`${isFairApp ? '/fair-app' : '/sales'}/${
            ordersUrlPrefix + order.salesId + '/false'
          }`}>
          <Tooltip title={'Open order'}>
            <Button color='primary'>{order.salesId}</Button>
          </Tooltip>
        </Link>
        {order.isRMA ? <Chip label='RMA' /> : null}
      </>
    )
  }

  const getValueFromKey = (key, order) => {
    switch (key) {
      case 'salesId':
        return getOrderNumber(order)
      case 'orderDate':
        return DateTransformer.formatDate(order.orderDate)
      case 'campaignId':
        return order.campaignId
      case 'orderStatus':
        return <OrderStatusLabel status={order.orderStatus} />
      case 'returnItemNum':
        return order.returnItemNum
      case 'updatedAt':
        return DateTransformer.formatDate(order.updatedAt)
      case 'customerRef':
        return order.customerRef
      default:
        return null
    }
  }

  function getRows() {
    if (orderModel.orders === null) return []

    return orderModel.orders.map((order) =>
      dataKeys.map((key) => getValueFromKey(key, order)),
    )
  }

  return (
    <div>
      <PageTitle
        label='orders'
        core={core}
      />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='subtitle1'>
          <TranslationLabel name='orderAccount' />
          {' ' + orderModel.customer}
        </Typography>

        <Typography variant='subtitle1'>
          {' ' + orderModel.ordersCount + ' '}
          <TranslationLabel name='orders' />
        </Typography>
      </div>

      <SimpleSearchField
        onSearch={orderModel.setSearchInput}
        isLoading={false}
      />

      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={lookingInRMA}
              onChange={() => setLookingInRMA(!lookingInRMA)}
              name='lookingInRMA'
              color='primary'
            />
          }
          label={<TranslationLabel name='displayRmaResults' />}
        />
      </FormGroup>

      {orderModel.isLoading ? (
        <LinearProgress />
      ) : (
        <div style={{ height: 5 }} />
      )}
      {!orderModel.isLoading && orderModel.orders.length === 0 ? (
        <EmptyState />
      ) : (
        <ResponsiveTable
          headers={headers}
          rows={getRows()}
        />
      )}
    </div>
  )
}
