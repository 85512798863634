import React from 'react'

import BasketProvider from '../../core/context/BasketContext'

import ProductsProvider from './context/ProductsContext'
import CheckoutProvider from './context/CheckoutContext'
import ReturnServiceProvider from './context/ReturnServiceContext'
import CategoryProvider from './context/CategoryContext'
import OrderProvider from './context/OrderContext'

import Navigation from './components/Navigation'
import Content from './components/Content'

export default function SalesAppRoot(props) {
  return (
    <ProductsProvider>
      <CheckoutProvider>
        <BasketProvider basketId={0}>
          <ReturnServiceProvider>
            <CategoryProvider>
              <OrderProvider>
                <Navigation />
                <Content match={props.match} />
              </OrderProvider>
            </CategoryProvider>
          </ReturnServiceProvider>
        </BasketProvider>
      </CheckoutProvider>
    </ProductsProvider>
  )
}
