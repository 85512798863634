import React, { useContext, useState } from 'react'

import ResponsiveTable from '../../components/ResponsiveTable'
import EmptyState from '../../components/EmptyState'
import PageTitle from '../../components/PageTitle'
import InvoiceProvider, { InvoiceContext } from '../../context/InvoiceContext'

import { LinearProgress, makeStyles, Tooltip } from '@material-ui/core'
import { CoreContext } from '../../../../core/context/CoreContext'
import * as DateTransformer from '../../libraries/DateTransformer'
import { formatCurrency } from '../../libraries/CurrencyTransformer'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import ShippingIcon from '@material-ui/icons/LocalShippingOutlined'
import { Link } from 'react-router-dom'
import InvoiceTracker from './InvoiceTracker'
import Button from '@material-ui/core/Button'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import Typography from '@material-ui/core/Typography'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Skeleton } from '@material-ui/lab'

const headers = [
  'invoiceNumber',
  'salesId',
  'invoiceDate',
  'dueDate',
  'invoiceAmount',
  'outstandingAmount',
  'trackAndTrace',
]
const dataKeys = [
  'invoiceNumber',
  'salesId',
  'invoiceDate',
  'dueDate',
  'invoiceAmount',
  'outstandingAmount',
  'trackAndTrace',
]

const useStyles = makeStyles((theme) => ({
  root: {},
  paginationBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1) + 'px 0 ' + theme.spacing(1) + 'px 0 ',
    borderBottom: '1px solid  #e0e0e0',
  },
  rightAligned: {
    width: '100%',
    maxWidth: '90px',
    textAlign: 'right',
    display: 'inline-block',
  },
  inputField: {
    marginTop: 15,
  },
  red: {
    color: 'red',
  },
  green: {
    color: 'green',
  },
}))

export default function InvoicesContextWrapper(props) {
  return (
    <InvoiceProvider>
      <InvoicesPage {...props} />
    </InvoiceProvider>
  )
}

function InvoicesPage() {
  const classes = useStyles()
  const core = useContext(CoreContext)
  const invoiceContext = useContext(InvoiceContext)
  const [open, setOpen] = React.useState(false)
  const [selectedSalesId, setSelectedSalesId] = React.useState()
  const [query, setQuery] = useState('')

  function handleClose() {
    setOpen(false)
  }

  function handleDisplayTracking(salesId) {
    setOpen(true)
    setSelectedSalesId(salesId)
  }

  function handleNavigateBefore() {
    invoiceContext.decrementPage()
  }

  function handleQueryChange(e) {
    setQuery(e.target.value)
  }

  const keypressHandler = (e) => {
    if (e.keyCode === 13) {
      //for the enter key
      invoiceContext.setSearchInput(query)
    }
  }

  function handleNavigateNext() {
    invoiceContext.incrementPage()
  }
  function generateSkeletonTable() {
    let arr = []
    for (let i = 0; i < 5; i++) {
      arr.push(generateSkeletonRow())
    }
    return arr
  }
  function generateSkeletonRow() {
    let n = 7 //Size from headers
    //let w = //(100 / n)+"%";
    let arr = []
    for (let i = 0; i < n; i++) {
      arr.push(
        <div style={{ padding: '16px', height: '69px', width: '100%' }}>
          <Skeleton variant='text' />
        </div>,
      )
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {arr}
      </div>
    )
  }

  function getRows() {
    let rs = []
    if (invoiceContext.invoices === null) return rs

    invoiceContext.invoices.map((r) =>
      rs.push(
        dataKeys.map((key) => {
          let obj = Object.assign({}, r)
          obj.invoiceDate = DateTransformer.formatDate(r.invoiceDate)
          obj.dueDate = DateTransformer.formatDate(r.dueDate)
          obj.invoiceAmount = (
            <span className={classes.rightAligned}>
              {formatCurrency(
                r.invoiceAmount,
                core.user.selectedCustomer.currency,
              )}
            </span>
          )
          obj.salesId = <span>{r.salesId}</span>
          obj.outstandingAmount = (
            <span
              className={`${classes.rightAligned} ${
                r.outstandingAmount ? classes.red : classes.green
              }`}>
              {formatCurrency(
                r.outstandingAmount,
                core.user.selectedCustomer.currency,
              )}
            </span>
          )
          obj.trackAndTrace = (
            <Tooltip title={'View track & trace info'}>
              <Button
                onClick={() => handleDisplayTracking(r.invoiceNumber + '')}>
                <ShippingIcon />
              </Button>
            </Tooltip>
          )
          obj.invoiceNumber = (
            <Link to={`/sales/invoices/${r.invoiceNumber}`}>
              <Tooltip title={'Open invoice'}>
                <Button color='primary'>{r.invoiceNumber}</Button>
              </Tooltip>
            </Link>
          )
          return obj[key]
        }),
      ),
    )
    return rs
  }

  return (
    <div className={classes.root}>
      <PageTitle
        label='invoices'
        core={core}
      />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='subtitle1'>
          <TranslationLabel name='invoiceAccount' />
          {' ' + invoiceContext.customer}
        </Typography>

        <Typography variant='subtitle1'>
          {' ' + invoiceContext.invoicesCount + ' '}
          <TranslationLabel name='invoices' />
        </Typography>
      </div>
      <TextField
        onKeyDown={keypressHandler}
        fullWidth
        label={<TranslationLabel name='searchInvoices' />}
        id='filled-margin-dense'
        type='search'
        variant='outlined'
        value={query}
        onChange={handleQueryChange}
        className={classes.inputField}
        InputProps={{
          startAdornment: <SearchIcon className={classes.searchIcon} />,
        }}
      />

      {invoiceContext.isLoading ? <LinearProgress /> : null}
      {!invoiceContext.isLoading && invoiceContext.invoices.length === 0 ? (
        <EmptyState />
      ) : (
        <>
          <ResponsiveTable
            headers={headers}
            rows={getRows()}
          />

          {invoiceContext.isLoading && invoiceContext.invoices.length === 0 ? (
            <Skeleton
              variant='text'
              height='10px'
            />
          ) : null}

          <div className={classes.paginationBox}>
            <Button
              color='primary'
              className={classes.button}
              startIcon={<NavigateBeforeIcon />}
              onClick={handleNavigateBefore}
              disabled={!invoiceContext.hasPrev()}
              aria-label='load previous page'>
              <TranslationLabel name='previous' />
            </Button>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant='subtitle2'>
                <TranslationLabel name='page' />
                {' ' +
                  invoiceContext.page +
                  ' / ' +
                  (invoiceContext.eofCount === Infinity
                    ? ''
                    : invoiceContext.eofCount)}
              </Typography>
            </div>
            <Button
              color='primary'
              className={classes.button}
              endIcon={<NavigateNextIcon />}
              onClick={handleNavigateNext}
              disabled={!invoiceContext.hasNext()}
              aria-label='load next page'>
              <TranslationLabel name='next' />
            </Button>
          </div>
        </>
      )}

      <InvoiceTracker
        open={open}
        onClose={handleClose}
        salesId={selectedSalesId}
      />
    </div>
  )
}
