import React, { useContext } from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import LoginPage from '../pages/Login'
import SignupPage from '../pages/Signup'
import { ProtectedRoute } from './ProtectedRoutes'

import { getRoutes } from '../settings/hubRoutes'
import { CoreContext } from '../context/CoreContext'
import NotFound from '../pages/NotFound'
import UserRegistrationPage from '../pages/UserRegistrationPage'
import UserPasswordResetPage from '../pages/UserPasswordResetPage'
import UserPasswordResetCompletionPage from '../pages/UserPasswordResetCompletionPage'

export default function HubRouter(props) {
  const core = useContext(CoreContext)
  const routes = getRoutes([...core.user.userSetting.apps])

  return (
    <Router history={core.history}>
      <Switch>
        <Route
          exact
          path='/login'
          render={() => <LoginPage />}
        />
        <Route
          exact
          path='/signup/:id/:language'
          component={SignupPage}
        />
        <Route
          exact
          path='/'
          render={() => <Redirect to='/login' />}
        />
        {routes.map((a) => (
          <ProtectedRoute
            path={a.path}
            key={a.id}
            isAuth={core.isAuthenticated}
            {...a}
          />
        ))}
        <Route
          path='/userRegistration/:userId/:token'
          component={UserRegistrationPage}
        />
        <Route
          path='/reset'
          component={UserPasswordResetPage}
        />
        <Route
          path='/userResetPassword/:userId/:token'
          component={UserPasswordResetCompletionPage}
        />
        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}
