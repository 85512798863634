import routeSettings from './routes'

const navItem = (id, index, labelKey) => {
  return {
    id: id,
    route: routeSettings.getRoute(id),
    index: index,
    labelKey: labelKey,
  }
}

const upperNavigation = [navItem('videoPage', 1, 'videoPage'), navItem('Help', 2, 'help')]

export default {
  upperNavigation: upperNavigation
}
