import React, { useEffect, useState } from 'react'
import { InputAdornment, makeStyles } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import TranslationLabel from '../../../../core/components/TranslationLabel'

import TextField from '@material-ui/core/TextField'
import ProductSearchBarFacets from './ProductSearchBarFacets'

const useStyles = makeStyles((theme) => ({
  searchBar: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minHeight: '54px',
    width: 'calc(100% + 32px)',
    zIndex: 1000,
    backgroundColor: '#EAEAEA',
    margin: '-8px 20px 0 -16px',
    padding: '5px 0',

    [theme.breakpoints.up('sm')]: {
      margin: '-17px 20px 0 -16px',
    },
  },
  textField: {
    width: 200,
    backgroundColor: 'white',
    borderRadius: 4,
    margin: '4px auto 4px 12px',
    '&::-webkit-search-decoration,::-webkit-search-cancel-button,::-webkit-search-results-button,::-webkit-search-results-decoration': {
      display: 'none'
    }
  },
}))

export default function ProductSearchBar(props) {
  const classes = useStyles()
  const {
    facets,
    query,
    selected,
    change,
    changeQuery,
    hideChosenFacetCategoryCounts,
  } = props
  const [value, setValue] = useState(query)

  useEffect(() => {
    setValue(query)
  }, [query])

  function handleOnChange(e) {
    setValue(e.target.value)
  }

  const keypressHandler = (e) => {
    if (e.keyCode === 13) {
      //for the enter key
      changeQuery(value)
    }
  }

  return (
    <>
      <div className={classes.searchBar}>
        <ProductSearchBarFacets
          hideChosenFacetCategoryCounts={hideChosenFacetCategoryCounts}
          facets={facets}
          selected={selected}
          change={change}
        />
        <TextField
          onKeyDown={keypressHandler}
          id='standard-search'
          label={<TranslationLabel name='searchProducts' />}
          type='search'
          className={classes.textField}
          variant='outlined'
          onChange={handleOnChange}
          value={value}
          size='small'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: <CloseIcon
              onClick={() => {
                setValue('')
                changeQuery('')
              }}
              className={classes.searchIcon} />
          }}
        />
      </div>
    </>
  )
}
