import React, { useState, useEffect, useContext } from 'react'
import DatePicker from '../../../../shared/DatePicker'
import TranslationLabel from '../../../../core/components/TranslationLabel'
import { CoreContext } from '../../../../core/context/CoreContext'
import { Typography } from '@material-ui/core'
import { AppointmentLine } from '../../components/appointmentList/AppointmentList'

const setHoursToZero = (date) => date.toJSON().split('T')[0] + 'T00:00:00'

export default function () {
  const core = useContext(CoreContext)
  const [from, setFrom] = useState(
    new Date(Date.now() - 1000 * 60 * 60 * 24 * 365),
  ) // 1 year ago
  const [to, setTo] = useState(new Date())
  const [appointments, setAppointments] = useState([])

  useEffect(() => {
    core.dataStore
      .getCustomerAppointmentsInDateRange(
        core.user.language,
        core.user.selectedCustomer.accountNo,
        setHoursToZero(from),
        setHoursToZero(new Date(to.getTime() + 3600 * 1000 * 24)),
      )
      .then((res) => setAppointments(res.data))
  }, [to, from, core.user.selectedCustomer.accountNo, core.user.language])

  return (
    <>
      <DatePicker
        label={<TranslationLabel name='appointmentsFrom' />}
        autoOk
        style={{ width: 190, margin: 10 }}
        inputVariant='outlined'
        onChange={setFrom}
        value={from}
      />
      <DatePicker
        label={<TranslationLabel name='appointmentsTo' />}
        autoOk
        style={{ width: 190, margin: 10 }}
        inputVariant='outlined'
        onChange={setTo}
        value={to}
      />
      <Typography
        variant='h6'
        style={{ marginLeft: 10, marginBottom: 5 }}>
        <TranslationLabel name='appointmentsInTheDateRange' />{' '}
        {appointments.length}
      </Typography>
      <div style={{ overflowY: 'scroll', height: 190 }}>
        {appointments.map((appointment) => (
          <AppointmentLine
            key={appointment.id}
            isHomePage
            {...appointment}
          />
        ))}
      </div>
    </>
  )
}
