//React imports
import React from 'react'

//Core imports
import CoreProvider from './core/context/CoreContext'

//Component imports
import Hub from './core/pages/Hub'
import Modal from './core/modals/Modals'

//Material UI components
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline'
import { SnackbarProvider } from 'notistack'

export function Root() {
  //remove the message in the html telling people to use a real browser if it can't run the javascript
  document.getElementById('internetExplorerMessage').innerHTML = ''

  return (
    <CoreProvider>
      <CssBaseline />
      <Hub key={1} />
      <Modal />
    </CoreProvider>
  )
}

export default function RootNotistackIntegration() {
  return (
    <SnackbarProvider
      preventDuplicate
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      autoHideDuration={3000}>
      <Root />
    </SnackbarProvider>
  )
}
