import React from 'react'
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import ListItem from '@material-ui/core/ListItem/ListItem'
import IconFinder from '../../../apps/sales-web-app/libraries/IconFinder'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: 45,
    marginLeft: 7,
  },
}))

export default function NavItem(props) {
  const classes = useStyles()

  return (
    <ListItem
      button
      key={props.index}
      onClick={props.action}
      selected={props.selected}>
      <ListItemIcon className={classes.icon}>
        {IconFinder.getIcon(props.id)}
      </ListItemIcon>
      <ListItemText primary={props.text} />
    </ListItem>
  )
}
