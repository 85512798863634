import React, { useState, useEffect, useContext } from 'react'
import { CoreContext } from '../../../context/CoreContext'
import TranslationLabel from '../../../components/TranslationLabel'
import FormSection from '../../../components/forms/FormSection'
import FormHeader from '../../../components/forms/FormHeader'
import { Box, FormControl, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ApiUrls from '../../../../core/data/ApiUrls'
import hubpic1 from '../../../../assets/images/hubpic1.jpg'
import hubpic2 from '../../../../assets/images/hubpic2.jpg'
import hubpic7 from '../../../../assets/images/hubpic7.jpg'
import hubpic9 from '../../../../assets/images/hubpic9.jpg'
import hubpic12 from '../../../../assets/images/hubpic12.jpg'

const images = {
  2: hubpic1,
  6: hubpic2,
  7: hubpic7,
  9: hubpic9,
  12: hubpic12,
}

const useStyles = makeStyles((theme) => ({
  actionLinks: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  resetMargin: {
    margin: '0',
  },
  formControl: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

export default function HubSettings(prop) {
  const core = useContext(CoreContext)
  const classes = useStyles()
  const [apps, setApps] = useState([])
  const [load, setLoad] = useState(false)

  useEffect(() => {
    core.dataStore.getApps().then((res) => {
      setApps(res.data.filter((app) => app.id !== 1))
    })
  }, [load])

  function removePreview(app) {
    setApps(
      apps.map((item) => {
        if (item.id == app.id) {
          item.previewImage = undefined
        }

        return item
      }),
    )
  }

  function removeImage(app) {
    let formData = new FormData()
    formData.append('file', [])

    core.dataStore.updateAppImage(app.id, formData).then(() => {
      setLoad(!load)
    })
  }

  function uploadImage(app) {
    let formData = new FormData()
    formData.append('file', app.previewImage)

    core.dataStore.updateAppImage(app.id, formData).then(() => {
      setLoad(!load)
    })
  }

  function showPreview(app, e) {
    setApps(
      apps.map((item) => {
        if (item.id == app.id) {
          item.previewImage = e.target.files[0]
        }

        return item
      }),
    )
  }

  function imageToPreview(app) {
    if (app.previewImage) {
      return (
        <Box>
          <img
            height={150}
            src={URL.createObjectURL(app.previewImage)}
          />
          <p>
            <small>
              <TranslationLabel name='hubSettingsPreviewImageInUse' />
              <span
                className={classes.actionLinks}
                onClick={(e) => removePreview(app)}>
                - <TranslationLabel name='hubSettingsDeletePreviewImageInUse' />
              </span>
            </small>
          </p>
        </Box>
      )
    }

    if (app.backgroundImage !== '' && app.backgroundImage != null) {
      return (
        <Box>
          <img
            height={150}
            src={ApiUrls.hubImageUrl + app.backgroundImage}
          />
          <p>
            <small>
              <TranslationLabel name='hubSettingsCurrentImageInUse' /> -
              <span
                className={classes.actionLinks}
                onClick={(e) => removeImage(app)}>
                <TranslationLabel name='hubSettingsDeleteCurrentImageInUse' />
              </span>
            </small>
          </p>
        </Box>
      )
    }

    return (
      <Box>
        <img
          height={150}
          src={images[app.id]}
        />
        <p className={classes.resetMargin}>
          <small>
            <TranslationLabel name='hubSettingsFallbackImageInUse' />
          </small>
        </p>
      </Box>
    )
  }
  return (
    <>
      {apps.map((app) => {
        return (
          <FormSection>
            <FormHeader title={core.dictionary.getLabel('app' + app.id)} />

            <FormControl
              variant='outlined'
              className={classes.formControl}>
              {imageToPreview(app)}

              {!app.previewImage ? (
                <Box>
                  <input
                    type='file'
                    accept='image/webp'
                    id={'appImage' + app.id}
                    style={{ display: 'none' }}
                    onChange={(e) => showPreview(app, e)}
                  />

                  <label
                    htmlFor={'appImage' + app.id}
                    style={{ display: 'block', textAlign: 'right' }}>
                    <Button
                      variant='contained'
                      color='default'
                      component='span'
                      style={{
                        marginBottom: '5px',
                      }}>
                      <TranslationLabel name='hubSettingsUpload' />
                    </Button>
                    <br />
                    <small>
                      <TranslationLabel name='hubSettingsUploadHelpText' />
                    </small>
                  </label>
                </Box>
              ) : (
                <Box>
                  <div style={{ textAlign: 'right' }}>
                    <Button
                      variant='contained'
                      color='primary'
                      component='span'
                      onClick={(e) => uploadImage(app)}
                      style={{
                        marginBottom: '5px',
                      }}>
                      <TranslationLabel name='hubSettingsGemUpload' />
                    </Button>
                    <br />
                    <small>
                      <TranslationLabel name='hubSettingsGemUploadHelpText' />
                    </small>
                  </div>
                </Box>
              )}
            </FormControl>
          </FormSection>
        )
      })}
    </>
  )
}
